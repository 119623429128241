/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { ReactComponent as SeatBufferSvg } from '../../../svgs/seats/buffer.svg';
import { ReactComponent as CompanionSvg } from '../../../svgs/seats/companion.svg';
import { ReactComponent as CoordinateSeatSvg } from '../../../svgs/seats/coordinateSeat.svg';
import { ReactComponent as EmergencyExitSvg } from '../../../svgs/seats/emergencyExit.svg';
import { ReactComponent as EntranceLeftSvg } from '../../../svgs/seats/entranceLeft.svg';
import { ReactComponent as SeatLuxurySvg } from '../../../svgs/seats/luxury.svg';
import { ReactComponent as ObstructedSvg } from '../../../svgs/seats/obstructed.svg';
import { ReactComponent as SeatSvg } from '../../../svgs/seats/seat.svg';
import { ReactComponent as SofaLeftSvg } from '../../../svgs/seats/sofaLeft.svg';
import { ReactComponent as SofaMiddleSvg } from '../../../svgs/seats/sofaMiddle.svg';
import { ReactComponent as SofaRightSvg } from '../../../svgs/seats/sofaRight.svg';
import { ReactComponent as StairsDown } from '../../../svgs/seats/stairsDown.svg';
import { ReactComponent as StairsUp } from '../../../svgs/seats/stairsUp.svg';
import { ReactComponent as WheelchairSvg } from '../../../svgs/seats/wheelchair.svg';

export const resolveSvg = (
  svgType: string,
  containerClassName: string,
  title: string | undefined,
  svgSource?: string
) => {
  const props = { title: title, className: containerClassName };
  if (svgType === 'Custom' && svgSource) {
    return <div {...props} dangerouslySetInnerHTML={{ __html: svgSource }} />;
  } else {
    return <div {...props}>{getNonCustomSvg(svgType)}</div>;
  }
};

const getNonCustomSvg = (svgType: string) => {
  switch (svgType) {
    case 'CompanionSvg':
      return <CompanionSvg />;
    case 'CoordinateSeatSvg':
      return <CoordinateSeatSvg />;
    case 'SeatBufferSvg':
      return <SeatBufferSvg />;
    case 'EmergencyExitSvg':
      return <EmergencyExitSvg />;
    case 'EntranceLeftSvg':
      return <EntranceLeftSvg />;
    case 'SeatLuxurySvg':
      return <SeatLuxurySvg />;
    case 'ObstructedSvg':
      return <ObstructedSvg />;
    case 'SeatSvg':
      return <SeatSvg />;
    case 'SofaLeftSvg':
      return <SofaLeftSvg />;
    case 'SofaMiddleSvg':
      return <SofaMiddleSvg />;
    case 'SofaRightSvg':
      return <SofaRightSvg />;
    case 'SofaLeftSvgSofaRightSvg':
      return (
        <>
          <SofaLeftSvg />
          <SofaRightSvg />
        </>
      );
    case 'StairsDown':
      return <StairsDown />;
    case 'StairsUp':
      return <StairsUp />;
    case 'WheelchairSvg':
      return <WheelchairSvg />;
    default:
      return null;
  }
};
