import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import ContextAwareQuantitySelector from './ContextAwareQuantitySelector';
import FixedCostGiftCardButton from './FixedCostGiftCardButton';
import VariableCostGiftCardButton from './VariableCostGiftCardButtonMulti';

import { ChangeGiftCardByIdContext } from '../../../../../@types/actionTypes';
import { Concession, Deal } from '../../../../../@types/modelTypes';
import { isVariableCostInvalid } from '../../../../../services/GiftCardHelpers';
import {
  getMatchingConcessionDealDetails,
  getMatchingConcessionDeals,
  handleConcessionQuantityChange,
} from '../../../../../services/Helpers';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectDeals,
  selectEGiftCardGroup,
  selectSelectedGiftCards,
  selectTotalGiftCardsSelected,
} from '../../../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../../../svgs/deal.svg';
import Heading from '../../../heading/Heading';
import GiftCardImage from '../../GiftCardImage';

interface GiftCardsRowProps {
  selectedConcessionIndex: number;
}

const MultiOptionGiftCardsRow: React.FC<GiftCardsRowProps> = ({
  selectedConcessionIndex,
}) => {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const selectedGiftCardCount = useSelector(selectTotalGiftCardsSelected);
  const deals = useSelector(selectDeals);
  const [imagePath, setImagePath] = useState<string | undefined>(undefined);
  const eGiftCardGroup = useSelector(selectEGiftCardGroup);

  const selectedGiftCard: Concession | undefined =
    selectedGiftCards.list.length > 0
      ? selectedGiftCards.list[selectedConcessionIndex]
      : undefined;

  const quantity: number =
    selectedGiftCard != null ? selectedGiftCard.quantity : 0;

  const shouldAddToCartAutomatically: boolean =
    quantity === 0 &&
    selectedGiftCardCount < config.giftCardsPurchase.maxQuantityOfGiftCards;

  const fixedPriceConcessions: Concession[] | undefined =
    eGiftCardGroup?.items.filter((i: Concession) => !i.isVariablePriceItem);
  const variableConcessions: Concession[] | undefined =
    eGiftCardGroup?.items.filter((i: Concession) => i.isVariablePriceItem);

  const handleVariableCostChange = (c: Concession) => {
    if (isVariableCostInvalid(c, c.cost)) {
      c.quantity = 0;
    } else if (shouldAddToCartAutomatically) {
      c.quantity = 1;
    } else {
      c.quantity = quantity;
    }

    dispatch(
      actionCreators.changeConcessionByIndex(c, selectedConcessionIndex, false)
    );
  };

  const handleConcessionSelect = (c: Concession) => {
    const isSameConcession = selectedGiftCard?.id === c.id;
    if (isSameConcession) {
      return;
    }
    if (c.isVariablePriceItem && isVariableCostInvalid(c, c.cost)) {
      c.quantity = 0;
    } else if (shouldAddToCartAutomatically) {
      c.quantity = 1;
    } else {
      c.quantity = quantity;
    }
    dispatch(
      actionCreators.changeConcessionByIndex(c, selectedConcessionIndex, false)
    );
    if (c.image) {
      setImagePath(c.image);
    }
  };

  const handleQuantityClick = (context: ChangeGiftCardByIdContext) => {
    handleConcessionQuantityChange(
      context,
      selectedGiftCard,
      selectedConcessionIndex,
      dispatch,
      false
    );
  };

  useEffect(() => {
    const calculateDiscount = () => {
      let amountSaved = 0;
      selectedGiftCards.list.forEach((c: Concession) => {
        const matchedConcessionDeal = getMatchingConcessionDealDetails(
          c.id,
          deals
        );
        if (matchedConcessionDeal?.amount) {
          const saving = c.cost - matchedConcessionDeal.amount;
          amountSaved += c.quantity * saving;
        }
      });
      return amountSaved;
    };
    if (selectedGiftCards.list.length) {
      const dealsDiscountTotal = calculateDiscount();
      dispatch(actionCreators.setTotalDiscount(dealsDiscountTotal));
    }
  }, [deals, dispatch, selectedGiftCards]);

  const quantityAddDisabled: boolean =
    (!!selectedGiftCard &&
      selectedGiftCard.isVariablePriceItem &&
      isVariableCostInvalid(selectedGiftCard, selectedGiftCard.cost)) ||
    selectedGiftCard === undefined ||
    selectedGiftCardCount >= config.giftCardsPurchase.maxQuantityOfGiftCards;

  const matchingDeals =
    getMatchingConcessionDeals(
      eGiftCardGroup?.items != undefined ? eGiftCardGroup.items : [],
      deals
    ) ?? [];

  return (
    <div className='giftcards-row py-3'>
      <Row className='d-flex align-items-center g-0'>
        <Col xs={'auto'} className='position-relative'>
          {matchingDeals.length > 0 && <DealSvg className='deal-icon' />}
          <GiftCardImage
            imageUrl={imagePath ?? content.giftCards.defaultImage}
          />
        </Col>
        <Col className='text-start px-2 d-flex flex-column'>
          {matchingDeals.length > 0 &&
            matchingDeals.map((deal: Deal, index: number) => (
              <div
                key={`deal_${index}`}
                className='mb-1 text-uppercase deal-text fw-bold'
              >
                {deal.description}
              </div>
            ))}
          <div className='d-md-none'>
            <Heading size={2}>{content.giftCards.giftCardOptionsTitle}</Heading>
            <div className='tiny mt-1'>
              {content.giftCards.giftCardOptionsDescription}
            </div>
          </div>
        </Col>
      </Row>
      <Row className='giftcard-options-container g-0 d-flex'>
        {fixedPriceConcessions?.map((c: Concession) => {
          const otherItems = selectedGiftCards.list.filter(
            (c: Concession, i: number) => i !== selectedConcessionIndex
          );
          const alreadySelected = otherItems.find(
            (x: Concession) => x.id === c.id
          );
          return (
            <Col className='mt-3' key={c.id}>
              <FixedCostGiftCardButton
                {...c}
                handleConcessionSelect={handleConcessionSelect}
                selectedGiftCard={selectedGiftCard}
                disabled={!!alreadySelected}
              />
            </Col>
          );
        })}
        {variableConcessions?.map((c: Concession) => (
          <Col className='mt-3' key={c.id}>
            <VariableCostGiftCardButton
              {...c}
              onChangeCostHandler={handleVariableCostChange}
              handleConcessionSelect={handleConcessionSelect}
              selectedConcessionIndex={selectedConcessionIndex}
              selectedGiftCard={selectedGiftCard}
            />
          </Col>
        ))}
      </Row>
      <Row>
        <Col className='giftcard-selection-container mt-3'>
          <ContextAwareQuantitySelector
            quantity={quantity}
            maxQuantity={config.giftCardsPurchase.maxQuantityOfGiftCards}
            handleQuantityClick={handleQuantityClick}
            addIsDisabled={quantityAddDisabled}
            selectQuantityText={content.giftCards.selectQuantity}
            optionButtonSelectedText={
              content.giftCards.optionButtonSelectedText
            }
            optionButtonUnselectedText={
              content.giftCards.optionButtonUnselectedText
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default MultiOptionGiftCardsRow;
