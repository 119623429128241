import React from 'react';

import { useSelector } from 'react-redux';

import { JOURNEY_TYPES } from '../../../constants';
import { handleRegisterClick } from '../../../services/LoyaltyHelpers';
import {
  selectCartSummary,
  selectConfig,
  selectJourneyTypeConfig,
  selectRegisterUrl,
} from '../../../store/Selectors';
import { content } from '../../../testMockData/mockContent';
import ActionButton from '../actionbutton/ActionButton';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

interface Props {
  showDivider?: boolean;
}

const Register: React.FC<Props> = ({ showDivider }) => {
  const config = useSelector(selectConfig);
  const registerUrl = useSelector(selectRegisterUrl);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const cartSummary = useSelector(selectCartSummary);

  if (!config?.signIn?.displayRegisterButton) return null;

  const getHeading = () => {
    if (journeyTypeConfig.type === JOURNEY_TYPES.VOUCHERS_ONLY) {
      return content.signIn.loyaltyOnlyVouchersRegisterHeading;
    } else if (cartSummary.isLoyaltyOnlyShowtime) {
      return content.signIn.loyaltyOnlyShowtimeRegisterHeading;
    } else {
      return content.signIn.registerHeading;
    }
  };

  const getRichText = () => {
    switch (journeyTypeConfig.type) {
      case JOURNEY_TYPES.LOYALTY_UPGRADE:
        return content.signIn.upgradeMemberRichText;
      case JOURNEY_TYPES.VOUCHERS_ONLY:
        return content.signIn.loyaltyOnlyVouchersRegisterRichText;
      default:
        return cartSummary.isLoyaltyOnlyShowtime
          ? content.signIn.loyaltyOnlyShowtimeRegisterRichText
          : content.signIn.registerRichText;
    }
  };

  return (
    <>
      {showDivider && (
        <ContainedRow classNameRow='mt-3' classNameCol='text-center'>
          <div className='separator' />
        </ContainedRow>
      )}
      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-2' size={2}>
          {getHeading()}
        </Heading>
        <RichText text={getRichText()} />
        <ActionButton
          onClick={() => handleRegisterClick(registerUrl)}
          showIcon
          contained
          variant='secondary'
          mx='mx-3'
        >
          {content.signIn.registerButtonText}
        </ActionButton>
      </ContainedRow>
    </>
  );
};

export default Register;
