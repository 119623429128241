import React from 'react';

import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { AppliedDeal } from '../../../@types/modelTypes';
import {
  selectAppliedDeals,
  selectBookingData,
  selectContent,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';

const HeaderDealsApplied: React.FC = () => {
  const appliedDeals = useSelector(selectAppliedDeals);
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  if (!content || !content.tickets || !appliedDeals || !appliedDeals.length) {
    return null;
  }

  return (
    <Row
      className={classnames(
        'banner py-1 text-uppercase b',
        bookingData?.isUserValidated && 'extra-margin'
      )}
      data-testid='header-deals_applied'
    >
      {appliedDeals.map((g: AppliedDeal) => {
        return (
          <Col className='py-1' xs={12} key={`header_deal_applied_${uuidv4()}`}>
            {g.title} - {content.tickets.dealAppliedText}
            <RadioSelectedSvg />
          </Col>
        );
      })}
    </Row>
  );
};

export default HeaderDealsApplied;
