/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ExtraMenuLoginOptions from './ExtraMenuLoginOptions';

import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import { ReactComponent as ExtraMenuSvg } from '../../../svgs/extraMenu.svg';
import MultiLanguage from '../multilanguage/MultiLanguage';

const ExtraMenu: React.FC = () => {
  const { pathname } = useLocation();

  const bookingData = useSelector(selectBookingData);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const isJourneyTypeValidForLogin = journeyTypeConfig.canLogin;

  const shouldShowLoginButton =
    bookingData &&
    !pathname.startsWith('/login/') &&
    (config.currentCinema?.allowSignIn || config.signIn.allowSignIn) &&
    isJourneyTypeValidForLogin;

  const shouldShowMultiLanguage =
    config?.availableLanguageCultures &&
    config.availableLanguageCultures.length > 1;

  if (!(shouldShowLoginButton || shouldShowMultiLanguage)) return null;

  return (
    <Dropdown
      align='end'
      autoClose={true}
      onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
      show={isDropdownOpen}
    >
      <Dropdown.Toggle
        id='extra-menu-button'
        data-testid='extra-menu-button'
        className='header-button extra-menu-button small d-flex align-items-center'
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        type='button'
        variant='link'
      >
        <span className='d-none d-sm-block'>{content.extraMenuButtonText}</span>
        <div className='header-button-icon extra-menu-icon'>
          <ExtraMenuSvg />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className='extra-menu-dropdown'
        sx={{
          backgroundColor: 'boxBackground',
          borderColor: 'boxBorderColor',
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
          color: 'mostReadableOnWebsiteBackground',
          '.dropdown-header': {
            backgroundColor: 'boxHeaderBackground',
            color: 'mostReadableOnWebsiteBackground',
          },
          '.dropdown-item-text': {
            color: 'mostReadableOnWebsiteBackground',
          },
          '.checkbox-button .selectable-button-svg': {
            '& .a': { fill: 'boxBackground' },
            '& .b': { fill: 'mostReadableOnWebsiteBackground' },
            '& .c': { fill: 'mostReadableOnWebsiteBackground' },
          },
        }}
      >
        {shouldShowLoginButton && (
          <>
            <Dropdown.Header>
              {bookingData.isUserValidated && bookingData.loyaltyEmailAddress
                ? bookingData.loyaltyEmailAddress
                : content.extraMenuLoginHeading}
            </Dropdown.Header>
            <Dropdown.ItemText>
              <ExtraMenuLoginOptions setIsDropdownOpen={setIsDropdownOpen} />
            </Dropdown.ItemText>
          </>
        )}
        {shouldShowMultiLanguage && (
          <>
            <Dropdown.Header>
              {content.extraMenuSelectLanguageHeading}
            </Dropdown.Header>
            <Dropdown.ItemText>
              <MultiLanguage setIsDropdownOpen={setIsDropdownOpen} />
            </Dropdown.ItemText>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ExtraMenu;
