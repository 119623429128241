import React from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import RecipientDetails from './RecipientDetails';

import { Customer } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectVoucherRecipient,
  selectContentVouchers,
} from '../../../store/Selectors';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import CustomerDetails from '../customerdetails/CustomerDetails';
import Heading from '../heading/Heading';
import RichText from '../richtext/RichText';

interface Props {
  isPageValidated: boolean;
}

const DeliveryDetails: React.FC<Props> = ({ isPageValidated }) => {
  const dispatch = useDispatch();

  const contentVouchers = useSelector(selectContentVouchers);
  const voucherRecipient = useSelector(selectVoucherRecipient);

  const handleCustomerChange = (useRecipient: boolean) => {
    let nextCustomerState: Customer | undefined;
    if (useRecipient) {
      nextCustomerState = {
        ...voucherRecipient,
      };
    } else {
      nextCustomerState = {
        name: '',
        nameIsValid: false,
        email: '',
        emailIsValid: false,
        confirmEmail: '',
        confirmEmailIsValid: false,
        telephone: '',
        telephoneIsValid: false,
        isValid: false,
      };
    }
    dispatch(actionCreators.setCustomerDetails(nextCustomerState));
  };

  const toggleBillingAsRecipient = () => {
    if (!voucherRecipient.useSameDetailsForBilling) {
      handleCustomerChange(true);
    } else {
      handleCustomerChange(false);
    }
    voucherRecipient.useSameDetailsForBilling =
      !voucherRecipient.useSameDetailsForBilling;
    dispatch(actionCreators.setVoucherRecipient(voucherRecipient));
  };

  const checked = voucherRecipient.useSameDetailsForBilling;

  if (!contentVouchers) {
    return null;
  }

  return (
    <Row className='delivery-details'>
      <Col xs={12} className='contained'>
        <Heading size={2} className='text-center mt-5'>
          {contentVouchers.recipientDetailsHeading}
        </Heading>
        <RichText
          className='mb-3'
          text={contentVouchers.recipientDetailsRichText}
        />
        <RecipientDetails
          isPageValidated={isPageValidated}
          billingAsRecipient
        />
        <Heading size={2} className='mt-5 text-center'>
          {contentVouchers.customerDetailsHeading}
        </Heading>
        <RichText text={contentVouchers.customerDetailsRichText} />

        <Row className='g-0 align-items-center'>
          <Col xs='auto'>
            <CheckBoxButton
              checked={checked}
              onClick={toggleBillingAsRecipient}
              smaller
            />
          </Col>
          <Col>
            <p className={classnames(checked && 'b')}>
              {contentVouchers.useSameEmailLabel}
            </p>
          </Col>
        </Row>

        {checked || (
          <>
            <p className='fw-bold mt-2'>
              {contentVouchers.useBillingEmailText}
            </p>
            <CustomerDetails isPageValidated={isPageValidated} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default DeliveryDetails;
