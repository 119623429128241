import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectUseBookingFeeStrategyInTickets,
} from '../../../store/Selectors';

interface Props {
  ticket: TicketTypeModel;
}

const TicketPriceWithBookingFeeTax: React.FC<Props> = ({ ticket }) => {
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const { useBookingFeeStrategy, perItemFee, perItemTax } = useSelector(
    selectUseBookingFeeStrategyInTickets
  );

  const { bookingFeeExclTax, bookingFeeTax } = useMemo(() => {
    let fee = 0;
    let tax = 0;
    if (useBookingFeeStrategy) {
      fee = perItemFee;
      tax = perItemTax;
    } else if (ticket.bookingFee) {
      fee = ticket.bookingFee - ticket.bookingFeeTax;
      tax = ticket.bookingFeeTax;
    }
    return { bookingFeeExclTax: fee, bookingFeeTax: tax };
  }, [perItemFee, perItemTax, ticket, useBookingFeeStrategy]);

  const ticketPriceMinusTax = ticket.price - ticket.tax;
  const totalTax = bookingFeeTax + ticket.tax;
  const ticketPriceIncludingFeeAndTaxes =
    ticketPriceMinusTax + bookingFeeExclTax + totalTax;

  const displayBookingFee = displayPrice(bookingFeeExclTax, currencyConfig);
  const displayTax = displayPrice(totalTax, currencyConfig);
  const displayPriceMinusTax = displayPrice(
    ticketPriceMinusTax,
    currencyConfig
  );
  const additionalPricingText = ` (${displayPriceMinusTax} + ${displayBookingFee} ${content.cartSummary.serviceChargeLabel} + ${displayTax} ${content.cartSummary.taxLabel})`;

  return (
    <>
      <b>{displayPrice(ticketPriceIncludingFeeAndTaxes, currencyConfig)}</b>
      <span>{additionalPricingText}</span>
    </>
  );
};

export default TicketPriceWithBookingFeeTax;
