import React from 'react';

import classnames from 'classnames';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../../../store/ActionCreators';
import { selectGrandTotalAfterDiscountsInCents } from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';

interface Props {
  variant: string;
}

const ActionButtonCartSummary: React.FC<Props> = ({ variant }) => {
  const dispatch = useDispatch();

  const grandTotalToUse = useSelector(selectGrandTotalAfterDiscountsInCents);

  return (
    <Col xs='auto' className='me-1'>
      <button
        data-testid='action-button'
        className={classnames('action-button cart-summary-button', variant)}
        onClick={(e) => {
          dispatch(actionCreators.setCartSummaryOpen(true));
          e.currentTarget.blur();
        }}
        type='button'
      >
        {grandTotalToUse ? <CartFull /> : <CartEmpty />}
      </button>
    </Col>
  );
};

export default ActionButtonCartSummary;
