/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../@types/modelTypes';
import {
  selectAppliedGiftCards,
  selectAppliedVouchers,
  selectBookingData,
  selectConfig,
  selectContent,
  selectDisplayPrice,
  selectJourneyTypeConfig,
  selectGrandTotalWithoutDonationInCents,
  selectGrandTotalAfterDiscountsInCents,
} from '../../../../store/Selectors';
import BorderedCollapse from '../../borderedcollapse/BorderedCollapse';
import ContainedRow from '../../layout/ContainedRow';
import GiftCardPayment from '../giftcardcomponents/GiftCardPayment';
import SplitLoyaltyRedemption from '../loyaltycomponents/SplitLoyaltyRedemption';
import CreditCardPaymentsContainer from '../paymentcomponents/cardpayments/CreditCardPaymentsContainer';
import VoucherPayment from '../vouchercomponents/VoucherPayment';

interface Props {
  creditCardType: string;
  showPaymentForm: boolean;
  showGiftCardForm: boolean;
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  setGiftCardFormVisibility?: (visibility: boolean) => void;
  setCreditCardType: (creditCardType: string) => void;
}

const SplitPaymentDesign: React.FC<Props> = ({
  creditCardType,
  showPaymentForm,
  showGiftCardForm,
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
  setCreditCardType,
}) => {
  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const appliedVouchers = useSelector(selectAppliedVouchers);

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const grandTotalWithoutDonation = useSelector(
    selectGrandTotalWithoutDonationInCents
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const [expandGiftCardForm, setExpandGiftCardForm] = useState(
    appliedGiftCards?.length > 0
  );
  const [expandVoucherForm, setExpandVoucherForm] = useState(
    appliedVouchers?.length > 0
  );
  const [expandRedemptionForm, setExpandRedemptionForm] = useState(
    bookingData.loyaltyCardBalance > 0
  );

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(
      state,
      Math.min(bookingData.loyaltyCardBalance, grandTotal)
    )
  );

  const shouldShowGiftCardWrapper =
    bookingData.isPaymentWithGiftCardEnabled &&
    showGiftCardForm &&
    grandTotalWithoutDonation > 0;

  const shouldShowVoucherWrapper =
    config.currentCinema.enableVoucherRedemption &&
    grandTotalWithoutDonation > 0;

  const showSplitLoyaltyRedemption =
    config.currentCinema.enableLoyaltyRedemption &&
    config.loyalty.allowSplitLoyaltyRedemption &&
    grandTotalWithoutDonation > 0 &&
    bookingData.isUserValidated &&
    !journeyTypeConfig.hidesLoyaltyRedemption;

  return (
    <div
      className='split-payment-wrapper text-start'
      data-testid='split-payment-design'
    >
      {showSplitLoyaltyRedemption && (
        <ContainedRow
          classNameRow='loyalty-redemption-wrapper mt-3'
          classNameCol='mb-3'
        >
          {bookingData.loyaltyCardBalance > 0 ? (
            <BorderedCollapse
              closeButtonText={content.payment.closeButtonText}
              heading={content.payment.loyaltyRedemptionHeading.replaceAll(
                '##LoyaltyCreditApplied##',
                priceToDisplay
              )}
              setShow={setExpandRedemptionForm}
              show={expandRedemptionForm}
            >
              <SplitLoyaltyRedemption
                isPageValidated={isPageValidated}
                handleValidatePage={handleValidatePage}
                setPaymentFormVisibility={setPaymentFormVisibility}
                setGiftCardFormVisibility={setGiftCardFormVisibility}
              />
            </BorderedCollapse>
          ) : (
            <div
              className='loyalty-redemption-disabled text-center text-uppercase fw-bold'
              sx={{
                backgroundColor: 'boxBackground',
                borderColor: 'boxBorderColor',
                color: 'mostReadableOnWebsiteBackground',
              }}
            >
              <h3>{content.payment.loyaltyRedemptionZeroBalanceHeading}</h3>
            </div>
          )}
        </ContainedRow>
      )}

      {shouldShowVoucherWrapper && (
        <ContainedRow classNameRow='giftcard-wrapper mt-3'>
          <BorderedCollapse
            closeButtonText={content.payment.voucherCloseButtonText}
            heading={content.payment.voucherRedemptionHeading}
            setShow={setExpandVoucherForm}
            show={expandVoucherForm}
          >
            <VoucherPayment
              setPaymentFormVisibility={setPaymentFormVisibility}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              isShown={expandVoucherForm}
            />
          </BorderedCollapse>
        </ContainedRow>
      )}
      {shouldShowGiftCardWrapper && (
        <ContainedRow classNameRow='giftcard-wrapper mt-3'>
          <BorderedCollapse
            closeButtonText={content.payment.closeButtonText}
            heading={content.payment.giftCardHeading}
            setShow={setExpandGiftCardForm}
            show={expandGiftCardForm}
          >
            <GiftCardPayment
              setPaymentFormVisibility={setPaymentFormVisibility}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              isShown={expandGiftCardForm}
            />
          </BorderedCollapse>
        </ContainedRow>
      )}
      {showPaymentForm && (
        <ContainedRow classNameRow='payment-details-wrapper mt-3'>
          <div className='payment-container bordered'>
            <CreditCardPaymentsContainer
              creditCardType={creditCardType}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              setCreditCardType={setCreditCardType}
            />
          </div>
        </ContainedRow>
      )}
    </div>
  );
};

export default SplitPaymentDesign;
