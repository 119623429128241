import {
  AnalyticsPurchaseProperties,
  GTagConfig,
  GoogleEcommerceItem,
} from '../@types/analyticsTypes';
import { Concession, TicketTypeModel } from '../@types/modelTypes';

export const defaultGtagConf: GTagConfig = {
  // https://support.google.com/analytics/answer/7201382?hl=en#zippy=%2Cglobal-site-tag-websites
  debug_mode: false,
  /**
   * Disable automatic sending of page views, instead let analytics.page() do this
   * https://developers.google.com/analytics/devguides/collection/gtagjs
   */
  send_page_view: false,
  // https://developers.google.com/analytics/devguides/collection/gtagjs/ip-anonymization
  anonymize_ip: false,
  /**
   * Disable All Advertising
   * https://developers.google.com/analytics/devguides/collection/ga4/display-features#disable_all_advertising_features
   */
  allow_google_signals: true,
  /**
   * Disable Advertising Personalization
   * https://developers.google.com/analytics/devguides/collection/ga4/display-features#disable_advertising_personalization
   */
  allow_ad_personalization_signals: true,
  /**
   * https://developers.google.com/analytics/devguides/collection/gtagjs/cookies-user-id#configure_cookie_field_settings
   */
  // cookie_domain: 'auto',
  // cookie_expires
  // cookie_prefix
  // cookie_update
  // cookie_flags
  /**
   * Cookie Flags
   * https://developers.google.com/analytics/devguides/collection/ga4/cookies-user-id#cookie_flags
   */
  cookie_flags: '',
};

export const defaultConfig = {
  gtagName: 'gtag',
  dataLayerName: 'ga4DataLayer',
  measurementIds: [],
  gtagConfig: defaultGtagConf,
};

/* Location of gtag script */
export const gtagScriptSource = 'https://www.googletagmanager.com/gtag/js';
// See https://developers.google.com/analytics/devguides/collection/ga4/reference/config

export const getPurchasePayload = ({
  confirmationData,
  ticketTypes,
  cartSummary,
  config,
  totalTax,
  bookingFee,
  trackingCategory,
  content,
  selectedConcessions,
  selectedGiftCards,
}: AnalyticsPurchaseProperties) => {
  const items: GoogleEcommerceItem[] = [];
  const addItem = (
    category: string,
    name: string,
    cost: number,
    quantity: number,
    id: string,
    affiliation: string
  ) => {
    if (quantity > 0) {
      items.push({
        item_category: category,
        item_name: name,
        price: parseFloat((cost / 100).toFixed(2)),
        quantity,
        item_id: id,
        affiliation,
      });
    }
  };

  switch (trackingCategory) {
    case 'Ticket':
      ticketTypes?.ticketTypeModels.forEach((ticket: TicketTypeModel) =>
        addItem(
          'Movie',
          cartSummary?.title,
          ticket.price,
          ticket.quantity,
          ticket.displayName,
          config?.currentCinema?.title
        )
      );
      selectedConcessions.list?.forEach((concession: Concession) => {
        addItem(
          'Concession',
          cartSummary?.title,
          concession.cost,
          concession.quantity,
          concession.description,
          config?.currentCinema?.title
        );
      });
      if (bookingFee)
        addItem(
          content.tickets.serviceChargeText,
          cartSummary?.title,
          bookingFee,
          1,
          content.tickets.serviceChargeText,
          config?.currentCinema?.title
        );
      break;
    case 'Kiosk':
      selectedConcessions.list?.forEach((concession: Concession) => {
        if (confirmationData?.codeUsedInBarcode) {
          addItem(
            'Concession',
            concession.description,
            concession.cost,
            concession.quantity,
            confirmationData.codeUsedInBarcode,
            config?.currentCinema?.title
          );
        }
      });
      break;
    case 'GiftCard':
      selectedGiftCards.list?.forEach((giftcard: Concession) => {
        addItem(
          'GiftCard',
          giftcard.description,
          giftcard.cost,
          giftcard.quantity,
          confirmationData?.codeUsedInBarcode ?? '',
          config?.currentCinema?.title
        );
      });
      totalTax = selectedGiftCards.list.reduce(
        (a, b) => a + (b.taxInCents || 0),
        0
      );
      break;
    default:
      selectedConcessions.list?.forEach((concession) => {
        addItem(
          trackingCategory ?? '',
          concession.description,
          concession.cost,
          concession.quantity,
          concession.description,
          config?.currentCinema?.title
        );
      });
      totalTax = selectedConcessions.list.reduce(
        (a, b) => a + (b.taxInCents || 0),
        0
      );
      break;
  }

  const transactionValue = confirmationData?.totalPrice
    ? parseFloat((confirmationData?.totalPrice / 100).toFixed(2))
    : null;

  return {
    category: 'purchase',
    action: 'addTransaction',
    transaction_id: confirmationData?.codeUsedInBarcode,
    value: transactionValue,
    tax: totalTax,
    currency: config.currency,
    items: items,
    transport: 'xhr',
  };
};
