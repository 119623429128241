import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isEmail, isLength } from 'validator';

import { GiftCardRecipient, Customer } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectVoucherRecipient,
  selectContentVouchers,
} from '../../../store/Selectors';

interface Props {
  isPageValidated: boolean;
  billingAsRecipient: boolean;
}

const RecipientDetails: React.FC<Props> = ({
  isPageValidated,
  billingAsRecipient,
}) => {
  const dispatch = useDispatch();

  const contentVouchers = useSelector(selectContentVouchers);
  const voucherRecipient = useSelector(selectVoucherRecipient);

  const [recipientState, setRecipientState] = useState<GiftCardRecipient>({
    name: voucherRecipient.name ?? '',
    nameIsValid: !!voucherRecipient.nameIsValid,
    nameIsValidated: false,
    email: voucherRecipient.email ?? '',
    emailIsValid: !!voucherRecipient.emailIsValid,
    emailIsValidated: false,
    confirmEmail: voucherRecipient.confirmEmail ?? '',
    confirmEmailIsValid: !!voucherRecipient.confirmEmailIsValid,
    confirmEmailIsValidated: false,
    message: voucherRecipient.message ?? '',
    messageIsValid: !!voucherRecipient.messageIsValid,
    messageIsValidated: false,
    isValid: false,
    useSameDetailsForBilling:
      voucherRecipient.useSameDetailsForBilling ?? false,
  });

  useEffect(() => {
    if (isPageValidated) {
      setRecipientState((prevState) => ({
        ...prevState,
        nameIsValidated: true,
        emailIsValidated: true,
        confirmEmailIsValidated: true,
        messageIsValidated: true,
      }));
    }
  }, [isPageValidated]);

  const handleRecipientChange = (nextRecipientState: GiftCardRecipient) => {
    setRecipientState(nextRecipientState);
    dispatch(actionCreators.setVoucherRecipient(nextRecipientState));
    if (billingAsRecipient) {
      const nextCustomerState: Customer = {
        ...nextRecipientState,
      };
      dispatch(actionCreators.setCustomerDetails(nextCustomerState));
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    const nameIsValid = isLength(name, { min: 1, max: 50 });
    const isValid =
      nameIsValid &&
      recipientState.confirmEmailIsValid &&
      recipientState.emailIsValid;

    handleRecipientChange({
      ...recipientState,
      name,
      nameIsValid,
      isValid,
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value.trim().toLowerCase();
    const emailIsValid = isEmail(email);
    const confirmEmailIsValid =
      recipientState.confirmEmail === email &&
      isEmail(recipientState.confirmEmail);
    const isValid =
      emailIsValid && confirmEmailIsValid && recipientState.nameIsValid;

    handleRecipientChange({
      ...recipientState,
      email,
      emailIsValid,
      confirmEmailIsValid,
      isValid,
    });
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmEmail = e.currentTarget.value.trim().toLowerCase();
    const confirmEmailIsValid =
      isEmail(confirmEmail) &&
      confirmEmail === recipientState.email &&
      confirmEmail !== '';
    const isValid =
      confirmEmailIsValid &&
      recipientState.nameIsValid &&
      recipientState.emailIsValid;

    handleRecipientChange({
      ...recipientState,
      confirmEmail,
      confirmEmailIsValid,
      isValid,
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const message = e.currentTarget.value;
    const messageIsValid = isLength(message, { min: 0, max: 1000 });
    const isValid =
      messageIsValid &&
      recipientState.emailIsValid &&
      recipientState.nameIsValid;
    handleRecipientChange({
      ...recipientState,
      message,
      messageIsValid,
      messageIsValidated: true,
      isValid,
    });
  };

  if (!contentVouchers) {
    return null;
  }

  return (
    <div className='recipient-details' data-testid='recipient-details'>
      <Form className='my-3 text-start' noValidate>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='fullName'>
            {contentVouchers.recipientNameLabel}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder={contentVouchers.recipientNamePlaceholder}
            onChange={handleNameChange}
            required
            maxLength={50}
            value={recipientState.name}
            isInvalid={
              recipientState.nameIsValidated && !recipientState.nameIsValid
            }
            isValid={
              recipientState.nameIsValidated && recipientState.nameIsValid
            }
            onBlur={() =>
              setRecipientState({ ...recipientState, nameIsValidated: true })
            }
            id='recipientFullName'
            name='recipientFullName'
          />
          <Form.Control.Feedback type='invalid'>
            {contentVouchers.recipientNameValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='email'>
            {contentVouchers.recipientEmailLabel}
          </Form.Label>
          <Form.Control
            type='email'
            placeholder={contentVouchers.recipientEmailPlaceholder}
            onChange={handleEmailChange}
            value={recipientState.email}
            required
            isInvalid={
              recipientState.emailIsValidated && !recipientState.emailIsValid
            }
            isValid={
              recipientState.emailIsValidated && recipientState.emailIsValid
            }
            onBlur={() =>
              setRecipientState({ ...recipientState, emailIsValidated: true })
            }
            id='recipientEmail'
            name='recipientEmail'
          />
          <Form.Control.Feedback type='invalid'>
            {contentVouchers.recipientEmailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='confirmEmail'>
            {contentVouchers.recipientConfirmEmailLabel}
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder={contentVouchers.recipientEmailPlaceholder}
            onChange={handleConfirmEmailChange}
            value={recipientState.confirmEmail}
            isInvalid={
              recipientState.confirmEmailIsValidated &&
              !recipientState.confirmEmailIsValid
            }
            isValid={
              recipientState.confirmEmailIsValidated &&
              recipientState.confirmEmailIsValid
            }
            onBlur={() =>
              setRecipientState({
                ...recipientState,
                confirmEmailIsValidated: true,
              })
            }
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
              e.preventDefault()
            }
            id='confirmRecipientEmail'
            name='confirmRecipientEmail'
          />
          <Form.Control.Feedback type='invalid'>
            {contentVouchers.recipientConfirmEmailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='fullName'>
            {contentVouchers.recipientMessageLabel}
          </Form.Label>
          <Form.Control
            as='textarea'
            rows={6}
            placeholder={contentVouchers.recipientMessagePlaceholder}
            onChange={handleMessageChange}
            maxLength={1000}
            value={recipientState.message}
            isInvalid={
              recipientState.messageIsValidated &&
              !recipientState.messageIsValid
            }
            isValid={
              recipientState.messageIsValidated && recipientState.messageIsValid
            }
            onBlur={() =>
              handleRecipientChange({
                ...recipientState,
                messageIsValidated: true,
              })
            }
            id='message'
            name='message'
          />
          <Form.Control.Feedback type='invalid'>
            {contentVouchers.recipientMessageValidationText}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </div>
  );
};

export default RecipientDetails;
