import React, { useState, useCallback, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { StartConcessionsJourneyRequestModel } from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import loadSentry from '../../../scripts/loadSentry';
import { getCustomer } from '../../../services/Helpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import { selectConfig, selectContent } from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import CinemaSelectorLoyaltyUpgrade from '../../common/loyalty/landingpage/CinemaSelectorLoyaltyUpgrade';
import LoyaltyLandingHeadings from '../../common/loyalty/landingpage/LoyaltyLandingHeadings';

const LoyaltyUpgradeLanding: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [searchParams] = useSearchParams();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const [cinemaId, setCinemaId] = useState(
    searchParams.get('cinemaId') ?? undefined
  );

  const journeyType = JOURNEY_TYPES.LOYALTY_UPGRADE;
  const circuitId = searchParams.get('circuitId');

  const startLoyaltyUpgradeJourney = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const selectedLanguageCulture =
      cookies.preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));

    const { requestData } = cookies;
    const sessionToken = requestData ?? searchParams.get('requestData');

    const data: StartConcessionsJourneyRequestModel = {
      selectedLanguageCulture,
      circuitId,
      requestData,
      journeyType: '',
    };
    if (cinemaId) {
      data.cinemaId = parseInt(cinemaId);
    }
    if (sessionToken) {
      dispatch(actionCreators.setRequestData(sessionToken));
      data.requestData = sessionToken;
    }

    const path = 'api/StartConcessions/';
    const response = await backend.post(path, data);
    let bookingData, config, content, customer, result, reservedSeats;
    if (response.ok) {
      result = response.content;
      bookingData = result.bookingData;
      reservedSeats = result.reservedSeats;
      config = result.circuit.config;
      content = result.circuit.content;
      customer = getCustomer(
        bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );

      delete config.cinemas;
      delete content.cinemas;

      const isUserValidated = bookingData?.isUserValidated;

      const step = isUserValidated ? 1 : 0;
      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        currency: 'USD',
        cartSummary: result.cartSummaryModel,
        availablePosTickets: result.selectTicketsModel,
        ticketTypes: { ticketTypeModels: [] },
        donation: 0,
        bookingFee: 0,
        seatsModel: result.selectSeatsModel,
        error: { show: false, message: '' },
        selectedSeats: reservedSeats,
        giftCard: null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: isUserValidated,
        journeyType: journeyType,
        customer: customer,
        source: source,
        device: device,
        step: step,
        kioskSubStep: 'fab',
        loyaltyRecognitionNumber: null,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        selectedDeliveryWindow: null,
        selectedLanguageCulture: content.culture,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        turnstileConfig: result.turnstileConfig,
        recaptchaConfig: result.recaptchaConfig,
      };

      dispatch(actionCreators.initializeSession(session));
      const journeyTypeConfig = journeyTypeConfigs[journeyType];
      const route = getRouteFromStep(journeyTypeConfig, step);
      navigate(`/${route}`);
    } else {
      result = response.errorContent;
      config = result.circuit.config;
      content = result.circuit.content;

      delete config.cinemas;
      delete content.cinemas;

      const session = {
        loading: true,
        config: config,
        content: content,
        error: {
          show: true,
          message: getContentForError(response.errorContent.peachCode, content),
        },
        step: -1,
      };
      dispatch(actionCreators.initializeSession(session));
    }
    dispatch(actionCreators.setLoading(false));
  }, [
    cinemaId,
    circuitId,
    cookies,
    dispatch,
    journeyType,
    navigate,
    searchParams,
  ]);

  useEffect(() => {
    const setConfigAndContent = async () => {
      dispatch(actionCreators.clearSession());
      dispatch(actionCreators.setLoading(true));
      const { requestData } = cookies;

      const response = await backend.post('api/Config', {
        selectedLanguageCulture:
          cookies.preferredLanguageCulture ?? searchParams.get('culture'),
        circuitId: circuitId ? parseInt(circuitId) : 0,
        requestData,
      });
      if (response.ok) {
        const result = response.content;
        const config = result.config;
        const content = result.content;
        dispatch(actionCreators.setCircuitConfig(config));
        dispatch(actionCreators.setCircuitContent(content));
        if (requestData) {
          dispatch(actionCreators.setRequestData(requestData));
        }
        if (config.sentryDsnKey) {
          loadSentry(config.sentryDsnKey);
        }
      }

      dispatch(actionCreators.setLoading(false));
    };
    const cinemaIdFromQuery = searchParams.get('cinemaId');
    if (cinemaIdFromQuery) {
      startLoyaltyUpgradeJourney();
    } else if (
      (!config?.cinemas && config?.loyalty?.allowCinemaSelection) ||
      !config
    ) {
      setConfigAndContent();
    }
  }, [
    circuitId,
    cookies,
    dispatch,
    config,
    searchParams,
    startLoyaltyUpgradeJourney,
  ]);

  useEffect(() => {
    if (config && !config?.loyalty.allowCinemaSelection) {
      startLoyaltyUpgradeJourney();
    }
  }, [config, startLoyaltyUpgradeJourney]);

  const handleCinemaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCinemaId(e.currentTarget.value);
  };

  if (!config || !content) return null;

  return (
    <div className='concessions-landing'>
      <LoyaltyLandingHeadings />

      <CinemaSelectorLoyaltyUpgrade
        handleCinemaChange={handleCinemaChange}
        cinemaId={cinemaId}
      />
      <ContainedRow classNameCol='text-start'>
        <ActionButton
          onClick={startLoyaltyUpgradeJourney}
          disabled={false}
          stickyMobileDesktop={false}
          showIcon
          contained
          mx='mx-3'
          variant='primary'
        >
          {content.loyalty?.landingContinueButtonText}
        </ActionButton>
      </ContainedRow>
    </div>
  );
};

export default LoyaltyUpgradeLanding;
