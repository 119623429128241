import React from 'react';

import ContainedRow from '../../layout/ContainedRow';

const RoktWidget = () => {
  return (
    <ContainedRow>
      <div className='mt-4 text-center'>
        <div id='rokt-above-payments'></div>
      </div>
    </ContainedRow>
  );
};

export default RoktWidget;
