import React from 'react';

import classnames from 'classnames';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { DeliveryWindow } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectDeliveryWindows,
  selectDisplayCommentsBoxForDevliveryWindows,
  selectSelectedDeliveryWindow,
} from '../../../store/Selectors';
import { ReactComponent as RadioDotSelectedSvg } from '../../../svgs/radioDotSelected.svg';
import { ReactComponent as RadioDotUnselectedSvg } from '../../../svgs/radioDotUnselected.svg';
import ActionButton from '../actionbutton/ActionButton';

interface Props {
  continueButtonClick: () => Promise<void>;
  continueButtonText: string;
}

const DeliveryWindowsList: React.FC<Props> = ({
  continueButtonClick,
  continueButtonText,
}) => {
  const dispatch = useDispatch();
  const deliveryWindows = useSelector(selectDeliveryWindows);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const selectedFoodAndBeverageDeliveryWindowsDisplayCommentsBox = useSelector(
    selectDisplayCommentsBoxForDevliveryWindows
  );
  const content = useSelector(selectContent);

  const hasDeliveryWindows = deliveryWindows && deliveryWindows.length > 0;

  const updateDeliveryWindow = (deliveryWindow: DeliveryWindow) => {
    dispatch(actionCreators.setSelectedDeliveryWindow(deliveryWindow));
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const maxLength = e.target.maxLength;
    const commentTrimmed = newValue.slice(0, maxLength);
    updateDeliveryWindow({
      comment: commentTrimmed,
      deliveryWindowDescription: `${
        selectedDeliveryWindow?.deliveryWindowDescription
          ? selectedDeliveryWindow.deliveryWindowDescription
          : ''
      }`,
      deliveryWindowValue: `${
        selectedDeliveryWindow?.deliveryWindowValue
          ? selectedDeliveryWindow.deliveryWindowValue
          : ''
      }`,
    });
  };

  return (
    <div data-testid='delivery-windows-list'>
      {hasDeliveryWindows && (
        <div className='delivery-options-list mt-2'>
          {deliveryWindows.map((deliveryWindow, i) => (
            <div
              key={`delivery_window_${i}`}
              className={classnames(
                'delivery-option px-1 py-2 d-flex align-items-center justify-content-between',
                deliveryWindow.deliveryWindowDescription ===
                  selectedDeliveryWindow?.deliveryWindowDescription &&
                  'selected'
              )}
            >
              <Button
                className='d-flex align-items-center'
                onClick={() =>
                  updateDeliveryWindow({
                    ...deliveryWindow,
                    comment: `${
                      selectedDeliveryWindow?.comment
                        ? selectedDeliveryWindow.comment
                        : ''
                    }`,
                  })
                }
                variant='link'
              >
                <span className='delivery-option-radio'>
                  {deliveryWindow.deliveryWindowDescription ===
                  selectedDeliveryWindow?.deliveryWindowDescription ? (
                    <RadioDotSelectedSvg className='sel' />
                  ) : (
                    <RadioDotUnselectedSvg className='unsel' />
                  )}
                </span>
                {deliveryWindow.deliveryWindowDescription}
              </Button>
              <div>{deliveryWindow.deliveryWindowValue}</div>
            </div>
          ))}
        </div>
      )}
      {selectedFoodAndBeverageDeliveryWindowsDisplayCommentsBox && (
        <div
          className={classnames(
            'form-group delivery-comment mb-1',
            !hasDeliveryWindows && 'mt-2'
          )}
        >
          <label className='form-label' htmlFor='deliveryComment'>
            {content.concessionsOnlyJourney.addCommentLabel}
          </label>
          <input
            data-testid='delivery-comment'
            id='deliveryComment'
            maxLength={255}
            onChange={(e) => handleCommentChange(e)}
            placeholder={content.concessionsOnlyJourney.addCommentPlaceholder}
            type='text'
            value={
              selectedDeliveryWindow?.comment
                ? selectedDeliveryWindow.comment
                : ''
            }
          />
        </div>
      )}

      <ActionButton
        disabled={!selectedDeliveryWindow}
        onClick={continueButtonClick}
        showIcon
        contained
        mt='mt-1'
        mx='mx-3'
        variant='primary'
      >
        {continueButtonText}
      </ActionButton>
    </div>
  );
};

export default DeliveryWindowsList;
