import React, { PropsWithChildren } from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
  size: number;
}

const Heading: React.FC<PropsWithChildren<Props>> = ({
  children,
  className,
  onClick,
  size,
}) => {
  if (!children) return null;

  return React.createElement(
    `h${size}`,
    {
      className: className,
      'data-testid': 'heading',
      onClick: onClick,
    },
    children
  );
};

export default Heading;
