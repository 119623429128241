import React from 'react';

import { useSelector } from 'react-redux';

import NewsletterCheckbox from './NewsletterCheckbox';
import ThirdPartyCheckbox from './ThirdPartyCheckbox';

import { selectConfig, selectContent } from '../../../../store/Selectors';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

interface Props {
  onEdit?: () => void;
}

const GuestMarketing: React.FC<Props> = ({ onEdit }) => {
  const configPayment = useSelector(selectConfig).payment;
  const contentPayment = useSelector(selectContent).payment;

  return (
    <ContainedRow classNameRow='guest-marketing-wrapper'>
      <Heading className='mt-4 text-center' size={2}>
        {contentPayment.guestMarketingSubtitle}
      </Heading>
      <RichText
        className='text-start'
        text={contentPayment.guestMarketingRichText}
      />
      {configPayment.showNewsletterCheckbox && (
        <NewsletterCheckbox onEdit={onEdit} />
      )}
      {configPayment.showThirdPartyCheckbox && (
        <ThirdPartyCheckbox onEdit={onEdit} />
      )}
    </ContainedRow>
  );
};

export default GuestMarketing;
