import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import PaymentIconsDesignContainer from './paymenticons/PaymentIconsDesignContainer';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { selectContent } from '../../../../../store/Selectors';
import { ReactComponent as AmazonPaySVG } from '../../../../../svgs/payment/amazonPayLogo.svg';

const hostname = window.origin;
const isTest =
  hostname.includes('localhost:') || hostname.includes('dti-dev-client');

interface Props {
  paymentProvider: PaymentProvidersEnum;
  creditCardType: string;
  hasOnePaymentOption: boolean;
}

const CreditCardPaymentsHeadingContent: React.FC<Props> = ({
  paymentProvider,
  creditCardType,
  hasOnePaymentOption,
}) => {
  const content = useSelector(selectContent);

  const getCardIcons = () => {
    return paymentProvider === PaymentProvidersEnum.AMAZONPAY ? (
      <AmazonPaySVG className='amazon-pay-icon d-flex align-items-center' />
    ) : (
      <div className='payment-icons-container d-flex align-items-center flex-shrink-1'>
        <PaymentIconsDesignContainer
          paymentDesignName='split'
          creditCardType={creditCardType}
        />
      </div>
    );
  };

  const getPaymentNameText = () => {
    const paymentName = isTest
      ? PaymentProvidersEnum[paymentProvider]?.slice(0, 12)
      : content.payment.multiplePaymentProvidersCardLabel;
    return paymentProvider === PaymentProvidersEnum.AMAZONPAY
      ? 'Amazon Pay'
      : paymentName;
  };
  return (
    <>
      {!hasOnePaymentOption && (
        <div
          className={classnames(
            'me-1 fw-bold text-uppercase flex-grow-1 text-start',
            isTest && 'small'
          )}
        >
          {getPaymentNameText()}
        </div>
      )}
      {getCardIcons()}
    </>
  );
};

export default CreditCardPaymentsHeadingContent;
