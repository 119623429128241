/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import classnames from 'classnames';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectJourneyTypeConfig,
  selectTandCsAccepted,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import RichText from '../richtext/RichText';

interface Props {
  isSignUp?: boolean;
  isPageValidated: boolean;
  onEdit?: () => void;
}

const TermsAndConditions: React.FC<Props> = ({
  isSignUp,
  isPageValidated,
  onEdit,
}) => {
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const isTermsAndConditionsChecked = useSelector(
    (state: GlobalState) => state.termsAndConditionsChecked
  );

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  if (!config || !content) return null;

  const isFormValid = isPageValidated && !isTandCsAccepted;

  const getTermsAndConditionsText = () => {
    if (isSignUp) {
      return content.signIn.signUpTermsAndConditionsRichText;
    }

    switch (journeyTypeConfig.type) {
      case JOURNEY_TYPES.PHYSICAL_GIFTCARDS_ONLY:
      case JOURNEY_TYPES.GIFTMEMBERSHIP_ONLY:
      case JOURNEY_TYPES.GIFTCARDS_ONLY:
      case JOURNEY_TYPES.VOUCHERS_ONLY:
        return content.giftCards.paymentTermsAndConditionsRichText;
      default:
        return content.payment.termsConditionsRichText;
    }
  };

  return (
    <Row
      className={classnames(
        'g-0 terms-and-conditions my-1',
        isFormValid && 'invalid',
        config.payment.showTermsAndConditionsCheckbox && 'p-1'
      )}
      sx={{
        svg: {
          '.a': {
            fill: 'websiteBackground',
          },

          '.b, .c': {
            fill: 'primary',
          },
        },

        '&.invalid': {
          borderColor: 'uiError',
          boxShadow: 'errorShadow',
        },
      }}
    >
      {config.payment.showTermsAndConditionsCheckbox && (
        <Col xs='auto' className='d-flex align-items-center'>
          <CheckBoxButton
            checked={isTermsAndConditionsChecked}
            onClick={() => {
              if (isPageValidated && !!onEdit) {
                onEdit();
              }
              dispatch(
                actionCreators.setTermsAndConditionsChecked(
                  !isTermsAndConditionsChecked
                )
              );
            }}
          />
        </Col>
      )}
      <Col className='d-flex align-items-center'>
        <p className='m-0' data-testid='terms-conditions'>
          {isSignUp
            ? content.signIn.signUpTermsAndConditionsCheckboxText
            : content.payment.termsConditionsText}{' '}
          <Button
            variant='link'
            className='a'
            onClick={toggleModal}
            data-testid='terms-conditions-btn'
          >
            {content.payment.termsConditionsLinkText}
          </Button>
        </p>
        <Modal
          className='layout-modal'
          show={showModal}
          onHide={toggleModal}
          centered
          backdrop='static'
          keyboard={false}
          data-testid='terms-conditions-modal'
        >
          <Modal.Header>
            <Modal.Title>{content.payment.termsConditionsHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <RichText text={getTermsAndConditionsText()} />
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              onClick={toggleModal}
              mb='mb-0'
              mt='mt-0'
              modal
              variant='primary'
            >
              {content.payment.closeButtonText}
            </ActionButton>
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
  );
};

export default TermsAndConditions;
