import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GiftCard } from '../../../../@types/modelTypes';
import {
  displayGiftCardAmountChargedInCents,
  getGiftCardsWithAmounts,
} from '../../../../services/GiftCardHelpers';
import { displayPrice } from '../../../../services/Helpers';
import { getGrandTotalDiscountFromVouchers } from '../../../../services/VoucherHelpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectAppliedVouchers,
  selectContent,
  selectCurrencyConfig,
  selectGrandTotalWithoutDonationAndRewards,
} from '../../../../store/Selectors';

interface Props {
  pricingStrategy: 'journey' | 'confirmation';
  variant: 'cartSummary' | 'confirmation' | 'paymentForm';
}

const GiftCardBreakDown: React.FC<Props> = ({ pricingStrategy, variant }) => {
  const dispatch = useDispatch();
  const currencyConfig = useSelector(selectCurrencyConfig);
  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const appliedVouchers = useSelector(selectAppliedVouchers);
  const content = useSelector(selectContent);
  const grandTotalWithoutDonationAndRewards = useSelector(
    selectGrandTotalWithoutDonationAndRewards
  );

  const getGiftCardNumberForDisplay = (
    giftCardNumber: GiftCard['giftCardNumber']
  ) => {
    const giftCardNumberMatch = giftCardNumber.match(/.{1,4}/g);
    return giftCardNumberMatch ? giftCardNumberMatch.join(' ') : giftCardNumber;
  };

  const handleRemoveGiftCard = (giftCard: GiftCard) => {
    dispatch(actionCreators.removeGiftCard(giftCard));
  };

  const grandTotal =
    variant === 'cartSummary'
      ? grandTotalWithoutDonationAndRewards -
        getGrandTotalDiscountFromVouchers(appliedVouchers)
      : grandTotalWithoutDonationAndRewards;

  const giftCardsWithAmounts = getGiftCardsWithAmounts(
    appliedGiftCards,
    grandTotalWithoutDonationAndRewards
  );

  const giftCardsToBeCharged = giftCardsWithAmounts?.filter(
    (giftCard) => giftCard.giftCardAmount > 0
  );

  const cartSummaryGiftCards =
    pricingStrategy === 'confirmation'
      ? giftCardsToBeCharged
      : appliedGiftCards;

  const getGiftCardRemainingBalance = (giftCardIndex: number) => {
    return (
      giftCardsToBeCharged.slice(0).reverse()[giftCardIndex]
        .giftCardBalanceInCents -
      giftCardsToBeCharged.slice(0).reverse()[giftCardIndex].giftCardAmount
    );
  };

  if (!appliedGiftCards?.length) return null;

  return (
    <>
      {variant === 'cartSummary' ? (
        <div
          className='breakdown'
          data-testid='gift-card-break-down-cart-summary'
        >
          {cartSummaryGiftCards
            .slice(0)
            .reverse()
            .map((giftCard, giftCardIndex) => {
              return (
                <Row
                  className='g-0'
                  key={`cart-summary-${giftCard.giftCardNumber}`}
                >
                  <Col xs={8} className='d-flex align-items-center'>
                    <span>{content.payment.giftCardAppliedLabel}</span>
                  </Col>
                  {pricingStrategy === 'journey' && (
                    <Col xs={2} className='d-flex align-items-center'>
                      <Button
                        onClick={() => handleRemoveGiftCard(giftCard)}
                        variant='link'
                        size='sm'
                        className='text-uppercase small'
                      >
                        {content.payment.removeButtonText}
                      </Button>
                    </Col>
                  )}
                  <Col
                    xs={pricingStrategy === 'confirmation' ? 4 : 2}
                    className='d-flex align-items-center justify-content-end'
                  >
                    {displayGiftCardAmountChargedInCents(
                      pricingStrategy === 'confirmation'
                        ? giftCardsToBeCharged
                        : appliedGiftCards,
                      giftCardIndex,
                      grandTotal,
                      currencyConfig
                    )}
                  </Col>
                </Row>
              );
            })}
        </div>
      ) : variant === 'confirmation' ? (
        <div
          className='breakdown mt-3'
          data-testid='gift-card-break-down-confirmation'
        >
          {giftCardsToBeCharged
            .slice(0)
            .reverse()
            .map((giftCard, giftCardIndex) => {
              return (
                <Row className='g-0 py-2' key={`${giftCard.giftCardNumber}`}>
                  <Col xs={9} className='d-flex align-items-center text-start'>
                    <span>
                      <b>{content.payment.giftCardAppliedLabel}</b>
                      {' ...'}
                      {getGiftCardNumberForDisplay(
                        giftCard.giftCardNumber.slice(-4)
                      )}
                    </span>
                  </Col>
                  <Col
                    xs={3}
                    className='d-flex align-items-center justify-content-end'
                  >
                    <b>
                      {displayGiftCardAmountChargedInCents(
                        giftCardsToBeCharged,
                        giftCardIndex,
                        grandTotal,
                        currencyConfig
                      )}
                    </b>
                  </Col>
                  <Col xs={9} className='d-flex align-items-center text-start'>
                    <p>{content.payment.giftCardRemainingBalanceLabel}</p>
                  </Col>
                  <Col
                    xs={3}
                    className='d-flex align-items-center justify-content-end'
                  >
                    <p>
                      {displayPrice(
                        getGiftCardRemainingBalance(giftCardIndex),
                        currencyConfig
                      )}
                    </p>
                  </Col>
                </Row>
              );
            })}
        </div>
      ) : (
        <div className='breakdown mt-3' data-testid='gift-card-break-down'>
          {appliedGiftCards
            .slice(0)
            .reverse()
            .map((giftCard, giftCardIndex) => {
              return (
                <Row className='g-0 py-2' key={`${giftCard.giftCardNumber}`}>
                  <Col xs={9} className='d-flex align-items-center text-start'>
                    <span>
                      <b>{content.payment.giftCardAppliedLabel}</b>
                      <p className='mt-0'>
                        {getGiftCardNumberForDisplay(giftCard.giftCardNumber)}
                      </p>
                    </span>
                  </Col>
                  <Col xs={1} className='d-flex align-items-center'>
                    <Button
                      onClick={() => handleRemoveGiftCard(giftCard)}
                      variant='link'
                      size='sm'
                      className='text-uppercase'
                    >
                      {content.payment.removeButtonText}
                    </Button>
                  </Col>
                  <Col
                    xs={2}
                    className='d-flex align-items-center justify-content-end'
                  >
                    <b>
                      {displayGiftCardAmountChargedInCents(
                        appliedGiftCards,
                        giftCardIndex,
                        grandTotal,
                        currencyConfig
                      )}
                    </b>
                  </Col>
                </Row>
              );
            })}
        </div>
      )}
    </>
  );
};

export default GiftCardBreakDown;
