import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ChangeGiftCardByIdContext } from '../../../../@types/actionTypes';
import { Concession, ConcessionPricing } from '../../../../@types/modelTypes';
import { useScreenWidth } from '../../../../contextProviders/screenWidthContext';
import { isVariableCostInvalid } from '../../../../services/GiftCardHelpers';
import {
  displayPrice,
  getMatchingConcessionDeal,
  getMatchingConcessionDealDetails,
} from '../../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../../services/KioskHelpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectCurrencyConfig,
  selectDeals,
  selectSelectedGiftCards,
} from '../../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../../svgs/deal.svg';
import ConcessionVariableInput from '../../concessionVariableInput/ConcessionVariableInput';
import QuantityButton from '../../quantitybutton/QuantityButton';
import RichText from '../../richtext/RichText';
import GiftCardImage from '../GiftCardImage';

interface Props extends Concession {
  defaultImage: string;
  totalCardsSelected: number;
}

const PhysicalConcessionItem: React.FC<Props> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  defaultImage,
  totalCardsSelected,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
  };
  const dispatch = useDispatch();
  const { isExtraSmallScreenWidth } = useScreenWidth();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const deals = useSelector(selectDeals);

  const [currentValue, setCurrentValue] = useState(
    concession?.isVariablePriceItem && concession.cost > 0
      ? (concession.cost / 100).toString()
      : ''
  );

  const currencyConfig = useSelector(selectCurrencyConfig);

  const hideTax: boolean = config.currentCinema.hideTax;

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const newValueInCents: number = Number(currentValue) * 100;

  const selectedConcession: Concession | undefined =
    concession.isVariablePriceItem
      ? selectedConcessions.list.find(
          (x) => x.id === concession.id && x.cost === newValueInCents
        )
      : selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const isAddDisabled =
    totalCardsSelected >=
      config.giftCardsPurchase.maxQuantityOfPhysicalGiftCards ||
    (concession.isVariablePriceItem &&
      isVariableCostInvalid(concession, newValueInCents));

  const handleConcessionChange = (
    context: ChangeGiftCardByIdContext,
    concession: Concession
  ) => {
    if (concession.isVariablePriceItem) {
      concession.cost = newValueInCents;
      concession.variablePriceInCents = newValueInCents;
    }
    dispatch(actionCreators.changeGiftCardById(concession, context));
  };

  const renderButton = (
    context: ChangeGiftCardByIdContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleConcessionChange(context, concession)}
      />
    );
  };

  const renderQuantityButtons = () => {
    return (
      <div className='quantity-selector d-flex justify-content-center justify-content-md-right'>
        <div className='quantity-button-container'>
          {renderButton('remove', quantityInCart === 0)}
        </div>
        <div className='quantity-col d-flex align-items-center justify-content-center fw-bold px-1'>
          {quantityInCart}
        </div>
        <div className='quantity-button-container'>
          {renderButton('add', !!isAddDisabled)}
        </div>
      </div>
    );
  };

  const concessionQuantitySelector = () => {
    const priceToDisplay = hideTax
      ? itemPricing.costIncTax
      : itemPricing.costMinusTax;
    const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * priceToDisplay;
    return (
      <Row className='options-selector-container text-center flex-grow-1 mt-1 mt-md-0'>
        <Col>
          <Row>
            <Col
              xs={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 0 }}
              className='options-selector'
            >
              {renderQuantityButtons()}
            </Col>
            {itemPricing.costIncTax > 0 && (
              <Col xs={3} md={4} className='text-end d-flex align-items-center'>
                <span className='w-100'>
                  {displayPrice(subTotal, currencyConfig)}
                </span>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    const calculateDiscount = () => {
      let amountSaved = 0;
      selectedConcessions.list.forEach((c: Concession) => {
        const matchedConcessionDeal = getMatchingConcessionDealDetails(
          c.id,
          deals
        );
        if (matchedConcessionDeal && matchedConcessionDeal.amount) {
          const saving = c.cost - matchedConcessionDeal.amount;
          amountSaved += c.quantity * saving;
        }
      });
      return amountSaved;
    };
    if (selectedConcessions.list.length) {
      const dealsDiscountTotal = calculateDiscount();
      dispatch(actionCreators.setTotalDiscount(dealsDiscountTotal));
    }
  }, [deals, dispatch, selectedConcessions]);

  const matchingDeal = getMatchingConcessionDeal(id, deals);

  return (
    <Row className='py-4 concession text-center align-items-center'>
      <Col>
        <Row>
          <Col xs={12} md={8}>
            <Row className='g-0 h-100'>
              <Col
                xs={'auto'}
                className='image-container pe-2 position-relative'
              >
                {matchingDeal && <DealSvg className='deal-icon' />}
                <GiftCardImage
                  imageUrl={imagePath || content.giftCards.defaultImage}
                  isPhysicalGc
                />
              </Col>
              <Col className='details text-start d-flex flex-column justify-content-between'>
                <div>
                  {matchingDeal && (
                    <div className='mb-1 text-uppercase deal-text fw-bold'>
                      {matchingDeal.description}
                    </div>
                  )}
                  <span className='h3 w-100'>{concession.description}</span>
                  {concession.extendedDescription && (
                    <RichText
                      className='tiny mt-1 w-100'
                      text={concession.extendedDescription}
                    />
                  )}
                </div>
                {concession.isVariablePriceItem && !isExtraSmallScreenWidth && (
                  <ConcessionVariableInput
                    concession={concession}
                    currentValue={currentValue}
                    setCurrentValue={setCurrentValue}
                  />
                )}
              </Col>
            </Row>
          </Col>

          {concession.isVariablePriceItem && isExtraSmallScreenWidth && (
            <Col>
              <ConcessionVariableInput
                concession={concession}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
              />
            </Col>
          )}

          <Col
            xs={12}
            md={4}
            className={classNames(
              'd-flex',
              concession.isVariablePriceItem
                ? 'align-items-end'
                : 'align-items-center'
            )}
          >
            {concessionQuantitySelector()}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PhysicalConcessionItem;
