/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import moment from 'moment';
import { Button, Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ShowtimeDate from './ShowtimeDate';

import { Time } from '../../../@types/modelTypes';
import {
  renderShowtimeAttributeList,
  timeFormat,
} from '../../../services/Helpers';
import {
  selectConfig,
  selectContent,
  selectQueryString,
  selectRequestData,
  selectSelectedLanguageCulture,
  selectShowtimes,
} from '../../../store/Selectors';
import { ReactComponent as SummaryEditSvg } from '../../../svgs/summaryEdit.svg';

interface ShowtimePickerProps {
  showShowtimes: boolean;
  setShowShowtimes: (showShowtimes: boolean) => void;
  displayShowtimePicker: boolean;
}

const ShowtimePicker: React.FC<ShowtimePickerProps> = ({
  showShowtimes,
  setShowShowtimes,
  displayShowtimePicker,
}) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_cookies, setCookies, _removeCookies] = useCookies();
  const { externalCinemaId, externalSessionId } = useParams();

  const config = useSelector(selectConfig);
  const queryString = useSelector(selectQueryString);
  const requestData = useSelector(selectRequestData);
  const showtimes = useSelector(selectShowtimes);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const content = useSelector(selectContent);

  const handleSessionChange = (externalId: string) => {
    const cookieOptions = {
      path: '/',
      expires: moment().add(5, 's').toDate(),
      secure: true,
      sameSite: true,
    };

    setCookies('requestData', requestData, cookieOptions);
    setCookies('isShowtimeChange', true, cookieOptions);

    const url = `/startticketing/${externalCinemaId}/${externalId}${queryString}`;
    window.location.href = url;
  };

  const handleShowSessions = () => {
    setShowShowtimes(true);
  };

  const renderShowtimeButton = (time: Time) => {
    return (
      <Col xs={4} key={`${time.externalSessionId}`}>
        <div className='showtime-button-container'>
          <Button
            className='showtime-button btn-link'
            disabled={
              moment(time.startDateTime) < moment() ||
              time.externalSessionId === externalSessionId ||
              time.notBookable ||
              time.soldOut
            }
            onClick={() => handleSessionChange(time.externalSessionId)}
            sx={{
              backgroundColor: 'secondaryButtonBackground',
              borderColor: 'accent',
              color: 'secondaryButtonColor',

              '&:disabled': {
                backgroundColor: 'secondaryButtonBackground',
                borderColor: 'accent',
                color: 'secondaryButtonColor',
              },

              '&:hover:enabled, &:focus:enabled, &:focus:hover:enabled': {
                backgroundColor: 'secondaryButtonBackgroundHover',
                borderColor: 'primary',
                color: 'secondaryButtonColorHover',
              },
            }}
          >
            <span className='showtime-hourminute'>
              {moment(time.startDateTime)
                .locale(selectedLanguageCulture)
                .format(timeFormat(selectedLanguageCulture))}
            </span>
          </Button>
        </div>
      </Col>
    );
  };

  const renderShowtimeButtonsContainer = (times: Time[]) => {
    return (
      <Col xs={12}>
        <Row className='g-0 showtime-buttons-container'>
          {times.map((time) => renderShowtimeButton(time))}
        </Row>
      </Col>
    );
  };

  const renderShowtimeRows = () => {
    return (
      showtimes &&
      showtimes.map((showtime) => (
        <Row
          className='g-0 showtime-picker-date-row text-start'
          key={`${showtime.date}`}
        >
          <ShowtimeDate date={showtime.date} />
          <Col className='showtime-picker-sessions-container pe-2'>
            <div
              className='showtime-picker-sessions'
              sx={{
                borderTop: 'bodySeparator',
              }}
            >
              {showtime.experienceTypes.map((experienceType, index) => (
                <Row key={`experienceType${index}`} className='g-0 ms-2'>
                  {experienceType.experienceAttributes.length > 0 && (
                    <Col xs={12} className='tiny mb-1'>
                      {renderShowtimeAttributeList(
                        experienceType.experienceAttributes,
                        config.showtimeAttributeIconsInShowtimePickerFormat
                      )}
                    </Col>
                  )}

                  {renderShowtimeButtonsContainer(experienceType.times)}
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      ))
    );
  };

  return (
    <Row
      className='showtime-picker g-0'
      data-testid='showtime-picker'
      sx={{
        '.update-showtime-btn': {
          color: 'mostReadableOnWebsiteBackground',

          '.icon': {
            '.a': {
              fill: 'primary',
            },
          },

          '&:hover': {
            color: 'primary',
          },
        },
      }}
    >
      {displayShowtimePicker && !showShowtimes && (
        <Col xs={12} className='d-flex justify-content-center'>
          <Button
            className='update-showtime-btn tiny d-flex align-items-center justify-content-center py-1 w-100'
            onClick={() => handleShowSessions()}
            variant='link'
          >
            {content.updateShowtimeText}
            <SummaryEditSvg className='icon' />
          </Button>
        </Col>
      )}
      {showShowtimes && (
        <Col xs={12} className='px-2'>
          {renderShowtimeRows()}
        </Col>
      )}
    </Row>
  );
};

export default ShowtimePicker;
