import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import VoucherImage from './VoucherImage';

import { Concession, GlobalState } from '../../../@types/modelTypes';
import {
  selectSelectedFaBConcessions,
  selectContentVouchers,
  selectDisplayPrice,
} from '../../../store/Selectors';

interface VouchersRowProps {
  selectedConcessionIndex: number;
}

const PaymentSummaryRow: React.FC<VouchersRowProps> = ({
  selectedConcessionIndex,
}) => {
  const contentVouchers = useSelector(selectContentVouchers);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedVoucher: Concession | undefined =
    selectedConcessions.list.length > 0
      ? selectedConcessions.list[selectedConcessionIndex]
      : undefined;
  const selectedVoucherCostToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, selectedVoucher?.cost)
  );
  if (!selectedVoucher || selectedVoucher.quantity < 1) return null;

  const getDescription = () => {
    return `${selectedVoucher.description} x ${selectedVoucher.quantity}`;
  };

  return (
    <div className='giftcards-row summary'>
      <Row className='g-0 giftcard-details d-flex align-items-center'>
        <Col xs={'auto'} className='image-container pe-2'>
          <VoucherImage
            imageUrl={selectedVoucher.image || contentVouchers.defaultImage}
          />
        </Col>
        <Col className='text-start summary-item b'>
          <span>{getDescription()}</span>
        </Col>
        <Col xs={'auto'} className='text-end ps-3 summary-item b'>
          <span>{selectedVoucherCostToDisplay}</span>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSummaryRow;
