import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import ContextAwareQuantitySelector from './ContextAwareQuantitySelector';

import { ChangeGiftCardByIdContext } from '../../../../../@types/actionTypes';
import { Concession } from '../../../../../@types/modelTypes';
import {
  selectConfig,
  selectContent,
  selectSelectedGiftCards,
} from '../../../../../store/Selectors';

interface VariableCostConcession extends Concession {
  handleConcessionChange: (
    context: ChangeGiftCardByIdContext,
    concession: Concession,
    newValueInCents: number | undefined
  ) => void;
  totalCardsSelected: number;
}

const VariableCostGiftCardButtonSingle: React.FC<VariableCostConcession> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  quantity,
  modifierGroups,
  cost,
  orderDelivery,
  handleConcessionChange,
  totalCardsSelected,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    quantity,
    modifierGroups,
    isVariablePriceItem: false,
    minVariableCost: 0,
    maxVariableCost: 0,
    orderDelivery,
    modifiers: [],
    cost,
    variablePriceInCents: 0,
    hidden: false,
    isSoldOut: false,
  };

  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const selectedConcession: Concession | undefined =
    selectedGiftCards.list.find((x) => x.id === concession.id);
  const isAddDisabled =
    totalCardsSelected >= config.giftCardsPurchase.maxQuantityOfGiftCards;
  return (
    <Row className='g-0 giftcard-container fixed-price single py-3'>
      <Col className='d-flex align-items-center'>
        <div className='h3'>{concession.description}</div>
      </Col>
      <Col className='d-flex align-items-center justify-content-end'>
        <ContextAwareQuantitySelector
          quantity={selectedConcession?.quantity ?? 0}
          maxQuantity={config.giftCardsPurchase.maxQuantityOfGiftCards}
          concession={concession}
          handleConcessionChange={handleConcessionChange}
          addIsDisabled={isAddDisabled}
          optionButtonSelectedText={content.giftCards.optionButtonSelectedText}
          optionButtonUnselectedText={
            content.giftCards.optionButtonUnselectedText
          }
          showPrice
          cost={concession.cost}
        />
      </Col>
    </Row>
  );
};

export default VariableCostGiftCardButtonSingle;
