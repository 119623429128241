import React from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getDisplayDateAndTime, timeFormat } from '../../../services/Helpers';
import {
  selectSelectedSeasonPassSessions,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';

const CartSummarySeasonPassSessions: React.FC = () => {
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const sessionsQuantity = selectedSeasonPassSessions.length;

  if (sessionsQuantity === 0) {
    return null;
  }

  return (
    <div className='cart-summary-row'>
      {selectedSeasonPassSessions.map((s, i) => {
        const movieTitle = s.movie.title;
        const dateDisplay = getDisplayDateAndTime(
          moment(s.session.startDateTime).toDate(),
          selectedLanguageCulture
        ).displayDate.slice(0, -5);
        const selectedTimeDisplay = moment(s.session.startDateTime)
          .locale(selectedLanguageCulture)
          .format(timeFormat(selectedLanguageCulture));

        return (
          <Row
            key={s.session.sessionId}
            className={classNames(
              i !== selectedSeasonPassSessions.length - 1 && 'mb-2'
            )}
          >
            <Col xs={8} className='text-start'>
              <div>
                <b>{`${movieTitle}`}</b>
              </div>
              <div className='ps-1'>
                <span className='pe-2'>{`• ${selectedTimeDisplay}`}</span>
                <span>{dateDisplay}</span>
              </div>
            </Col>
            <Col xs={4}>
              <div className='d-flex flex-wrap text-end justify-content-end ps-3'>
                {s.seats.length > 0 ? s.seats.join(', ') : '-'}
              </div>
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default CartSummarySeasonPassSessions;
