import { useCallback, useEffect } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../@types/enums';
import { GlobalState } from '../@types/modelTypes';
import { useRecaptcha } from '../contextProviders/recaptchaContext';
import { useTurnstile } from '../contextProviders/turnstileContext';
import { actionCreators } from '../store/ActionCreators';
import {
  selectSelectedPaymentProvider,
  selectBankCardAmount,
  selectIsCustomerReadyForPayment,
  selectGrandTotalAfterDiscountsInCents,
} from '../store/Selectors';

export const useGetPaymentProvider = (
  paymentProvider: PaymentProvidersEnum
) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const bankCardAmount = useSelector(selectBankCardAmount);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const hostedPayment = useSelector(
    (state: GlobalState) => state.hostedPayments[paymentProvider]
  );
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const isFormValid = isCustomerReadyForPayment;

  const getPaymentProvider = useCallback(
    async (paymentProvider: PaymentProvidersEnum) => {
      if (!isFormValid || !executeRecaptcha) return;

      dispatch(
        actionCreators.hostedPaymentRequest({
          paymentProvider,
          grandTotal,
          executeRecaptcha,
          recaptcha,
          turnstile,
        })
      );
    },
    [dispatch, executeRecaptcha, grandTotal, isFormValid, recaptcha, turnstile]
  );

  // reacts to bankCardAmount Changing and sets hostedpayment to null
  useEffect(() => {
    if (
      hostedPayment &&
      (bankCardAmount !== hostedPayment.bankCardAmount ||
        grandTotal !== hostedPayment?.grandTotal)
    ) {
      dispatch(actionCreators.setHostedPaymentInProgress(false));
      dispatch(actionCreators.setHostedPayment(paymentProvider, null));
    }
  }, [bankCardAmount, dispatch, grandTotal, hostedPayment, paymentProvider]);

  //  calls getPaymentProvider
  useEffect(() => {
    if (selectedPaymentProvider === paymentProvider && !hostedPayment) {
      getPaymentProvider(paymentProvider);
    }
  }, [
    hostedPayment,
    getPaymentProvider,
    paymentProvider,
    selectedPaymentProvider,
  ]);
};
