import React from 'react';

interface Props {
  id?: string;
}

const CustomHtmlDiv: React.FC<Props> = ({ id }) => {
  return <div id={id}></div>;
};

export default CustomHtmlDiv;
