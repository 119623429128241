import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectContent,
  selectDisplayPrice,
  selectLoyaltyRedemptionRewards,
} from '../../../../store/Selectors';
import RichText from '../../richtext/RichText';

interface Props {
  loyaltyPaymentCoversFullAmount: boolean;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
}

const SplitLoyaltyRedemptionBreakDown: React.FC<Props> = ({
  loyaltyPaymentCoversFullAmount,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const loyaltyRedemptionRewards = useSelector(selectLoyaltyRedemptionRewards);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, loyaltyRedemptionRewards)
  );

  const handleRemoveLoyaltyRedemption = () => {
    dispatch(actionCreators.setLoyaltyRedemptionRewards(0));
    setPaymentFormVisibility(true);
    setGiftCardFormVisibility && setGiftCardFormVisibility(true);
  };

  return (
    <div className='breakdown mt-1' data-testid='loyalty-redemption-break-down'>
      <p>{content.payment.loyaltyRedemptionDollarsAppliedText}</p>
      <RichText
        text={content.payment.loyaltyRedemptionRemainingBalanceRichText}
      />
      {!loyaltyPaymentCoversFullAmount && (
        <RichText text={content.payment.loyaltyRedemptionStillToPayRichText} />
      )}
      <Row className='g-0 border-y-solid py-2 mb-1 mt-2'>
        <Col xs={8} className='fw-bold text-uppercase'>
          {content.payment.loyaltyRedemptionDollarsAppliedLabel}
        </Col>
        <Col xs={2} className='d-flex justify-content-center'>
          <Button
            onClick={handleRemoveLoyaltyRedemption}
            variant='link'
            size='sm'
            className='text-uppercase small'
          >
            {content.payment.removeButtonText}
          </Button>
        </Col>
        <Col xs={2}>
          <span className='float-end fw-bold'>{priceToDisplay}</span>
        </Col>
      </Row>
    </div>
  );
};

export default SplitLoyaltyRedemptionBreakDown;
