/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import '../../../../../../scss/components/views/_adyenpayment.scss';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import useConfirmationPageRedirect from '../../../../../../hooks/useConfirmationPageRedirect';
import { actionCreators } from '../../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectConfig,
  selectCustomer,
} from '../../../../../../store/Selectors';

interface Props {
  adyenConfig: CoreOptions | undefined;
  setAdyenConfig: Dispatch<SetStateAction<CoreOptions | undefined>>;
}

const Adyen: React.FC<Props> = ({ adyenConfig, setAdyenConfig }) => {
  const dispatch = useDispatch();
  useConfirmationPageRedirect();

  const config = useSelector(selectConfig);
  const customer = useSelector(selectCustomer);
  const bankCardAmount = useSelector(selectBankCardAmount);

  const [dropinElement, setDropinElement] = useState<
    DropinElement | undefined
  >();
  const paymentContainerRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleSubmission = useCallback(
    (state: any, component: any) => {
      if (!state.isValid || !adyenConfig) {
        return;
      }
      dispatch(
        actionCreators.adyenInitiatePayment({
          adyenStateData: state.data,
          adyenComponent: component,
          setAdyenConfig,
        })
      );
    },
    [adyenConfig, dispatch, setAdyenConfig]
  );

  const handleAdditionalDetails = useCallback(
    (state: any, component: any) => {
      dispatch(
        actionCreators.adyenSubmitAdditionalDetails({
          adyenStateData: state.data,
          adyenComponent: component,
          setAdyenConfig,
        })
      );
    },
    [dispatch, setAdyenConfig]
  );

  const createCheckout = useCallback(async () => {
    if (!adyenConfig) return;
    const adyenConfigToUse: CoreOptions = JSON.parse(
      JSON.stringify(adyenConfig)
    );

    const checkout = await AdyenCheckout({
      ...adyenConfigToUse,
      onSubmit: (state: any, component: any) => {
        handleSubmission(state, component);
      },
      onAdditionalDetails: (state: any, component: any) => {
        handleAdditionalDetails(state, component);
      },
      amount: {
        value: bankCardAmount,
        currency: config.currency,
      },
    });
    if (paymentContainerRef.current) {
      const newDropin: DropinElement = checkout
        .create('dropin', {
          openFirstPaymentMethod: true,
          openFirstStoredPaymentMethod: false,
        })
        .mount(paymentContainerRef.current);
      setDropinElement(newDropin);
    }
  }, [
    adyenConfig,
    bankCardAmount,
    config.currency,
    handleAdditionalDetails,
    handleSubmission,
  ]);

  useEffect(() => {
    if (adyenConfig && customer.isValid && customer.email) {
      createCheckout();
    }
  }, [adyenConfig, createCheckout, customer]);

  return (
    <Accordion.Collapse in={dropinElement !== undefined} eventKey='0'>
      <div ref={paymentContainerRef} />
    </Accordion.Collapse>
  );
};
export default Adyen;
