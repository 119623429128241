import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import { card, cvc } from 'creditcards';
import { Form, Col, Row } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector, useDispatch } from 'react-redux';
import { isNumeric, isLength } from 'validator';

import { Payment } from '../../../../../@types/modelTypes';
import { useRecaptcha } from '../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../contextProviders/turnstileContext';
import {
  getCardNumberInvalidMessageKey,
  getCvvInvalidMessageKey,
  getExpDateInvalidMessageKey,
  isCardTypeValid,
  isExpiryDateValid,
  tryGetTestCard,
} from '../../../../../services/PaymentHelpers';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectBankCardAmountDisplayPrice,
  selectConfig,
  selectContent,
  selectIsCustomerReadyForPayment,
  selectLoyaltyRecognitionNumber,
  selectPayment,
} from '../../../../../store/Selectors';
import ActionButton from '../../../actionbutton/ActionButton';
import ExpiryOptions from '../common/ExpiryOptions';
import LabelWithTooltip from '../common/LabelWithTooltip';

interface Props {
  useCardType?: boolean;
  useAddress?: boolean;
  isPageValidated?: boolean;
  handleValidatePage: () => void;
  setCreditCardType: (cardType: string) => void;
}

const IntegratedPayment: React.FC<Props> = ({
  useCardType = false,
  useAddress = false,
  isPageValidated = false,
  handleValidatePage,
  setCreditCardType,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const payment = useSelector(selectPayment);

  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const [paymentState, setPaymentState] = useState<Payment>({
    nameOnCard: payment?.nameOnCard ?? '',
    nameOnCardIsValid: payment?.nameOnCardIsValid ?? false,
    nameOnCardIsValidated: false,
    cardNumberForDisplay: payment?.cardNumberForDisplay ?? '',
    cardNumber: payment?.cardNumber ?? '',
    cardNumberIsValid: payment?.cardNumberIsValid ?? false,
    cardNumberIsValidated: false,
    cardType: payment?.cardType ?? '',
    cardTypeIsValid: payment ? payment.cardTypeIsValid : !useCardType,
    cardTypeIsValidated: false,
    setCreditCardType: payment?.cardType ?? '',
    month: payment?.month ?? '',
    monthIsValid: payment?.monthIsValid ?? false,
    monthIsValidated: false,
    year: payment?.year ?? '',
    yearIsValid: payment?.yearIsValid ?? false,
    yearIsValidated: false,
    expiryDateIsValid: false,
    cvvCode: '',
    cvvCodeIsValid: false,
    cvvCodeIsValidated: false,
    zipCode: payment?.zipCode ?? '',
    zipCodeIsValid: payment?.zipCodeIsValid ?? false,
    zipCodeIsValidated: false,
    address: payment?.address ?? '',
    addressIsValid: payment ? payment.addressIsValid : !useAddress,
    addressIsValidated: false,
  });
  const [paymentStarted, setPaymentStarted] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(actionCreators.setPaymentDetails(paymentState));
    };
  }, [dispatch, paymentState]);

  const isCvvCodeValid = (cvvCode: string, cardType: string) => {
    return cvc.isValid(cvvCode, cardType);
  };

  // Validate CVV code.
  useEffect(() => {
    const cardType = paymentState.cardTypeIsValid && paymentState.cardType;
    if (cardType) {
      setPaymentState((prevState) => ({
        ...prevState,
        cvvCodeIsValid: isCvvCodeValid(paymentState.cvvCode, cardType),
      }));
    }
  }, [
    paymentState.cardNumber,
    paymentState.cvvCode,
    paymentState.cardType,
    paymentState.cardTypeIsValid,
  ]);

  const showWarningMessage =
    (paymentState.nameOnCardIsValidated && !paymentState.nameOnCardIsValid) ||
    (paymentState.monthIsValidated && !paymentState.monthIsValid) ||
    (paymentState.yearIsValidated && !paymentState.yearIsValid) ||
    (paymentState.cvvCodeIsValidated && !paymentState.cvvCodeIsValid) ||
    (paymentState.zipCodeIsValidated && !paymentState.zipCodeIsValid) ||
    (useAddress &&
      paymentState.addressIsValidated &&
      !paymentState.addressIsValid) ||
    (paymentState.monthIsValid &&
      paymentState.yearIsValid &&
      !paymentState.expiryDateIsValid);

  const isFormValid =
    paymentState.nameOnCardIsValid &&
    paymentState.cardTypeIsValid &&
    paymentState.monthIsValid &&
    paymentState.yearIsValid &&
    paymentState.expiryDateIsValid &&
    paymentState.cvvCodeIsValid &&
    paymentState.zipCodeIsValid &&
    (!useAddress || paymentState.addressIsValid) &&
    isCustomerReadyForPayment;

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isCardTypeFieldValid = (cardType: string) => {
      return isCardTypeValid(cardType, config);
    };
    const isCardNumberFieldValid = (cardNumber: string) => {
      return card.isValid(cardNumber);
    };
    const newValue = e.currentTarget.value;
    const cardNumber = card.parse(newValue);
    const cardType = card.type(cardNumber, true) ?? '';
    setCreditCardType(cardType);
    let cardTypeIsValid = isCardTypeFieldValid(cardType);
    let cardNumberIsValid = isCardNumberFieldValid(cardNumber);

    if ((!cardTypeIsValid || !cardNumberIsValid) && cardNumber.length >= 12) {
      const testCard = tryGetTestCard(cardNumber);
      if (testCard) {
        cardTypeIsValid = true;
        cardNumberIsValid = true;
        setCreditCardType(testCard.type);
      }
    }

    setPaymentState({
      ...paymentState,
      cardNumber,
      cardNumberForDisplay: card.format(cardNumber),
      cardType,
      cardTypeIsValid,
      cardNumberIsValid,
      cardNumberIsValidated: true,
    });
  };

  const handleNameOnCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNameOnCardFieldValid = (nameOnCard: string) => {
      return isLength(nameOnCard, { min: 1, max: 50 });
    };
    const nameOnCard = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      nameOnCard,
      nameOnCardIsValid: isNameOnCardFieldValid(nameOnCard),
      nameOnCardIsValidated: true,
    });
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isMonthFieldValid = (month: string) => {
      return !!month && month !== content.payment.expiryMonthPlaceholder;
    };
    const month = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      expiryDateIsValid: isExpiryDateValid(month, paymentState.year),
      month,
      monthIsValid: isMonthFieldValid(month),
      monthIsValidated: true,
    });
    setMonthSelected(true);
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const isYearFieldValid = (year: string) => {
      return !!year && year !== content.payment.expiryYearPlaceholder;
    };
    const year = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      expiryDateIsValid: isExpiryDateValid(paymentState.month, year),
      year,
      yearIsValid: isYearFieldValid(year),
      yearIsValidated: true,
    });
    setYearSelected(true);
  };

  const handleCvvCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    const maxLength = e.currentTarget.maxLength;
    const cvvCodeTrimmed = newValue.slice(0, maxLength);
    const cvvCode = isNumeric(cvvCodeTrimmed)
      ? cvvCodeTrimmed
      : cvvCodeTrimmed.replace(/\D/g, '');
    setPaymentState({ ...paymentState, cvvCode });
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isZipCodeFieldValid = (zipCode: string) => {
      return isLength(zipCode, { min: 1, max: 8 });
    };
    const zipCode = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      zipCode,
      zipCodeIsValid: isZipCodeFieldValid(zipCode),
      zipCodeIsValidated: true,
    });
  };

  const isAddressFieldValid = (address: string) => {
    return isLength(address, { min: 1, max: 255 });
  };

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const address = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      address,
      addressIsValid: isAddressFieldValid(address),
      addressIsValidated: true,
    });
  };

  const resetPaymentOnError = () => {
    setPaymentStarted(false);
  };

  const handleMakePayment = async () => {
    if (!isFormValid || !executeRecaptcha) return;
    setPaymentStarted(true);
    const expiryMonth = paymentState.month.padStart(2, '0');
    const expiryYear = paymentState.year.slice(-2);

    dispatch(
      actionCreators.submitMakePayment({
        executeRecaptcha,
        callBackFunction: resetPaymentOnError,
        makePaymentModelOverrideProps: {
          nameOnCard: paymentState.nameOnCard,
          cardNumber: paymentState.cardNumber,
          cardType: paymentState.cardType,
          expiryDateMonth: expiryMonth,
          expiryDateYear: expiryYear,
          cvv: paymentState.cvvCode,
          billingAddress: paymentState.address,
          billingPostal: paymentState.zipCode,
          loyaltyCardNumber: loyaltyRecognitionNumber,
        },
        turnstile,
        recaptcha,
      })
    );
  };

  const validateFields = () => {
    const {
      cardNumberIsValidated,
      cardNumberIsValid,
      nameOnCardIsValidated,
      nameOnCardIsValid,
      month,
      year,
      monthIsValidated,
      monthIsValid,
      yearIsValidated,
      yearIsValid,
      zipCodeIsValidated,
      zipCodeIsValid,
      cvvCodeIsValidated,
      cvvCodeIsValid,
      addressIsValidated,
      addressIsValid,
    } = paymentState;

    if (
      cardNumberIsValidated &&
      nameOnCardIsValidated &&
      monthIsValidated &&
      yearIsValidated &&
      zipCodeIsValidated &&
      cvvCodeIsValidated &&
      (!useAddress || addressIsValidated)
    )
      return;

    setPaymentState({
      ...paymentState,
      cardNumberIsValid: cardNumberIsValidated && cardNumberIsValid,
      cardNumberIsValidated: true,
      nameOnCardIsValid: nameOnCardIsValidated && nameOnCardIsValid,
      nameOnCardIsValidated: true,
      expiryDateIsValid: isExpiryDateValid(month, year),
      monthIsValid: monthIsValidated && monthIsValid,
      monthIsValidated: true,
      yearIsValid: yearIsValidated && yearIsValid,
      yearIsValidated: true,
      zipCodeIsValid: zipCodeIsValidated && zipCodeIsValid,
      zipCodeIsValidated: true,
      cvvCodeIsValid: cvvCodeIsValidated && cvvCodeIsValid,
      cvvCodeIsValidated: true,
      addressIsValid: !useAddress || (addressIsValidated && addressIsValid),
      addressIsValidated: true,
    });
  };

  const handlePaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }

    validateFields();

    if (!isFormValid) return;

    handleMakePayment();
  };

  const getValueOf = (feedback: string) => {
    return feedback
      ? content.payment[feedback as keyof typeof content.payment]
      : null;
  };

  const cardNumberCmsFeedback: string =
    getCardNumberInvalidMessageKey(paymentState);
  const cvvCmsFeedback: string = getCvvInvalidMessageKey(paymentState);
  const expMonthCmsFeedback: string = getExpDateInvalidMessageKey(
    paymentState,
    'month'
  );
  const expYearCmsFeedback = getExpDateInvalidMessageKey(paymentState, 'year');

  return (
    <div className='integrated-payment' data-testid='integrated-payment'>
      <Form noValidate>
        <Form.Group className='mb-2'>
          <LabelWithTooltip
            labelFor='cardNumber'
            helpText={content.payment.cardNumberHelpText}
          >
            {content.payment.cardNumberLabel}
          </LabelWithTooltip>
          <Form.Control
            className={classnames(
              'spaced-letters',
              (!paymentState.cardTypeIsValid ||
                !paymentState.cardNumberIsValid) &&
                paymentState.cardNumberIsValidated &&
                'is-invalid'
            )}
            type='text'
            placeholder={content.payment.cardNumberPlaceHolder}
            onChange={handleCardNumberChange}
            value={paymentState.cardNumberForDisplay}
            inputMode={'tel'}
            maxLength={19}
            isInvalid={
              paymentState.cardNumberIsValidated &&
              (!paymentState.cardTypeIsValid || !paymentState.cardNumberIsValid)
            }
            isValid={
              paymentState.cardNumberIsValidated &&
              paymentState.cardTypeIsValid &&
              paymentState.cardNumberIsValid
            }
            onBlur={() =>
              setPaymentState({
                ...paymentState,
                cardNumberIsValidated: true,
              })
            }
            id='cardNumber'
            name='cardNumber'
          />
          <Form.Control.Feedback type='invalid'>
            {getValueOf(cardNumberCmsFeedback)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-2'>
          <LabelWithTooltip
            labelFor='nameOnCard'
            helpText={content.payment.nameOnCardHelpText}
          >
            {content.payment.nameOnCardLabel}
          </LabelWithTooltip>
          <Form.Control
            className={classnames(
              !paymentState.nameOnCardIsValid &&
                paymentState.nameOnCardIsValidated &&
                'is-invalid'
            )}
            type='text'
            placeholder={content.payment.nameOnCardPlaceHolder}
            onChange={handleNameOnCardChange}
            value={paymentState.nameOnCard}
            required
            maxLength={50}
            isInvalid={
              paymentState.nameOnCardIsValidated &&
              !paymentState.nameOnCardIsValid
            }
            isValid={
              paymentState.nameOnCardIsValidated &&
              paymentState.nameOnCardIsValid
            }
            onBlur={() =>
              setPaymentState({
                ...paymentState,
                nameOnCardIsValidated: true,
              })
            }
            id='nameOnCard'
            name='nameOnCard'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.nameOnCardValidationText}
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Form.Group as={Col} className='mb-2'>
            <LabelWithTooltip
              labelFor='creditCardMonth'
              helpText={content.payment.expiryDateHelpText}
            >
              {content.payment.expiryDateLabel}
            </LabelWithTooltip>
            <Row>
              <Col>
                <Form.Select
                  className={classnames(
                    'form-control',
                    !paymentState.monthIsValid &&
                      paymentState.monthIsValidated &&
                      'is-invalid',
                    !monthSelected && 'default-value'
                  )}
                  onChange={handleMonthChange}
                  value={paymentState.month}
                  required
                  isInvalid={
                    paymentState.monthIsValidated && !paymentState.monthIsValid
                  }
                  isValid={
                    paymentState.monthIsValidated && paymentState.monthIsValid
                  }
                  onBlur={() =>
                    setPaymentState({
                      ...paymentState,
                      monthIsValidated: true,
                    })
                  }
                  id='creditCardMonth'
                  name='creditCardMonth'
                >
                  <ExpiryOptions type={'month'} />
                </Form.Select>
                <Form.Control.Feedback
                  className={classnames(
                    !paymentState.monthIsValid &&
                      paymentState.monthIsValidated &&
                      'is-invalid'
                  )}
                  type='invalid'
                >
                  {getValueOf(expMonthCmsFeedback)}
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Select
                  className={classnames(
                    'form-control',
                    !paymentState.yearIsValid &&
                      paymentState.yearIsValidated &&
                      'is-invalid',
                    !yearSelected && 'default-value'
                  )}
                  onChange={handleYearChange}
                  value={paymentState.year}
                  required
                  isInvalid={
                    paymentState.yearIsValidated && !paymentState.yearIsValid
                  }
                  isValid={
                    paymentState.yearIsValidated && paymentState.yearIsValid
                  }
                  onBlur={() =>
                    setPaymentState({
                      ...paymentState,
                      yearIsValidated: true,
                    })
                  }
                  id='creditCardYear'
                  name='creditCardYear'
                >
                  <ExpiryOptions type={'year'} />
                </Form.Select>
                <Form.Control.Feedback
                  className={classnames(
                    !paymentState.yearIsValid &&
                      paymentState.yearIsValidated &&
                      'is-invalid'
                  )}
                  type='invalid'
                >
                  {getValueOf(expYearCmsFeedback)}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Form.Group>
        </Row>

        {useAddress && (
          <Form.Group className='mb-2'>
            <LabelWithTooltip
              labelFor='address'
              helpText={content.payment.addressHelpText}
            >
              {content.payment.addressLabel}
            </LabelWithTooltip>
            <Form.Control
              className={classnames(
                !paymentState.addressIsValid &&
                  paymentState.addressIsValidated &&
                  'is-invalid'
              )}
              type='text'
              placeholder={content.payment.addressPlaceholder}
              onChange={handleAddressChange}
              value={paymentState.address}
              required
              maxLength={255}
              isInvalid={
                paymentState.addressIsValidated && !paymentState.addressIsValid
              }
              isValid={
                paymentState.addressIsValidated && paymentState.addressIsValid
              }
              onBlur={() =>
                setPaymentState({
                  ...paymentState,
                  addressIsValidated: true,
                })
              }
              id='address'
              name='address'
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.addressValidationText}
            </Form.Control.Feedback>
          </Form.Group>
        )}

        <Row>
          <Form.Group as={Col}>
            <LabelWithTooltip
              labelFor='securityNumber'
              helpText={content.payment.cvvHelpText}
            >
              {content.payment.cvvLabel}
            </LabelWithTooltip>
            <Form.Control
              className={classnames(
                'spaced-letters',
                !paymentState.cvvCodeIsValid &&
                  paymentState.cvvCodeIsValidated &&
                  'is-invalid'
              )}
              type='text'
              placeholder={content.payment.cvvPlaceholder}
              onChange={handleCvvCodeChange}
              value={paymentState.cvvCode}
              required
              maxLength={4}
              inputMode={'tel'}
              isInvalid={
                paymentState.cvvCodeIsValidated && !paymentState.cvvCodeIsValid
              }
              isValid={
                paymentState.cvvCodeIsValidated && paymentState.cvvCodeIsValid
              }
              onBlur={() =>
                setPaymentState({
                  ...paymentState,
                  cvvCodeIsValidated: true,
                })
              }
              id='securityNumber'
              name='securityNumber'
            />
            <Form.Control.Feedback type='invalid'>
              {getValueOf(cvvCmsFeedback)}
            </Form.Control.Feedback>
            <Col />
          </Form.Group>

          <Form.Group as={Col}>
            <LabelWithTooltip
              labelFor='zipCode'
              helpText={content.payment.zipCodeHelpText}
            >
              {content.payment.zipCodeLabel}
            </LabelWithTooltip>
            <Form.Control
              className={classnames(
                'spaced-letters',
                !paymentState.zipCodeIsValid &&
                  paymentState.zipCodeIsValidated &&
                  'is-invalid'
              )}
              type='text'
              placeholder={content.payment.zipCodePlaceholder}
              onChange={handleZipCodeChange}
              value={paymentState.zipCode}
              required
              maxLength={8}
              isInvalid={
                paymentState.zipCodeIsValidated && !paymentState.zipCodeIsValid
              }
              isValid={
                paymentState.zipCodeIsValidated && paymentState.zipCodeIsValid
              }
              onBlur={() =>
                setPaymentState({
                  ...paymentState,
                  zipCodeIsValidated: true,
                })
              }
              id='zipCode'
              name='zipCode'
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.zipCodeValidationText}
            </Form.Control.Feedback>
            <Col />
          </Form.Group>
        </Row>
      </Form>

      <ActionButton
        onClick={handlePaymentClick}
        disabled={paymentStarted}
        showIcon
        warningMessage={content.payment.formErrorsMessage}
        warningTitle={content.payment.formErrorsSubTitle}
        showWarningMessage={showWarningMessage}
        mb='mb-0'
        variant='primary'
      >{`${content.payment.submitText} ${priceToDisplay}`}</ActionButton>
    </div>
  );
};

export default IntegratedPayment;
