import React from 'react';

import classnames from 'classnames';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import LegendItem from './LegendItem';

import { SeatCustomStyle } from '../../../@types/modelTypes';
import { SEAT_TYPE } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  selectConfig,
  selectContentSeats,
  selectSeatsModel,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';

interface Props {
  showUnavailableWithSelectedTicketsInLegend: boolean;
}

const Legend: React.FC<Props> = ({
  showUnavailableWithSelectedTicketsInLegend,
}) => {
  const { isMobileScreenWidth } = useScreenWidth();

  const config = useSelector(selectConfig);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const contentSeats = useSelector(selectContentSeats);
  const availableSeatTypes = useSelector(selectSeatsModel).availableSeatTypes;

  const getUnavailableWithSelectedTicketsIcon = () => {
    const icon = { svgType: 'Custom', css: '', svgSource: '' };

    if (availableSeatTypes.customSeatStyles.length > 0) {
      const customIcons = availableSeatTypes.customSeatStyles.filter(
        (x) => !x.data.hideLegend
      );
      const sofaIcon = customIcons.find(
        (seatCustomStyle: SeatCustomStyle) =>
          seatCustomStyle.data.seatType === SEAT_TYPE.SOFA_SEAT
      );

      if (sofaIcon) {
        icon.css = 'd-flex sofa';
        if (sofaIcon.data.siblingId) {
          const sibling = availableSeatTypes.customSeatStyles.find(
            (x) => x.id === sofaIcon.data.siblingId
          );
          if (sibling) {
            icon.svgSource = `${sofaIcon.svg}${sibling.svg}`;
          } else {
            icon.svgSource = customIcons[0].svg;
          }
        }
      } else {
        icon.svgSource = customIcons[0].svg;
      }
    } else {
      icon.svgType = 'SeatSvg';
    }

    return icon;
  };

  const getUnavailableWithSelectedTickets = () => {
    const unavailWithTicket = getUnavailableWithSelectedTicketsIcon();
    return (
      <LegendItem
        label={contentSeats.titleUnavailableWithSelectedTicketsText}
        svgType={unavailWithTicket.svgType}
        svgSource={unavailWithTicket.svgSource}
        svgClassName={classnames(unavailWithTicket.css, 'available disabled')}
      />
    );
  };

  return (
    <Row
      className='seating-legend g-0 seat mt-sm-3 py-3'
      style={{
        padding: !isMobileScreenWidth
          ? `0 ${config.seats.seatMapXPaddingPx}px`
          : '14px',
      }}
    >
      <LegendItem
        label={contentSeats.titleAvailableText}
        svgType='SeatSvg'
        svgClassName='available'
      />
      {availableSeatTypes.hasLuxuryRecliners && (
        <LegendItem
          label={contentSeats.titleLuxuryReclinerText}
          svgType='SeatLuxurySvg'
          svgClassName='available recliner'
        />
      )}
      <LegendItem
        label={contentSeats.titleSelectedText}
        svgType='SeatSvg'
        svgClassName='selected'
      />
      <LegendItem
        label={contentSeats.titleUnavailableText}
        svgType='SeatSvg'
        svgClassName='unavailable'
      />

      {availableSeatTypes.hasWc && (
        <LegendItem
          label={contentSeats.titleAccessibleText}
          svgType='WheelchairSvg'
          svgClassName='with-icon available'
        />
      )}
      {availableSeatTypes.hasC && (
        <LegendItem
          label={contentSeats.titleCompanionText}
          svgType='CompanionSvg'
          svgClassName='with-icon available'
        />
      )}
      {availableSeatTypes.hasCouch && (
        <LegendItem
          label={contentSeats.titleSofaText}
          svgType={`SofaLeftSvgSofaRightSvg`}
          svgClassName='d-flex sofa available'
        />
      )}
      {availableSeatTypes.hasObstructedView && (
        <LegendItem
          label={contentSeats.titleObstructedView}
          svgType='ObstructedSvg'
          svgClassName='with-icon available'
        />
      )}
      {availableSeatTypes.hasBuffer && (
        <LegendItem
          label={contentSeats.titleBufferText}
          svgType='SeatBufferSvg'
          svgClassName='with-icon buffer'
        />
      )}
      {availableSeatTypes.customSeatStyles.length > 0 &&
        availableSeatTypes.customSeatStyles
          .filter((x) => !x.data.hideLegend)
          .map((seatCustomStyle: SeatCustomStyle) => {
            const cssClass = classnames(
              'available',
              seatCustomStyle.data.seatType === SEAT_TYPE.SOFA_SEAT &&
                'd-flex sofa'
            );
            const legendLabel =
              seatCustomStyle.data.content[selectedLanguageCulture][
                'legendLabel'
              ];
            let svgSource = seatCustomStyle.svg;
            if (seatCustomStyle.data.siblingId) {
              const sibling = availableSeatTypes.customSeatStyles.find(
                (x) => x.id === seatCustomStyle.data.siblingId
              );
              if (sibling) {
                svgSource = `${seatCustomStyle.svg}${sibling.svg}`;
              }
            }
            return (
              <LegendItem
                key={`custom_${seatCustomStyle.id}`}
                label={legendLabel}
                svgType='Custom'
                svgSource={svgSource}
                svgClassName={cssClass}
              />
            );
          })}
      {availableSeatTypes.hasEntrance && (
        <LegendItem
          label={contentSeats.titleEntranceText}
          svgType='EntranceLeftSvg'
          svgClassName=''
        />
      )}
      {availableSeatTypes.hasEmergencyExit && (
        <LegendItem
          label={contentSeats.titleEmergencyExitText}
          svgType='EmergencyExitSvg'
          svgClassName=''
        />
      )}
      {availableSeatTypes.hasStairs && (
        <LegendItem
          label={contentSeats.titleStairsText}
          svgType='StairsUp'
          svgClassName=''
        />
      )}
      {showUnavailableWithSelectedTicketsInLegend &&
        getUnavailableWithSelectedTickets()}
    </Row>
  );
};

export default Legend;
