import React, { useState } from 'react';

import classnames from 'classnames';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import LoyaltyRedemptionBreakDown from './LoyaltyRedemptionBreakDown';

import { useRecaptcha } from '../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { displayPrice } from '../../../../services/Helpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBookingData,
  selectContent,
  selectCurrencyConfig,
  selectCustomer,
  selectIsCustomerReadyForPayment,
  selectMaxPayableByLoyaltyInCents,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import Heading from '../../heading/Heading';
import RichText from '../../richtext/RichText';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
}

const LoyaltyRedemption: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const grandTotal = useSelector(selectMaxPayableByLoyaltyInCents);

  const [loyaltyRedemptionApplied, setLoyaltyRedemptionApplied] =
    useState(false);
  const currencyConfig = useSelector(selectCurrencyConfig);
  if (!content || !bookingData.isUserValidated) return null;

  const submitButtonText = `${content.payment.submitText} ${displayPrice(
    grandTotal,
    currencyConfig
  )}`;
  const isFormValid = isCustomerReadyForPayment;
  const haveEnoughBalance = bookingData.loyaltyCardBalance >= grandTotal;

  const handleApplyLoyaltyRedemption = () => {
    setLoyaltyRedemptionApplied(true);
    dispatch(actionCreators.setLoyaltyRedemptionRewards(grandTotal));
    setPaymentFormVisibility(false);
    !!setGiftCardFormVisibility && setGiftCardFormVisibility(false);
  };

  const handleLoyaltyPayment = async () => {
    if (!executeRecaptcha) return;

    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          payWithLoyaltyDollars: true,
          giftCards: [],
        },
        executeRecaptcha,
        turnstile,
        recaptcha,
      })
    );
  };

  const handleLoyaltyPaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    if (customer.isValid) {
      handleLoyaltyPayment();
    }
  };

  return (
    <div
      className='loyalty-redemption bordered mt-5'
      data-testid='loyalty-redemption'
    >
      <Heading size={3} className='text-uppercase text-center'>
        {content.payment.loyaltyRedemptionHeading.replaceAll(
          '##LoyaltyCreditApplied##',
          displayPrice(
            Math.min(bookingData.loyaltyCardBalance, grandTotal),
            currencyConfig
          )
        )}
      </Heading>
      <RichText
        className={classnames(
          'mt-2',
          haveEnoughBalance || 'border-t border-b py-2'
        )}
        text={content.payment.loyaltyRedemptionRichText}
      />

      {haveEnoughBalance && (
        <ActionButton
          onClick={handleApplyLoyaltyRedemption}
          disabled={loyaltyRedemptionApplied}
          data-testid={'loyalty-apply-btn'}
          variant='primary'
        >
          {content.payment.loyaltyRedemptionButtonText.replaceAll(
            '##LoyaltyCreditApplied##',
            displayPrice(
              Math.min(bookingData.loyaltyCardBalance, grandTotal),
              currencyConfig
            )
          )}
        </ActionButton>
      )}

      {haveEnoughBalance || (
        <div className='info-container mt-3 p-3'>
          <p>{content.payment.loyaltyRedemptionNotEnoughBalanceText}</p>
        </div>
      )}

      {loyaltyRedemptionApplied && (
        <React.Fragment>
          <LoyaltyRedemptionBreakDown
            grandTotal={grandTotal}
            setPaymentFormVisibility={setPaymentFormVisibility}
            setGiftCardFormVisibility={setGiftCardFormVisibility}
            setLoyaltyRedemptionApplied={setLoyaltyRedemptionApplied}
          />
          <ActionButton
            onClick={handleLoyaltyPaymentClick}
            showIcon
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
            showWarningMessage={isPageValidated && !isFormValid}
            disabled={isPageValidated && !isFormValid}
            mb='mb-0'
            variant='primary'
          >
            {submitButtonText}
          </ActionButton>
        </React.Fragment>
      )}
    </div>
  );
};

export default LoyaltyRedemption;
