/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { Concession } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContentPhysicalGiftcards,
  selectCurrencyConfig,
} from '../../../store/Selectors';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';

interface Props {
  concession: Concession;
  selected: boolean;
  onClick: (e: Concession) => void;
}
const DeliveryChargeOption: React.FC<Props> = ({
  concession,
  selected,
  onClick,
}) => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const currencyConfig = useSelector(selectCurrencyConfig);
  if (!contentPhysicalGiftCards) {
    return null;
  }

  return (
    <div
      className={classnames(
        'delivery-charge-option mt-1 p-1 d-flex align-items-center',
        selected && 'selected'
      )}
      onClick={() => onClick(concession)}
      sx={{
        border: 'mostReadableOnWebsiteBackgroundRgbaBorder',
        backgroundColor: 'websiteBackground',
        color: 'mostReadableOnWebsiteBackground',

        '&.selected': {
          border: 'mostReadableOnWebsiteBackgroundBorder',
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
        },
      }}
    >
      <div className='flex-grow-1'>
        <div className='fw-bold'>{concession.description}</div>
        <div className='tiny'>{concession.extendedDescription}</div>
      </div>
      <div className={classnames('px-2', selected && 'fw-bold')}>
        {displayPrice(concession.cost, currencyConfig)}
      </div>
      <div>
        <CheckBoxButton checked={selected} />
      </div>
    </div>
  );
};

export default DeliveryChargeOption;
