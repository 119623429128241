import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { QuantitySelectorContext } from '../../../../@types/actionTypes';
import { TicketCredit } from '../../../../@types/modelTypes';
import { getTotalNumberActualTickets } from '../../../../services/Helpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectContent,
  selectTicketCredits,
  selectTicketCreditsAvailable,
  selectTicketTypes,
} from '../../../../store/Selectors';
import QuantityButton from '../../quantitybutton/QuantityButton';
import RichText from '../../richtext/RichText';

const priceDescending = (a: TicketCredit, b: TicketCredit) => b.price - a.price;
const priceAscending = (a: TicketCredit, b: TicketCredit) => a.price - b.price;
const sumCreditsUsed = (a: number, b: TicketCredit) => a + b.used;

const RtsTicketCreditsRedemption: React.FC = () => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);

  const selectedTickets = useSelector(selectTicketTypes);
  const ticketCredits = useSelector(selectTicketCredits);
  const ticketCreditsAvailable = useSelector(selectTicketCreditsAvailable);

  const creditsUsed = ticketCredits?.reduce(sumCreditsUsed, 0) ?? 0;

  const countOfActualTickets = selectedTickets
    ? getTotalNumberActualTickets(selectedTickets.ticketTypeModels)
    : 0;

  const handleApplyTicketCredits = (context: QuantitySelectorContext) => {
    if (!ticketCredits) return;
    const credits = [...ticketCredits];
    if (context === 'add') {
      const creditToUpdate = credits
        .toSorted(priceDescending)
        .find((x: TicketCredit) => x.used < x.max);
      if (creditToUpdate) creditToUpdate.used += 1;
      dispatch(actionCreators.setTicketCredits(credits));
    } else {
      const creditToUpdate = credits
        .toSorted(priceAscending)
        .find((x: TicketCredit) => x.used > 0);
      if (creditToUpdate) creditToUpdate.used -= 1;
      dispatch(actionCreators.setTicketCredits(credits));
    }
  };
  if (!ticketCreditsAvailable) return null;
  return (
    <div className='mt-2'>
      <p className='fw-bold text-uppercase text-center mb-2'>
        {ticketCreditsAvailable}{' '}
        {content.payment.loyaltyRecognitionTicketCreditsAvailableLabel}
      </p>
      <RichText
        text={content.payment.loyaltyRecognitionTicketCreditsRichText}
      />
      <div className='d-flex align-items-center justify-content-center border-y-solid mt-1 p-2'>
        <QuantityButton
          context='remove'
          disabled={creditsUsed <= 0}
          onClick={() => handleApplyTicketCredits('remove')}
        />
        <div className='flex-grow-1 text-center'>
          {content.payment.loyaltyRecognitionTicketCreditLabel}{' '}
          <span className='fw-bold'>x{creditsUsed}</span>
        </div>
        <QuantityButton
          context='add'
          disabled={
            creditsUsed >= ticketCreditsAvailable ||
            creditsUsed >= countOfActualTickets
          }
          onClick={() => handleApplyTicketCredits('add')}
        />
      </div>
    </div>
  );
};

export default RtsTicketCreditsRedemption;
