import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  ChangeGiftCardByIdContext,
  QuantitySelectorContext,
} from '../../../../../@types/actionTypes';
import { Concession } from '../../../../../@types/modelTypes';
import { displayPrice } from '../../../../../services/Helpers';
import {
  selectCurrencyConfig,
  selectJourneyTypeConfig,
} from '../../../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../../../svgs/radioUnselected.svg';
import QuantityButton from '../../../quantitybutton/QuantityButton';

interface Props {
  quantity: number;
  addIsDisabled: boolean;
  concession?: Concession;
  handleQuantityClick?: (context: ChangeGiftCardByIdContext) => void;
  handleConcessionChange?: (
    context: ChangeGiftCardByIdContext,
    concession: Concession
  ) => void;
  selectQuantityText: string;
  maxQuantity: number;
  optionButtonSelectedText: string;
  optionButtonUnselectedText: string;
  showPrice?: boolean;
  price?: number;
}
const ContextAwareQuantitySelector: React.FC<Props> = ({
  quantity,
  addIsDisabled,
  concession,
  handleQuantityClick,
  handleConcessionChange,
  selectQuantityText,
  maxQuantity,
  optionButtonSelectedText,
  optionButtonUnselectedText,
  showPrice,
  price,
}) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const concessionSelected = quantity >= 1;

  const renderButton = (
    context: QuantitySelectorContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleButtonClick(context)}
      />
    );
  };

  const handleButtonClick = (context: QuantitySelectorContext) => {
    if (handleConcessionChange && concession) {
      handleConcessionChange(context, concession);
    } else if (handleQuantityClick) {
      handleQuantityClick(context);
    }
  };

  return (
    <div className='context-aware-quantity-selector d-flex align-items-center justify-content-center'>
      {maxQuantity === 1 && !journeyTypeConfig.isConcessionsOnlyJourney && (
        <div className='options-selector-container'>
          <Button
            className={classNames(
              'option-button px-2 py-1 d-flex justify-content-center',
              concessionSelected && 'selected'
            )}
            onClick={() =>
              handleButtonClick(concessionSelected ? 'remove' : 'add')
            }
          >
            <div className='d-flex flex-direction-column align-items-center'>
              {concessionSelected ? (
                <RadioSelectedSvg className='check' />
              ) : (
                <RadioUnselectedSvg className='check' />
              )}

              <div>
                {concessionSelected
                  ? optionButtonSelectedText
                  : optionButtonUnselectedText}
              </div>
            </div>
          </Button>
        </div>
      )}
      {maxQuantity > 1 && (
        <div>
          <div className='justify-content-center d-flex fw-bold tiny'>
            {selectQuantityText}
          </div>
          <div className='justify-content-center d-flex'>
            <div className='quantity-selector d-flex justify-content-between'>
              <div className='quantity-button-container'>
                {renderButton('remove', quantity === 0)}
              </div>
              <div className='quantity-col d-flex align-items-center justify-content-center fw-bold mx-2'>
                {quantity}
              </div>
              <div className='quantity-button-container'>
                {renderButton('add', addIsDisabled)}
              </div>
            </div>
          </div>
        </div>
      )}
      {showPrice !== undefined && price !== undefined && (
        <div className='options-price text-end'>
          {displayPrice(price, currencyConfig)}
        </div>
      )}
    </div>
  );
};

export default ContextAwareQuantitySelector;
