import React from 'react';

import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import {
  selectContentCartSummary,
  selectDisplayPrice,
  selectDonation,
} from '../../../store/Selectors';

const CartSummaryDonations: React.FC = () => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const donation = useSelector(selectDonation);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, donation)
  );

  if (donation === 0) return null;

  return (
    <div
      className='cart-summary-row cart-summary-row-flex'
      data-testid='cs-donations'
    >
      {contentCartSummary.donationsLabel}
      <span className='text-end'>{priceToDisplay}</span>
    </div>
  );
};

export default CartSummaryDonations;
