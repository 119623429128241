import { useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { JOURNEY_TYPES } from '../constants';
import {
  selectConfig,
  selectBookingData,
  selectToken,
  selectJourneyTypeConfig,
  selectJourneyType,
} from '../store/Selectors';

export const useValidateJourneyShowtime = () => {
  const navigate = useNavigate();
  const { externalCinemaId, externalSessionId } = useParams();

  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const token = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const journeyType = useSelector(selectJourneyType);

  const redirectToStartTicketing = useCallback(() => {
    navigate(`/startticketing/${externalCinemaId}/${externalSessionId}`);
  }, [navigate, externalCinemaId, externalSessionId]);

  const isValidRouteData =
    !!config &&
    !!token &&
    externalSessionId === bookingData.externalSessionId &&
    externalCinemaId === bookingData.externalCinemaId;

  useEffect(() => {
    if (
      !isValidRouteData &&
      !journeyTypeConfig.isConcessionsOnlyJourney &&
      journeyType !== JOURNEY_TYPES.SEASON_PASS
    ) {
      redirectToStartTicketing();
    }
  }, [
    isValidRouteData,
    journeyTypeConfig,
    journeyType,
    redirectToStartTicketing,
  ]);
};
