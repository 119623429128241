import React from 'react';

import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import SingleItemRow from './SingleItemRow';

import { Concession } from '../../../../../@types/modelTypes';
import {
  selectEGiftCardGroup,
  selectEGiftCardImage,
} from '../../../../../store/Selectors';
import GiftCardImage from '../../GiftCardImage';

const SingleItemLayout: React.FC = () => {
  const eGiftCardImage = useSelector(selectEGiftCardImage);
  const eGiftCardGroup = useSelector(selectEGiftCardGroup);

  return (
    <Row
      className={classNames(
        'giftcards-row d-flex align-items-start justify-content-center'
      )}
    >
      <Col sm={'auto'} className='image-container pt-3'>
        <GiftCardImage imageUrl={eGiftCardImage} />
      </Col>
      <Col className='text-start single-item-row-container'>
        {eGiftCardGroup?.items.map((concession: Concession) => (
          <SingleItemRow key={`${concession.id}`} concession={concession} />
        ))}
      </Col>
    </Row>
  );
};

export default SingleItemLayout;
