import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import {
  SeatButtonStyle,
  SeatMapRow,
  SeatMapSeat,
} from '../../../../@types/modelTypes';
import { selectSeatsModel } from '../../../../store/Selectors';
import SeatRow from '../GridLayout/SeatRow';

interface GridStrategyProps {
  forceSeatText: boolean;
  handleSeatClick: (seat: SeatMapSeat) => void;
  seatButtonStyle: SeatButtonStyle;
  overrideSoldAsGroup: boolean;
}

const GridStrategy: React.FC<GridStrategyProps> = ({
  forceSeatText,
  handleSeatClick,
  seatButtonStyle,
  overrideSoldAsGroup,
}) => {
  const seatsModel = useSelector(selectSeatsModel);

  const [activeRow, setActiveRow] = useState<SeatMapRow | null>(null);

  return (
    <div className='grid-strategy strategy-wrapper'>
      {seatsModel.seatsLayoutModel.rows.map((r, i) => (
        <SeatRow
          key={`${r.physicalName}_${i}`}
          row={r}
          rowIndex={i}
          activeRow={activeRow}
          forceSeatText={forceSeatText}
          handleSeatClick={handleSeatClick}
          setActiveRow={setActiveRow}
          seatButtonStyle={seatButtonStyle}
          overrideSoldAsGroup={overrideSoldAsGroup}
        />
      ))}
    </div>
  );
};

export default GridStrategy;
