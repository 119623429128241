/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectContent,
} from '../../../../store/Selectors';
import { ReactComponent as GiftCardSvg } from '../../../../svgs/giftcard.svg';
import RichText from '../../richtext/RichText';
import GiftCardPayment from '../giftcardcomponents/GiftCardPayment';
import CreditCardPaymentsContainer from '../paymentcomponents/cardpayments/CreditCardPaymentsContainer';
import PaymentIconsDesignContainer from '../paymentcomponents/cardpayments/paymenticons/PaymentIconsDesignContainer';

interface Props {
  creditCardType: string;
  showGiftCardForm: boolean;
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  setCreditCardType: (creditCardType: string) => void;
}

const TabbedFullPaymentDesign: React.FC<Props> = ({
  creditCardType,
  showGiftCardForm,
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setCreditCardType,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const appliedGiftCards = useSelector(selectAppliedGiftCards);

  const handleRemoveOtherPayments = () => {
    if (appliedGiftCards.length) {
      dispatch(actionCreators.removeAllGiftCards());
    }
    dispatch(actionCreators.setSelectedPaymentProvider(null));
    dispatch(actionCreators.removeHostedPayments());
  };

  return (
    <div
      className='full-payment-wrapper text-start'
      data-testid='tabbed-full-payment-design'
      sx={{
        '.tab-content': {
          border: 'mostReadableOnWebsiteBackgroundBorder',
        },

        '.nav-tabs': {
          backgroundColor: 'boxHeaderBackground',

          '& .nav-link.active, & .nav-item.show .nav-link': {
            border: 'mostReadableOnWebsiteBackgroundBorder',
            borderBottom: 'none',
          },
        },

        '.nav-item': {
          'a.active': {
            backgroundColor: 'boxBackground',
            color: 'mostReadableOnWebsiteBackground',
          },

          'a:not(.active)': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            borderBottom: 'mostReadableOnWebsiteBackgroundBorder',
          },
        },
      }}
    >
      <Row className='my-3'>
        <Col xs={12} className='contained'>
          <div className='tabbed-container bordered'>
            <Tab.Container id='left-tabs-example' defaultActiveKey='payment'>
              <Nav fill variant='tabs' className='flex-row'>
                <Nav.Item>
                  <Nav.Link
                    eventKey='payment'
                    onClick={handleRemoveOtherPayments}
                  >
                    {content.payment.tabHeadingCreditCard}
                    <PaymentIconsDesignContainer
                      paymentDesignName='tabbed'
                      creditCardType={creditCardType}
                    />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey='giftcard'>
                    {content.payment.tabHeadingGiftCard}
                    <div className='payment-icons my-2'>
                      <GiftCardSvg className='giftcard' />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content className='p-3'>
                <Tab.Pane eventKey='payment'>
                  <CreditCardPaymentsContainer
                    creditCardType={creditCardType}
                    isPageValidated={isPageValidated}
                    handleValidatePage={handleValidatePage}
                    setCreditCardType={setCreditCardType}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey='giftcard'>
                  {content.payment.giftCardEnterFullPaymentRichText && (
                    <RichText
                      className='mb-2'
                      text={content.payment.giftCardEnterFullPaymentRichText}
                    />
                  )}
                  <GiftCardPayment
                    setPaymentFormVisibility={setPaymentFormVisibility}
                    isPageValidated={isPageValidated}
                    handleValidatePage={handleValidatePage}
                    isShown={showGiftCardForm}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TabbedFullPaymentDesign;
