/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect } from 'react';

import classnames from 'classnames';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { shouldHideCartSummary } from '../../../services/Helpers';
import TokenReplacer from '../../../services/TokenReplacer';
import {
  selectBookingData,
  selectContent,
  selectSelectedSeasonPassItem,
  selectSelectedSeasonPassSessions,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import Heading from '../heading/Heading';

const SeasonPassShowtimesTopBar: React.FC = () => {
  const content = useSelector(selectContent);
  const bookingData = useSelector(selectBookingData);
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();
  const hideCartSummary = shouldHideCartSummary(pathname);

  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);
  const { minRequiredMovies } = selectedSeasonPassItem;
  const selectedNumberOfSessions = selectedSeasonPassSessions?.length;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const topbar = document.querySelector('.seasonpass-topbar');

      if (topbar) {
        if (bookingData?.isUserValidated) {
          if (scrollTop > 32) {
            topbar.classList.remove('extra-margin');
          } else {
            topbar.classList.add('extra-margin');
          }
        } else {
          topbar.classList.remove('extra-margin');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bookingData]);

  const originalText = TokenReplacer(
    content.seasonPass.selectSessionsTopBarText
  );

  const regex = new RegExp(
    `(${selectedNumberOfSessions}.*?${minRequiredMovies})`
  );

  const parts = originalText.split(regex);

  const showCheckmark = selectedNumberOfSessions >= minRequiredMovies;

  return (
    <Col
      lg={hideCartSummary ? 12 : 8}
      className={classnames(
        'seasonpass-topbar offcanvas-title',
        !isLargeScreenWidth && 'w-100'
      )}
      data-testid='header-login'
      sx={{
        bg: 'websiteBackground',
        boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
      }}
    >
      <Heading
        size={2}
        className={classnames(
          'select-heading d-flex align-items-center justify-content-center',
          showCheckmark && 'all-selected'
        )}
      >
        {showCheckmark && <RadioSelectedSvg className='all-selected-check' />}
        <span>
          {parts.map((part, index) =>
            regex.test(part) ? <strong key={index}>{part}</strong> : part
          )}
        </span>
      </Heading>
    </Col>
  );
};

export default SeasonPassShowtimesTopBar;
