import React from 'react';

import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  Concession,
  ConcessionPricing,
  GlobalState,
} from '../../../@types/modelTypes';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectContent,
  selectCurrentCinema,
  selectDisplayPrice,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import TierImage from '../loyalty/TierImage';

interface Props extends Concession {
  defaultImage: string;
}

const TierItem: React.FC<Props> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  defaultImage,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
  };
  const currentCinema = useSelector(selectCurrentCinema);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const hideTax: boolean = currentCinema.hideTax;

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const selectedConcession: Concession | undefined =
    selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const displayPrice = hideTax
    ? itemPricing.costIncTax
    : itemPricing.costMinusTax;
  const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * displayPrice;

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, subTotal)
  );
  const selected = quantityInCart >= 1;

  return (
    <Row className='text-center align-items-center'>
      <Col md={12} sm={12}>
        <Row className='text-center align-items-center'>
          <Col xs={12} sm={'auto'} className='py-2 image-container'>
            <TierImage imageUrl={imagePath || content.giftCards.defaultImage} />
          </Col>
          <Col className='details text-start'>
            <span className='h3'>{concession.description}</span>
          </Col>
          <Col className='d-flex flex-wrap text-end align-items-right'>
            <div className='col'>
              <span className={classNames(selected && 'fw-bold')}>
                {itemPricing.costMinusTax < 100
                  ? content.loyalty.signupFreeTierCostText
                  : priceToDisplay}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TierItem;
