import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Concession, OrderDelivery } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useMaintainDeliveryItemCount } from '../../../hooks/useMaintainDeliveryItemCount';
import { setGiftCardConcessions } from '../../../services/GiftCardHelpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectLoading,
  selectPhysicalGiftCardRecipient,
  selectQueryString,
  selectSelectedGiftCards,
  selectStep,
  selectToken,
  selectJourneyTypeConfig,
  selectMemberGiftCardGroup,
  selectConcessionsAdded,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import DeliveryDetails from '../../common/concessionsonlycommon/physicalgiftcard/DeliveryDetailsPhysical';
import PhysicalConcessionItem from '../../common/concessionsonlycommon/physicalgiftcard/SinglePhysicalGiftCardOptionRow';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';

const GiftMembershipSelect: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bookingData = useSelector(selectBookingData);
  const concessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const loading = useSelector(selectLoading);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );
  const queryString = useSelector(selectQueryString);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const step: number = useSelector(selectStep);
  const giftMembershipGroup = useSelector(selectMemberGiftCardGroup);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const [validateForm, setValidateForm] = useState<boolean>(false);

  const hasConcessions = selectedConcessions
    ? selectedConcessions.list.some((x: Concession) => x.quantity > 0)
    : false;

  const selectedGiftCardsList = selectedConcessions.list.filter(
    (concession) => !concession.isDeliveryItem
  );

  const totalCardsSelected = selectedGiftCardsList.reduce(
    (a: number, b: Concession) => a + (b['quantity'] || 0),
    0
  );

  useMaintainDeliveryItemCount(totalCardsSelected);

  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/GiftCard/GetMemberGiftCards/${bookingData.circuitId}/${bookingData.cinemaId}`;
      const response = await backend.get(path);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        setGiftCardConcessions(
          response.content.listConcessionGrouping,
          queryString,
          dispatch
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!concessions && bookingData) {
      getConcessionData();
    }
  }, [bookingData, concessions, dispatch, queryString]);

  const navigateToNextPath = () => {
    navigate(`/${getRouteFromStep(journeyTypeConfig, step + 1)}`);
  };

  const addGiftCardsSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: false,
        isGiftWrapped: false,
        giftMessage: physicalGiftCardRecipient.message,
        deliveryAddress: {
          name: physicalGiftCardRecipient.name,
          address1: physicalGiftCardRecipient.addressLineOne,
          address2: physicalGiftCardRecipient.addressLineTwo,
          town: physicalGiftCardRecipient.city,
          postcode: physicalGiftCardRecipient.postcode,
        },
        billingAddress: {
          name: customer.name,
          email: customer.email,
        },
      };

      selectedConcessions?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedConcessions
          ? selectedConcessions.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const response = await backend.post(
        `api/GiftCard/AddMemberGiftCards`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        navigateToNextPath();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addGiftCardsSelectionToPos();
  };

  const getPhysicalGiftCardsRows = () => {
    return giftMembershipGroup?.items?.map((concession: Concession) => (
      <PhysicalConcessionItem
        {...concession}
        key={concession.id}
        defaultImage={content.giftMembership.giftMembershipDefaultImage}
        totalCardsSelected={totalCardsSelected}
      />
    ));
  };

  if (!content.giftMembership || !config) return null;

  return (
    <div className='giftcards' data-testid='giftcards'>
      <Row className='text-center'>
        <Col>
          <Heading size={1}>{content.giftMembership.stepOneHeading}</Heading>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='contained'>
          <Heading className='mt-3 text-center' size={2}>
            1. {content.giftMembership.selectGiftMembershipsHeading}
          </Heading>
          <RichText
            text={content.giftMembership.selectGiftMembershipsRichText}
          />
        </Col>
      </Row>

      {giftMembershipGroup && giftMembershipGroup.items.length > 0 ? (
        <Row>
          <Col>
            <Row>
              <Col md={10} className='giftcard-rows-container mt-4'>
                {getPhysicalGiftCardsRows()}
              </Col>
            </Row>

            {!showRecipientForm ? (
              <ActionButton
                disabled={!hasConcessions}
                mt='mt-5'
                mx='mx-3'
                onClick={() => setShowRecipientForm(true)}
                sticky={false}
                showIcon={true}
                contained
                variant='primary'
              >
                {content.giftMembership.continueToDetailsButtonText}
              </ActionButton>
            ) : (
              <>
                <DeliveryDetails isPageValidated={validateForm} />
                <Col xs={12} className='contained'>
                  <RichText text={content.giftMembership.additionalRichText} />
                </Col>

                <ActionButton
                  mt='mt-5'
                  mx='mx-3'
                  sticky={false}
                  showIcon={true}
                  contained
                  onClick={handleContinueToPaymentClick}
                  disabled={
                    !hasConcessions ||
                    !physicalGiftCardRecipient.isValid ||
                    !customer.isValid
                  }
                  stickyMobileDesktop={false}
                  variant='primary'
                >
                  {content.giftMembership.continueToPaymentButtonText}
                </ActionButton>
              </>
            )}
          </Col>
        </Row>
      ) : (
        !loading && (
          <Row>
            <Col xs={12} className='contained mt-1'>
              <div className='warning-container mt-3 p-3'>
                <p>{content.giftMembership.noGiftMembershipAvailableText}</p>
              </div>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default GiftMembershipSelect;
