/** @jsxImportSource theme-ui */
import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { GoBackButton } from './GoBackButton';
import SeasonPassShowtimesTopBar from './SeasonPassShowtimesTopBar';

import { JOURNEY_TYPES, SEASON_PASS_STEPS } from '../../../constants';
import {
  selectJourneyTypeConfig,
  selectSelectedSeasonPassSessions,
  selectStep,
} from '../../../store/Selectors';
import ExtraMenu from '../extramenu/ExtraMenu';
import Logo from '../logo/Logo';

const HeaderContainer: React.FC = () => {
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );
  const step = useSelector(selectStep);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const shoulShowSeasonPassShowtimesTopBar =
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS &&
    step === SEASON_PASS_STEPS.SHOWTIMES &&
    selectedSeasonPassSessions.length > 0;

  return (
    <>
      {shoulShowSeasonPassShowtimesTopBar && (
        <div className='seasonpass-topbar-container header-container'>
          <SeasonPassShowtimesTopBar />
        </div>
      )}
      <Row
        className='header-container g-0 my-3'
        data-testid='header-container'
        sx={{
          button: {
            color: 'mostReadableOnWebsiteBackground',
            svg: {
              '.icon': {
                fill: 'mostReadableOnWebsiteBackground',
              },
            },

            '&:hover': {
              svg: {
                '.icon': {
                  fill: 'primary',
                },
              },
            },
          },
        }}
      >
        <Col
          xs={3}
          className='header-button-container d-flex justify-content-start'
        >
          <GoBackButton />
        </Col>
        <Col xs={6}>
          <Logo />
        </Col>
        <Col
          xs={3}
          className='header-button-container d-flex justify-content-end'
        >
          <ExtraMenu />
        </Col>
      </Row>
    </>
  );
};

export default HeaderContainer;
