/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import classNames from 'classnames';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import { BaseResponse, GlobalState } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedCeaCards,
  selectCeaCardTicketTypeModel,
  selectContent,
  selectDisplayPrice,
  selectIsSeatsFirstJourney,
  selectTicketTypes,
  selectUseDynamicTicket,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ActionButtonSpinner from '../actionbuttonspinner/ActionButtonSpinner';
import BorderedCollapse from '../borderedcollapse/BorderedCollapse';

enum ContextEvent {
  ADD_TICKET,
  REMOVE_TICKET,
}

interface Props {
  orderHasMaxTickets: boolean;
}

const CeaCardsSelector: React.FC<Props> = ({ orderHasMaxTickets }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const {
    tickets: ticketsContent,
    payment: paymentContent,
    checkButtonText,
  } = useSelector(selectContent);
  const ticketTypes = useSelector(selectTicketTypes);
  const appliedCeaCards = useSelector(selectAppliedCeaCards);
  const ceaCardTicketTypeModel = useSelector(selectCeaCardTicketTypeModel);
  const ceaCardDisplayPrice = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, ceaCardTicketTypeModel?.price)
  );
  const useDynamicTicket = useSelector(selectUseDynamicTicket);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  const [showCeaCardForm, setShowCeaCardForm] = useState(
    !!appliedCeaCards.length
  );
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [ceaCardInput, setCeaCardInput] = useState('');
  const [feedback, setFeedback] = useState<string | undefined>(undefined);

  const handleCeaCardClick = async (context: ContextEvent) => {
    if (!ticketTypes || !ceaCardTicketTypeModel) {
      return;
    }

    if (context === ContextEvent.ADD_TICKET) {
      useDynamicTicket &&
        dispatch(actionCreators.addSingleTicket(ceaCardTicketTypeModel));

      ceaCardTicketTypeModel.quantity += 1;
    } else {
      useDynamicTicket &&
        dispatch(actionCreators.removeSingleTicket(ceaCardTicketTypeModel));

      ceaCardTicketTypeModel.quantity -= 1;

      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
    }

    dispatch(
      actionCreators.setTicketTypes({
        ticketTypeModels: ticketTypes.ticketTypeModels,
      })
    );
  };

  if (!ceaCardTicketTypeModel || !ticketTypes) return null;

  const handleValidateCeaCardResponse = (
    validateCeaCardResponse: BaseResponse
  ) => {
    setDisableApplyButton(false);

    const { ceaCardErrorText, ceaCardExpiredText } = ticketsContent;

    if (validateCeaCardResponse.peachCode === PEACH_CODES.noError) {
      handleCeaCardClick(ContextEvent.ADD_TICKET);
      dispatch(actionCreators.addCeaCard(ceaCardInput));
      setFeedback(undefined);
      setCeaCardInput('');
    } else if (
      validateCeaCardResponse.peachCode === PEACH_CODES.loyaltyMemberExpired
    ) {
      setFeedback(ceaCardExpiredText);
    } else {
      setFeedback(ceaCardErrorText);
    }
  };

  const handleCeaCardCheck = async () => {
    if (!ceaCardInput) return;
    setDisableApplyButton(true);

    if (!appliedCeaCards.includes(ceaCardInput)) {
      setFeedback(undefined);
      dispatch(
        actionCreators.validateCeaCard({
          ceaCardCode: ceaCardInput,
          executeRecaptcha,
          handleValidateCeaCardResponse,
          turnstile,
          recaptcha,
        })
      );
    } else {
      setDisableApplyButton(false);
      setFeedback(ticketsContent.ceaCardAlreadyAppliedText);
    }
  };

  const handleRemoveCeaCard = (cardNumber: string) => {
    setFeedback(undefined);

    dispatch(actionCreators.removeCeaCard(cardNumber));
    handleCeaCardClick(ContextEvent.REMOVE_TICKET);
  };

  return (
    <BorderedCollapse
      closeButtonText={paymentContent.closeButtonText}
      heading={ticketsContent.ceaCardHeading}
      setShow={setShowCeaCardForm}
      show={showCeaCardForm}
    >
      <div className='voucher-container' data-testid='ceacard-selector'>
        <div className='voucher-selector'>
          <p>{ticketsContent.ceaCardLabel}</p>
          <Form>
            <Form.Label
              className={classNames(disableApplyButton && 'disabled')}
            />
            <Row className='g-0'>
              <Col className='pe-2'>
                <Form.Control
                  id='ceaCardNumber'
                  name='ceaCardNumber'
                  type='text'
                  placeholder={ticketsContent.ceaCardPlaceHolder}
                  onChange={(e) => {
                    setCeaCardInput(e.target.value);
                  }}
                  value={ceaCardInput}
                  disabled={disableApplyButton || orderHasMaxTickets}
                />
              </Col>
              <Col xs={'auto'}>
                <ActionButton
                  onClick={handleCeaCardCheck}
                  disabled={disableApplyButton || orderHasMaxTickets}
                  variant='secondary'
                  mb='mb-0'
                  mt='mt-0'
                >
                  {disableApplyButton ? (
                    <ActionButtonSpinner />
                  ) : (
                    checkButtonText
                  )}
                </ActionButton>
              </Col>
            </Row>
          </Form>
          {!!feedback && (
            <div className='warning-container mt-3 p-3'>
              <p>{feedback}</p>
            </div>
          )}
          {appliedCeaCards.map((c) => (
            <Row
              className='g-0 voucher-row'
              key={`${ceaCardTicketTypeModel.id}${c}`}
              sx={{
                '&:not(:last-child)': {
                  borderBottom: 'boxSeparator',
                },
              }}
            >
              <Col xs={1} className='d-flex align-items-center'>
                <span className='quantity'>1 x</span>
              </Col>
              <Col xs={8} className='d-flex align-items-center text-start'>
                <span>{`${ceaCardTicketTypeModel.displayName} ${c}`}</span>
              </Col>
              <Col xs={1} className='d-flex align-items-center'>
                <Button
                  onClick={() => handleRemoveCeaCard(c)}
                  variant='link'
                  size='sm'
                  className='text-uppercase'
                >
                  {paymentContent.removeButtonText}
                </Button>
              </Col>
              <Col
                xs={2}
                className='d-flex align-items-center justify-content-end'
              >
                <b>{ceaCardDisplayPrice}</b>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </BorderedCollapse>
  );
};

export default CeaCardsSelector;
