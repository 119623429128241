import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeUIProvider } from 'theme-ui';
import type { Theme } from 'theme-ui';

import { AnalyticsProvider } from './analytics/analyticsContext';
import AppRoutes from './AppRoutes';
import LoadingMask from './components/common/loadingmask/LoadingMask';
import { RecaptchaProvider } from './contextProviders/recaptchaContext';
import { ScreenWidthProvider } from './contextProviders/screenWidthContext';
import { TurnstileProvider } from './contextProviders/turnstileContext';
import { useDidomi } from './hooks/useDidomi';
import { createTheme } from './services/CreateTheme';
import { selectConfig, selectLoading } from './store/Selectors';

import './scss/app.scss';

export interface ProviderProps {
  children: React.ReactNode;
}

const App = () => {
  useDidomi();

  const config = useSelector(selectConfig);
  const loading = useSelector(selectLoading);

  const [theme, setTheme] = useState<Theme>({});

  useEffect(() => {
    if (config) {
      const newTheme = createTheme(config);
      setTheme(newTheme);
    }
  }, [config]);

  return (
    <div className='app'>
      {loading && <LoadingMask />}

      <ThemeUIProvider theme={theme}>
        <BrowserRouter>
          <AnalyticsProvider>
            <TurnstileProvider>
              <RecaptchaProvider>
                <ScreenWidthProvider>
                  <AppRoutes />
                </ScreenWidthProvider>
              </RecaptchaProvider>
            </TurnstileProvider>
          </AnalyticsProvider>
        </BrowserRouter>
      </ThemeUIProvider>
    </div>
  );
};

export default App;
