/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import classnames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { selectContent, selectQueryString } from '../../../store/Selectors';
import { ReactComponent as ButtonAddSvg } from '../../../svgs/buttonAdd.svg';
import { ReactComponent as ButtonRemoveSvg } from '../../../svgs/buttonRemove.svg';

interface Props {
  context: string;
  disabled: boolean;
  onClick: () => void;
  size?: 'tiny' | 'small';
}

const QuantityButton: React.FC<Props> = ({
  context,
  disabled,
  onClick,
  size,
}) => {
  const content = useSelector(selectContent);
  const queryString = useSelector(selectQueryString);

  const [isDisabled, setIsDisabled] = useState(false);
  const [touchStart, setTouchStart] = useState(false);

  const handleClick = () => {
    if (isDisabled) return;
    const shouldUseTimeout = queryString?.includes('voiceover=true');

    if (shouldUseTimeout) {
      setIsDisabled(true);
      onClick();
      setTimeout(() => {
        setIsDisabled(false);
      }, 800);
    } else {
      onClick();
    }
  };

  const onTouchStart = () => {
    setTouchStart(true);
  };

  const onTouchEnd = () => {
    setTouchStart(false);
  };

  const addItemText = content.qtyButtonAddItemText;
  const removeItemtext = content.qtyButtonRemoveItemText;

  return (
    <button
      aria-label={context === 'add' ? addItemText : removeItemtext}
      className={classnames(
        'quantity-button',
        isMobile && 'is-mobile',
        touchStart && 'touch-start',
        !!size && size
      )}
      data-testid={`quantity-button-${context}`}
      disabled={disabled}
      onClick={handleClick}
      onTouchEnd={() => onTouchEnd()}
      onTouchStart={() => onTouchStart()}
      title={context === 'add' ? addItemText : removeItemtext}
      type='button'
      sx={{
        '.btn-qty-a': {
          fill: 'uiContainerBackground',
        },

        '.btn-qty-b': {
          fill: 'primary',
        },

        '.btn-qty-c': {
          fill: 'mostReadableOnWebsiteBackground',
        },

        '&.touch-start:enabled svg .btn-qty-a, &:not(.is-mobile):hover:enabled svg .btn-qty-a':
          {
            fill: 'primary',
          },

        '&.touch-start:enabled svg .btn-qty-c, &:not(.is-mobile):hover:enabled svg .btn-qty-c':
          {
            fill: 'mostReadableOnPrimaryBackground',
          },
      }}
    >
      {context === 'add' ? <ButtonAddSvg /> : <ButtonRemoveSvg />}
    </button>
  );
};

export default QuantityButton;
