import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { displaySelectedSeatsNames } from '../../../services/Helpers';
import {
  selectContentCartSummary,
  selectSelectedSeatNames,
  selectSelectedSeats,
} from '../../../store/Selectors';

interface Props {
  hideTotal?: boolean;
}

const CartSummarySeats: React.FC<Props> = ({ hideTotal }) => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const selectedSeats = useSelector(selectSelectedSeats);
  const selectedSeatNames = useSelector(selectSelectedSeatNames);
  if (!selectedSeats || selectedSeats.length <= 0) return null;

  return (
    <div
      className={classnames(
        'cart-summary-row cart-summary-row-flex',
        hideTotal && 'bottom-border'
      )}
      data-testid='cs-selected-seats'
    >
      {contentCartSummary.yourSeatsLabel}
      <span className='text-end'>
        {displaySelectedSeatsNames(selectedSeatNames)}
      </span>
    </div>
  );
};

export default CartSummarySeats;
