import React from 'react';

import GiftCardImage from '../concessionsonlycommon/GiftCardImage';
import Heading from '../heading/Heading';

interface Props {
  image?: string;
  heading: string;
}

const CartSummaryImage: React.FC<Props> = ({ image, heading }) => {
  return (
    <div
      className='cart-summary-row cart-summary-giftcard-image d-flex'
      data-testid='cs-giftcard-image'
    >
      <div className='image-container text-start'>
        <GiftCardImage imageUrl={image} />
      </div>
      <div className='text-container text-start'>
        <Heading size={2}>{heading}</Heading>
      </div>
    </div>
  );
};

export default CartSummaryImage;
