/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectDisplayPrice,
  selectGrandTotalAfterDiscountsInCents,
} from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';
import Countdown from '../countdown/Countdown';

const TotalWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const { isLargeScreenWidth } = useScreenWidth();

  const content = useSelector(selectContent);
  const grandTotalToUse = useSelector(selectGrandTotalAfterDiscountsInCents);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, grandTotalToUse)
  );

  return (
    <Row
      className={classNames(
        'total-wrapper justify-content-between',
        isLargeScreenWidth && 'mt-4'
      )}
      data-testid='totalwrapper'
      sx={{
        backgroundColor: 'totalBarBackground',
        color: 'mostReadableOnTotalBarBackground',

        '.total-text': {
          fontFamily: 'heading',
        },

        '.countdown-wrapper': {
          '.countdown-container': {
            backgroundColor: 'totalBarBackgroundLighter',
            border: 'mostReadableOnTotalBarBackgroundBorder',
          },
        },
      }}
    >
      {isLargeScreenWidth ? (
        <Col xs={{ span: 6, offset: 3 }}>
          <span className='total-text px-2'>
            {`${content.payment.totalToPayText} : ${priceToDisplay}`}
          </span>
        </Col>
      ) : (
        <>
          <Col
            xs={'auto'}
            className='cart-button'
            sx={{
              svg: {
                fill: 'mostReadableOnTotalBarBackground',
              },
            }}
          >
            <Button
              className='d-flex'
              onClick={() => dispatch(actionCreators.setCartSummaryOpen(true))}
              variant='link'
            >
              {grandTotalToUse ? <CartFull /> : <CartEmpty />}
            </Button>
          </Col>
          <Col className='text-start total-text p-0'>{priceToDisplay}</Col>
        </>
      )}
      <Col xs={6} lg={3} className='countdown-wrapper'>
        <Countdown />
      </Col>
    </Row>
  );
};

export default TotalWrapper;
