import { applyMiddleware, compose, createStore } from 'redux';
import { logger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { reducer } from './Reducers';
import rootSaga from './RootSaga';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState) {
  const middleware = [thunk, sagaMiddleware];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  // eslint-disable-next-line no-undef
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (
    isDevelopment &&
    typeof window !== 'undefined' &&
    window.devToolsExtension
  ) {
    enhancers.push(window.devToolsExtension());
  }
  if (isDevelopment) {
    middleware.push(logger);
  }

  const persistConfig = {
    key: 'root',
    storage: storageSession,
    blacklist: [
      'payment',
      'giftcard',
      'confirmationData',
      'requestData',
      'loading',
      'refundData',
      'hostedPayments',
      'showtimes',
      'error',
      'selectedPaymentProvider',
    ],
  };

  const persistedReducer = persistReducer(persistConfig, reducer);

  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  let persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
