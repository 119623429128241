import React from 'react';

import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { selectConfig, selectContent } from '../../../store/Selectors';
import RichText from '../richtext/RichText';

interface Props {
  marginClass?: string;
  paddingClass?: string;
}

const RecaptchaText: React.FC<Props> = ({ marginClass, paddingClass }) => {
  const { isMobileScreenWidth } = useScreenWidth();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  if (!config || !config.enableCaptcha || !content || !isMobileScreenWidth)
    return null;

  return (
    <Row
      className={classnames(
        marginClass ? marginClass : 'mt-1',
        paddingClass && paddingClass
      )}
      data-testid='recaptcha-text'
    >
      <Col xs={12} className='contained text-center'>
        <RichText
          className='rich-text-tiny'
          text={content.googleRecaptchaRichText}
        />
      </Col>
    </Row>
  );
};

export default RecaptchaText;
