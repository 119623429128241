import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Concession } from '../../../@types/modelTypes';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import SignUpSummaryTierItem from '../../common/signup/SignUpSummaryTierItem';

const SignupPayment: React.FC = () => {
  const navigate = useNavigate();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);

  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const [validateForms, setValidateForms] = useState(false);
  const [creditCardType, setCreditCardType] = useState('');

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setValidateForms(!nextShowPaymentForm);
  };

  useConfirmationPageRedirect();

  const concession: Concession | undefined = selectedConcessions.list.find(
    (c: Concession) => c.quantity === 1
  );

  if (!content || !config || !bookingData) return null;

  return (
    <div className='loyalty payment text-center' data-testid='loyalty-payment'>
      <div className='giftcards'>
        <Row className='text-center'>
          <Col xs={12}>
            <Heading size={1}>{content.loyalty.signupPaymentTitle}</Heading>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className='contained'>
            <Heading size={2} className='mt-3 text-center'>
              {content.loyalty.signupPaymentHeading}
            </Heading>
            <RichText
              text={content.loyalty.signupPaymentRichText}
              className='text-start'
            />
          </Col>
        </Row>
        <RecaptchaText />
        <div className='loyalty-rows-container mt-3'>
          <div className='separator' />
          <Row className='summary-row pb-1'>
            {concession ? (
              <Col xs={12}>
                <SignUpSummaryTierItem
                  {...concession}
                  key={concession.id}
                  defaultImage={
                    concession?.image?.replace('https://', '') ??
                    content.loyalty.defaultImage
                  }
                />
              </Col>
            ) : null}
          </Row>
          <Row className='text-start tiny pb-3'>
            <Col xs={12} className='contained'>
              <Row className='detail'>
                <Col>
                  <div>
                    <span className='fw-bold'>
                      {content.loyalty.namePaymentLabel}
                    </span>{' '}
                    <span>{customer.name}</span>
                  </div>

                  <div className='pt-1'>
                    <span className='fw-bold'>
                      {content.loyalty.emailPaymentLabel}
                    </span>{' '}
                    <span>{customer.email}</span>
                  </div>
                  {customer.zipCodeIsValidated && (
                    <div className='pt-1'>
                      <span className='fw-bold'>
                        {content.loyalty.zipCodePaymentLabel}
                      </span>{' '}
                      <span>{customer.zipCode}</span>
                    </div>
                  )}
                </Col>
                <Col>
                  <div>
                    <span className='fw-bold'>
                      {content.loyalty.telephonePaymentLabel}
                    </span>{' '}
                    <span>{customer.telephone}</span>
                  </div>

                  <div className='pt-1'>
                    <span className='fw-bold'>
                      {content.loyalty.signupDateOfBirthLabel}
                    </span>{' '}
                    <span>
                      {customer.month} / {customer.day} / {customer.year}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className='mt-2'>
                  <Button
                    onClick={() => navigate(-1)}
                    variant='link'
                    className='btn-edit b text-center text-uppercase'
                  >
                    {content.loyalty.paymentEditText}
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
          <div className='separator' />
        </div>
      </div>

      <PaymentDesignWrapper
        giftcardEnabled={false}
        loyaltyPaymentEnabled={false}
        creditCardType={creditCardType}
        showPaymentForm={true}
        showGiftCardForm={false}
        isPageValidated={validateForms}
        handleValidatePage={() => {
          setValidateForms(true);
        }}
        setPaymentFormVisibility={setPaymentFormVisibility}
        setGiftCardFormVisibility={undefined}
        setCreditCardType={setCreditCardType}
      />
    </div>
  );
};

export default SignupPayment;
