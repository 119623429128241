import React from 'react';

import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectDisplayPrice,
  selectLoyaltyRedemptionRewards,
} from '../../../store/Selectors';

const CartSummaryLoyaltyRedemption: React.FC = () => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const loyaltyRedemptionRewards = useSelector(selectLoyaltyRedemptionRewards);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, loyaltyRedemptionRewards)
  );
  if (!loyaltyRedemptionRewards) return null;

  const handleRemoveLoyaltyRedemption = () => {
    dispatch(actionCreators.setLoyaltyRedemptionRewards(0));
  };

  return (
    <div
      className='cart-summary-row d-flex w-100'
      data-testid='cs-loyalty-redemption'
    >
      <Col xs={8} className='text-start'>
        {content.payment.loyaltyRedemptionDollarsAppliedLabel}
      </Col>
      <Col xs={2} className='d-flex align-items-center'>
        <Button
          onClick={handleRemoveLoyaltyRedemption}
          variant='link'
          size='sm'
          className='text-uppercase small'
        >
          {content.payment.removeButtonText}
        </Button>
      </Col>
      <Col xs={2} className='text-end'>
        <span className='text-end'>{priceToDisplay}</span>
      </Col>
    </div>
  );
};

export default CartSummaryLoyaltyRedemption;
