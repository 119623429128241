import React from 'react';

import { Col, Row } from 'react-bootstrap';

import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';

const Warning: React.FC = () => {
  return (
    <div className='warning' data-testid='warning'>
      <Row>
        <Col xs={12} className='contained'>
          <ShowtimeHero />
        </Col>
      </Row>
    </div>
  );
};

export default Warning;
