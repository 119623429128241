import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import PaymentIcons from './PaymentIcons';

import { selectConfig, selectContent } from '../../../../../../store/Selectors';

interface Props {
  creditCardType: string;
  containerClassName?: string;
  showPaymentIconsText: boolean;
  paymentIconsTextClassName?: string;
}

const PaymentIconsContainer: React.FC<Props> = ({
  creditCardType,
  containerClassName,
  showPaymentIconsText,
  paymentIconsTextClassName,
}) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const allPaymentIconsHidden =
    !config.payment.showApplePay &&
    !config.payment.showGooglePay &&
    config.payment.hideAmex &&
    config.payment.hideDiscover &&
    config.payment.hideMaestro &&
    config.payment.hideMastercard &&
    config.payment.hideVisa;

  return (
    <>
      {allPaymentIconsHidden || (
        <>
          {showPaymentIconsText && (
            <div
              className={classNames(
                'payment-logos-text tiny',
                paymentIconsTextClassName && paymentIconsTextClassName
              )}
              data-testid='payment-logos-text'
            >
              {content.payment.paymentLogosText}
            </div>
          )}
          <div
            className={classNames(containerClassName && containerClassName)}
            data-testid='payment-icons-container'
          >
            <PaymentIcons cardType={creditCardType} config={config} />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentIconsContainer;
