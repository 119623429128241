/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { useSelector } from 'react-redux';
import { Grid, Box, NavLink } from 'theme-ui';

import ConcessionItem from './ConcessionItem';

import { Concession, ConcessionGrouping } from '../../../@types/modelTypes';
import {
  selectCurrentCinema,
  selectFaBConcessions,
} from '../../../store/Selectors';
import Heading from '../heading/Heading';

const TabbedKiosk: React.FC = () => {
  const tabContainer = useRef<null | HTMLDivElement>(null);
  const currentCinema = useSelector(selectCurrentCinema);
  const concessions = useSelector(selectFaBConcessions);
  const [activeTab, setActiveTab] = useState(0);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: true,
    loop: false,
    slides: '.embla-slide',
  });
  const selectTab = (i: number) => {
    if (tabContainer.current) {
      tabContainer.current.scrollIntoView({ block: 'start' });
    }
    setActiveTab(i);
  };
  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (emblaApi) {
      onSelect(emblaApi);
      emblaApi.on('reInit', onSelect).on('select', onSelect);
    }
  }, [emblaApi, onSelect]);

  return (
    <Grid className='available-concessions-tabs' gap={1} mt={2}>
      <Box
        sx={{
          backgroundColor: 'websiteBackground',
          '& .embla-button-prev': {
            background: 'categoryNavigationGradianLeftStart',
            left: 0,
          },
          '& .embla-button-next': {
            background: 'categoryNavigationGradianRightStart',
            right: 0,
          },
        }}
        px={0}
        className='sticky-top'
        my={2}
      >
        <Box>
          <div className='embla-viewport' ref={emblaRef}>
            <Box className='embla-container' py={3}>
              {concessions?.map((x: ConcessionGrouping, i: number) => (
                <NavLink
                  key={`category-tab-button-${i}`}
                  sx={{
                    border: 'mostReadableOnWebsiteBackgroundBorder',
                    backgroundColor: 'websiteBackground',
                    '&.active': {
                      backgroundColor: 'primary',
                      borderColor: 'primary',
                      color: 'mostReadableOnPrimaryBackground',
                    },
                    '&:hover': {
                      borderColor: 'primaryAlpha',
                      textDecoration: 'none',
                    },
                    '&:first-of-type': {
                      marginLeft: 4,
                    },
                    '&:last-of-type': {
                      marginRight: 4,
                    },
                  }}
                  onClick={() => selectTab(i)}
                  onKeyDown={(e) => (e.key === 'Enter' ? selectTab(i) : null)}
                  className={`embla-slide ${activeTab === i ? 'active' : ''}`}
                  py={2}
                  px={4}
                  mr={3}
                  tabIndex={0}
                >
                  {x.title}
                </NavLink>
              ))}
            </Box>
            <button
              className='embla-button embla-button-next'
              disabled={nextBtnDisabled}
              tabIndex={-1}
            />
            <button
              className='embla-button embla-button-prev'
              disabled={prevBtnDisabled}
              tabIndex={-1}
            />
          </div>
        </Box>
      </Box>
      <Box px={[0, 0, 8]} ref={tabContainer} sx={{ scrollMarginTop: '90px' }}>
        {concessions?.map((cg: ConcessionGrouping, i: number) => {
          const showImageColumn =
            currentCinema?.foodAndBeverageHideAllItemImages
              ? false
              : cg.items.some((c: Concession) => c.image);
          return (
            <Box
              variant='boxes.containedLarge'
              key={`category-tab-${i}`}
              sx={{ display: activeTab === i ? 'block' : 'none' }}
            >
              <Box
                mb={1}
                pb={3}
                sx={{
                  borderBottom: 'bodySeparator',
                }}
              >
                <Heading size={2}>{cg.title}</Heading>
              </Box>
              {cg.items?.map((y: Concession) => (
                <ConcessionItem
                  {...y}
                  key={y.id}
                  showImageColumn={showImageColumn}
                />
              ))}
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
};

export default TabbedKiosk;
