import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  ConsentData,
  DidomiInstance,
  DidomiRegulation,
  getRegulation,
} from '../services/DidomiHelpers';
import { actionCreators } from '../store/ActionCreators';
import { selectContent, selectConfig } from '../store/Selectors';

const GOOGLE_ANALYTICS_DIDOMI_ID = 'c:googleana-XMqagawa';
const GOOGLE_MARKETING_DIDOMI_ID = 'google';

const initialConsents: ConsentData = {
  analytics: true,
  marketing: true,
};

const getConsent = (
  didomiInstance: DidomiInstance | null | undefined
): ConsentData => {
  const regulation: DidomiRegulation | null = getRegulation(didomiInstance);
  const newConsents = { ...initialConsents };
  switch (regulation) {
    case 'gdpr':
      if (didomiInstance?.isConsentRequired?.()) {
        newConsents.analytics =
          didomiInstance.getUserConsentStatusForVendor?.(
            GOOGLE_ANALYTICS_DIDOMI_ID
          ) || false;
        newConsents.marketing =
          didomiInstance.getUserConsentStatusForVendor?.(
            GOOGLE_MARKETING_DIDOMI_ID
          ) || false;
      }
      break;
    case 'ccpa':
    case 'cpra':
      {
        const doNotSellStatus =
          didomiInstance?.CCPA?.getDoNotSellStatus?.() || false;
        newConsents.analytics = !doNotSellStatus;
        newConsents.marketing = !doNotSellStatus;
      }
      break;
    default:
      {
        newConsents.analytics = true;
        newConsents.marketing = true;
      }
      break;
  }
  return newConsents;
};

export const useDidomi = () => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const [isConfigured, setIsConfigured] = useState<boolean>(false);

  useEffect(() => {
    // sets consent to true when didomi is disabled in config
    const didomiIsNotEnabled = config && !config.didomiApiKey;
    if (didomiIsNotEnabled) {
      const initialConsents: ConsentData = {
        analytics: true,
        marketing: true,
      };
      dispatch(actionCreators.setConsent(initialConsents));
    }
  }, [config, dispatch]);

  useEffect(() => {
    // configures didomi
    window.didomiConfig = window.didomiConfig || {};
    const shouldConfigureDidomi =
      window.didomiConfig &&
      !window.didomiConfig?.app &&
      !isConfigured &&
      content &&
      config;

    if (shouldConfigureDidomi) {
      window.didomiConfig.integrations = {
        vendors: {
          gcm: {
            enable: true,
            setDefaultStatus: true,
            defaultStatus: {
              analytics: true,
              ad: true,
            },
          },
        },
      };
      window.didomiConfig.app = {
        ...window.didomiConfig?.app,
        name: content.title,
        logoUrl: content.logoUrl,
      };
      if (config.didomiPrivacyPolicyUrl) {
        window.didomiConfig.app.privacyPolicyURL =
          config.didomiPrivacyPolicyUrl;
      }
      window.didomiConfig.user = {
        ...window?.didomiConfig?.user,
        externalConsent: {
          ...window?.didomiConfig?.user?.externalConsent,
          enabled: true,
        },
      };
      window.didomiConfig.regulations = {
        ...window.didomiConfig.regulations,
        ccpa: {
          ...window.didomiConfig.regulations?.ccpa,
          enabled: true,
        },
      };
      setIsConfigured(true);
    }
  }, [isConfigured, content, config]);

  useEffect(() => {
    // runs when didomi is configured and watches consent
    if (!isConfigured) return;
    if (!window.didomiOnReady) {
      window.didomiOnReady = [];
    }

    window.didomiOnReady.push((didomiInstance) => {
      const initialConsent = getConsent(didomiInstance);
      dispatch(actionCreators.setConsent(initialConsent));

      didomiInstance?.on?.('consent.changed', () => {
        const nextConsent = getConsent(didomiInstance);
        dispatch(actionCreators.setConsent(nextConsent));
      });
    });
  }, [dispatch, isConfigured]);
};
