import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import ShowHideButton from './ShowHideButton';

import { GlobalState } from '../../../@types/modelTypes';
import { selectContent } from '../../../store/Selectors';

interface Props {
  handlePasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  isInvalid: boolean;
  isValid: boolean;
  onBlur: (value: React.FocusEvent<Element>) => void;
}

const ConfirmPasswordInput: React.FC<Props> = ({
  handlePasswordChange,
  value,
  isInvalid,
  isValid,
  onBlur,
}) => {
  const contentSignIn = useSelector(
    (state: GlobalState) => state.content.signIn
  );
  const content = useSelector(selectContent);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Form.Group className='mt-1'>
      <Form.Label htmlFor='confirmPassword'>
        {content.loyalty.signupConfirmPasswordLabel}
      </Form.Label>
      <div className='password-container'>
        <Form.Control
          id='confirmPassword'
          name='confirmPassword'
          type={showPassword ? 'text' : 'password'}
          placeholder={content.loyalty.signupConfirmPasswordLabel}
          onChange={handlePasswordChange}
          required
          maxLength={50}
          value={value}
          isInvalid={isInvalid}
          isValid={isValid}
          onBlur={onBlur}
        />
        <ShowHideButton
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      </div>
      <Form.Control.Feedback type='invalid'>
        {contentSignIn.loginPasswordValidationText}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
export default ConfirmPasswordInput;
