/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import TicketSelector from './TicketSelector';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedMemberCards,
  selectConfig,
  selectContent,
  selectCurrencyConfig,
} from '../../../store/Selectors';

interface GroupProps {
  orderHasMaxTickets: boolean;
  classNames: string;
  dataTestId: string;
  tickets: TicketTypeModel[];
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  ticketsHeading: string | undefined;
}

const TicketGroup: React.FC<GroupProps> = ({
  orderHasMaxTickets,
  tickets,
  classNames,
  dataTestId,
  handleTicketButtonClick,
  ticketsHeading,
}) => {
  const dispatch = useDispatch();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const appliedMemberCards: ValidatedMemberCard[] = useSelector(
    selectAppliedMemberCards
  );
  const currencyConfig = useSelector(selectCurrencyConfig);

  // START MultipleLoyaltyTicket appliedCard logic
  const isMultipleMemberTicketPurchaseAllowed =
    config.tickets.enableMultipleLoyaltyCardTicketPurchase;

  const areAllMemberTickets = tickets.every((ticket) => ticket.isMemberTicket);

  const showAppliedMemberCards =
    isMultipleMemberTicketPurchaseAllowed &&
    areAllMemberTickets &&
    appliedMemberCards?.length > 0;

  const handleRemoveMemberCard = (memberCardNo: string) => {
    const appliedMemberCard = appliedMemberCards?.find(
      (usedMemberCard) => usedMemberCard.memberCardNumber === memberCardNo
    );
    const ticketIdToRemove = appliedMemberCard?.usedTicket.id;
    if (ticketIdToRemove) {
      const memberTicket = tickets.find((t) => t.id === ticketIdToRemove);
      if (memberTicket?.validatedVouchers) {
        memberTicket.validatedVouchers = memberTicket.validatedVouchers.filter(
          (card) => card !== memberCardNo
        );
      }
      dispatch(actionCreators.removeMemberCard(memberCardNo));
      handleTicketButtonClick(ticketIdToRemove, 'remove');
    }
  };
  // END MultipleLoyaltyTicket appliedCard logic

  return tickets.length > 0 ? (
    <div className={classNames} data-testid={dataTestId}>
      {ticketsHeading && <h2 className='mt-4 mb-2'>{ticketsHeading}</h2>}
      <TicketSelector
        handleTicketButtonClick={handleTicketButtonClick}
        orderHasMaxTickets={orderHasMaxTickets}
        ticketTypeModels={tickets}
      />

      {showAppliedMemberCards && (
        <div
          className='applied-loyalty-cards-container mt-3'
          data-testid='applied-loyalty-cards'
        >
          <div
            className='mt-2 pb-2 text-center applied-loyalty-cards-title h3'
            sx={{
              borderBottom: 'boxSeparator',
            }}
          >
            {content.tickets.memberTicketsAdditionalTicketHeading}
          </div>
          {appliedMemberCards?.map((usedMemberCard: ValidatedMemberCard) => (
            <Row
              className='applied-loyalty-cards-item pt-2 pb-2 contained d-flex justify-content-between'
              key={usedMemberCard?.memberCardNumber}
              sx={{
                '&:not(:last-child)': {
                  borderBottom: 'boxSeparator',
                },
              }}
            >
              <Col xs={6} md={8} className='text-start'>
                <span>
                  {content.tickets.memberTicketsAddedMemberCardText}
                  {usedMemberCard?.memberCardNumber}
                </span>
              </Col>
              <Col
                xs={3}
                md={2}
                className='float-start d-flex justify-content-center align-items-center small'
              >
                <Button
                  onClick={() =>
                    handleRemoveMemberCard(usedMemberCard?.memberCardNumber)
                  }
                  variant='link'
                  size='sm'
                >
                  {content.payment.removeButtonText.toUpperCase()}
                </Button>
              </Col>
              <Col
                xs={3}
                md={2}
                className='d-flex align-items-center justify-content-end strong'
              >
                <span>
                  {displayPrice(
                    usedMemberCard.usedTicket.price,
                    currencyConfig
                  )}
                </span>
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  ) : null;
};

export default TicketGroup;
