import React from 'react';

import { useSelector } from 'react-redux';

import { selectContentCartSummary } from '../../../store/Selectors';
import Heading from '../heading/Heading';

const CartSummarySubtitle: React.FC = () => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  if (contentCartSummary.yourSummarySubtitle === '') return null;

  return <Heading size={2}>{contentCartSummary.yourSummarySubtitle}</Heading>;
};

export default CartSummarySubtitle;
