import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Concession, GlobalState } from '../../../../@types/modelTypes';
import { getMatchingConcessionDeal } from '../../../../services/Helpers';
import {
  selectContent,
  selectDeals,
  selectDisplayPrice,
} from '../../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../../svgs/deal.svg';
import GiftCardImage from '../GiftCardImage';

interface GiftCardsRowProps {
  selectedGiftCard: Concession;
}

const PaymentSummaryRowEGiftCard: React.FC<GiftCardsRowProps> = ({
  selectedGiftCard,
}) => {
  const content = useSelector(selectContent);
  const deals = useSelector(selectDeals);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, selectedGiftCard?.cost)
  );
  if (!selectedGiftCard || selectedGiftCard.quantity < 1) return null;

  const getDescription = () => {
    return `${content.giftCards.summaryGiftCardLabel} x ${selectedGiftCard.quantity}`;
  };

  const matchingDeal = getMatchingConcessionDeal(selectedGiftCard.id, deals);

  return (
    <div className='giftcards-row'>
      <Row className='g-0 d-flex align-items-center py-3'>
        <Col xs={'auto'} className='position-relative pe-2'>
          <GiftCardImage
            imageUrl={selectedGiftCard.image || content.giftCards.defaultImage}
          />
          {!!matchingDeal && <DealSvg className='deal-icon' />}
        </Col>
        <Col className='text-start summary-item fw-bold'>
          {getDescription()}
          {matchingDeal && (
            <div className='mb-1 text-uppercase deal-text'>
              {matchingDeal.description}
            </div>
          )}
        </Col>{' '}
        <Col xs={'auto'} className='text-end ps-3 summary-item fw-bold'>
          <span>{priceToDisplay}</span>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSummaryRowEGiftCard;
