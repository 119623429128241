import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { AppliedDeal, DealInTicketsStep } from '../../../@types/modelTypes';
import {
  selectAppliedDeals,
  selectDealsInTicketsStep,
} from '../../../store/Selectors';

const CartSummaryDealsApplied: React.FC = () => {
  const { pathname } = useLocation();
  const appliedDeals = useSelector(selectAppliedDeals);
  const dealsInTicketsStep = useSelector(selectDealsInTicketsStep);

  const isTicketsStep = pathname.startsWith('/tickets/');
  const qualifiedDealsInTicketsStep = dealsInTicketsStep
    ? dealsInTicketsStep.filter((d) => !d.onlyValidForRequirements)
    : [];

  const displayAppliedDeals =
    appliedDeals && appliedDeals.some((d) => d.isConcessionRelated);

  const displayDealsInTicketsStep =
    isTicketsStep &&
    qualifiedDealsInTicketsStep &&
    qualifiedDealsInTicketsStep.length &&
    !displayAppliedDeals;

  if (!displayDealsInTicketsStep && !displayAppliedDeals) {
    return null;
  }

  const appliedDealsList: AppliedDeal[] = [];
  if (displayDealsInTicketsStep) {
    qualifiedDealsInTicketsStep.forEach((d: DealInTicketsStep) => {
      const quantity = d.appliedByTicketTypes.reduce(
        (a, b) => a + (b.quantity || 0),
        0
      );

      if (quantity === 0) {
        return;
      }

      appliedDealsList.push({
        title: d.title,
        quantity,
        isConcessionRelated: false,
      });
    });
  }

  if (displayAppliedDeals) {
    appliedDeals.forEach((d: AppliedDeal) => {
      appliedDealsList.push({
        title: d.title,
        quantity: d.quantity,
        isConcessionRelated: false,
      });
    });
  }

  return (
    <>
      {appliedDealsList.map((d: AppliedDeal, i: number) => {
        return (
          <Row key={`cs_deal_applied_${i}`} className='g-0'>
            <Col xs={9} className='text-start b'>
              {d.title}
            </Col>
            <Col xs={1} className='text-center tickets-quantity'>
              {d.quantity}
            </Col>
            <Col xs={2} className='text-end'>
              -
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default CartSummaryDealsApplied;
