import React from 'react';

import classNames from 'classnames';
import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import TierImage from './TierImage';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  GlobalState,
} from '../../../@types/modelTypes';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectDisplayPrice,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../svgs/radioUnselected.svg';
import RichText from '../richtext/RichText';

const TierItem: React.FC<Concession> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  memberLevelId,
  memberClubId,
  recognitionId,
  sequenceNumber,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
    memberLevelId,
    memberClubId,
    recognitionId,
    sequenceNumber,
  };
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const hideTax: boolean = config.currentCinema.hideTax;

  const imagePath: string | undefined = concession.image
    ? concession.image.replace('https://', '')
    : undefined;

  const selectedConcession: Concession | undefined =
    selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const handleConcessionChange = (
    context: QuantitySelectorContext,
    concession: Concession
  ) => {
    dispatch(actionCreators.setLoyaltyTierSelection(concession, context));
  };

  const priceToDisplay = hideTax
    ? itemPricing.costIncTax
    : itemPricing.costMinusTax;
  const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * priceToDisplay;
  const subTotalToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, subTotal)
  );

  const selected = quantityInCart >= 1;

  return (
    <Row className='concession py-2 text-center align-items-center'>
      <Col md={8} sm={12}>
        <Row>
          <Col xs={12} sm={'auto'} className='image-container'>
            <TierImage imageUrl={imagePath ?? content.loyalty.defaultImage} />
          </Col>
          <Col className='details text-start d-flex flex-wrap align-items-center mt-3 mt-sm-0'>
            <Row>
              <Col xs={12}>
                <span className='h3'>{concession.description}</span>
              </Col>
              <Col xs={12}>
                {concession.extendedDescription && (
                  <RichText
                    className='tiny mt-1'
                    text={concession.extendedDescription}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        md={4}
        sm={12}
        className='options-selector-container g-0 d-flex mt-3 mt-md-0 justify-content-center align-items-center'
      >
        <Button
          className={classNames(
            'option-button px-2 py-1 w-100 d-flex justify-content-center me-4 ms-1 ms-md-0',
            selected && 'selected'
          )}
          onClick={() =>
            handleConcessionChange(selected ? 'remove' : 'add', concession)
          }
        >
          <div className='d-flex flex-direction-column align-items-center'>
            {selected ? (
              <RadioSelectedSvg className='check' />
            ) : (
              <RadioUnselectedSvg className='check' />
            )}

            <div>
              {itemPricing.costMinusTax < 100
                ? content.loyalty.signupOptionButtonFreeText
                : content.loyalty.signupOptionButtonPaidText}
            </div>
          </div>
        </Button>
        <div className='options-price text-end d-flex align-items-center me-1'>
          <span className={classNames(selected && 'fw-bold')}>
            {itemPricing.costMinusTax < 100
              ? content.loyalty.signupFreeTierCostText
              : subTotalToDisplay}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default TierItem;
