/* eslint-disable no-console */
import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import FixedCostGiftCard from './FixedCostGiftCard';
import VariableCostGiftCardSingleRow from './VariableCostGiftCardSingleRow';

import { ChangeGiftCardByIdContext } from '../../../../../@types/actionTypes';
import { Concession } from '../../../../../@types/modelTypes';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectSelectedGiftCards,
  selectTotalGiftCardsSelected,
} from '../../../../../store/Selectors';

interface GiftCardsRowProps {
  concession: Concession;
}

const SingleItemRow: React.FC<GiftCardsRowProps> = ({ concession }) => {
  const dispatch = useDispatch();
  const selectedGiftCardCount = useSelector(selectTotalGiftCardsSelected);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const selectedConcession: Concession | undefined =
    selectedConcessions.list.find((x) => x.id === concession.id);

  const handleConcessionChange = (
    context: ChangeGiftCardByIdContext,
    concessionToUpdate: Concession,
    newValueInCents?: number | undefined
  ) => {
    // I dont think we need this IF
    if (concessionToUpdate.isVariablePriceItem && !!newValueInCents) {
      concessionToUpdate.cost = newValueInCents;
      concessionToUpdate.variablePriceInCents = newValueInCents;
    }
    dispatch(actionCreators.changeGiftCardById(concessionToUpdate, context));
  };

  return (
    <Row className='single-item-row'>
      <Col className='giftcard-options-container'>
        {concession.isVariablePriceItem ? (
          <VariableCostGiftCardSingleRow
            {...concession}
            handleConcessionChange={handleConcessionChange}
            totalCardsSelected={selectedGiftCardCount}
            selectedConcession={selectedConcession}
          />
        ) : (
          <FixedCostGiftCard
            {...concession}
            handleConcessionChange={handleConcessionChange}
            totalCardsSelected={selectedGiftCardCount}
          />
        )}
      </Col>
    </Row>
  );
};

export default SingleItemRow;
