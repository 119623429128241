import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectContent } from '../../../../store/Selectors';
import Heading from '../../heading/Heading';
import RichText from '../../richtext/RichText';

const LoyaltyLandingHeadings = () => {
  const content = useSelector(selectContent);
  return (
    <Row>
      <Col xs={12} className='contained'>
        <Heading size={1} className='text-center'>
          {content.loyalty?.landingHeading}
        </Heading>
        <RichText className='mt-2' text={content.loyalty?.landingText} />
      </Col>
    </Row>
  );
};
export default LoyaltyLandingHeadings;
