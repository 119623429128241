import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getContentForError } from '../../../services/PeachErrorResolver';
import { selectContent } from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';

const Error: React.FC = () => {
  const [searchParams] = useSearchParams();
  const content = useSelector(selectContent);

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const errorCode = parseInt(searchParams.get('code') ?? '', 10);
    const message: string = isNaN(errorCode)
      ? ''
      : getContentForError(errorCode, content);
    setErrorMessage(message);
  }, [content, searchParams]);

  return (
    <div className='error' data-testid='error'>
      <Row>
        <Col xs={12} className='contained'>
          <Heading size={2}>{content.error.title}</Heading>
          <RichText text={errorMessage} />
        </Col>
      </Row>
    </div>
  );
};

export default Error;
