import {
  getAttributeWarnings,
  getScreenWarning,
  shouldShowFoodAndBeverage,
} from './Helpers';

import { Config } from '../@types/configTypes';
import { AttributeWarning } from '../@types/contentTypes';
import { FoodAndBeverageJourneyDeliveryType } from '../@types/enums';
import {
  BookingData,
  GlobalState,
  JourneyTypeConfig,
} from '../@types/modelTypes';
import { JOURNEY_TYPES } from '../constants';
import * as StepPaths from '../constants/stepPaths';

export const getRouteFromStep = (
  journeyTypeConfig: JourneyTypeConfig,
  step: GlobalState['step']
) => {
  return journeyTypeConfig.routes[step];
};

type GetTicketingJourneyProps = {
  isReservedSeating: GlobalState['bookingData']['isReservedSeating'];
  enableSeatsFirst: GlobalState['config']['currentCinema']['enableSeatsFirst'];
  foodAndBeverageTicketingJourney: FoodAndBeverageJourneyDeliveryType;
  isFoodAndBeverageLoyaltyOnly: GlobalState['config']['isFoodAndBeverageLoyaltyOnly'];
  isUserValidated: boolean;
  enableGiftCardUpsellOnTicketingJourney: boolean;
};

export const getTicketingJourneyType = ({
  isReservedSeating,
  enableSeatsFirst,
  foodAndBeverageTicketingJourney,
  isFoodAndBeverageLoyaltyOnly,
  isUserValidated,
  enableGiftCardUpsellOnTicketingJourney,
}: GetTicketingJourneyProps) => {
  const showFoodAndBeverage = shouldShowFoodAndBeverage(
    foodAndBeverageTicketingJourney,
    isFoodAndBeverageLoyaltyOnly,
    isUserValidated
  );
  const shouldShowKiosk =
    showFoodAndBeverage || enableGiftCardUpsellOnTicketingJourney;
  if (isReservedSeating && enableSeatsFirst) {
    return shouldShowKiosk
      ? JOURNEY_TYPES.SEAT_FIRST_KIOSK
      : JOURNEY_TYPES.SEAT_FIRST;
  } else if (isReservedSeating) {
    return shouldShowKiosk
      ? JOURNEY_TYPES.TICKET_FIRST_KIOSK
      : JOURNEY_TYPES.TICKET_FIRST;
  } else {
    return shouldShowKiosk
      ? JOURNEY_TYPES.TICKETS_KIOSK
      : JOURNEY_TYPES.TICKETS_ONLY;
  }
};

export const getStepNumberForDisplay = (stepNumber: GlobalState['step']) => {
  const stepNumbersForDisplay: { [key: string]: string } = {
    '-1': '',
    0: '',
    1: '1. ',
    2: '2. ',
    3: '3. ',
    4: '4. ',
    5: '5. ',
  };
  return stepNumbersForDisplay[stepNumber];
};

export const getCurrentStepNumber = (
  journeyTypeConfig: JourneyTypeConfig,
  pathname: string
) => {
  let locationToCompare: string;
  const cleanPath = pathname.startsWith('/') ? pathname.substring(1) : pathname;
  if (
    journeyTypeConfig.isConcessionsOnlyJourney ||
    journeyTypeConfig.type == JOURNEY_TYPES.SEASON_PASS
  ) {
    locationToCompare = cleanPath.split('/', 2).join('/');
  } else {
    locationToCompare = cleanPath.split('/')[0];
  }
  const myKey = Object.keys(journeyTypeConfig.routes).find(
    (key) => journeyTypeConfig.routes[key] === locationToCompare.toLowerCase()
  );
  const stepNumber = parseInt(String(myKey), 10);
  return stepNumber;
};

export const getStartTicketingNextStep = (
  config: GlobalState['config'],
  content: GlobalState['content'],
  journeyTypeConfig: JourneyTypeConfig,
  cartSummary: GlobalState['cartSummary'],
  isUserValidated: boolean
) => {
  const allowSignIn =
    config.currentCinema.allowSignIn || config.signIn.allowSignIn;
  const attributeWarnings: AttributeWarning[] = getAttributeWarnings(
    cartSummary.sessionAttributes,
    content.attributeWarnings
  );
  const displayAttributeWarning = !!attributeWarnings.length;
  const displayScreenWarning = !!getScreenWarning(
    cartSummary.screen,
    content.screenWarnings,
    config.currentCinema.cinemaId
  );
  if (displayAttributeWarning || displayScreenWarning) {
    const warningStepNumber = getStepNumberForStepPath(
      journeyTypeConfig,
      StepPaths.WARNING
    );
    if (warningStepNumber) {
      return warningStepNumber;
    }
  }

  return allowSignIn && !isUserValidated ? 0 : 1;
};

export const getPreviousRoutePath = (
  journeyTypeConfig: JourneyTypeConfig,
  pathname: string,
  paramIds: { [key: string]: string }
) => {
  const currentStep = getCurrentStepNumber(journeyTypeConfig, pathname);
  const previousStep = currentStep >= 0 ? currentStep - 1 : 0;
  const previousStepPath = journeyTypeConfig.routes[previousStep];
  const journeyParams = journeyTypeConfig.routes.params;
  const routeParams =
    Array.isArray(journeyParams) && journeyParams.length > 0
      ? journeyParams
      : [];
  const replacedRouteParams = routeParams.map((key) => paramIds[key]);
  const previousRoutePath = `${previousStepPath}/${replacedRouteParams.join(
    '/'
  )}`;
  return previousRoutePath;
};

export const getStepNumberForStepPath = (
  journeyTypeConfig: JourneyTypeConfig,
  stepName: string
) => {
  const matchedSteps = Object.entries(journeyTypeConfig.routes).filter(
    (r) => r[1] === stepName
  );
  return matchedSteps.length ? matchedSteps[0][0] : '';
};

export const getConcessionsBasedStartJourneyRoute = (
  journeyTypeConfig: JourneyTypeConfig,
  config: Config
) => {
  let baseurl: string;
  baseurl = `/${journeyTypeConfig.routes['start']}`;
  if (!journeyTypeConfig.resolvesCinemaFromConfig) {
    baseurl += `/${config.currentCinema.cinemaId}/`;
  }
  return baseurl;
};

export const getTicketingStartJourneyRoute = (bookingData: BookingData) => {
  return `/startticketing/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`;
};

export const getSeasonPassStartJourneyRoute = () => {
  return '/startseasonpass';
};

export const getStartJourneyRoute = (
  journeyTypeConfig: JourneyTypeConfig,
  config: Config,
  bookingData: BookingData
) => {
  let baseurl = getTicketingStartJourneyRoute(bookingData);

  if (journeyTypeConfig.isConcessionsOnlyJourney) {
    baseurl = getConcessionsBasedStartJourneyRoute(journeyTypeConfig, config);
  } else if (journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS) {
    baseurl = getSeasonPassStartJourneyRoute();
  }
  return baseurl;
};
