import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getFullAddress } from '../../../services/GiftCardHelpers';
import {
  selectContentPhysicalGiftcards,
  selectPhysicalGiftCardRecipient,
} from '../../../store/Selectors';

const CartSummaryPhysicalGiftCards: React.FC = () => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );

  return (
    <div className='cart-summary-row' data-testid='cs-physical-gift-cards'>
      <Row className='g-0'>
        <Col className='text-start'>
          {contentPhysicalGiftCards.cartSummaryNameLabel}
        </Col>
        <Col className='text-end'>{physicalGiftCardRecipient.name}</Col>
      </Row>
      <Row className='mt-2 g-0'>
        <Col className='text-start'>
          {contentPhysicalGiftCards.cartSummaryAddressLabel}
        </Col>
        <Col className='text-end'>
          {getFullAddress(
            physicalGiftCardRecipient.addressLineOne,
            physicalGiftCardRecipient.addressLineTwo,
            physicalGiftCardRecipient.city,
            physicalGiftCardRecipient.postcode
          )}
        </Col>
      </Row>
      {physicalGiftCardRecipient.message && (
        <Row className='mt-2 g-0'>
          <Col className='text-start'>
            {contentPhysicalGiftCards.cartSummaryMessageLabel}
          </Col>
          <Col className='text-end'>{physicalGiftCardRecipient.message}</Col>
        </Row>
      )}
    </div>
  );
};

export default CartSummaryPhysicalGiftCards;
