import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from '../../analytics/analyticsContext';
import {
  selectBookingData,
  selectToken,
  selectCartSummary,
  selectConfig,
  selectCountdownExpired,
  selectIsHostedPaymentInProgress,
} from '../../store/Selectors';

const newJourneyKey = 'forceNewJourney';

export const useUnloadListeners = () => {
  const { pathname } = useLocation();
  const analytics = useAnalytics();

  const bookingData = useSelector(selectBookingData);
  const dataToken = useSelector(selectToken);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const countdownExpired = useSelector(selectCountdownExpired);
  const isHostedPaymentInProgress = useSelector(
    selectIsHostedPaymentInProgress
  );
  const [hasUnloadEvent, setHasUnloadEvent] = useState<boolean>(false);

  const shouldAddUnloadListeners =
    !hasUnloadEvent &&
    dataToken &&
    !pathname.startsWith('/confirmation') &&
    window.sessionStorage.getItem(newJourneyKey) !== '1' &&
    !countdownExpired &&
    !isHostedPaymentInProgress;

  const beforeUnloadListener = useCallback(
    (e: BeforeUnloadEvent) => {
      if (config?.cancelOrderWhenLeavingBooking) {
        e.preventDefault();
      }
    },
    [config]
  );

  const unloadListener = useCallback(() => {
    const callApi = () => {
      if (config?.cancelOrderWhenLeavingBooking) {
        const cancelOrderRequest = {
          dataToken: dataToken,
        };
        const cancelOrderHeaders = {
          type: 'application/json',
        };
        const blob = new Blob(
          [JSON.stringify(cancelOrderRequest)],
          cancelOrderHeaders
        );
        window.sessionStorage.setItem(newJourneyKey, '1');
        navigator.sendBeacon('api/order/cancel', blob);
      }
    };
    if (analytics) {
      analytics
        .track('abortedPurchase', { bookingData, cartSummary })
        .then(() => callApi());
    }
  }, [analytics, bookingData, cartSummary, config, dataToken]);

  useEffect(() => {
    if (shouldAddUnloadListeners) {
      window.addEventListener('beforeunload', beforeUnloadListener);
      window.addEventListener('unload', unloadListener);
      setHasUnloadEvent(true);
    }

    return () => {
      if (hasUnloadEvent) {
        window.removeEventListener('beforeunload', beforeUnloadListener);
        window.removeEventListener('unload', unloadListener);
        setHasUnloadEvent(false);
      }
    };
  }, [
    beforeUnloadListener,
    hasUnloadEvent,
    shouldAddUnloadListeners,
    unloadListener,
  ]);
};
