import React from 'react';

import { useSelector } from 'react-redux';

import { selectContent } from '../../../../store/Selectors';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import LoginSection from '../LoginSection';
import Register from '../Register';

const LoyaltyOnlyUpgradeOptions: React.FC = () => {
  const content = useSelector(selectContent);
  return (
    <>
      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-4' size={2}>
          {content.signIn.upgradeMemberHeading}
        </Heading>
        <RichText text={content.signIn.upgradeMemberRichText} />
        <LoginSection />
      </ContainedRow>

      <Register showDivider />
    </>
  );
};

export default LoyaltyOnlyUpgradeOptions;
