/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  SeasonPassMovie,
  SeasonPassMovieSession,
} from '../../../@types/modelTypes';
import { timeFormat } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectSelectedLanguageCulture,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';

interface Props {
  session: SeasonPassMovieSession;
  movie: SeasonPassMovie;
}

const SeasonPassSelectSessionButton: React.FC<Props> = ({ session, movie }) => {
  const dispatch = useDispatch();

  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const { seasonPassItem, sessions } = useSelector(selectSelectedSeasonPass);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const maxRequiredMovies = seasonPassItem.maxRequiredMovies;
  const { notBookable, soldOut } = session;
  const existingSelectedSession = sessions.find(
    (selectedSession) => selectedSession.movie.filmId === movie.filmId
  );
  const isSelectedSession =
    existingSelectedSession?.session.sessionId === session.sessionId;

  useEffect(() => {
    const disabled = notBookable || soldOut;
    setIsDisabled(disabled);
  }, [notBookable, soldOut]);

  const handleSessionClick = () => {
    if (isSelectedSession) {
      dispatch(actionCreators.removeSelectedSeasonPassSession(session));
    } else if ((sessions?.length ?? 0) < maxRequiredMovies) {
      if (existingSelectedSession) {
        dispatch(
          actionCreators.removeSelectedSeasonPassSession(
            existingSelectedSession.session
          )
        );
      }
      dispatch(
        actionCreators.setSelectedSeasonPassSessions({ movie, session })
      );
    }
  };

  return (
    <Col
      xs={6}
      key={`${session.externalSessionId}`}
      className='session-button-container'
    >
      <Button
        onClick={handleSessionClick}
        disabled={isDisabled}
        className={classNames(
          'session-button',
          isSelectedSession && 'selected'
        )}
        sx={{
          backgroundColor: 'secondaryButtonBackground',
          borderColor: 'primary',
          color: 'secondaryButtonColor',
          '&:disabled': {
            backgroundColor: 'inherit',
            borderColor: 'primary',
            color: 'inherit',
          },
          '&:hover, &:first-child:active, &:first-child:focus-visible': {
            backgroundColor: 'primaryButtonBackground',
            borderColor: 'primary',
            color: 'primaryButtonColorHover',
          },
          '&.selected': {
            backgroundColor: 'primaryButtonBackground',
            borderColor: 'primary',
            color: 'primaryButtonColorHover',
          },
          '& .checked': {
            '& .a': { fill: 'primaryButtonBackground' },
            '& .b': { fill: 'secondaryButtonBackground' },
            '& .c': { fill: 'secondaryButtonBackground' },
          },
        }}
      >
        {isSelectedSession && (
          <span className='radio me-1'>
            <RadioSelectedSvg className='checked' />
          </span>
        )}
        <span className='showtime-hourminute'>
          {moment(session.startDateTime)
            .locale(selectedLanguageCulture)
            .format(timeFormat(selectedLanguageCulture))}
        </span>
      </Button>
    </Col>
  );
};

export default SeasonPassSelectSessionButton;
