/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { displayPrice } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfigDonationValues,
  selectContent,
  selectCurrencyConfig,
  selectDonation,
  selectGrandTotalWithoutDonationInCents,
  selectSelectedDonation,
} from '../../../store/Selectors';
import AmountSelector from '../amountselector/AmountSelector';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import Heading from '../heading/Heading';

export const maxDonationValue = 100;

const Donations: React.FC = () => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const donation = useSelector(selectDonation);
  const donationValues = useSelector(selectConfigDonationValues);
  const config = useSelector(selectConfig);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const selectedDonation = useSelector(selectSelectedDonation);
  const grandTotal = useSelector(selectGrandTotalWithoutDonationInCents);

  let roundUpValue = 0;
  const grandTotalCeil = Math.ceil(grandTotal / 100) * 100;
  if (grandTotalCeil === grandTotal) {
    roundUpValue = 100;
  } else {
    roundUpValue = grandTotalCeil - grandTotal;
  }

  const [roundUpAmount, setRoundUpAmount] = useState(
    donation - selectedDonation.amount
  );
  const [roundUpChecked, setRoundUpChecked] = useState(roundUpAmount > 0);

  useEffect(() => {
    let roundUpValue = 0;
    if (roundUpChecked) {
      let grandTotalCeil = Math.ceil(grandTotal / 100) * 100;
      if (grandTotalCeil === grandTotal) {
        grandTotalCeil = grandTotalCeil + 100;
      }

      roundUpValue = grandTotalCeil - grandTotal;
    }
    setRoundUpAmount(roundUpValue);
  }, [grandTotal, roundUpChecked]);

  useEffect(() => {
    const donationValue = selectedDonation ? selectedDonation.amount : 0;

    dispatch(actionCreators.setDonation(donationValue + roundUpAmount));
  }, [dispatch, roundUpAmount, selectedDonation]);

  return (
    <Row
      className='donations-wrapper mt-3'
      sx={{
        backgroundColor: 'primary',
      }}
    >
      <Col
        xs={12}
        className='donations contained text-center py-3'
        data-testid='donations'
        sx={{
          color: 'mostReadableOnPrimaryBackground',
        }}
      >
        <Heading
          size={2}
          className='mt-0'
          sx={{ color: 'mostReadableOnPrimaryBackground' }}
        >
          {content.payment.donationSubTitle}
        </Heading>
        {content.payment.donationText && <p>{content.payment.donationText}</p>}
        {config.payment.enableDonationRoundUpField && (
          <div
            className='donation-roundup-toggle-button d-flex justify-content-start mt-2'
            sx={{
              '& .checkbox-button .selectable-button-svg': {
                '& .a': { fill: 'primary' },
                '& .b': { fill: 'mostReadableOnPrimaryBackground' },
                '& .c': { fill: 'mostReadableOnPrimaryBackground' },
              },
            }}
          >
            <Row className='g-0 text-start'>
              <Col className='d-flex align-items-center'>
                <CheckBoxButton
                  checked={roundUpChecked}
                  onClick={() => setRoundUpChecked(!roundUpChecked)}
                />
                {content.payment.donationRoundUpRichText && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: content.payment.donationRoundUpRichText.replace(
                        '##DonationRoundUpValue##',
                        displayPrice(roundUpValue, currencyConfig)
                      ),
                    }}
                  ></span>
                )}
              </Col>
            </Row>
          </div>
        )}
        <div className='mt-3'>
          <AmountSelector
            maxAmount={maxDonationValue}
            setAmount={actionCreators.setSelectedDonation}
            values={donationValues}
            variant={'donation'}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Donations;
