import React, { useEffect, useState, useCallback } from 'react';

import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Config } from '../../../@types/configTypes';
import { Content } from '../../../@types/contentTypes';
import { FoodAndBeverageJourneyDeliveryType } from '../../../@types/enums';
import { BookingData, EmbargoData } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useMockedCypher } from '../../../hooks/useMockedCypher';
import loadSentry from '../../../scripts/loadSentry';
import { getCustomer } from '../../../services/Helpers';
import {
  getTicketingJourneyType,
  getRouteFromStep,
  getStartTicketingNextStep,
} from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectEmbargoData,
  selectJourneyTypeConfig,
  selectStep,
} from '../../../store/Selectors';

const StartTicketing: React.FC = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { externalCinemaId, externalSessionId } = useParams();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const embargoData = useSelector(selectEmbargoData);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);

  const [shouldRedirect, setShouldRedirect] = useState(false);

  useMockedCypher();

  const getBookingData = useCallback(async () => {
    dispatch(actionCreators.clearSession());
    dispatch(actionCreators.setLoading(true));
    const { requestData, preferredLanguageCulture } = cookies;
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const circuitId = searchParams.get('circuitId') ?? undefined;
    const selectedLanguageCulture =
      preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));
    if (requestData) {
      dispatch(actionCreators.setRequestData(requestData));
    }

    const data = {
      requestData,
      circuitId,
      selectedLanguageCulture,
    };
    const url = `api/StartTicketing/${externalCinemaId}/${externalSessionId}`;
    const response = await backend.post(url, data);
    let bookingData: BookingData,
      config: Config,
      content: Content,
      customer,
      result;

    if (response.ok) {
      result = response.content;
      bookingData = result.bookingData;
      delete result.circuit.config.cinemas;
      delete result.circuit.content.cinemas;
      config = result.circuit.config;
      content = result.circuit.content;
      customer = getCustomer(
        bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );
      const embargoData: EmbargoData = result.embargoData;
      if (embargoData) {
        const millisecondsToEmbargoLeft = embargoData.millisecondsToEmbargoLeft;
        embargoData.usersEmbargoDate = moment
          .utc()
          .add(millisecondsToEmbargoLeft, 'ms')
          .toDate();
      }
      dispatch(actionCreators.setEmbargoData(embargoData));

      const journeyType = getTicketingJourneyType({
        isReservedSeating: bookingData.isReservedSeating,
        enableSeatsFirst: config.currentCinema.enableSeatsFirst,
        foodAndBeverageTicketingJourney:
          config.currentCinema.foodAndBeverageTicketingJourney,
        isFoodAndBeverageLoyaltyOnly: config.isFoodAndBeverageLoyaltyOnly,
        isUserValidated: bookingData.isUserValidated,
        enableGiftCardUpsellOnTicketingJourney:
          config.enableGiftCardUpsellOnTicketingJourney,
      });
      const journeyTypeConfig = journeyTypeConfigs[journeyType];
      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        cartSummary: result.cartSummaryModel,
        availablePosTickets: result.selectTicketsModel,
        ticketTypes: null,
        bookingFee: 0,
        seatsModel: result.selectSeatsModel,
        error: { show: false, message: '', peachCode: 0 },
        selectedSeats: [],
        giftCard: null,
        guestMarketing:
          config.payment.enableGuestMarketing &&
          !(
            config.payment.hideGuestMarketingWhenLoggedIn &&
            bookingData.isUserValidated
          )
            ? {
                contactByThirdParty: false,
                sendNewsletter: false,
              }
            : null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: result.bookingData.isUserValidated,
        journeyType: journeyType,
        kioskSubStep:
          config.currentCinema.foodAndBeverageTicketingJourney !==
          FoodAndBeverageJourneyDeliveryType.Disabled
            ? 'fab'
            : 'egc',
        customer: customer,
        source: source,
        device: device,
        step: getStartTicketingNextStep(
          config,
          content,
          journeyTypeConfig,
          result.cartSummaryModel,
          bookingData.isUserValidated
        ),
        loyaltyRecognitionNumber: result.loyaltyRecognitionNumber,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        concessions: null,
        selectedLanguageCulture: content.culture,
        gratuityLimitInCents: result.gratuityLimitInCents,
        bookingFeeStrategy: result.bookingFeeStrategy,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        deals: result.deals,
        hostedPaymentInProgress: false,
        turnstileConfig: result.turnstileConfig,
        recaptchaConfig: result.recaptchaConfig,
        contentSecurityPolicyMetaTagContent:
          result.contentSecurityPolicyMetaTagContent,
        enableContentSecurityPolicy: result.enableContentSecurityPolicy,
      };

      if (result.peachCode) {
        session.error = {
          show: true,
          message: result.errorMessage,
          peachCode: result.peachCode,
        };
      } else if (session.config.currentCinema.isBookingDisabled) {
        session.error = {
          show: true,
          message: session.content.error.sessionNotBookableRichText,
          peachCode: PEACH_CODES.sessionNotBookable,
        };
      }

      if (config.sentryDsnKey) {
        loadSentry(config.sentryDsnKey);
      }

      dispatch(actionCreators.initializeSession(session));
      setShouldRedirect(true);
    } else {
      result = response.errorContent;
      config = result.circuit.config;
      content = result.circuit.content;

      dispatch(actionCreators.setCircuitConfig(config));
      dispatch(actionCreators.setCircuitContent(content));
      dispatch(actionCreators.setError(response.errorContent.errorMessage));
    }

    dispatch(actionCreators.setLoading(false));
  }, [
    cookies,
    dispatch,
    externalCinemaId,
    externalSessionId,
    location,
    searchParams,
  ]);

  useEffect(() => {
    if (externalCinemaId && externalSessionId) {
      getBookingData();
    }
  }, [externalCinemaId, externalSessionId, getBookingData]);

  if (!externalCinemaId || !externalSessionId) {
    return <Navigate to={'/'} state={{ from: location }} />;
  }

  if (!config || !bookingData || !shouldRedirect) return null;
  const stepNumber = step;
  const path = getRouteFromStep(journeyTypeConfig, stepNumber);
  const pathname = embargoData
    ? '/embargo'
    : `/${path}/${externalCinemaId}/${externalSessionId}`;

  return <Navigate to={pathname} replace={true} />;
};

export default StartTicketing;
