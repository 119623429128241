import React, { useEffect } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';

import { getLanguageFromCulture } from '../../../services/Helpers';
import {
  selectConfig,
  selectContent,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';

export const NUM_YEARS = 100;

interface Props {
  type: 'day' | 'month' | 'year';
}

const DateOfBirthOptions: React.FC<Props> = ({ type }) => {
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  useEffect(() => {
    moment.locale(getLanguageFromCulture(selectedLanguageCulture));
  }, [selectedLanguageCulture]);

  return (
    <>
      <option value='' disabled>
        {type === 'month'
          ? content.loyalty.signupDateOfBirthMonthLabel
          : type === 'day'
          ? content.loyalty.signupDateOfBirthDayLabel
          : content.loyalty.signupDateOfBirthYearLabel}
      </option>
      {type === 'day' &&
        [...Array(31)].map((_val, i) => {
          return (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          );
        })}
      {type === 'month' &&
        [...Array(12)].map((_val, i) => {
          const text = moment(i + 1, 'MM').format('M - MMM');
          return (
            <option key={text} value={i + 1}>
              {text}
            </option>
          );
        })}
      {type === 'year' &&
        [...Array(NUM_YEARS)].map((_val, i) => {
          const year = moment(
            moment().subtract(
              config.loyalty.signupMinimumAge &&
                config.loyalty.signupMinimumAge > 0
                ? config.loyalty.signupMinimumAge
                : 1,
              'years'
            )
          )
            .subtract(i, 'years')
            .format('YYYY');
          return (
            <option key={year} value={year}>
              {year}
            </option>
          );
        })}
    </>
  );
};

export default DateOfBirthOptions;
