import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Collapse, Button } from 'react-bootstrap';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { ReactComponent as MinusSVG } from '../../../../../svgs/payment/minus.svg';
import { ReactComponent as AddSVG } from '../../../../../svgs/payment/plus.svg';

interface Props {
  setShow: () => void;
  show: boolean;
  headingContent?: React.ReactNode;
  paymentProvider: PaymentProvidersEnum;
  hasOnePaymentOption: boolean;
  className?: string;
  isShownClassName?: string;
}

const CollapsiblePaymentProviderContainer: React.FC<
  PropsWithChildren<Props>
> = ({
  children,
  setShow,
  show,
  headingContent,
  paymentProvider,
  hasOnePaymentOption,
  className,
  isShownClassName,
}) => {
  const iconToDisplay = show ? (
    <MinusSVG className='icon-plus-minus d-flex align-items-center' />
  ) : (
    <AddSVG className='icon-plus-minus d-flex align-items-center' />
  );
  return (
    <div
      className={classnames(
        'bordered-collapse',
        className,
        hasOnePaymentOption ? 'hide-border' : show ? isShownClassName : '',
        PaymentProvidersEnum[paymentProvider]?.toLowerCase()
      )}
    >
      <Button
        className={classnames(
          'bordered-collapse-btn d-flex justify-content-between align-items-center',
          hasOnePaymentOption ? 'no-pointer px-0 pt-0' : 'px-2'
        )}
        onClick={() => !hasOnePaymentOption && setShow()}
        variant='link'
      >
        <>
          {!!headingContent && headingContent}
          {hasOnePaymentOption || iconToDisplay}
        </>
      </Button>
      <Collapse in={hasOnePaymentOption || show}>
        <div
          className={classnames(
            'bordered-collapse-open',
            hasOnePaymentOption && 'p-0'
          )}
        >
          {children}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapsiblePaymentProviderContainer;
