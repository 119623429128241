import { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PEACH_CODES } from '../constants';
import backend from '../services/RestUtilities';
import { actionCreators } from '../store/ActionCreators';
import {
  selectBookingData,
  selectContent,
  selectSeasonPassMovies,
  selectSelectedSeasonPassItem,
} from '../store/Selectors';

export const useGetSeasonPassShowtimes = () => {
  const dispatch = useDispatch();
  const { cinemaId, circuitId } = useSelector(selectBookingData);
  const content = useSelector(selectContent);
  const selectedSeasonPassTicket = useSelector(selectSelectedSeasonPassItem);
  const promotionId = selectedSeasonPassTicket?.id;
  const seasonPassMovies = useSelector(selectSeasonPassMovies);

  const getSeasonPassShowtimes = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));

    const path = `api/SeasonPass/${promotionId}?cinemaId=${cinemaId}&circuitId=${circuitId}`;
    const response = await backend.get(path);

    let result;
    if (
      response.ok &&
      (response.content.peachCode == PEACH_CODES.ok ||
        response.content.peachCode == PEACH_CODES.noError)
    ) {
      result = response.content;
      dispatch(actionCreators.setSeasonPassMovies(result.films));
    } else {
      dispatch(
        actionCreators.setError(
          content.error.unexpectedErrorRichText,
          response.content.peachCode
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    promotionId,
    cinemaId,
    circuitId,
    content.error.unexpectedErrorRichText,
  ]);

  useEffect(() => {
    if (circuitId && cinemaId && promotionId && !seasonPassMovies?.length) {
      getSeasonPassShowtimes();
    }
  }, [
    selectedSeasonPassTicket,
    circuitId,
    cinemaId,
    promotionId,
    getSeasonPassShowtimes,
    seasonPassMovies?.length,
  ]);
};
