import {
  Content,
  ContentConfirmation,
  ContentEmbargo,
  ContentError,
  ContentGiftCards,
  ContentPhysicalGiftCards,
  ContentGiftMembership,
  ContentVouchers,
  ContentKiosk,
  ContentPayment,
  ContentRefund,
  ContentSeats,
  ContentCartSummary,
  ContentSignIn,
  ContentTickets,
  ContentConcessionsOnlyJourney,
  ContentLoyalty,
  ContentSeasonPass,
} from '../@types/contentTypes';

export const contentConfirmation: ContentConfirmation = {
  aboutYourBookingRichText:
    '<p><strong>INSTRUCTIONS:</strong> <br />Present your confirmation number or credit card at the box office or ticket kiosk to retrieve your ticket purchase. Refund Policy: Tickets may be refunded or exchanged at the box office prior to the show time on the date of the show only. The same credit card used to purchase the tickets must be presented in order to receive a refund. Service fees are non-refundable. Please contact your local MJR Digital Cinema if you are in need of assistance.</p>',
  aboutYourBookingTitle: 'About your tickets',
  bookingReferenceSubTitle: 'Your booking reference is:',
  collectTicketsRichText:
    '<p><span>Show the QR code to the Usher at the ticket stand for entry, or present your booking number or card used to make payment at the kiosk or box office for ticket pickup.</span></p>',
  collectTicketsSubTitle: 'To collect your tickets:',
  confirmationIdSubTitle: 'Your booking number is:',
  donationsLabel: 'Donations',
  gratuityLabel: 'Gratuity',
  giftCardsSubTitle: 'Gift Cards Subtitle',
  giftCardsRichText: 'Here is your Gift Cards breakdown:',
  googleMapSubTitle: 'Google Map',
  inText: 'in',
  promoSpaceRichText: '',
  serviceChargeLabel: 'Service charge',
  taxLabel: 'Tax',
  title: 'Confirmation:',
  yourSeatsLabel: 'Your Seat(s):',
  yourTicketsSubTitle: 'Your tickets have been emailed to you at:',
  registerForCarParkingTitle: 'Register for free parking',
  registerForCarParkingRichText:
    "<p>You can register your car's registration number and get up to 6 hours free parking. Valid for 1 car per adult ticket purchased. Example copy only.",
  registerForCarParkingButtonText: 'REGISTER FOR FREE PARKING',
};

export const contentEmbargo: ContentEmbargo = {
  title: 'Embargo Title',
  embargoRichText: 'Embargo Rich Text',
  onSaleFromText: 'Tickets will go on sale from:',
  buttonText: 'Go back',
};

export const contentError: ContentError = {
  countDownRichText:
    '<p>Oops. Your time ran out out. If you select your tickets again we can start a fresh order for you.</p>',
  giftCardBalanceErrorRichText:
    '<p>There was a problem checking your Gift Card balance. Please try again</p>',
  giftCardInsufficientErrorRichText:
    '<p>There is not enough balance on Gift Card. Additional payment is required.</p>',
  invalidOrderErrorRichText:
    '<p>There was an unexpected error with this order, please return to the showtime and start a new ticketing journey.</p>',
  loyaltyNumberErrorRichText:
    '<p>There was a problem checking your Loyalty number. Please try again</p>',
  networkErrorRichText:
    '<p>We are unable to continue your online tickets journey. Please contact the box office directly.</p>',
  orderAlreadyRefundedRichText: '<p>This order has already been refunded.</p>',
  orderTimedOutRichText:
    '<p>The order has not been completed in time. Please return to the showtime and start a new ticketing journey.</p>',
  paymentCardTypeNotAcceptedRichText: 'Payment card not accepted.',
  paymentDeclinedRichText:
    '<p>Your payment was declined, please check card details and try again.</p>',
  paymentModelErrorRichText:
    '<p>There seems to be a problem with your details, please review your customer or payment details</p>',
  paymentRefundedErrorRichText:
    '<p>Sorry. Something went wrong while reserving your tickets and we have refunded your card payment successfully. Please contact the box office or try again.</p>',
  paymentTakenInErrorRichText:
    '<p>Sorry. Something went wrong while reserving your tickets and your card may have been charged. Please contact the box office to arrange tickets or get a refund.</p>',
  refundFailedRichText: '<p>Refund failed.</p>',
  seatMapErrorRichText:
    '<p>We could not display the seating layout for this cinema screen. Please contact the box office</p>',
  seatsAlreadyTakenRichText:
    '<p>Unfortunately one or more of your seats has been reserved by someone else. Please select alternative seats.</p>',
  seatsUnavailableRichText:
    '<p>Unfortunately we do not have this number of available tickets. This showtime may have sold out.</p>',
  securityIssueRichText:
    '<p>No Bank card was provided to complete the sale.</p>',
  sessionExpiredRichText:
    '<p>There are no tickets available for this showtime. It might be too late to buy these tickets online.</p>',
  ticketsCouldNotBeAddedRichText:
    '<p>Unfortunately we are unable to add this ticket selection. Please try again</p>',
  title: 'Oops... there was a problem.',
  tooLateForRefundRichText:
    '<p>Unfortunately we can only refund your tickets for sessions that have not expired. We can only refund tickets up to one hour before performance starts.</p>',
  captchaFailedRichText: '<p>ReCaptcha verification failed</p>',
  unexpectedErrorRichText: '<p>An unexpected error has occured.</p>',
  sessionSoldOutRichText: '<p>This session is sold out</p>',
  concessionsCouldNotBeAddedRichText:
    '<p>Unfortunately we could not add your food and beverage items. We have removed the items from your basket.</p><p>Please continue to payment and pay for your tickets only.</p>',
  gratuityNotAcceptedRichText: '<p>Gratuity not accepted</p>',
  tooEarlyToOrderFoodAndBeverageRichText: '<p>Too early to order F&B</p>',
  tooLateToOrderFoodAndBeverageRichText: '<p>Too late to order F&B</p>',
  unableToRetrieveBookingRichText: '<p>unable to retrieve booking</p>',
  foodAndBeverageConfigurationNotValidRichText: '<p>F&B config not valid</p>',
  foodAndBeverageOrderingIsDisabledRichText: '<p>F&B disabled</p>',
  sessionNotBookableRichText: '<p>This session is not bookable</p>',
  loginDetailsIncorrectRichText: '<p>The login details are incorrect</p>',
  loyaltyMemberAlreadyExistsRichText: '<p>The member already exists</p>',
  promotionTicketsNotAvailableRichText:
    '<p>Promotion Tickets Not Available</p>',
};

export const contentGiftCards: ContentGiftCards = {
  addAnotherText: 'test',
  addAnotherSubText: 'test',
  buyAnotherGiftCardButtonText: 'test',
  confirmationTitle: 'test',
  confirmationSubheading: 'test',
  confirmationText: 'test',
  confirmationAdditionalHeading: 'test',
  confirmationAdditionalRichText: 'test',
  confirmationButtonText: 'test',
  confirmationButtonUrl: 'test',
  customerDetailsHeading: 'test',
  customerDetailsRichText: 'test',
  defaultImage: undefined,
  instructionsRichText: 'test',
  giftCardOptionsTitle: 'test',
  giftCardOptionsDescription: 'test',
  giftCardSelectionContinueButtonText: 'test',
  mainTitle: 'test',
  noGiftCardsMessage: 'test',
  paymentHeading: 'test',
  paymentEditText: 'test',
  paymentRecipientNameLabel: 'test',
  paymentRecipientEmailLabel: 'test',
  paymentRecipientMessageLabel: 'test',
  paymentRichText: 'test',
  paymentBillingEmailLabel: 'test',
  paymentBillingNameLabel: 'test',
  paymentTermsAndConditionsRichText: 'test',
  recipientDetailsHeading: 'test',
  recipientDetailsContinueButtonText: 'test',
  recipientDetailsRichText: 'test',
  recipientEmailLabel: 'test',
  recipientEmailPlaceholder: 'test',
  recipientEmailValidationText: 'test',
  recipientConfirmEmailLabel: 'test',
  recipientConfirmEmailValidationText: 'test',
  recipientMessageLabel: 'test',
  recipientMessagePlaceholder: 'test',
  recipientMessageValidationText: 'test',
  recipientNameLabel: 'test',
  recipientNamePlaceholder: 'test',
  recipientNameValidationText: 'test',
  saveText: 'save',
  selectionHeading: 'test',
  selectQuantity: 'test',
  summaryGiftCardLabel: 'test',
  summaryGiftCardHeading: 'test',
  useSameEmailLabel: 'test',
  useBillingEmailText: 'test',
  variableCostToolTip: 'Max is $100',
  optionButtonSelectedText: 'test',
  optionButtonUnselectedText: 'test',
  optionButtonUpdateText: 'test',
};

export const contentVouchers: ContentVouchers = {
  mainTitle: 'test',
  selectVouchersHeading: 'test',
  recipientDetailsHeading: 'test',
  customerDetailsHeading: 'test',
  instructionsRichText: 'test',
  noVouchersMessage: 'test',
  voucherSelectionContinueButtonText: 'test',
  recipientDetailsContinueButtonText: 'test',
  recipientDetailsRichText: 'test',
  customerDetailsRichText: 'test',
  recipientNameLabel: 'test',
  recipientEmailLabel: 'test',
  recipientConfirmEmailLabel: 'test',
  recipientMessageLabel: 'test',
  recipientNamePlaceholder: 'test',
  recipientEmailPlaceholder: 'test',
  recipientMessagePlaceholder: 'test',
  recipientNameValidationText: 'test',
  recipientEmailValidationText: 'test',
  recipientConfirmEmailValidationText: 'test',
  recipientMessageValidationText: 'test',
  useSameEmailLabel: 'test',
  useBillingEmailText: 'test',
  defaultImage: 'test',
  cartSummaryHeading: 'test',
  paymentHeading: 'test',
  paymentRichText: 'test',
  paymentEditText: 'test',
  paymentRecipientNameLabel: 'test',
  paymentRecipientEmailLabel: 'test',
  paymentRecipientMessageLabel: 'test',
  paymentBillingNameLabel: 'test',
  paymentBillingEmailLabel: 'test',
  paymentTermsAndConditionsRichText: 'test',
  confirmationTitle: 'test',
  confirmationSubheading: 'test',
  confirmationInfoText: 'test',
  confirmationAdditionalHeading: 'test',
  confirmationAdditionalRichText: 'test',
  confirmationButtonText: 'test',
  confirmationButtonUrl: 'test',
};

export const contentPhysicalGiftCards: ContentPhysicalGiftCards = {
  stepOneHeading: 'test',
  selectGiftCardsHeading: 'test',
  selectGiftCardsRichText: 'test',
  customAmountPlaceholder: 'test',
  continueToDetailsButtonText: 'test',
  recipientDetailsHeading: 'test',
  recipientDetailsRichText: 'test',
  recipientDetailsNameLabel: 'test',
  recipientDetailsNamePlaceholder: 'test',
  recipientDetailsOptionalMessageLabel: 'test',
  recipientDetailsOptionalMessagePlaceholder: 'test',
  recipientDetailsAddressLineOneLabel: 'test',
  recipientDetailsAddressLineOnePlaceholder: 'test',
  recipientDetailsAddressLineTwoLabel: 'test',
  recipientDetailsAddressLineTwoPlaceholder: 'test',
  recipientDetailsCityLabel: 'test',
  recipientDetailsCityPlaceholder: 'test',
  recipientDetailsStateLabel: 'test',
  recipientDetailsStatePlaceholder: 'test',
  recipientDetailsPostcodeLabel: 'test',
  recipientDetailsPostcodePlaceholder: 'test',
  billingDetailsHeading: 'test',
  billingDetailsRichText: 'test',
  additionalRichText: 'test',
  continueToPaymentButtonText: 'test',
  stepTwoHeading: 'test',
  orderSummaryHeading: 'test',
  orderSummaryRichText: 'test',
  orderSummaryNameLabel: 'test',
  orderSummaryAddressLabel: 'test',
  orderSummaryMessageLabel: 'test',
  orderSummaryEditButtonText: 'test',
  stepThreeHeading: 'test',
  confirmationHeading: 'test',
  confirmationRichText: 'test',
  confirmationMessageIncludedText: 'test',
  confirmationAdditionalHeading: 'test',
  confirmationAdditionalRichText: 'test',
  goBackToWebsiteButtonText: 'test',
  buyAnotherGiftCardButtonText: 'test',
  cartSummaryDefaultImage: 'test',
  cartSummaryPhysicalGiftCardHeading: 'test',
  cartSummaryNameLabel: 'test',
  cartSummaryAddressLabel: 'test',
  cartSummaryMessageLabel: 'test',
  noPhysicalGiftCardsAvailableText: 'test',
  customAmountTooltipText: 'test',
  invalidInputText: 'test',
  physicalGiftCardDefaultImage: 'test',
};

export const contentGiftMembership: ContentGiftMembership = {
  stepOneHeading: 'test',
  selectGiftMembershipsHeading: 'test',
  selectGiftMembershipsRichText: 'test',
  continueToDetailsButtonText: 'test',
  additionalRichText: 'test',
  continueToPaymentButtonText: 'test',
  stepTwoHeading: 'test',
  orderSummaryHeading: 'test',
  orderSummaryRichText: 'test',
  orderSummaryNameLabel: 'test',
  orderSummaryAddressLabel: 'test',
  orderSummaryMessageLabel: 'test',
  orderSummaryEditButtonText: 'test',
  stepThreeHeading: 'test',
  confirmationHeading: 'test',
  confirmationRichText: 'test',
  confirmationMessageIncludedText: 'test',
  confirmationAdditionalHeading: 'test',
  confirmationAdditionalRichText: 'test',
  goBackToWebsiteButtonText: 'test',
  buyAnotherGiftMembershipButtonText: 'test',
  noGiftMembershipAvailableText: 'test',
  giftMembershipDefaultImage: 'test',
  cartSummaryDefaultImage: 'test',
  cartSummaryGiftMembershipHeading: 'test',
};

export const contentPayment: ContentPayment = {
  title: 'Secure payment:',
  totalToPayText: 'TOTAL TO PAY',
  subTitle: 'Select your payment method',
  optionalPaymentRichText: '',
  customerDetailsSubtitle: 'Send my ticket/s to...',
  customerDetailsRichText:
    "<p>Please complete the recipients' details below. We'll use this information to send you your tickets</p>",
  formErrorsMessage:
    "Looks like you've missed something. Please check and complete any fields highlighted in red.",
  formErrorsSubTitle: 'Oops. Something is wrong!',
  fullNameLabel: 'FULL NAME',
  fullNamePlaceholder: 'Name',
  fullNameValidationText: 'Must be 50 characters or less',
  emailLabel: 'EMAIL',
  emailPlaceholder: 'Email',
  emailValidationText: 'Please enter a valid email address',
  confirmEmailLabel: 'CONFIRM EMAIL',
  confirmEmailPlaceholder: 'Email',
  confirmEmailValidationText: 'Email must match',
  tabHeadingGiftCard: 'PAY BY GIFT CARD',
  tabHeadingCreditCard: 'DEBIT/CREDIT CARD',
  giftCardHeading: 'USE A GIFTCARD',
  giftCardEnterRichText:
    '<p>Enter your Gift Card number (located on the reverse side, above the magnetic strip).</p>',
  giftCardEnterFullPaymentRichText:
    '<p>You will need to have enough balance on your Gift Card to pay for your complete order.</p>',
  giftCardPlaceholder: 'GIFT CARD NO',
  closeButtonText: 'CLOSE',
  giftCardZeroBalanceText:
    'There is no balance on this Gift Card. Please use an alternative payment method.',
  giftCardBalanceText: 'YOUR GIFT CARD BALANCE IS:',
  giftCardExpiredText: 'THIS VOUCHER EXPIRED on {}/{}/{}',
  giftCardExpirationDateText: 'AND EXPIRES ON {}/{}/{}',
  giftCardRemainingBalanceText:
    "Once you click 'Confirm Payment' your Gift Card will have a balance of",
  giftCardPayButtonText: 'APPLY GIFT CARD',
  giftCardAppliedLabel: 'PAID ON GIFT CARD :',
  giftCardStillToPayLabel: 'STILL TO PAY',
  giftCardAppliedText: 'of your',
  giftCardAppliedPartTwoText: 'Gift Card has been applied',
  giftCardPayRemainingBalanceByCardText:
    'Pay remaining balance by a Credit Card.',
  giftCardInputTooltipText:
    'Remove a Gift Card if you want to use another one.',
  removeButtonText: 'REMOVE',
  giftCardRemainingBalanceLabel: 'REMAINING BALANCE',
  giftCardPayRemainingBalanceText:
    'Please pay your remaining balance by Debit/Credit Card or use another Gift Card',
  gratuityTitle: 'Add Gratuity',
  gratuityAddText: 'I want to add Gratuity',
  gratuityHeading: 'Select gratuity amoun',
  gratuityRichText: 'Add Gratuity here, yeah yeah.',
  paymentLogosText: 'Pay by Debit / Credit Card',
  paymentDetailsSubtitle: 'Complete Payment form',
  paymentInstructionsRichText:
    '<p>Just fill out the info below to finalize your transaction</p>',
  nameOnCardLabel: 'NAME ON CARD',
  nameOnCardPlaceHolder: 'Full Name',
  nameOnCardValidationText: 'Name on card is required',
  cardNumberLabel: 'Card Number',
  cardNumberValidationText: 'Card number is required',
  cardNumberPlaceHolder: 'Card Number',
  expiryDateLabel: 'Exp. Date',
  expiryMonthPlaceholder: 'Month',
  expiryMonthValidationText: 'Exp. month is required',
  expiryYearPlaceholder: 'Year',
  expiryYearValidationText: 'Exp. year is required',
  cvvLabel: 'Security Number',
  cvvPlaceholder: 'Security Number',
  cvvValidationText: 'Security number is required',
  zipCodeLabel: 'ZIP CODE',
  zipCodePlaceholder: 'Zip Code',
  zipCodeValidationText: 'Zip code is required',
  addressLabel: 'BILLING ADDRESS',
  addressPlaceholder: 'First Line of Address',
  addressValidationText: 'Billing address is required',
  submitText: 'SUBMIT PAYMENT OF',
  stripePayButtonText: 'Pay',
  termsConditionsHeading: 'Terms & Conditions',
  termsConditionsText: 'By clicking submit you agree to the',
  termsConditionsLinkText: 'Terms & Conditions',
  termsConditionsRichText:
    '<p>Children under 12 will not be admitted to R-Rated movies at any time.</p>\n<p>Companion seats are reserved for companions of guests using wheelchairs.</p>\n<p>Tickets can be refunded at the box office up to one hour before showtime, minus service fee.</p>',
  guestMarketingRichText: 'Guest Marketing',
  guestMarketingSubtitle: 'Let us know how ywe can contact you',
  guestMarketingNewsletterCheckboxText: 'Check to receive the newsletter',
  guestMarketingThirdPartyCheckboxText: 'Check to be contacted by third party',
  refundPolicyHeading: '',
  refundPolicyRichText: '',
  loyaltyRedemptionHeading: 'PAY WITH LOYALTY POINTS',
  loyaltyRedemptionZeroBalanceHeading: 'NO LOYALTY CREDIT AVAILABLE',
  loyaltyRedemptionRichText:
    '<p>YOU CURRENTLY HAVE ##LoyaltyPoints## POINTS WORTH: ##LoyaltyDollars##</p>',
  loyaltyRedemptionButtonText: 'PAY WITH LOYALTY POINTS',
  loyaltyRedemptionDollarsAppliedText: 'Your loyalty points have been applied',
  loyaltyRedemptionDollarsAppliedLabel: 'LOYALTY POINTS APPLIED',
  loyaltyRedemptionNotEnoughBalanceText:
    "You don't currently have enough Points to pay for this order.",
  loyaltyRedemptionRemainingBalanceRichText:
    '<p>You now have <strong>##LoyaltyDollarsRemaining##</strong> Loyalty Credit remaining.</p>',
  loyaltyRedemptionStillToPayRichText:
    '<p>Please pay the remaining balance of <strong>##BalanceToPayAfterLoyaltyDollars##</strong> with another payment method.</p>',
  customLoyaltyRichText:
    'To use your Reward Balance, enter the amount you wish to use. You can apply any amount up to the full value of your booking. You can pay any remaining balance using any other available payment method below.',
  customLoyaltyValueToApplyLabel: 'Credit to apply',
  customLoyaltyValueInputPlaceholder: 'Enter a custom amount',
  customLoyaltyValueTooltipText: 'Max. amount is',
  nameOnCardHelpText: 'Your full name as it appears on your card',
  cardNumberHelpText: 'The long card number as printed on front of card',
  expiryDateHelpText: 'The card exp. month and year',
  cvvHelpText: 'The last few digits on the back of your card',
  zipCodeHelpText: 'The zip or postal code of billing address',
  addressHelpText: 'The 1st line of your billing address',
  loyaltyRecognitionSubtitle: 'Have a loyalty account?',
  loyaltyRecognitionHeading: 'ENTER YOUR DETAILS & EARN REWARDS',
  loyaltyRecognitionLabel: 'Enter your loyalty number here',
  loyaltyRecognitionButtonText: 'SUBMIT',
  loyaltyRecognitionPlaceHolder: 'Loyalty number',
  loyaltyRecognitionErrorText: 'This loyalty number is not recognized',
  loyaltyRecognitionSuccessText:
    'Your account will be rewarded with loyalty points',
  loyaltyRecognitionTicketCreditLabel: 'Reward Ticket Credit',
  loyaltyRecognitionTicketCreditsAvailableLabel: 'reward ticket(s) available',
  loyaltyRecognitionTicketCreditsRichText:
    'Select how many of your <strong>##TICKETCREDITSAVAILABLE## reward ticket(s)</strong> you would like to use. They will automatically be redeemed against highest cost tickets.',
  cardNumberInvalidText: 'Card number is invalid',
  cardTypeNotSupportedText: 'Card type is not supported',
  dateInPastValidationText: 'Date is in the past',
  cvvInvalidText: 'Security number is invalid',
  telephoneLabel: 'Phone Number',
  telephonePlaceholder: 'Phone Number',
  telephoneValidationText: 'Invalid phone number',
  voucherRedemptionHeading: 'USE A VOUCHER',
  voucherEnterRichText: 'text',
  voucherRedemptionPlaceholder: 'text',
  voucherApplyButtonText: 'text',
  voucherCloseButtonText: 'text',
  voucherAppliedLabel: 'text',
  voucherAppliedText: 'text',
  voucherAppliedPartTwoText: 'text',
  voucherInputTooltipText: 'text',
  voucherRemoveButtonText: 'text',
  voucherRemainingBalanceText: 'text',
  voucherStillToPayLabel: 'text',
  donationText:
    'Support our mission of meaningful employment for people with disabilities!',
  donationSubTitle: "I'd like to Donate...",
  donationRoundUpRichText:
    '<b>Round up for Good</b> (+##DonationRoundUpValue##) Benefiting Name of the Charity. <a href="https://boxoffice.com" target="_blank">More info</a>',
  giftCardDonationNotIncludedRichText:
    '<p>Please note: As we cannot currently accept donations by Gift Card payment, your Gift Card payment will not include your ##DonationAmount## donation.</p><p>Your can still pay your donation amount by Debit/Credit card if you wish.</p>',
  storedCardPaymentSelectSavedCardText: '',
  storedCardPaymentNoSavedCardsText: '',
  storedCardPaymentRemoveCardButtonText: '',
  storedCardPaymentExpirationLabel: '',
  storedCardPaymentSaveNewCardText: '',
  storedCardPaymentPayWithNewCardHeading: '',
  multiplePaymentProvidersCardLabel: '',
  guestNewsletterSignUpCheckboxText: '',
  storedCardPaymentRemoveCardConfirmationText: '',
  storedCardPaymentRemoveCardConfirmationYesButtonText: '',
  storedCardPaymentRemoveCardConfirmationNoButtonText: '',
  customerDetailsCollapsedSummaryHeadingText: '',
  customerDetailsCollapsedSummaryEditButtonText: '',
  giftCardSummaryShowDetailsButtonText: '',
  giftCardSummaryHideDetailsButtonText: '',
};

export const contentRefund: ContentRefund = {
  title: 'Refund Request',
  bookingReferenceText: 'Your booking reference is:',
  ticketsEmailedToText: 'Your confirmation will be emailed to you at:',
  loyaltyLoginRefundRequestMessage:
    '<h4 style="text-align: center;">Loyalty Members Log in</h4>\n<p>Loyalty members enjoy guaranteed refunds. Log in to have the transaction entirely refunded, including the service charge.</p>',
  continueAsGuestMessage:
    '<h4 style="text-align: center;">Non-Loyalty refund</h4>\n<p>If you are not a loyalty member, the refund will not include the service charge.</p>',
  genericRefundRequestMessage:
    '<h4 style="text-align: center;">Please Note:</h4>\n<p>You are about to request a refund for your tickets.<br />Your entire transaction will be refunded, including the service charge.</p>',
  loyaltyRefundRequestConfirmation:
    '<h4 style="text-align: center;">About the Refund</h4>\n<p>You are about to request a refund for your tickets.<br />As you are a Loyalty Member, your entire transaction will be refunded, including the service charge.</p>',
  nonLoyaltyRefundRequestConfirmation:
    '<h4 style="text-align: center;">Please Note:</h4>\n<p>You are about to request a refund for your tickets.</p>',
  refundAgreeTermsText:
    '<p>Yes, I understand that my refund will only be for the tickets, and not include Service Charge</p>',
  refundRequestedMessage:
    '<p>Thank you!</p>\n<p>Your refund is now being processed, you will receive a confirmation email shortly.</p>',
  confirmButtonText: 'Submit',
  logInButtonText: 'Log In',
  continueAsGuestButtonText: 'Submit',
  backToWebsiteButtonText: 'Go Back To The Website',
};

export const contentSeats: ContentSeats = {
  additionalRichText: 'Leave space of ##BUFFERSEATS##',
  title: 'Your seats:',
  subTitle: 'Confirm or select your',
  yourSeats: 'Your Seats',
  screenLabel: 'SCREEN',
  backOfTheaterLabel: 'BACK OF THEATER',
  companionSeatWarningTitle: 'Companion Seat Warning',
  companionSeatWarningMessage:
    'This seat is reserved for companions of wheelchair users. If this seat is required by a companion, you may be asked to move. Would you still like to select this seat?',
  wheelchairSeatWarningTitle: 'Wheelchair Space Warning',
  wheelchairSeatWarningMessage:
    'This space is reserved for wheelchair use only and does not have a seat. This wheelchair space and the seat adjacent to it are reserved for a patron using a wheelchair and their companion only.',
  seatWarningButtonNo: 'Cancel',
  seatWarningButtonYes: 'Select',
  singleSeatRuleMessage:
    'No single seats between occupied seats or aisle permitted.',
  titleSeatText: 'Seat',
  titleRowText: 'Row',
  titleSeatNumberText: 'Number',
  titleAvailableText: 'Available',
  titleSelectedText: 'Selected',
  titleUnavailableText: 'Unavailable',
  titleUnavailableWithSelectedTicketsText: 'Unavailable With Selected Tickets',
  titleAccessibleText: 'Accessible',
  titleBufferText: 'Buffer Seat',
  titleCompanionText: 'Companion',
  titleEmergencyExitText: 'Emergency Exit',
  titleStairsText: 'Stairs',
  titleEntranceText: 'Entrance',
  titleSofaText: 'Sofa',
  titleLeftSofaText: 'Left Sofa',
  titleMiddleSofaText: 'Middle Sofa',
  titleRightSofaText: 'Right Sofa',
  titleLuxuryReclinerText: 'Luxury Recliner',
  titleObstructedView: 'Restricted View',
  titleVipText: 'VIP',
  titleZoomInText: 'Zoom In',
  titleZoomOutText: 'Zoom Out',
  cartUpdateModalTitle: 'Your Cart Has Been Updated',
  cartUpdateModalExplanationText: 'We had to adjust your cart items.',
  cartUpdateModalRemovedItemText: 'Below items will be removed from the cart:',
  cartUpdateModalKioskCollectionText:
    'Remaining items in the cart need to picked up at the counter:',
  cartUpdateModalCancelButtonTitle: 'Cancel',
  cartUpdateModalContinueButtonTitle: 'Continue',
};

export const contentCartSummary: ContentCartSummary = {
  yourSummarySubtitle: 'Your Summary',
  atLabel: 'At:',
  onLabel: 'On:',
  emailLabel: 'Email:',
  yourSeatsLabel: 'Your Seats',
  serviceChargeLabel: 'Service Charge',
  donationsLabel: 'Donations',
  gratuityLabel: 'Gratuity',
  taxLabel: 'Tax',
  totalLabel: 'Total:',
  rewardsLabel: 'Rewards',
  editButtonText: 'EDIT',
  closeButtonText: 'CLOSE',
  excTaxText: '(exc tax)',
  pricesLabel: 'All prices shown ##include-exclude## tax',
  pricesLabelIncludeText: 'include',
  pricesLabelExcludeText: 'exclude',
  subtotalLabel: 'Subtotal',
  deliveryOptionLabel: 'Delivery option:',
  deliveryCommentLabel: 'test comment',
  bookingRefLabel: 'ABCD1234',
  discountLabel: 'Discount',
  dealsLabel: 'Deals have saved you',
  ticketCreditLabel: 'Free reward discount',
};

export const contentSignIn: ContentSignIn = {
  title: 'Have a loyalty account?',
  loginAsMemberSubTitle: 'Existing Rewards Members',
  loginAsMemberRichText:
    '<p>To earn points or rewards for your booking. please login to your rewards account before you buy your tickets.</p>',
  loginButtonText: 'LOG IN',
  continueAsGuestSubTitle: 'Not yet a member?',
  continueAsGuestRichText:
    "<p>Don't have a Rewards account? Just continue as a guest, or register for an account before trying to buy tickets.</p>",
  continueAsGuestButtonText: 'CONTINUE AS GUEST',
  registerRichText: '<p>Register here!</p>',
  registerButtonText: 'Register Now',
  signedInLabel: 'SIGNED IN:',
  loyaltyOnlyShowtimeHeading:
    'Members Only Showtime. Log in or Register to Continue.',
  loyaltyOnlyShowtimeMemberHeading: 'Already a Member?',
  loyaltyOnlyShowtimeMemberRichText:
    'Sign in to purchase tickets for this showtime, and to earn your rewards',
  loyaltyOnlyShowtimeRegisterHeading: 'Not a member?',
  loyaltyOnlyShowtimeRegisterRichText:
    '<p>Register here to sign up for rewards so you can buy tickets for this showtime</p>',
  loyaltyOnlyVouchersHeading:
    'Members Only Vouchers. Log in or Register to Continue.',
  loyaltyOnlyVouchersMemberHeading: 'Already a Member?',
  loyaltyOnlyVouchersMemberRichText:
    'Sign in to purchase tickets for this Vouchers, and to earn your rewards',
  loyaltyOnlyVouchersRegisterHeading: 'Not a member?',
  loyaltyOnlyVouchersRegisterRichText:
    '<p>Register here to sign up for rewards so you can buy tickets for this Vouchers</p>',
  loginRedirectInfoText: '',
  loginUsernameLabel: '',
  loginUsernamePlaceholder: '',
  loginUsernameValidationText: '',
  loginPasswordLabel: '',
  loginPasswordPlaceholder: '',
  loginPasswordValidationText: '',
  loginShowPasswordButtonTitle: '',
  loginHidePasswordButtonTitle: '',
  loginRememberUsernameText: '',
  loginForgottenPasswordLinkText: '',
  loggedInHeading: '',
  loggedInRichText: '',
  loggedInContinueButtonText: '',
  loggedInLogOutHeading: '',
  loggedInLogOutButtonText: '',
  signUpHeading: '',
  signUpRichText: '',
  signUpUsernameLabel: '',
  signUpNameLabel: '',
  signUpPasswordRequirementsRichText: '',
  signUpTermsAndConditionsCheckboxText: '',
  signUpTermsAndConditionsRichText: '',
  signUpButtonText: '',
  signUpCompleteRichText: '',
  registerHeading: '',
  upgradeHeading: '',
  upgradeMemberHeading: '',
  upgradeMemberRichText: '',
};

export const contentTickets: ContentTickets = {
  title: 'Select tickets:',
  subTitle: '',
  serviceChargeText: 'Service charge',
  dealWithThisTicketText: 'with this ticket',
  dealAppliedText: 'applied',
  dealAvailableText: 'available',
  dealAddMoreText: 'add one more',
  introductionRichText: '',
  additionalSubTitle: '',
  additionalRichText: '',
  seatsFirstSubtitle: 'Select your',
  voucherHeading: 'USE A VOUCHER CODE',
  voucherLabel: 'Enter your voucher here',
  voucherPlaceholder: 'Voucher number',
  voucherErrorText: 'This voucher code was not recognised.',
  voucherAlreadyAppliedText: 'This voucher has already been applied.',

  packageTicketsHeading: 'Package Tickets',
  packageTicketsIncludesLabel: 'Includes',
  standardTicketsHeading: 'Standard Tickets',
  ceaCardHeading: 'Have a CEA card?',
  ceaCardLabel: 'Enter your CEA card here',
  ceaCardPlaceHolder: 'CEA card number',
  ceaCardErrorText: 'This CEA card was not recognised',
  ceaCardInsufficientTicketsText:
    'Please select as many paid-for tickets as CEA tickets',
  ceaCardAlreadyAppliedText: 'This CEA card has already been applied',
  ceaCardExpiredText: 'This CEA card has expired',
  memberTicketsHeading: 'Member Tickets',
  memberTicketsInfoText:
    'Members are entitled to purchase one reduced price ticket for themselves',
  memberTicketsAdditionalCardText:
    'To add additional loyalty tickets, enter another (not your own) loyalty card number',
  memberTicketsCardPlaceHolder: 'Loyalty card no.',
  memberTicketsCardAlreadyAppliedText: 'This card has already been applied',
  memberTicketsAdditionalTicketHeading: 'Additional loyalty tickets',
  memberTicketsAddedMemberCardText: 'INSIDER ticket for:',
  memberTicketsReselectReminderText:
    'Note: You may need to reselect your tickets once you login.',
  memberTicketsLoginOrRegisterText:
    'The loyalty card you have entered is not recognised',
  memberTicketsLoginButtonText: 'Login',
  memberTicketsRegisterButtonText: 'Register',
  memberTicketsMemberCardInvalidErrorText:
    'The loyalty card you have entered is not recognised',
  thirdPartyMemberTicketsVoucherHeading: 'Have a third party voucher?',
  thirdPartyMemberTicketsVoucherText: 'Enter your meerkat code here',
  thirdPartyMemberTicketsVoucherLabel: 'Enter your third party voucher code',
  thirdPartyMemberTicketsVoucherPlaceholder: 'Voucher code',
  thirdPartyMemberTicketsVoucherInvalidVoucherText:
    'This third party voucher code is invalid',
  thirdPartyMemberTicketsVoucherAlreadyAppliedText:
    'This third party voucher code has already been used',
  thirdPartyMemberTicketsVoucherNoTicketsReturnedText:
    'No tickets available for this session',
  bookingFeeInfoBoxRichText: '',
};

export const contentKiosk: ContentKiosk = {
  title: 'Choose your snacks',
  subTitle: '',
  introductionRichText: '',
  additionalSubTitle: '',
  additionalRichText: '',
  noConcessionsMessage:
    'Unfortunately, there are currently no food and beverage items to display, please continue to payment to pay for your tickets.',
  showAllOptionsButtonText: 'SHOW OPTIONS',
  hideAllOptionsButtonText: 'HIDE ALL',
  selectOptionsButtonText: 'SELECT OPTIONS',
  editOptionsButtonText: 'EDIT OPTIONS',
  hideOptionsButtonText: 'HIDE OPTIONS',
  selectedOptionsButtonText:
    '##quantity## of your ##maxquantity## options selected',
  selectedOptionsLabel: 'Selected Options',
  addRemoveCartLabel: 'Add/Remove to cart',
  closeButtonText: 'CLOSE',
  itemsLabel: 'Items',
  qtyLabel: 'Qty.',
  priceLabel: 'Price',
  optionalLabel: '(optional)',
  selectCategoryText: 'Select a category',
  soldOutText: 'Sold out',
  giftCardUpsellTitle: '',
  giftCardUpsellRichText: '',
  buttonRequiredText: '',
  buttonUpdateText: '',
  buttonUpdatedText: '',
  buttonAddedText: '',
  buttonText: '',
  groupRequiredDescription: '',
  groupSelectedDescription: '',
  groupChangesMadeDescription: '',
  selectRequiredText: '',
  selectedOfRequiredText: '',
  selectOptionalExtras: '',
  kioskModalCancel: '',
  kioskModalRemove: '',
  smartModifierGroupTitle: '',
};

export const contentConcessionsOnlyJourney: ContentConcessionsOnlyJourney = {
  addCommentLabel: 'Additional comments:',
  addCommentPlaceholder: 'Add dietary requirements, etc. here',
  closeButtonText: 'Close',
  collectFromKioskLabel: 'Collect from kiosk',
  continueToDeliveryOptionsText: 'Continue to delivery options',
  deliveryOptionsHeading: 'How would you like to collect your order?',
  deliveryToSeatLabel: 'Deliver to my seat',
  deliveryWindowsListHeading: 'When shall we deliver your order to your seat?',
  goBackButtonText: 'Go Back',
  confirmationAdditionalContentHeading: 'Additional info',
  confirmationAdditionalContentRichText: 'Additional info goes here...',
  confirmationCollectFromKioskDetailsHeading: 'Details of Collect from Kiosk',
  confirmationCollectFromKioskDetailsRichText:
    'Informatin about how to collect from the kiosk blah blah blah...',
  confirmationCollectFromKioskHeading: 'Collect from Kiosk',
  confirmationCollectFromKioskText: 'Collect your order from the kiosk at:',
  confirmationDeliveryDetailsHeading: 'Delivery to my seat',
  confirmationOptionalCollectionHeading: 'If there are any problems',
  confirmationOptionalCollectionRichText:
    'If there are any problems you can still collect your order from kiosk using this barcode',
  confirmationOrderDetailsHeading: 'Your concessions order is confirmed',
  confirmationOrderEmailLabel: 'Receipt emailed to:',
  confirmationOrderNumberLabel: 'Order no:',
  confirmationOrderToSeatDetailsHeading: 'Details of order to seat delivery',
  confirmationOrderToSeatDetailsRichText: 'We will deliver blah blah blah...',
  landingHeading: 'Enter Booking Ref',
  landingText: 'please i beg you to enter the booking ref',
  landingContinueButtonText: 'Continue',
  landingStartConcessionsButtonText: 'continue',
  landingGoBackButtonText: 'go back and change the booking ref',
  landingBookingReferenceLabel: 'Booking Reference',
  landingBookingReferenceError: 'incorrect booking ref',
  landingBookingReferencePlaceholder: 'Example: 123456AB',
  postageOptionsHeading: 'Select your postage options',
  postageOptionsRichText: '<p>This applies to all items in basket.</p>',
};

export const contentLoyalty: ContentLoyalty = {
  billingHeading: 'billingHeading',
  billingRichText: 'billingRichText',
  confirmationTitle: 'confirmationTitle',
  confirmationSubHeading: 'confirmationSubHeading',
  confirmationButtonText: 'confirmationButtonText',
  continueToBillingButtonText: 'continueToBillingButtonText',
  continueToPaymentButtonText: 'continueToPaymentButtonText',
  defaultImage: 'defaultImage',
  landingContinueButtonText: 'landingContinueButtonText',
  landingHeading: 'landingHeading',
  landingText: 'landingText',
  noUpgradesMessage: 'noUpgradesMessage',
  optionButtonUnselectedText: 'optionButtonUnselectedText',
  optionButtonSelectedText: 'optionButtonSelectedText',
  paymentBillingNameLabel: 'paymentBillingNameLabel',
  paymentBillingEmailLabel: 'paymentBillingEmailLabel',
  paymentEditText: 'paymentEditText',
  paymentHeading: 'paymentHeading',
  unselectedContinueButtonText: 'unselectedContinueButtonText',
  upgradeConfirmationRichText: 'upgradeConfirmationRichText',
  upgradeConfirmationBenefitsHeading: 'upgradeConfirmationBenefitsHeading',
  upgradeConfirmationBenefitsRichText: 'upgradeConfirmationBenefitsRichText',
  upgradeMainTitle: 'upgradeMainTitle',
  upgradeInstructionsRichText: 'upgradeInstructionsRichText',
  upgradePaymentRichText: 'upgradePaymentRichText',
  upgradeSelectionHeading: 'upgradeSelectionHeading',
  signUpCartHeading: 'signUpCartHeading',
  signupConfirmationPageTitle: 'signupConfirmationPageTitle',
  signupSelectTierHeading: 'signupSelectTierHeading',
  signUpSelectionBoxHeading: 'signUpSelectionBoxHeading',
  signupSelectTierInstructionsRichText: 'signupSelectTierInstructionsRichText',
  signupOptionButtonFreeText: 'signupOptionButtonFreeText',
  signupOptionButtonPaidText: 'signupOptionButtonPaidText',
  signupFreeTierSelectedContinueButton: 'signupFreeTierSelectedContinueButton',
  signupPaidTierSelectedContinueButton: 'signupPaidTierSelectedContinueButton',
  signupFreeTierCostText: 'signupFreeTierCostText',
  signUpDetailsHeading: 'signUpDetailsHeading',
  signupDetailsRichText: 'signupDetailsRichText',
  signUpYourDetailsHeading: 'signUpYourDetailsHeading',
  signupYourDetailsRichText: 'signupYourDetailsRichText',
  signUpPasswordHeading: 'signUpPasswordHeading',
  signupPasswordRichText: 'signupPasswordRichText',
  signupDateOfBirthLabel: 'signupDateOfBirthLabel',
  signupDateOfBirthInvalid: 'signupDateOfBirthInvalid',
  signupConfirmPasswordLabel: 'signupConfirmPasswordLabel',
  namePaymentLabel: 'namePaymentLabel',
  emailPaymentLabel: 'emailPaymentLabel',
  telephonePaymentLabel: 'telephonePaymentLabel',
  zipCodePaymentLabel: 'zipCodePaymentLabel',
  signupPaymentTitle: 'signupPaymentTitle',
  signupPaymentHeading: 'signupPaymentHeading',
  signupPaymentRichText: 'signupPaymentRichText',
  goToMembersPage: 'goToMembersPage',
  confirmationHeading: 'confirmationHeading',
  confirmationRichText: 'confirmationRichText',
  confirmationAdditionalHeading: 'confirmationAdditionalHeading',
  confirmationAdditionalRichText: 'confirmationAdditionalRichText',
  signupDateOfBirthMonthLabel: 'Month',
  signupDateOfBirthDayLabel: 'Day',
  signupDateOfBirthYearLabel: 'Year',
};

export const contentSeasonPass: ContentSeasonPass = {
  selectSeasonPassTitle: 'string',
  selectSeasonPassRichText: 'string',
  selectSeasonPassButtonText: 'string',
  selectTicketsTitle: 'string',
  selectTicketsRichText: 'string',
  selectSessionsTitle: 'string',
  selectSessionsRichText: 'string',
  selectSessionsButtonText: 'string',
  selectSessionsTopBarText: 'string',
  confirmSeatsTitle: 'string',
  confirmSeatsRichText: 'string',
  updateSeatsButtonText: 'string',
  selectedSeatsText: 'string',
  backToShowtimesWarningModalTitle: 'Are you sure?',
  backToShowtimesWarningModalRichText:
    '<p>You may loose current selected seats for all showtimes</p>',
  backToShowtimesWarningModalAcceptButtonText: 'Ok',
  backToShowtimesWarningModalCancelButtonText: 'Cancel',
};

export const content: Content = {
  concessionsOnlyJourney: contentConcessionsOnlyJourney,
  confirmation: contentConfirmation,
  continueButtonText: 'Continue',
  continueToPaymentText: 'Continue to payment',
  continueToSeatsText: 'Continue to seats',
  continueToTicketsText: 'Continue to tickets',
  continueToFoodText: 'Continue to food and beverage',
  continueToGiftCardsText: 'Continue to gift cards',
  countDownLabel: 'Time to complete:',
  culture: 'en-US',
  descriptionText:
    "I'm going to see ##film## at ##cinema## on ##date## at ##time##, wanna join me?",
  embargo: contentEmbargo,
  error: contentError,
  faviconUrl: 'https://wmp-dti-uat-cms.azurewebsites.net/media/1020/mjr.ico',
  giftCards: contentGiftCards,
  goBackText: 'Go back',
  primaryFontUrl: 'Volkhov',
  secondaryFontUrl: 'Roboto Condensed',
  logoFooterUrl: '',
  logoUrl: 'https://wmp-dti-uat-cms.azurewebsites.net/media/1007/mjr.png',
  loyalty: contentLoyalty,
  googleRecaptchaRichText: 'This site uses recaptcha',
  payment: contentPayment,
  refund: contentRefund,
  seats: contentSeats,
  cartSummary: contentCartSummary,
  signIn: contentSignIn,
  tickets: contentTickets,
  kiosk: contentKiosk,
  title: 'MJR Digital Cinemas',
  selectShowtimeText: 'Select A Showtime',
  updateShowtimeText: 'Update Showtime',
  qtyButtonAddItemText: 'Add item',
  qtyButtonRemoveItemText: 'Remove item',
  amountSelectorSelectedText: 'Selected',
  amountSelectorCustomAmountLabel: 'Other',
  amountSelectorCustomAmountTooltipText: 'Max amount is',
  vouchers: contentVouchers,
  physicalGiftCards: contentPhysicalGiftCards,
  giftMembership: contentGiftMembership,
  loyaltyPromoCloseButtonText: 'Not interested',
  loyaltyPromoRichText:
    '<p>Sign up for <b>Rewards Program</b> and ear reward $ for every purchase.',
  loyaltyPromoRegisterButtonText: 'Register now',
  loyaltyPromoLoginText: 'Already a member?',
  loyaltyPromoLoginButtonText: 'Log in here',
  selectANewShowtimeText: 'SELECT A NEW SHOWTIME',
  attributeWarnings: [],
  screenWarnings: [],
  extraMenuButtonText: '',
  extraMenuLoginHeading: '',
  extraMenuLoginButtonText: '',
  extraMenuSelectLanguageHeading: '',
  extraMenuLogoutButtonText: '',
  seasonPass: contentSeasonPass,
  checkButtonText: 'Check',
  checkingButtonText: 'Checking...',
  footerPrivacySettingsText: 'Privacy Settings',
};
