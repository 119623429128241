import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectConfig, selectContent } from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import RichText from '../richtext/RichText';

const RefundConfirmed: React.FC = () => {
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);

  return (
    <Row data-testid='refund-confirmed'>
      <Col className='contained' xs={12}>
        <RichText text={content.refund.refundRequestedMessage} />
        <ActionButton
          onClick={() =>
            (window.location.href = config.currentCinema.cinemaHomeUrl)
          }
          showIcon
          contained
          variant='primary'
        >
          {content.refund.backToWebsiteButtonText}
        </ActionButton>
      </Col>
    </Row>
  );
};

export default RefundConfirmed;
