import React from 'react';

import PaymentIconsContainer from './PaymentIconsContainer';

interface Props {
  creditCardType: string;
  paymentDesignName: 'split' | 'tabbed';
}

const PaymentIconsDesignContainer: React.FC<Props> = ({
  creditCardType,
  paymentDesignName,
}) => {
  const containerClassName =
    paymentDesignName == 'split'
      ? 'd-flex justify-content-start align-items-center'
      : undefined;

  const paymentIconsTextClassName =
    paymentDesignName == 'tabbed' ? 'tiny' : undefined;

  const showPaymentIconsText = paymentDesignName === 'split';

  return (
    <PaymentIconsContainer
      creditCardType={creditCardType}
      showPaymentIconsText={showPaymentIconsText}
      containerClassName={containerClassName}
      paymentIconsTextClassName={paymentIconsTextClassName}
    />
  );
};

export default PaymentIconsDesignContainer;
