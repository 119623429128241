import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Concession, OrderDelivery } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { getRouteFromStep } from '../../../services/JourneyService';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectStep,
  selectToken,
  selectVoucherRecipient,
  selectJourneyTypeConfig,
  selectEVouchersGroup,
  selectConcessionsAdded,
  selectAllConcessions,
  selectSelectedGiftCards,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import RichText from '../../common/richtext/RichText';
import DeliveryDetails from '../../common/voucherstore/DeliveryDetails';
import VoucherKiosk from '../../common/voucherstore/VoucherKiosk';

const VouchersSelect: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const availableConcessions = useSelector(selectAllConcessions);
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const step: number = useSelector(selectStep);
  const voucherRecipient = useSelector(selectVoucherRecipient);
  const eVoucherGroup = useSelector(selectEVouchersGroup);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [noConcessionReturn, setNoConcessionReturn] = useState<boolean>(false);

  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/EVouchers/Get/${bookingData.circuitId}/${bookingData.cinemaId}`;
      const response = await backend.get(path);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(
          actionCreators.setConcessions(response.content.listConcessionGrouping)
        );
        if (response.content.listConcessionGrouping.count === 0) {
          setNoConcessionReturn(true);
        }
      } else {
        setNoConcessionReturn(true);
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!availableConcessions && bookingData) {
      getConcessionData();
    }
  }, [
    availableConcessions,
    dispatch,
    bookingData,
    noConcessionReturn,
    config.circuitId,
  ]);

  const hasConcessions = selectedConcessions
    ? selectedConcessions.list.some((x: Concession) => x.quantity > 0)
    : false;

  const navigateToNextPath = () => {
    navigate(`/${getRouteFromStep(journeyTypeConfig, step + 1)}`);
  };

  const addVouchersSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: true,
        isGiftWrapped: false,
        giftMessage: voucherRecipient.message,
        deliveryAddress: {
          name: voucherRecipient.name,
          email: voucherRecipient.email,
        },
        billingAddress: {
          email: customer.email,
          name: customer.name,
        },
      };

      selectedConcessions?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedConcessions
          ? selectedConcessions.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const response = await backend.post(
        `api/EVouchers/Post/${bookingData.cinemaId}`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        navigateToNextPath();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addVouchersSelectionToPos();
  };

  if (!content.vouchers || !config) return null;

  return (
    <div className='giftcards' data-testid='vouchers'>
      <Row>
        <Col xs={12} className='contained'>
          <Heading className='text-center' size={1}>
            {content.vouchers.selectVouchersHeading}
          </Heading>
          <RichText text={content.vouchers.instructionsRichText} />
        </Col>
      </Row>

      {eVoucherGroup ? (
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <VoucherKiosk />
            {!showRecipientForm ? (
              <ActionButton
                disabled={!hasConcessions}
                mt='mt-5'
                mx='mx-3'
                onClick={() => setShowRecipientForm(true)}
                sticky={false}
                showIcon={true}
                contained
                variant='primary'
              >
                {content.vouchers.voucherSelectionContinueButtonText}
              </ActionButton>
            ) : (
              <>
                <DeliveryDetails isPageValidated={validateForm} />
                <ActionButton
                  mt='mt-5'
                  mx='mx-3'
                  sticky={false}
                  showIcon={true}
                  contained
                  onClick={handleContinueToPaymentClick}
                  disabled={
                    !hasConcessions ||
                    !voucherRecipient.isValid ||
                    !customer.isValid
                  }
                  stickyMobileDesktop={false}
                  variant='primary'
                >
                  {content.vouchers.recipientDetailsContinueButtonText}
                </ActionButton>
              </>
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12} className='contained mt-1'>
            <div className='warning-container mt-3 p-3'>
              <p>{content.vouchers.noVouchersMessage}</p>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default VouchersSelect;
