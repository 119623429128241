import React from 'react';

import { useSelector } from 'react-redux';

import { displayPrice } from '../../../services/Helpers';
import {
  selectContentCartSummary,
  selectCurrencyConfig,
  selectTicketCreditDiscount,
} from '../../../store/Selectors';

const CartSummaryLoyaltyRecognition: React.FC = () => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const ticketCreditDiscount = useSelector(selectTicketCreditDiscount);
  const currencyConfig = useSelector(selectCurrencyConfig);
  if (!ticketCreditDiscount) return null;
  return (
    <div
      className='cart-summary-row cart-summary-row-flex'
      data-testid='cs-loyalty-recognition'
    >
      {contentCartSummary.ticketCreditLabel}
      <span className='text-end'>
        - {displayPrice(ticketCreditDiscount, currencyConfig)}
      </span>
    </div>
  );
};

export default CartSummaryLoyaltyRecognition;
