/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  SeatMapRow,
  SeatMapSeat,
  SeatButtonStyle,
  GlobalState,
} from '../../../../@types/modelTypes';
import { SEAT_STYLE } from '../../../../constants';
import { isSeatSelected } from '../../../../services/SeatMapHelpers';
import {
  selectIsSeatsFirstJourney,
  selectSeatsAvailableInArea,
  selectSelectedSeats,
} from '../../../../store/Selectors';
import SeatButton from '../SeatButton';

interface Props {
  seat: SeatMapSeat;
  index: number;
  row: SeatMapRow;
  rowIndex: number;
  activeRow: SeatMapRow | null;
  forceSeatText: boolean;
  handleSeatClick: (seat: SeatMapSeat) => void;
  seatButtonStyle: SeatButtonStyle;
  className: string;
  borderColor: string | undefined;
  highlightColor: string | undefined;
  areaLabel: string | undefined;
  overrideSoldAsGroup: boolean;
}

const SeatColumn: React.FC<Props> = ({
  seat,
  index,
  row,
  rowIndex,
  activeRow,
  forceSeatText: forceSeatNumbers,
  handleSeatClick,
  seatButtonStyle,
  className,
  borderColor,
  highlightColor,
  areaLabel,
  overrideSoldAsGroup,
}) => {
  const selectedSeats = useSelector(selectSelectedSeats);
  const seatsAvailableInArea = useSelector((state: GlobalState) =>
    selectSeatsAvailableInArea(state, seat.areaCategoryCode)
  );
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  const isSelectedRow = activeRow === row;
  const isSelected = isSeatSelected(seat, selectedSeats);
  const areaProhibited =
    !isSeatsFirstJourney && !(seat.areaCategoryCode && seatsAvailableInArea);

  function isThreeSeatSofa() {
    const nextSeat = row.seats[index + 1];
    const previousSeat = row.seats[index - 1];
    let isThreeSeatSofa = false;
    isThreeSeatSofa =
      (nextSeat && nextSeat.style === SEAT_STYLE.SOFA_MIDDLE) ||
      (previousSeat && previousSeat.style === SEAT_STYLE.SOFA_MIDDLE);
    return isThreeSeatSofa;
  }

  const seatCssStyle = {
    backgroundColor: highlightColor,
    borderColor: borderColor,
  };

  return (
    <Col
      className={classnames('seat-column', className)}
      key={`seat-${rowIndex}-${index}`}
      style={seatCssStyle}
      sx={{
        '&.area1, &.area2, &.area3': {
          borderColor: 'bodySeparatorColor',
          color: 'mostReadableOnWebsiteBackground',
          backgroundColor: 'boxHeaderBackground',
        },
      }}
    >
      {areaLabel && (
        <div className='position-absolute area-label'>{areaLabel}</div>
      )}
      {seat.isASeat && (
        <SeatButton
          areaProhibited={areaProhibited}
          seatButtonStyle={seatButtonStyle}
          forceSeatText={forceSeatNumbers}
          handleSeatClick={handleSeatClick}
          isSelected={isSelected}
          isSelectedRow={isSelectedRow}
          isThreeSeatSofa={isThreeSeatSofa()}
          seat={seat}
          isCoordinateBased={false}
          overrideSoldAsGroup={overrideSoldAsGroup}
        />
      )}
    </Col>
  );
};

export default SeatColumn;
