import React from 'react';

import { useSelector } from 'react-redux';

import { selectContent } from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import LoginSection from '../LoginSection';
import Register from '../Register';

interface Props {
  handleContinueClick: () => void;
}

const GuestLoginOptions: React.FC<Props> = ({ handleContinueClick }) => {
  const content = useSelector(selectContent);
  return (
    <>
      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-4' size={2}>
          {content.signIn.loginAsMemberSubTitle}
        </Heading>
        <RichText text={content.signIn.loginAsMemberRichText} />
        <LoginSection />
      </ContainedRow>

      <ContainedRow classNameRow='mt-3' classNameCol='text-center'>
        <div className='separator' />
      </ContainedRow>

      <ContainedRow classNameCol='text-center'>
        <Heading className='mt-5' size={2}>
          {content.signIn.continueAsGuestSubTitle}
        </Heading>
        <RichText text={content.signIn.continueAsGuestRichText} />

        <ActionButton
          onClick={handleContinueClick}
          showIcon
          contained
          mx='mx-3'
          variant='primary'
        >
          {content.signIn.continueAsGuestButtonText}
        </ActionButton>
      </ContainedRow>

      <Register />
    </>
  );
};

export default GuestLoginOptions;
