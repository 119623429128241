import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isLength } from 'validator';

import { ContentGiftCards } from '../../../../@types/contentTypes';
import { GiftCardRecipient, Customer } from '../../../../@types/modelTypes';
import { isBansheeSafeEmail } from '../../../../services/Helpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectContent,
  selectGiftCardRecipient,
} from '../../../../store/Selectors';

interface Props {
  isPageValidated: boolean;
  billingAsRecipient: boolean;
}

const RecipientDetails: React.FC<Props> = ({
  isPageValidated,
  billingAsRecipient,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);

  const [recipientState, setRecipientState] = useState<GiftCardRecipient>({
    name: giftCardRecipient.name ?? '',
    nameIsValid: !!giftCardRecipient.nameIsValid,
    nameIsValidated: false,
    email: giftCardRecipient.email ?? '',
    emailIsValid: !!giftCardRecipient.emailIsValid,
    emailIsValidated: false,
    confirmEmail: giftCardRecipient.confirmEmail ?? '',
    confirmEmailIsValid: !!giftCardRecipient.confirmEmailIsValid,
    confirmEmailIsValidated: false,
    message: giftCardRecipient.message ?? '',
    messageIsValid: !!giftCardRecipient.messageIsValid,
    messageIsValidated: false,
    isValid: false,
    useSameDetailsForBilling:
      giftCardRecipient.useSameDetailsForBilling || false,
  });

  const giftCardContent: ContentGiftCards = content.giftCards;

  useEffect(() => {
    if (isPageValidated) {
      setRecipientState((prevState) => ({
        ...prevState,
        nameIsValidated: true,
        emailIsValidated: true,
        confirmEmailIsValidated: true,
        messageIsValidated: true,
      }));
    }
  }, [isPageValidated]);

  const handleRecipientChange = (nextRecipientState: GiftCardRecipient) => {
    setRecipientState(nextRecipientState);
    dispatch(actionCreators.setGiftCardRecipient(nextRecipientState));
    if (billingAsRecipient) {
      const nextCustomerState: Customer = {
        ...nextRecipientState,
      };
      dispatch(actionCreators.setCustomerDetails(nextCustomerState));
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    const nameIsValid = isLength(name, { min: 1, max: 50 });
    const isValid =
      nameIsValid &&
      recipientState.confirmEmailIsValid &&
      recipientState.emailIsValid;

    handleRecipientChange({
      ...recipientState,
      name,
      nameIsValid,
      isValid,
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value.trim().toLowerCase();
    const emailIsValid = isBansheeSafeEmail(email);
    const confirmEmailIsValid =
      recipientState.confirmEmail === email &&
      isBansheeSafeEmail(recipientState.confirmEmail);
    const isValid =
      emailIsValid && confirmEmailIsValid && recipientState.nameIsValid;

    handleRecipientChange({
      ...recipientState,
      email,
      emailIsValid,
      confirmEmailIsValid,
      isValid,
    });
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmEmail = e.currentTarget.value.trim().toLowerCase();
    const confirmEmailIsValid =
      isBansheeSafeEmail(confirmEmail) &&
      confirmEmail === recipientState.email &&
      confirmEmail !== '';
    const isValid =
      confirmEmailIsValid &&
      recipientState.nameIsValid &&
      recipientState.emailIsValid;

    handleRecipientChange({
      ...recipientState,
      confirmEmail,
      confirmEmailIsValid,
      isValid,
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const message = e.currentTarget.value;
    const messageIsValid = isLength(message, { min: 0, max: 1000 });
    const isValid =
      messageIsValid &&
      recipientState.emailIsValid &&
      recipientState.nameIsValid;
    handleRecipientChange({
      ...recipientState,
      message,
      messageIsValid,
      messageIsValidated: true,
      isValid,
    });
  };

  if (!giftCardContent) {
    return null;
  }

  return (
    <div className='recipient-details' data-testid='recipient-details'>
      <Form className='my-3 text-start' noValidate>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='fullName'>
            {giftCardContent.recipientNameLabel}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder={giftCardContent.recipientNamePlaceholder}
            onChange={handleNameChange}
            required
            maxLength={50}
            value={recipientState.name}
            isInvalid={
              recipientState.nameIsValidated && !recipientState.nameIsValid
            }
            isValid={
              recipientState.nameIsValidated && recipientState.nameIsValid
            }
            onBlur={() =>
              setRecipientState({ ...recipientState, nameIsValidated: true })
            }
            id='recipientFullName'
            name='recipientFullName'
          />
          <Form.Control.Feedback type='invalid'>
            {giftCardContent.recipientNameValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='email'>
            {giftCardContent.recipientEmailLabel}
          </Form.Label>
          <Form.Control
            type='email'
            placeholder={giftCardContent.recipientEmailPlaceholder}
            onChange={handleEmailChange}
            value={recipientState.email}
            required
            isInvalid={
              recipientState.emailIsValidated && !recipientState.emailIsValid
            }
            isValid={
              recipientState.emailIsValidated && recipientState.emailIsValid
            }
            onBlur={() =>
              setRecipientState({ ...recipientState, emailIsValidated: true })
            }
            id='recipientEmail'
            name='recipientEmail'
          />
          <Form.Control.Feedback type='invalid'>
            {giftCardContent.recipientEmailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='confirmEmail'>
            {giftCardContent.recipientConfirmEmailLabel}
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder={giftCardContent.recipientEmailPlaceholder}
            onChange={handleConfirmEmailChange}
            value={recipientState.confirmEmail}
            isInvalid={
              recipientState.confirmEmailIsValidated &&
              !recipientState.confirmEmailIsValid
            }
            isValid={
              recipientState.confirmEmailIsValidated &&
              recipientState.confirmEmailIsValid
            }
            onBlur={() =>
              setRecipientState({
                ...recipientState,
                confirmEmailIsValidated: true,
              })
            }
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
              e.preventDefault()
            }
            id='confirmRecipientEmail'
            name='confirmRecipientEmail'
          />
          <Form.Control.Feedback type='invalid'>
            {giftCardContent.recipientConfirmEmailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='fullName'>
            {giftCardContent.recipientMessageLabel}
          </Form.Label>
          <Form.Control
            as='textarea'
            rows={6}
            placeholder={giftCardContent.recipientMessagePlaceholder}
            onChange={handleMessageChange}
            maxLength={1000}
            value={recipientState.message}
            isInvalid={
              recipientState.messageIsValidated &&
              !recipientState.messageIsValid
            }
            isValid={
              recipientState.messageIsValidated && recipientState.messageIsValid
            }
            onBlur={() =>
              handleRecipientChange({
                ...recipientState,
                messageIsValidated: true,
              })
            }
            id='message'
            name='message'
          />
          <Form.Control.Feedback type='invalid'>
            {giftCardContent.recipientMessageValidationText}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </div>
  );
};

export default RecipientDetails;
