import { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Config } from '../@types/configTypes';
import { Content } from '../@types/contentTypes';
import {
  BookingData,
  StartConcessionsJourneyRequestModel,
} from '../@types/modelTypes';
import { JOURNEY_TYPES } from '../constants';
import loadSentry from '../scripts/loadSentry';
import { getCustomer } from '../services/Helpers';
import backend from '../services/RestUtilities';
import { actionCreators } from '../store/ActionCreators';

export const useStartConcessionsForCircuit = (
  journeyType: string,
  setShouldRedirect: (value: React.SetStateAction<boolean>) => void
) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const getJourneyData = async (journeyType: string) => {
      dispatch(actionCreators.clearSession());
      dispatch(actionCreators.setLoading(true));
      const { search } = location;
      const source = (searchParams.get('source') ?? 'web').toLowerCase();
      const device = (searchParams.get('device') ?? '').toLowerCase();
      const circuitId = searchParams.get('circuitId') ?? null;
      const { requestData, preferredLanguageCulture } = cookies;
      const sessionToken = requestData ?? searchParams.get('requestData');
      const selectedLanguageCulture =
        preferredLanguageCulture ?? searchParams.get('culture');
      dispatch(actionCreators.setQueryString(search));

      const data: StartConcessionsJourneyRequestModel = {
        selectedLanguageCulture,
        circuitId,
        journeyType,
        requestData: null,
        cinemaId: undefined,
      };

      if (sessionToken) {
        // used by vouchers journey? but not others
        dispatch(actionCreators.setRequestData(sessionToken));
        data.requestData = sessionToken;
      }

      const path = 'api/startconcessions/';
      const response = await backend.post(path, data);
      let bookingData: BookingData,
        config: Config,
        content: Content,
        result,
        customer;
      if (response.ok) {
        result = response.content;
        bookingData = result.bookingData;
        delete result.circuit.config.cinemas;
        delete result.circuit.content.cinemas;
        config = result.circuit.config;
        content = result.circuit.content;
        customer = getCustomer(
          bookingData,
          config.payment.captureTelephoneNumber,
          config.currentCinema.captureZipCode,
          config.currentCinema.isZipCodeRequired
        );

        const isUserValidated = bookingData && bookingData.isUserValidated;
        const step =
          journeyType === JOURNEY_TYPES.VOUCHERS_ONLY &&
          config.signIn.isSignInRequiredForVouchersPurchase &&
          !isUserValidated
            ? 0
            : 1;

        const session = {
          loading: true,
          bookingData: bookingData,
          token: result.dataToken,
          config: config,
          content: content,
          cartSummary: { sessionAttributes: [] },
          availablePosTickets: result.selectTicketsModel,
          ticketTypes: { ticketTypeModels: [] },
          donation: 0,
          bookingFee: 0,
          seatsModel: result.selectSeatsModel,
          error: { show: false, message: '' },
          selectedSeats: [],
          giftCard: null,
          countdownEndTime: null,
          countdownExpired: false,
          isUserValidated: result.bookingData.isUserValidated,
          journeyType: journeyType,
          source: source,
          device: device,
          step: step,
          kioskSubStep: 'fab',
          customer,
          loyaltyRecognitionNumber: null,
          concessions: null,
          selectedGiftCards: { list: [] },
          selectedConcessions: { list: [] },
          selectedLanguageCulture: result.circuit.content.culture,
          imageProcessorUrl: result.imageProcessorUrl,
          imageProcessorContainer: result.imageProcessorContainer,
          turnstileConfig: result.turnstileConfig,
          recaptchaConfig: result.recaptchaConfig,
        };

        if (config.sentryDsnKey) {
          loadSentry(config.sentryDsnKey);
        }

        if (result.errorMessage) {
          session.error = { show: true, message: result.errorMessage };
        }
        dispatch(actionCreators.initializeSession(session));
        setShouldRedirect(true);
      } else {
        result = response.errorContent;
        config = result.circuit.config;
        content = result.circuit.content;

        delete config.cinemas;

        dispatch(actionCreators.setCircuitConfig(config));
        dispatch(actionCreators.setCircuitContent(content));
        dispatch(actionCreators.setError(response.errorContent.errorMessage));
      }
      dispatch(actionCreators.setLoading(false));
    };
    getJourneyData(journeyType);
  }, [
    cookies,
    dispatch,
    journeyType,
    location,
    searchParams,
    setShouldRedirect,
  ]);
};
