/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import DeliveryWindowsList from './DeliveryWindowsList';

import {
  DeliveryPreference,
  FoodAndBeverageDeliveryWindowsDisplayOptionEnum,
} from '../../../@types/enums';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCollectFromKioskOnly,
  selectContent,
  selectDeliveryWindows,
  selectFoodAndBeverageDeliveryWindowsDisplayOption,
  selectHasSeatsWithSeatDeliveryAvailable,
  selectIsDeliveryOptionBasedOnSeatingArea,
  selectOrderToSeatOnly,
} from '../../../store/Selectors';
import { ReactComponent as ChevronLeftSvg } from '../../../svgs/chevronLeft.svg';
import ActionButton from '../actionbutton/ActionButton';
import Heading from '../heading/Heading';

interface Props {
  continueButtonClick: (selectedDeliveryPreference: DeliveryPreference) => void;
  continueButtonText: string;
  hideDeliveryOptions: () => void;
}

const DeliveryOptions: React.FC<Props> = ({
  continueButtonClick,
  continueButtonText,
  hideDeliveryOptions,
}) => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const deliveryWindows = useSelector(selectDeliveryWindows);
  const isOrderToSeatOnly = useSelector(selectOrderToSeatOnly);
  const isCollectFromKioskOnly = useSelector(selectCollectFromKioskOnly);
  const hasSeatsWithSeatDeliveryAvailable = useSelector(
    selectHasSeatsWithSeatDeliveryAvailable
  );
  const isDeliveryOptionBasedOnSeatingArea = useSelector(
    selectIsDeliveryOptionBasedOnSeatingArea
  );
  const foodAndBeverageDeliveryWindowsDisplayOption = useSelector(
    selectFoodAndBeverageDeliveryWindowsDisplayOption
  );
  const [isOrderToSeatSelected, setIsOrderToSeatSelected] = useState(
    hasSeatsWithSeatDeliveryAvailable || false
  );
  const [isCollectFromKioskSelected, setIsCollectFromKioskSelected] =
    useState(false);

  const displayDeliveryWindowsOnDeliverToMySeat =
    !!deliveryWindows?.length &&
    foodAndBeverageDeliveryWindowsDisplayOption ==
      FoodAndBeverageDeliveryWindowsDisplayOptionEnum.DisplayDeliveryWindowsOnDeliverToMySeat;

  const displayDeliveryWindowsOnPickupFromKiosk =
    !!deliveryWindows?.length &&
    foodAndBeverageDeliveryWindowsDisplayOption ==
      FoodAndBeverageDeliveryWindowsDisplayOptionEnum.DisplayDeliveryWindowsOnPickupFromKiosk;

  const shouldShowDeliveryWindows =
    (isOrderToSeatOnly ||
      isOrderToSeatSelected ||
      isCollectFromKioskOnly ||
      isCollectFromKioskSelected) &&
    (displayDeliveryWindowsOnDeliverToMySeat ||
      displayDeliveryWindowsOnPickupFromKiosk);

  const handleDeliverToSeatButtonClick = () => {
    setIsOrderToSeatSelected(true);
    setIsCollectFromKioskSelected(false);
    dispatch(
      actionCreators.setDeliveryPreference(DeliveryPreference.ORDER_TO_SEAT)
    );
    if (!displayDeliveryWindowsOnDeliverToMySeat) {
      continueButtonClick(DeliveryPreference.ORDER_TO_SEAT);
      hideDeliveryOptions();
    }
  };

  const handleCollectFromKioskButtonClick = () => {
    setIsOrderToSeatSelected(false);
    setIsCollectFromKioskSelected(true);
    dispatch(
      actionCreators.setDeliveryPreference(
        DeliveryPreference.COLLECT_FROM_KIOSK
      )
    );
    if (!displayDeliveryWindowsOnPickupFromKiosk) {
      continueButtonClick(DeliveryPreference.COLLECT_FROM_KIOSK);
      hideDeliveryOptions();
    }
  };

  const handleCloseButtonClick = () => {
    if (!isDeliveryOptionBasedOnSeatingArea) {
      setIsOrderToSeatSelected(false);
      setIsCollectFromKioskSelected(false);
      dispatch(actionCreators.setDeliveryPreference(null));
    }
    hideDeliveryOptions();
  };

  const handleBackButtonClick = () => {
    setIsOrderToSeatSelected(false);
    setIsCollectFromKioskSelected(false);
    dispatch(actionCreators.setDeliveryPreference(null));
  };

  const handleContinueButtonClick = () => {
    let deliveryPreference = DeliveryPreference.ORDER_TO_SEAT;

    if (isCollectFromKioskOnly && displayDeliveryWindowsOnPickupFromKiosk) {
      deliveryPreference = DeliveryPreference.COLLECT_FROM_KIOSK;
    }

    dispatch(actionCreators.setDeliveryPreference(deliveryPreference));

    continueButtonClick(deliveryPreference);
  };

  return (
    <Row
      className='delivery-options contained text-center'
      sx={{
        backgroundColor: 'boxBackground',
        borderColor: 'primary',

        '.top-buttons': {
          button: {
            '.icon': {
              fill: 'primary',
            },

            '&:hover': {
              color: 'primary',
            },
          },
        },

        '.delivery-options-list': {
          borderTop: 'boxSeparatorDashed',

          '.delivery-option': {
            borderTop: 'boxSeparator',

            button: {
              '.unsel': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },

              '.sel': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
                '.c': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },
            },
          },
        },

        '.delivery-comment': {
          input: {
            backgroundColor: 'formInputBackground',
            border: 'mostReadableOnWebsiteBackgroundBorder',
            color: 'mostReadableOnWebsiteBackground',
          },
        },
      }}
    >
      <Col>
        <Row className='top-buttons my-1'>
          {!hasSeatsWithSeatDeliveryAvailable &&
            (isOrderToSeatSelected || isCollectFromKioskSelected) &&
            (displayDeliveryWindowsOnDeliverToMySeat ||
              displayDeliveryWindowsOnPickupFromKiosk) && (
              <Col className='d-flex justfiy-content-start mb-1'>
                <Button
                  className='d-flex align-items-center tiny'
                  onClick={() => handleBackButtonClick()}
                  variant='link'
                >
                  <ChevronLeftSvg className='d-flex' />{' '}
                  {content.concessionsOnlyJourney.goBackButtonText}
                </Button>
              </Col>
            )}
          <Col className='d-flex justify-content-end'>
            <Button
              className='tiny'
              onClick={() => handleCloseButtonClick()}
              variant='link'
            >
              {content.concessionsOnlyJourney.closeButtonText}
            </Button>
          </Col>
        </Row>

        <Row className='px-md-4'>
          <Col>
            <Row>
              <Col>
                <Heading size={1}>
                  {shouldShowDeliveryWindows
                    ? content.concessionsOnlyJourney.deliveryWindowsListHeading
                    : content.concessionsOnlyJourney.deliveryOptionsHeading}
                </Heading>
              </Col>
            </Row>

            {shouldShowDeliveryWindows && (
              <DeliveryWindowsList
                continueButtonClick={async () => handleContinueButtonClick()}
                continueButtonText={continueButtonText}
              />
            )}

            {!isOrderToSeatOnly &&
              !isOrderToSeatSelected &&
              !isCollectFromKioskOnly &&
              !isCollectFromKioskSelected && (
                <div data-testid='delivery-option-buttons'>
                  <ActionButton
                    onClick={() => handleDeliverToSeatButtonClick()}
                    showIcon
                    contained
                    mb='mb-0'
                    mt='mt-1'
                    mx='mx-3'
                    variant='secondary'
                  >
                    {content.concessionsOnlyJourney.deliveryToSeatLabel}
                  </ActionButton>

                  <ActionButton
                    onClick={() => handleCollectFromKioskButtonClick()}
                    showIcon
                    contained
                    mt='mt-1'
                    mx='mx-3'
                    variant='primary'
                  >
                    {content.concessionsOnlyJourney.collectFromKioskLabel}
                  </ActionButton>
                </div>
              )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DeliveryOptions;
