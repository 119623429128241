import React from 'react';

import { useSelector } from 'react-redux';

import { selectContent } from '../../../store/Selectors';

const ActionButtonSpinner: React.FC = () => {
  const content = useSelector(selectContent);

  return (
    <div className='d-flex align-items-center justify-content-center'>
      <span
        className='spinner-grow spinner-grow-sm spinner-icon'
        role='status'
        aria-hidden='true'
      ></span>
      <span>{content.checkingButtonText}</span>
    </div>
  );
};

export default ActionButtonSpinner;
