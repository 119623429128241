import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import { selectContent, selectDisplayPrice } from '../../../../store/Selectors';

interface Props {
  grandTotal: number;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
  setLoyaltyRedemptionApplied: (applied: boolean) => void;
}

const LoyaltyRedemptionBreakDown: React.FC<Props> = ({
  grandTotal,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
  setLoyaltyRedemptionApplied,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const grandTotalToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, grandTotal)
  );
  const handleRemoveLoyaltyRedemption = () => {
    setLoyaltyRedemptionApplied(false);
    dispatch(actionCreators.setLoyaltyRedemptionRewards(0));
    setPaymentFormVisibility(true);
    setGiftCardFormVisibility && setGiftCardFormVisibility(true);
  };

  return (
    <div className='breakdown mt-3' data-testid='loyalty-redemption-break-down'>
      <p className='text-center fw-bold'>
        {content.payment.loyaltyRedemptionDollarsAppliedText}
      </p>
      <Row className='g-0 py-3'>
        <Col xs={8}>
          <strong>
            {content.payment.loyaltyRedemptionDollarsAppliedLabel}
          </strong>
        </Col>
        <Col xs={2}>
          <Button
            onClick={handleRemoveLoyaltyRedemption}
            variant='link'
            size='sm'
            className='float-center'
          >
            {content.payment.removeButtonText}
          </Button>
        </Col>
        <Col>
          <span className='float-end'>{grandTotalToDisplay}</span>
        </Col>
      </Row>
    </div>
  );
};

export default LoyaltyRedemptionBreakDown;
