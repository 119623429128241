import React from 'react';

import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectContent } from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import ContainedRow from '../../layout/ContainedRow';

interface Props {
  bookingRef: string;
  isBookingRefInvalid: boolean;
  handleBookingRefChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkBookingRef: () => Promise<void>;
}

const BookingRefCaptureForm: React.FC<Props> = ({
  bookingRef,
  isBookingRefInvalid,
  handleBookingRefChange,
  checkBookingRef,
}) => {
  const content = useSelector(selectContent);
  return (
    <>
      <ContainedRow classNameRow='mt-3'>
        <div className='separator' />
      </ContainedRow>
      <ContainedRow classNameRow='mt-3' classNameCol='text-start'>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='bookingRef'>
            {content.concessionsOnlyJourney.landingBookingReferenceLabel}
          </Form.Label>
          <Form.Control
            required
            type='text'
            placeholder={
              content.concessionsOnlyJourney.landingBookingReferencePlaceholder
            }
            onChange={handleBookingRefChange}
            value={bookingRef}
            id='bookingRef'
            name='bookingRef'
            isInvalid={isBookingRefInvalid}
          />
          <Form.Control.Feedback type='invalid'>
            {content.concessionsOnlyJourney.landingBookingReferenceError}
          </Form.Control.Feedback>
        </Form.Group>
        <ActionButton
          onClick={checkBookingRef}
          disabled={bookingRef ? false : true}
          stickyMobileDesktop={false}
          showIcon
          contained
          mx='mx-3'
          variant='primary'
        >
          {content.concessionsOnlyJourney.landingContinueButtonText}
        </ActionButton>
      </ContainedRow>
    </>
  );
};

export default BookingRefCaptureForm;
