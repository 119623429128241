import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Concession } from '../../../@types/modelTypes';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import {
  selectBookingData,
  selectConfig,
  selectCustomer,
  selectSelectedFaBConcessions,
  selectVoucherRecipient,
  selectContentVouchers,
  selectEVouchersGroup,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import SplitPaymentDesign from '../../common/payment/design/SplitPaymentDesign';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';
import PaymentSummaryRow from '../../common/voucherstore/PaymentSummaryRow';

const VouchersPayment: React.FC = () => {
  const navigate = useNavigate();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const contentVouchers = useSelector(selectContentVouchers);
  const customer = useSelector(selectCustomer);
  const voucherRecipient = useSelector(selectVoucherRecipient);

  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const eVoucherGroup = useSelector(selectEVouchersGroup);
  const [validateForms, setValidateForms] = useState(false);
  const [creditCardType, setCreditCardType] = useState('');

  useConfirmationPageRedirect();

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setValidateForms(!nextShowPaymentForm);
  };

  const getVoucherRows = () => {
    if (eVoucherGroup === undefined) return null;

    return selectedConcessions.list.map((c: Concession, i: number) => (
      <PaymentSummaryRow selectedConcessionIndex={i} key={`voucherrow_${i}`} />
    ));
  };

  if (!contentVouchers || !config || !bookingData) return null;

  return (
    <div className='payment text-center' data-testid='payment'>
      <div className='giftcards'>
        <Row>
          <Col xs={12} className='contained'>
            <Heading size={1} className='text-center'>
              {contentVouchers.paymentHeading}
            </Heading>
            <RichText text={contentVouchers.paymentRichText} />
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <div className=' mt-4 position-relative'>
              <div className='separator' />
              <div className='summary-rows mt-3'>{getVoucherRows()}</div>
              <div className='recipient-details summary text-start tiny pb-3'>
                <div className='contained detail mt-2 px-md-3'>
                  <div>
                    <span className='font-weight-bold'>
                      {contentVouchers.paymentRecipientNameLabel}
                    </span>{' '}
                    <span>{voucherRecipient.name}</span>
                  </div>
                  <div>
                    <span className='font-weight-bold'>
                      {contentVouchers.paymentRecipientEmailLabel}
                    </span>{' '}
                    <span>{voucherRecipient.email}</span>
                  </div>

                  <div className='mt-1'>
                    <span className='font-weight-bold'>
                      {contentVouchers.paymentBillingNameLabel}
                    </span>{' '}
                    <span>{customer.name}</span>
                  </div>

                  <div>
                    <span className='font-weight-bold'>
                      {contentVouchers.paymentBillingEmailLabel}
                    </span>{' '}
                    <span>{customer.email}</span>
                  </div>
                  {voucherRecipient.message && (
                    <div className='mt-1'>
                      <span className='font-weight-bold'>
                        {contentVouchers.paymentRecipientMessageLabel}
                      </span>{' '}
                      <span>{voucherRecipient.message}</span>
                    </div>
                  )}
                </div>
                <ActionButton
                  onClick={() => navigate(-1)}
                  sticky={false}
                  showIcon={false}
                  contained
                  variant={'outline'}
                >
                  {contentVouchers.paymentEditText}
                </ActionButton>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <ContainedRow classNameCol='text-start'>
        <TermsAndConditions isPageValidated={validateForms} />
      </ContainedRow>

      <SplitPaymentDesign
        creditCardType={creditCardType}
        showPaymentForm={true}
        showGiftCardForm={false}
        isPageValidated={validateForms}
        handleValidatePage={() => setValidateForms(true)}
        setPaymentFormVisibility={setPaymentFormVisibility}
        setCreditCardType={setCreditCardType}
      />
    </div>
  );
};

export default VouchersPayment;
