import React from 'react';

import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GuestMarketing } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectGuestMarketing,
} from '../../../../store/Selectors';
import CheckBoxButton from '../../checkboxbutton/CheckBoxButton';

interface Props {
  onEdit?: () => void;
}

const NewsletterCheckbox: React.FC<Props> = ({ onEdit }) => {
  const dispatch = useDispatch();

  const guestMarketing = useSelector(selectGuestMarketing);
  const configPayment = useSelector(selectConfig).payment;
  const contentPayment = useSelector(selectContent).payment;

  if (!configPayment || !contentPayment) return null;

  return (
    <Row className='g-0 checkbox-with-text mt-2 ms-1'>
      {configPayment.showNewsletterCheckbox && (
        <Col xs='auto' className='d-flex align-items-center'>
          <CheckBoxButton
            checked={!!guestMarketing?.sendNewsletter}
            onClick={() => {
              !!onEdit && onEdit();
              dispatch(
                actionCreators.setGuestMarketing({
                  ...guestMarketing,
                  sendNewsletter: !guestMarketing?.sendNewsletter,
                } as GuestMarketing)
              );
            }}
          />
        </Col>
      )}
      <Col className='d-flex align-items-center'>
        <p
          className={classnames(
            'm-0 text-start tiny',
            guestMarketing?.sendNewsletter && 'strong'
          )}
          data-testid='newsletter'
        >
          {contentPayment.guestMarketingNewsletterCheckboxText}
        </p>
      </Col>
    </Row>
  );
};

export default NewsletterCheckbox;
