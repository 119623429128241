import React from 'react';

import Image from 'react-bootstrap/Image';
import { useSelector } from 'react-redux';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  selectConfig,
  selectContent,
  selectSelectedSeasonPassItem,
} from '../../../store/Selectors';
import Heading from '../heading/Heading';

const SeasonPassCartSummaryImage: React.FC = () => {
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const { isLargeScreenWidth } = useScreenWidth();

  if (!selectedSeasonPassItem || !isLargeScreenWidth || !content || !config) {
    return null;
  }

  const { cartSummary: cartSummaryContent } = content;

  const imageUrl = selectedSeasonPassItem.imageUrl;
  const heading = selectedSeasonPassItem.title;

  return (
    <div
      className='cart-summary-row cart-summary-season-pass-image d-flex'
      data-testid='cs-seasonpass-image'
    >
      <div>
        <Image src={`${imageUrl}?width=100`} alt={heading} rounded />
      </div>
      <div className='text-start ps-2'>
        <Heading size={3}>{heading}</Heading>

        <p>
          <b>{cartSummaryContent.atLabel} </b>
          {config.currentCinema.title}
        </p>
      </div>
    </div>
  );
};

export default SeasonPassCartSummaryImage;
