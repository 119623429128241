import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';

interface Props {
  classNameRow?: string;
  classNameCol?: string | undefined | boolean;
  dataTestId?: string;
  key?: string;
}

const ContainedRow: React.FC<PropsWithChildren<Props>> = ({
  children,
  classNameRow,
  classNameCol,
  dataTestId,
  key,
}) => {
  return (
    <Row
      className={classnames(classNameRow)}
      data-testid={dataTestId}
      key={key}
    >
      <Col className={classnames('contained', classNameCol)} xs={12}>
        {children}
      </Col>
    </Row>
  );
};

export default ContainedRow;
