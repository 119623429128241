import React, { useEffect, useState } from 'react';

import { Image } from 'react-bootstrap';

import backend from '../../../services/RestUtilities';

import '../../../scss/components/views/launcher.scss';

const fetchVersion = async (
  setVersion: React.Dispatch<React.SetStateAction<string>>
) => {
  const response = await backend.get('api/data/Version');

  if (response.ok) {
    const version = response.content;
    setVersion(version);
  }
};

const Home: React.FC = () => {
  const [version, setVersion] = useState('');

  useEffect(() => {
    fetchVersion(setVersion);
  }, []);

  return (
    <div data-testid='home' className='launcher d-flex justify-content-center'>
      <div className='align-self-center'>
        <Image src='/images/boxoffice.png' />
      </div>
      {version && (
        <div className='version-container text-center'>
          <p>Version: {version}</p>
        </div>
      )}
    </div>
  );
};

export default Home;
