/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Col, Collapse, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalState } from '../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../constants';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectContent,
  selectError,
  selectSelectedGratuity,
} from '../../../../store/Selectors';
import AmountSelector from '../../amountselector/AmountSelector';
import CheckBoxButton from '../../checkboxbutton/CheckBoxButton';
import Heading from '../../heading/Heading';
import RichText from '../../richtext/RichText';

const Gratuity: React.FC = () => {
  const dispatch = useDispatch();

  const configPayment = useSelector(
    (state: GlobalState) => state.config.payment
  );
  const content = useSelector(selectContent);
  const error = useSelector(selectError);
  const gratuityLimitInCents = useSelector(
    (state: GlobalState) => state.gratuityLimitInCents
  );
  const selectedGratuity = useSelector(selectSelectedGratuity);

  const [showGratuitySelector, setShowGratuitySelector] = useState(
    selectedGratuity.amount > 0 || selectedGratuity.percentage > 0
      ? true
      : false
  );

  useEffect(() => {
    if (error.peachCode === PEACH_CODES.gratuityNotAccepted) {
      setShowGratuitySelector(false);
      dispatch(actionCreators.setSelectedGratuity(false, 0, 0));
    }
  }, [dispatch, error]);

  useEffect(() => {
    !showGratuitySelector &&
      dispatch(actionCreators.setSelectedGratuity(false, 0, 0));
  }, [dispatch, showGratuitySelector]);

  return (
    <div className='gratuity mt-4'>
      <Row>
        <Col xs={12} className='contained'>
          <div className='bordered'>
            <Heading size={3}>{content.payment.gratuityTitle}</Heading>
            <div
              className='gratuity-toggle-button mt-2 pt-2'
              sx={{
                borderTop: 'boxSeparatorDashed',
              }}
            >
              <Row className='g-0'>
                <Col className='d-flex align-items-center'>
                  <CheckBoxButton
                    checked={showGratuitySelector}
                    onClick={() =>
                      setShowGratuitySelector(!showGratuitySelector)
                    }
                  />
                  {content.payment.gratuityAddText}
                </Col>
              </Row>
            </div>
            <Collapse in={showGratuitySelector}>
              <div>
                <div
                  className='mt-2 pt-2'
                  sx={{
                    borderTop: 'boxSeparatorDashed',
                  }}
                >
                  <Heading size={2}>{content.payment.gratuityHeading}</Heading>
                  <RichText
                    className='text-start'
                    text={content.payment.gratuityRichText}
                  />
                  <div className='mt-3'>
                    <AmountSelector
                      isPercentage={configPayment.gratuityIsPercentage}
                      maxAmount={gratuityLimitInCents / 100}
                      setAmount={actionCreators.setSelectedGratuity}
                      values={configPayment.gratuityValues}
                      variant={'gratuity'}
                    />
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Gratuity;
