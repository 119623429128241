import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { ChangeGiftCardByIdContext } from '../../../../../@types/actionTypes';
import { Concession } from '../../../../../@types/modelTypes';
import { displayPrice } from '../../../../../services/Helpers';
import { selectCurrencyConfig } from '../../../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../../../svgs/radioUnselected.svg';
import QuantityButton from '../../../quantitybutton/QuantityButton';

interface Props {
  quantity: number | undefined;
  addIsDisabled: boolean;
  concession: Concession;
  handleConcessionChange: (
    context: ChangeGiftCardByIdContext,
    concession: Concession,
    newValueInCents: number | undefined
  ) => void;
  maxQuantity: number;
  optionButtonSelectedText: string;
  optionButtonUnselectedText: string;
  optionButtonUpdateText?: string;
  showPrice: boolean;
  cost: number;
  variableCostIsInvalid?: boolean;
  useUpdateButton?: boolean;
}

const ContextAwareQuantitySelector: React.FC<Props> = ({
  quantity,
  addIsDisabled,
  concession,
  handleConcessionChange,
  maxQuantity,
  optionButtonSelectedText,
  optionButtonUnselectedText,
  optionButtonUpdateText,
  showPrice,
  cost,
  variableCostIsInvalid,
  useUpdateButton,
}) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const concessionSelected = quantity && quantity >= 1;

  const renderButton = (
    context: ChangeGiftCardByIdContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleButtonClick(context)}
      />
    );
  };

  const handleButtonClick = (context: ChangeGiftCardByIdContext) => {
    if (handleConcessionChange && concession) {
      handleConcessionChange(context, concession, cost);
    }
  };

  return (
    <div className='context-aware-quantity-selector d-flex align-items-center justify-content-end'>
      {(maxQuantity === 1 || !!useUpdateButton) && (
        <div className='options-selector-container'>
          <Button
            className={classNames(
              'option-button px-2 py-1 d-flex justify-content-center',
              concessionSelected && !useUpdateButton && 'selected',
              variableCostIsInvalid && 'disabled'
            )}
            disabled={variableCostIsInvalid}
            onClick={() =>
              handleButtonClick(
                concessionSelected
                  ? useUpdateButton
                    ? 'swap'
                    : 'remove'
                  : 'addAndRemoveOthers'
              )
            }
          >
            <div className='d-flex flex-direction-column align-items-center'>
              {concessionSelected && !useUpdateButton ? (
                <RadioSelectedSvg className='check' />
              ) : (
                <RadioUnselectedSvg className='check' />
              )}

              <div>
                {concessionSelected
                  ? useUpdateButton
                    ? optionButtonUpdateText
                    : optionButtonSelectedText
                  : optionButtonUnselectedText}
              </div>
            </div>
          </Button>
        </div>
      )}
      {maxQuantity > 1 && !useUpdateButton && (
        <div className='justify-content-center d-flex'>
          <div className='quantity-selector d-flex justify-content-between'>
            <div className='quantity-button-container'>
              {renderButton('remove', quantity === 0)}
            </div>
            <div className='quantity-col d-flex align-items-center justify-content-center fw-bold mx-2'>
              {quantity}
            </div>
            <div className='quantity-button-container'>
              {renderButton('add', addIsDisabled)}
            </div>
          </div>
        </div>
      )}
      {showPrice && (
        <div
          className={classNames(
            'options-price text-end',
            concessionSelected && 'fw-bold'
          )}
        >
          {displayPrice(cost, currencyConfig)}
        </div>
      )}
    </div>
  );
};

export default ContextAwareQuantitySelector;
