import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import {
  selectContentCartSummary,
  selectDisplayPrice,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
  productsTax: number;
  bookingFeeTax: number;
}

const CartSummaryTax: React.FC<Props> = ({
  bookingFeeTax,
  hideTax,
  productsTax,
}) => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const totalTax = productsTax + bookingFeeTax;
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, totalTax)
  );
  if (hideTax || totalTax === 0) return null;

  return (
    <Row className='cart-summary-row-flex g-0' data-testid='cs-tax'>
      <Col className='text-start'>{contentCartSummary.taxLabel}</Col>
      <Col className='text-end'>{priceToDisplay}</Col>
    </Row>
  );
};

export default CartSummaryTax;
