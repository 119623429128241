import React from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Concession, ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectCurrencyConfig,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
}

const CartSummaryLoyaltyConcessions: React.FC<Props> = ({ hideTax }) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const loyaltyConcession = (c: Concession, hideTax: boolean) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    return (
      <Row className='concession-item g-0'>
        <Col className='text-start pe-1'>{c.description}</Col>
        <Col xs={1} className={classnames('text-center tickets-quantity')}>
          x {c.quantity}
        </Col>
        {itemPricing.costMinusTax > 100 && (
          <Col xs={2} className='text-end price'>
            {displayPrice(
              c.quantity *
                (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
              currencyConfig
            )}
          </Col>
        )}
      </Row>
    );
  };

  if (
    selectedConcessions === null ||
    selectedConcessions?.list === null ||
    !selectedConcessions?.list?.some((x: Concession) => x.quantity > 0)
  ) {
    return null;
  }

  return (
    <div className='cart-summary-row'>
      {selectedConcessions.list
        .filter((c: Concession) => c.quantity > 0)
        .map((c: Concession, ci: number) => (
          <div
            className='concessions-item-list'
            data-testid='cs-concessions'
            key={`c_${c.id}_${ci}`}
          >
            {loyaltyConcession(c, hideTax)}
          </div>
        ))}
    </div>
  );
};

export default CartSummaryLoyaltyConcessions;
