/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { SeasonPassMovie } from '../../../@types/modelTypes';
import Heading from '../heading/Heading';

interface Props {
  movie: SeasonPassMovie;
  children: React.ReactNode;
}

const SeasonPassMovieContainer: React.FC<Props> = ({ movie, children }) => {
  const posterUrl = movie.imageUrl
    ? movie.imageUrl
    : `${window.location.origin}/images/film-placeholder.png`;

  return (
    <div
      className='season-pass-movie-item w-100 my-2 pb-2'
      sx={{
        borderBottom: 'bodySeparator',
      }}
    >
      <div className='d-flex '>
        <div>
          <img
            src={posterUrl}
            alt={movie.title}
            className='season-pass-movie-img'
          />
        </div>
        <div className='ms-2 w-100'>
          <Heading className='mt-1 mb-1' size={3}>
            {movie.title}
          </Heading>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SeasonPassMovieContainer;
