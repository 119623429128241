import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TrackingEvent } from '../../../@types/trackingTypes';
import { JOURNEY_TYPES } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateJourneyShowtime } from '../../../hooks/useValidateJourneyShowtime';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { actionCreators } from '../../../store/ActionCreators';
import {
  isIosApp,
  selectBookingData,
  selectConfig,
  selectContent,
  selectIsCustomerReadyForPayment,
  selectJourneyTypeConfig,
  selectSelectedGiftCards,
  selectShouldShowGuestMarketingOptions,
  selectStep,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomerDetailsCollapse from '../../common/customerdetails/CustomerDetailsCollapse';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import RtsLoyaltyRecognition from '../../common/payment/loyaltycomponents/RtsLoyaltyRecognition';
import GiftCardUpsellSummaryRow from '../../common/payment/others/GiftCardUpsellSummaryRow';
import Gratuity from '../../common/payment/others/Gratuity';
import GuestMarketing from '../../common/payment/others/GuestMarketing';
import GuestNewsletterSignUpCheckbox from '../../common/payment/others/GuestNewsletterSignUpCheckbox';
import RoktWidget from '../../common/payment/others/RoktWidget';
import RichText from '../../common/richtext/RichText';
import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';
import ShowtimeHeroConcessions from '../../common/showtimehero/ShowtimeHeroConcessions';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';
import Donations from '../../common/tickets/Donations';
import TotalWrapper from '../../common/totalwrapper/TotalWrapper';

const Payment: React.FC = () => {
  const dispatch = useDispatch();
  const { isLargeScreenWidth } = useScreenWidth();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const step = useSelector(selectStep);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const shouldShowGuestMarketingOptions = useSelector(
    selectShouldShowGuestMarketingOptions
  );
  const isIOSApp = useSelector(isIosApp);

  const showNewsLetterSignUp =
    config.payment.showGuestNewsletterSignUpCheckbox &&
    !bookingData.isUserValidated;
  const canLaunchPayment =
    isCustomerReadyForPayment &&
    //!config.payment.enableGuestMarketing &&
    !showNewsLetterSignUp;

  const [isPageValidated, setIsPageValidated] = useState(!!canLaunchPayment);
  const [showPaymentForm, setShowPaymentForm] = useState(true);
  const [showGiftCardForm, setShowGiftCardForm] = useState(true);
  const [creditCardType, setCreditCardType] = useState('');
  const isReadyForPayment = isPageValidated && isCustomerReadyForPayment;
  useAnalyticsTrackOnce(TrackingEvent.PAYMENT_LANDING);

  useValidateJourneyShowtime();

  useConfirmationPageRedirect();

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setShowPaymentForm(nextShowPaymentForm);
  };

  const setGiftCardFormVisibility = (nextShowGiftCardForm: boolean) => {
    setShowGiftCardForm(nextShowGiftCardForm);
  };

  const handleValidatePage = () => {
    setIsPageValidated(true);
  };

  const resetHostedPayment = () => {
    setIsPageValidated(false);
    dispatch(actionCreators.removeHostedPayments());
  };

  if (!content || !config || !bookingData) return null;

  return (
    <div className='payment' data-testid='payment'>
      <ContainedRow>
        <Heading className='text-center' size={1}>
          {getStepNumberForDisplay(step)}
          {content.payment.title}
        </Heading>
      </ContainedRow>

      <ContainedRow>
        {journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY ? (
          <ShowtimeHeroConcessions />
        ) : (
          <ShowtimeHero />
        )}
      </ContainedRow>

      {selectedGiftCards.list.length > 0 && (
        <ContainedRow>
          <div className='giftcard-upsell-summary'>
            {selectedGiftCards.list.map((x, i) => (
              <GiftCardUpsellSummaryRow concession={x} key={`${x.id}_${i}`} />
            ))}
          </div>
        </ContainedRow>
      )}

      {isLargeScreenWidth && <TotalWrapper />}

      <CustomerDetailsCollapse
        isPageValidated={isPageValidated}
        onEditCustomer={resetHostedPayment}
      />

      {showNewsLetterSignUp && <GuestNewsletterSignUpCheckbox />}

      {shouldShowGuestMarketingOptions && (
        <GuestMarketing onEdit={resetHostedPayment} />
      )}

      <ContainedRow>
        <TermsAndConditions
          isPageValidated={isPageValidated}
          onEdit={resetHostedPayment}
        />
      </ContainedRow>

      {config.payment.showRoktWidget && <RoktWidget />}

      {!isReadyForPayment && (
        <ActionButton
          onClick={handleValidatePage}
          showIcon
          contained
          showWarningMessage={isPageValidated && !isReadyForPayment}
          warningMessage={content.payment.formErrorsMessage}
          warningTitle={content.payment.formErrorsSubTitle}
          mx='mx-3'
          variant='primary'
          showCartSummaryButtonOnMobile
        >
          {content.continueToPaymentText}
        </ActionButton>
      )}

      {isReadyForPayment && (
        <>
          {config.payment.enableGratuity && <Gratuity />}

          {config.payment.enableDonationField && !isIOSApp && <Donations />}

          {bookingData.isLoyaltyEarnPointsEnabled && <RtsLoyaltyRecognition />}

          <PaymentDesignWrapper
            giftcardEnabled={bookingData.isPaymentWithGiftCardEnabled}
            loyaltyPaymentEnabled={true}
            creditCardType={creditCardType}
            showPaymentForm={showPaymentForm}
            showGiftCardForm={showGiftCardForm}
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
            setPaymentFormVisibility={setPaymentFormVisibility}
            setGiftCardFormVisibility={setGiftCardFormVisibility}
            setCreditCardType={setCreditCardType}
          />

          {content.payment.refundPolicyRichText && (
            <ContainedRow classNameRow='mt-3'>
              <div className='info-container refund-policy p-3'>
                <Heading size={3} className='text-center'>
                  {content.payment.refundPolicyHeading}
                </Heading>
                <RichText text={content.payment.refundPolicyRichText} />
              </div>
            </ContainedRow>
          )}
        </>
      )}
    </div>
  );
};

export default Payment;
