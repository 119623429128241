import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectBookingData, selectContent } from '../../../store/Selectors';

const HeaderLogin: React.FC = () => {
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  if (!(bookingData && bookingData.isUserValidated)) return null;

  return (
    <Row className='banner py-1' data-testid='header-login'>
      <Col>
        <span>{content.signIn.signedInLabel} </span>
        <span>{bookingData.loyaltyEmailAddress}</span>
      </Col>
    </Row>
  );
};

export default HeaderLogin;
