/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import ActionButtonCartSummary from './ActionButtonCartSummary';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { ReactComponent as IconArrowSvg } from '../../../svgs/iconArrow.svg';
import WarningMessage from '../warningmessage/WarningMessage';

interface Props {
  contained?: boolean;
  disabled?: boolean;
  mb?: string;
  modal?: boolean;
  mt?: string;
  mx?: string;
  onClick?: React.MouseEventHandler;
  showIcon?: boolean;
  showWarningMessage?: boolean;
  sticky?: boolean;
  stickyMobileDesktop?: boolean;
  warningMessage?: string;
  warningTitle?: string;
  variant: string;
  iconReversed?: boolean;
  showCartSummaryButtonOnMobile?: boolean;
}

const ActionButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  contained,
  disabled,
  mb,
  modal,
  mt,
  mx,
  onClick,
  showIcon,
  showWarningMessage,
  sticky,
  stickyMobileDesktop,
  warningMessage,
  warningTitle,
  variant,
  iconReversed,
  showCartSummaryButtonOnMobile,
}) => {
  const { isLargeScreenWidth } = useScreenWidth();

  const shouldAlwaysBeSticky =
    showCartSummaryButtonOnMobile && !isLargeScreenWidth;

  return (
    <Row
      className={classnames(
        (shouldAlwaysBeSticky || sticky) && 'sticky-button',
        (shouldAlwaysBeSticky || stickyMobileDesktop) &&
          'sticky-button-mobile-desktop'
      )}
      sx={{
        '&.sticky-button, &.sticky-button-mobile-desktop': {
          backgroundColor: 'websiteBackground',
          '> div': {
            borderTop: 'bodySeparator',
          },
        },
        button: {
          backgroundColor:
            variant === 'primary'
              ? 'primaryButtonBackground'
              : 'secondaryButtonBackground',
          borderColor: variant === 'primary' ? 'primary' : 'accent',
          color:
            variant === 'primary'
              ? 'primaryButtonColor'
              : 'secondaryButtonColor',
          '& svg': {
            fill:
              variant === 'primary'
                ? 'primaryButtonColor'
                : 'secondaryButtonColor',
          },

          '&:hover:enabled, &:focus:enabled, &:focus:hover:enabled': {
            backgroundColor:
              variant === 'primary'
                ? 'primaryButtonBackgroundHover'
                : 'secondaryButtonBackgroundHover',
            borderColor: variant === 'primary' ? 'accent' : 'primary',
            color:
              variant === 'primary'
                ? 'primaryButtonColorHover'
                : 'secondaryButtonColorHover',
            transition:
              'background-color 0.1s ease-out, border-color 0.1s ease-out, color 0.1s ease-out;',
            '& svg': {
              fill:
                variant === 'primary'
                  ? 'primaryButtonColorHover'
                  : 'secondaryButtonColorHover',
            },
          },
        },
      }}
    >
      <Col className={classnames(contained && 'contained')} xs={12}>
        <div
          className={classnames(
            'action-button-container',
            mt ?? 'mt-2 mt-md-3',
            mb ?? 'mb-2 mb-md-3',
            mx ?? 'mx-0'
          )}
        >
          {showWarningMessage && (
            <WarningMessage
              warningTitle={warningTitle}
              warningMessage={warningMessage}
            />
          )}
          <Row className='g-0'>
            {showCartSummaryButtonOnMobile && !isLargeScreenWidth && (
              <ActionButtonCartSummary variant={variant} />
            )}
            <Col>
              <button
                data-testid='action-button'
                className={classnames(
                  'action-button tiny',
                  modal && 'btn-modal',
                  showIcon &&
                    'btn-large d-flex align-items-center justify-content-between',
                  variant
                )}
                onClick={onClick}
                disabled={disabled}
                type='button'
              >
                {children}
                {showIcon && (
                  <IconArrowSvg
                    className={classnames(
                      iconReversed ? 'icon-arrow-reverse' : 'icon-arrow',
                      'd-flex align-items-center'
                    )}
                    data-testid='action-button-icon'
                  />
                )}
              </button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ActionButton;
