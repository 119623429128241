import React from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import SeatColumn from './SeatColumn';

import {
  SeatMapRow,
  GlobalState,
  SeatMapSeat,
  SeatButtonStyle,
} from '../../../../@types/modelTypes';
import { selectContentSeats } from '../../../../store/Selectors';

interface Props {
  row: SeatMapRow;
  rowIndex: number;
  activeRow: SeatMapRow | null;
  forceSeatText: boolean;
  handleSeatClick: (seat: SeatMapSeat) => void;
  setActiveRow: React.Dispatch<React.SetStateAction<SeatMapRow | null>>;
  seatButtonStyle: SeatButtonStyle;
  overrideSoldAsGroup: boolean;
}

const Legend: React.FC<Props> = ({
  row,
  rowIndex,
  activeRow,
  forceSeatText: forceSeatNumbers,
  handleSeatClick,
  setActiveRow,
  seatButtonStyle,
  overrideSoldAsGroup,
}) => {
  const areas = useSelector(
    (state: GlobalState) => state.seatsModel.seatsLayoutModel.areas
  );
  const contentSeats = useSelector(selectContentSeats);

  const getColumns = () => {
    const columns: React.ReactElement[] = [];
    row.seats.forEach((seat, i) => {
      let cssClass = '';
      let areaLabel = undefined;
      const areaIndex = areas.findIndex((x) => x.areaNumber === seat.areaId);
      const area = areas[areaIndex];
      let inBoundary = false;
      if (area && seat.highlightArea) {
        const boundary = area.boundary;
        cssClass = classnames(
          row.index >= boundary.rowStart &&
            row.index <= boundary.rowEnd &&
            i >= boundary.colStart &&
            i <= boundary.colEnd &&
            `area${areaIndex + 1}`,
          row.index === boundary.rowStart &&
            i >= boundary.colStart &&
            i <= boundary.colEnd &&
            'bt',
          row.index === boundary.rowEnd &&
            i >= boundary.colStart &&
            i <= boundary.colEnd &&
            'bb',
          i === boundary.colStart && 'bl',
          i === boundary.colEnd && 'br',
          row.index === boundary.rowStart && `area-top`
        );
        if (row.index === boundary.rowStart && i === boundary.colStart) {
          areaLabel = area.name != null ? area.name : area.description;
        }

        inBoundary =
          row.index >= boundary.rowStart &&
          row.index <= boundary.rowEnd &&
          i >= boundary.colStart &&
          i <= boundary.colEnd;
      }

      columns.push(
        <SeatColumn
          key={`${row.physicalName}_${i}_${seat.id}`}
          seat={seat}
          index={i}
          row={row}
          rowIndex={rowIndex}
          activeRow={activeRow}
          forceSeatText={forceSeatNumbers}
          handleSeatClick={handleSeatClick}
          seatButtonStyle={seatButtonStyle}
          className={cssClass}
          borderColor={
            seat.highlightArea && inBoundary ? area.borderColor : undefined
          }
          highlightColor={
            seat.highlightArea && inBoundary ? area.highlightColor : undefined
          }
          areaLabel={areaLabel}
          overrideSoldAsGroup={overrideSoldAsGroup}
        />
      );
    });
    return columns;
  };

  return (
    <Row
      className='g-0 seat-row-wrapper'
      key={`row-${rowIndex}`}
      onMouseEnter={() => setActiveRow(row)}
      onMouseLeave={() => setActiveRow(null)}
      style={{
        minHeight: `${seatButtonStyle.minHeight}px`,
      }}
    >
      <Col className='row-label d-flex align-items-center justify-content-center'>
        <div>
          <span className='visually-hidden'>{contentSeats.titleRowText}</span>
          {row.physicalName}
        </div>
      </Col>
      <Col className='seat-row text-center'>
        <Row className='g-0'>{row.isARow && getColumns()}</Row>
      </Col>
      <Col className='row-label d-flex align-items-center justify-content-center'>
        <div>
          <span className='visually-hidden'>{contentSeats.titleRowText}</span>
          <span>{row.physicalName}</span>
        </div>
      </Col>
    </Row>
  );
};

export default Legend;
