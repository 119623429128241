import React, { useEffect } from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/da';

import { getLanguageFromCulture } from '../../../../../services/Helpers';
import {
  selectContent,
  selectSelectedLanguageCulture,
} from '../../../../../store/Selectors';

export const NUM_YEARS = 20;

interface Props {
  type: 'month' | 'year';
}

const ExpiryOptions: React.FC<Props> = ({ type }) => {
  const content = useSelector(selectContent);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  useEffect(() => {
    moment.locale(getLanguageFromCulture(selectedLanguageCulture));
  }, [selectedLanguageCulture]);

  return (
    <>
      <option value='' disabled>
        {type === 'month'
          ? content.payment.expiryMonthPlaceholder
          : content.payment.expiryYearPlaceholder}
      </option>
      {type === 'month' &&
        [...Array(12)].map((_val, i) => {
          const text = moment(i + 1, 'MM').format('M - MMM');
          return (
            <option key={text} value={i + 1}>
              {text}
            </option>
          );
        })}
      {type === 'year' &&
        [...Array(NUM_YEARS)].map((_val, i) => {
          const year = moment().add(i, 'years').format('YYYY');
          return (
            <option key={year} value={year}>
              {year}
            </option>
          );
        })}
    </>
  );
};

export default ExpiryOptions;
