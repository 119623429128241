/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectSelectedLanguageCulture } from '../../../store/Selectors';

const fullyVisible = 1;
const intersectionOptions = { threshold: [fullyVisible] };

interface ShowtimeDateProps {
  date: Date;
}

const ShowtimeDate: React.FC<ShowtimeDateProps> = ({ date }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsActive(
      entry.intersectionRatio < 1 &&
        entry.boundingClientRect.top < entry.intersectionRect.top
    );
  };

  useEffect(() => {
    const current = containerRef.current;
    const observer = new IntersectionObserver(
      callbackFunction,
      intersectionOptions
    );
    if (current) {
      observer.observe(current);
    }
    return () => {
      if (current) {
        observer.unobserve(current);
      }
    };
  }, [containerRef]);

  return (
    <Col className='showtime-picker-date-container text-center ps-2'>
      <div
        ref={containerRef}
        className={classnames('showtime-picker-date', isActive && 'is-active')}
      >
        <div
          className='date-top-container'
          sx={{
            borderTop: 'bodySeparator',
          }}
        >
          <div className='small'>
            {moment(date).locale(selectedLanguageCulture).format('ddd')}
          </div>
          <div className='h2'>
            {moment(date).locale(selectedLanguageCulture).format('D')}
          </div>
          <div className='small'>
            {moment(date).locale(selectedLanguageCulture).format('MMM')}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ShowtimeDate;
