import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectHideTax,
  selectUseBookingFeeStrategyInTickets,
  shouldDisplayBookingFeeOnTicketsStep,
} from '../../../store/Selectors';

interface Props {
  ticket: TicketTypeModel;
}

const TicketPriceWithBookingFee: React.FC<Props> = ({ ticket }) => {
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const { useBookingFeeStrategy, perItemFee } = useSelector(
    selectUseBookingFeeStrategyInTickets
  );
  const hideTaxInSummary = useSelector(selectHideTax);
  const shouldDisplayAdditionalPrices = useSelector(
    shouldDisplayBookingFeeOnTicketsStep
  );

  const priceWithHideTaxConfigAdjustment = hideTaxInSummary
    ? ticket.price
    : ticket.price - ticket.tax;

  const bookingFee = useMemo(() => {
    let fee = 0;
    if (useBookingFeeStrategy) {
      fee = perItemFee;
    } else if (ticket.bookingFee) {
      fee = ticket.bookingFee;
      if (!hideTaxInSummary) {
        fee -= ticket.bookingFeeTax;
      }
    }
    return fee;
  }, [hideTaxInSummary, perItemFee, ticket, useBookingFeeStrategy]);

  const ticketPriceIncludingFeeAndTax =
    priceWithHideTaxConfigAdjustment + bookingFee;

  const totalPriceToDisplay =
    bookingFee > 0 && shouldDisplayAdditionalPrices
      ? ticketPriceIncludingFeeAndTax
      : priceWithHideTaxConfigAdjustment;

  const displayBookingFee = displayPrice(bookingFee, currencyConfig);
  const displayPriceWithHideTaxConfigAdjustment = displayPrice(
    priceWithHideTaxConfigAdjustment,
    currencyConfig
  );
  const additionalPricingText = ` (${displayPriceWithHideTaxConfigAdjustment} + ${displayBookingFee} ${content.cartSummary.serviceChargeLabel})`;

  return (
    <>
      <b>{displayPrice(totalPriceToDisplay, currencyConfig)}</b>
      {shouldDisplayAdditionalPrices && <span>{additionalPricingText}</span>}
    </>
  );
};

export default TicketPriceWithBookingFee;
