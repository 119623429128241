import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Voucher } from '../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../constants';
import { displayPrice } from '../../../../services/Helpers';
import { getContentForError } from '../../../../services/PeachErrorResolver';
import backend from '../../../../services/RestUtilities';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedVouchers,
  selectContent,
  selectCurrencyConfig,
  selectToken,
} from '../../../../store/Selectors';

interface Props {
  grandTotal: number;
  isConfirmation?: boolean;
  variant?: 'cartSummary' | 'confirmation';
}

const VoucherBreakDown: React.FC<Props> = ({
  grandTotal,
  isConfirmation,
  variant,
}) => {
  const dispatch = useDispatch();

  const appliedVouchers = useSelector(selectAppliedVouchers);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const content = useSelector(selectContent);
  const token = useSelector(selectToken);

  const handleRemoveVoucher = async (voucher: Voucher) => {
    const voucherCode = voucher.voucherCode;

    if (!voucherCode) return;
    dispatch(actionCreators.setLoading(true));

    const response = await backend.post(
      'api/Voucher/RemoveVoucher/' + voucherCode,
      token
    );
    if (response.ok) {
      const responseContent = response.content;
      if (responseContent.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setHasVoucherApplied(false));
        dispatch(actionCreators.removeVoucher(voucher));
        dispatch(actionCreators.setGrandTotalWithDiscount(undefined, 0));
      } else {
        dispatch(
          actionCreators.setError(
            getContentForError(responseContent.peachCode, content),
            responseContent.peachCode
          )
        );
      }
    } else {
      dispatch(
        actionCreators.setError(
          getContentForError(PEACH_CODES.unknownError, content),
          PEACH_CODES.unknownError
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  };

  if (!appliedVouchers?.length) return null;

  return (
    <>
      {variant === 'cartSummary' ? (
        <div className='breakdown'>
          {appliedVouchers
            .slice(0)
            .reverse()
            .map((voucher) => {
              return (
                <Row
                  className='g-0'
                  key={`cart-summary-${voucher.voucherCode}`}
                >
                  <Col xs={8} className='d-flex align-items-center'>
                    <span>
                      {content.payment.voucherAppliedLabel}{' '}
                      {voucher.voucherCode}
                    </span>
                  </Col>
                  {!isConfirmation && (
                    <Col xs={2} className='d-flex align-items-center'>
                      <Button
                        onClick={() => handleRemoveVoucher(voucher)}
                        variant='link'
                        size='sm'
                        className='text-uppercase small'
                      >
                        {content.payment.voucherRemoveButtonText}
                      </Button>
                    </Col>
                  )}
                  <Col
                    xs={isConfirmation ? 4 : 2}
                    className='d-flex align-items-center justify-content-end'
                  >
                    {displayPrice(voucher.totalDiscount, currencyConfig)}{' '}
                  </Col>
                </Row>
              );
            })}
        </div>
      ) : variant === 'confirmation' ? (
        <div
          className='breakdown mt-3'
          data-testid='gift-card-break-down-confirmation'
        >
          {appliedVouchers
            .slice(0)
            .reverse()
            .map((voucher) => {
              return (
                <Row className='g-0 py-2' key={`${voucher.voucherCode}`}>
                  <Col xs={9} className='d-flex align-items-center text-start'>
                    <span>
                      <b>{content.payment.voucherAppliedLabel}</b>
                      {' ...'}
                      {voucher.voucherCode}
                    </span>
                  </Col>
                  <Col
                    xs={3}
                    className='d-flex align-items-center justify-content-end'
                  >
                    <b></b>
                  </Col>
                  <Col
                    xs={3}
                    className='d-flex align-items-center justify-content-end'
                  >
                    <p></p>
                  </Col>
                </Row>
              );
            })}
        </div>
      ) : (
        <div className='breakdown mt-3' data-testid='gift-card-break-down'>
          {appliedVouchers
            .slice(0)
            .reverse()
            .map((voucher) => {
              return (
                <Row className='g-0 py-2' key={`${voucher.voucherCode}`}>
                  <Col xs={9} className='d-flex align-items-center text-start'>
                    <span>
                      <b>{content.payment.voucherAppliedLabel}</b>{' '}
                      {voucher.voucherCode}
                    </span>
                  </Col>
                  <Col xs={1} className='d-flex align-items-center'>
                    <Button
                      onClick={() => handleRemoveVoucher(voucher)}
                      variant='link'
                      size='sm'
                      className='text-uppercase'
                    >
                      {content.payment.voucherRemoveButtonText}
                    </Button>
                  </Col>
                  <Col
                    xs={2}
                    className='d-flex align-items-center justify-content-end'
                  >
                    <b>{displayPrice(voucher.totalDiscount, currencyConfig)}</b>
                  </Col>
                </Row>
              );
            })}
          <Row className='g-0 py-2'>
            <Col xs={9} className='d-flex align-items-center text-start'>
              <span>
                <b>{content.payment.voucherStillToPayLabel}</b>
              </span>
            </Col>
            <Col xs={1} className='d-flex align-items-center'></Col>
            <Col
              xs={2}
              className='d-flex align-items-center justify-content-end'
            >
              <b>{displayPrice(grandTotal, currencyConfig)}</b>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default VoucherBreakDown;
