import React from 'react';

import classNames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import TierImage from './TierImage';

import {
  Concession,
  ConcessionPricing,
  GlobalState,
} from '../../../@types/modelTypes';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectContent,
  selectCurrentCinema,
  selectDisplayPrice,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';

interface Props extends Concession {
  defaultImage: string;
}

const TierItem: React.FC<Props> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  defaultImage,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
  };
  const currentCinema = useSelector(selectCurrentCinema);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const hideTax: boolean = currentCinema.hideTax;

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const selectedConcession: Concession | undefined =
    selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const displayPrice = hideTax
    ? itemPricing.costIncTax
    : itemPricing.costMinusTax;
  const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * displayPrice;

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, subTotal)
  );
  const selected = quantityInCart >= 1;

  return (
    <Row className='py-2 text-center align-items-center'>
      <Col md={8} sm={12}>
        <Row>
          <Col xs={12} sm={'auto'} className='image-container'>
            <TierImage imageUrl={imagePath || content.giftCards.defaultImage} />
          </Col>
          <Col className='details text-start d-flex flex-wrap align-items-center mt-3 mt-sm-0'>
            <Row>
              <Col xs={12}>
                <span className='h3'>{concession.description}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col
        md={4}
        sm={12}
        className='options-selector-container g-0 d-flex mt-3 mt-md-0 justify-content-center align-items-center'
      >
        <div className='options-price text-end d-flex align-items-center mx-1'>
          <span className={classNames(selected && 'fw-bold')}>
            {priceToDisplay}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default TierItem;
