/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Collapse, Button } from 'react-bootstrap';

interface BorderedCollapseProps {
  className?: string;
  closeButtonText: string;
  heading: string;
  setShow: (show: boolean) => void;
  show: boolean;
  headingClassName?: string;
}

const BorderedCollapse: React.FC<PropsWithChildren<BorderedCollapseProps>> = ({
  children,
  className,
  closeButtonText,
  heading,
  setShow,
  show,
  headingClassName,
}) => {
  return (
    <div
      className={classnames('bordered-collapse', className)}
      sx={{
        backgroundColor: 'boxBackground',
        borderColor: 'boxBorderColor',
        color: 'mostReadableOnWebsiteBackground',
      }}
    >
      <Button
        className='bordered-collapse-btn text-center'
        onClick={() => {
          setShow(!show);
        }}
        variant='link'
      >
        <span className={classnames('h3', headingClassName)}>{heading}</span>
        <span
          data-testid='bordered-collapse-close-button'
          className={classnames('float-end close-button', show && 'show')}
        >
          {closeButtonText}
        </span>
      </Button>
      <Collapse in={show} data-testid='bordered-collapse-content'>
        <div>
          <div className='bordered-collapse-open'>
            <div className='bordered-collapse-border-top'>{children}</div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default BorderedCollapse;
