/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { MemberCardInput } from './MemberCardInput';
import TicketPriceWithBookingFee from './TicketPriceWithBookingFee';
import TicketPriceWithBookingFeeTax from './TicketPriceWithBookingFeeTax';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Deal,
  DealInTicketsStep,
  TicketTypeModel,
} from '../../../@types/modelTypes';
import { getTicketDeals } from '../../../services/Helpers';
import { getPackageTicketsDescription } from '../../../services/TicketHelpers';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectTicketTypes,
  shouldDisplayTaxInTicketsPricing,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  ticket: TicketTypeModel;
  onClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  disableAdd: boolean;
  disableRemove: boolean;
  isPackageTicket?: boolean;
  deals: Deal[];
  dealsInTicketsStep: DealInTicketsStep[];
  orderHasMaxTickets: boolean;
}

const TicketType: React.FC<Props> = ({
  ticket,
  onClick,
  disableAdd,
  disableRemove,
  isPackageTicket,
  deals,
  dealsInTicketsStep,
  orderHasMaxTickets,
}) => {
  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const ticketTypes = useSelector(selectTicketTypes);
  const content = useSelector(selectContent);
  const ticketDeals = getTicketDeals(ticket.id, deals);
  const shouldDisplayTaxInTicketPricing = useSelector(
    shouldDisplayTaxInTicketsPricing
  );
  const [showMemberCardInput, setShowMemberCardInput] = useState(false);
  const [hasOneMemberTicket, setHasOneMemberTicket] = useState(false);

  useEffect(() => {
    // Check if there's at least one member ticket with quantity 1
    const hasOneMemberTicket = ticketTypes?.ticketTypeModels?.some(
      (ticketTypeModel: TicketTypeModel) =>
        ticketTypeModel.isMemberTicket && ticketTypeModel.quantity > 0
    );

    if (hasOneMemberTicket) {
      setHasOneMemberTicket(true);
    } else {
      setHasOneMemberTicket(false);
      setShowMemberCardInput(false);
    }
  }, [ticketTypes]);

  const displayDealTextWithStatus = (ticketDeal: Deal, dealIndex: number) => {
    if (!dealsInTicketsStep) {
      return '';
    }

    const { title } = ticketDeal;

    const deal = dealsInTicketsStep.find((d) => d.title === title);

    const index = deal?.appliedByTicketTypes.findIndex(
      (tt) => tt.id === ticket.id
    );

    let dealStatusText = '';
    let isApplied = false;

    if (!deal || index === -1) {
      dealStatusText = ticketDeal.isConcessionRelated
        ? content.tickets.dealWithThisTicketText
        : content.tickets.dealAvailableText;
    } else if (deal.onlyValidForRequirements) {
      dealStatusText = content.tickets.dealAddMoreText.replace(
        '##TICKETQUANTITYREMAININGFORDEAL##',
        String(deal.ticketsQuantityStillToAdd)
      );
    } else {
      dealStatusText = content.tickets.dealAppliedText;
      isApplied = true;
    }

    return (
      <p
        className='deal-text-with-status tiny b text-uppercase'
        key={`deal_with_text_status_${dealIndex}`}
        sx={{
          svg: {
            '.a': {
              fill: 'websiteBackground',
            },
            '.b': {
              fill: 'primary',
            },
            '.c': {
              fill: 'primary',
            },
          },
        }}
      >
        {title} - {dealStatusText}
        {isApplied && <RadioSelectedSvg />}
      </p>
    );
  };

  const handleAddClick = () => {
    if (
      bookingData.isUserValidated &&
      config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
      hasOneMemberTicket &&
      ticket.isMemberTicket
    ) {
      setShowMemberCardInput(true);
    } else {
      onClick(ticket.id, 'add');
    }
  };

  const handleRemoveClick = () => {
    onClick(ticket.id, 'remove');
    if (
      bookingData.isUserValidated &&
      config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
      !ticket.isMemberTicket
    ) {
      setShowMemberCardInput(false);
    }
  };

  return (
    <>
      <Row
        className='ticket-type g-0'
        data-testid='ticket-type'
        sx={{
          borderTop: 'bodySeparator',
        }}
      >
        <Col className='btn-container d-flex justify-content-start align-items-center'>
          <QuantityButton
            context={'remove'}
            disabled={disableRemove}
            onClick={handleRemoveClick}
          />
        </Col>
        <Col xs={8}>
          <p>{ticket.displayName}</p>

          {isPackageTicket &&
            config.tickets.useLongDescriptionForPackageTicket && (
              <p className='tiny' data-testid='longdesc-label'>
                {ticket.longDescription}
              </p>
            )}
          {isPackageTicket &&
            !config.tickets.useLongDescriptionForPackageTicket && (
              <p className='tiny' data-testid='includes-label'>
                {content.tickets.packageTicketsIncludesLabel}{' '}
                {getPackageTicketsDescription(ticket)}
              </p>
            )}

          {ticketDeals &&
            ticketDeals.map((td, i) => {
              return displayDealTextWithStatus(td, i);
            })}
          <p className={ticket.quantity > 0 ? 'selected' : ''}>
            {shouldDisplayTaxInTicketPricing ? (
              <TicketPriceWithBookingFeeTax ticket={ticket} />
            ) : (
              <TicketPriceWithBookingFee ticket={ticket} />
            )}

            {ticket.quantity > 0 && (
              <span className='quantity'> x {ticket.quantity}</span>
            )}
          </p>
        </Col>
        <Col className='btn-container d-flex justify-content-end align-items-center'>
          <QuantityButton
            context={'add'}
            disabled={disableAdd}
            onClick={handleAddClick}
          />
        </Col>
      </Row>
      {showMemberCardInput && hasOneMemberTicket && !orderHasMaxTickets && (
        <MemberCardInput
          orderHasMaxTickets={orderHasMaxTickets}
          handleAddTicket={onClick}
          ticket={ticket}
        />
      )}
    </>
  );
};

export default TicketType;
