import React, { useEffect, useCallback, useState } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navigate,
  useParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { JOURNEY_TYPES, PEACH_CODES } from '../../../constants';
import loadSentry from '../../../scripts/loadSentry';
import { getCustomer } from '../../../services/Helpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import { selectStep } from '../../../store/Selectors';

const StartConcessions: React.FC = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { cinemaId } = useParams();
  const step = useSelector(selectStep);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const journeyType = JOURNEY_TYPES.CONCESSIONS_ONLY;

  const getJourneyDataUsingCinemaId = useCallback(async () => {
    dispatch(actionCreators.clearSession());
    dispatch(actionCreators.setLoading(true));
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const circuitId = searchParams.get('circuitId') ?? undefined;
    const bookingRef = searchParams.get('bookingRef') ?? null;
    const selectedLanguageCulture =
      cookies.preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));

    const { requestData } = cookies;

    if (requestData) {
      dispatch(actionCreators.setRequestData(requestData));
    }

    const data = {
      selectedLanguageCulture,
      circuitId,
      requestData,
    };
    const path = `api/StartConcessions/${cinemaId}${
      bookingRef ? `?bookingRef=${bookingRef}` : ''
    }`;
    const response = await backend.post(path, data);
    let bookingData, config, content, customer, result, reservedSeats;
    if (
      response.ok &&
      (response.content.peachCode == PEACH_CODES.ok ||
        response.content.peachCode == PEACH_CODES.noError)
    ) {
      result = response.content;
      bookingData = result.bookingData;
      reservedSeats = result.reservedSeats;
      config = result.circuit.config;
      content = result.circuit.content;

      customer = getCustomer(
        bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );

      delete config.cinemas;
      delete content.cinemas;

      const isUserValidated = bookingData?.isUserValidated;
      const allowSignIn =
        config.currentCinema?.allowSignIn || config.signIn.allowSignIn;

      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        currency: 'USD',
        cartSummary: result.cartSummaryModel,
        availablePosTickets: result.selectTicketsModel,
        ticketTypes: { ticketTypeModels: [] },
        donation: 0,
        bookingFee: 0,
        seatsModel: result.selectSeatsModel,
        error: { show: false, message: '' },
        selectedSeats: reservedSeats,
        giftCard: null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: isUserValidated,
        journeyType: journeyType,
        customer: customer,
        source: source,
        device: device,
        step: allowSignIn && !isUserValidated ? 0 : 1,
        kioskSubStep: 'fab',
        loyaltyRecognitionNumber: null,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        selectedDeliveryWindow: null,
        selectedLanguageCulture: content.culture,
        gratuityLimitInCents: result.gratuityLimitInCents,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        turnstileConfig: result.turnstileConfig,
        recaptchaConfig: result.recaptchaConfig,
      };

      if (bookingRef) {
        dispatch(
          actionCreators.setConcessionsJourneyParentBookingRef(
            bookingRef.toString()
          )
        );
      }

      if (config.sentryDsnKey) {
        loadSentry(config.sentryDsnKey);
      }

      dispatch(actionCreators.initializeSession(session));
      setShouldRedirect(true);
    } else {
      result = response.content;
      config = result.circuit.config;
      content = result.circuit.content;

      delete config.cinemas;
      delete content.cinemas;

      const session = {
        loading: true,
        config: config,
        content: content,
        error: {
          show: true,
          message: getContentForError(response.content.peachCode, content),
        },
        step: -1,
      };

      dispatch(actionCreators.setCircuitConfig(config));
      dispatch(actionCreators.setCircuitContent(content));
      dispatch(actionCreators.initializeSession(session));
      setShouldRedirect(true);
    }
    dispatch(actionCreators.setLoading(false));
  }, [dispatch, searchParams, cookies, location, cinemaId, journeyType]);

  useEffect(() => {
    if (cinemaId) {
      getJourneyDataUsingCinemaId();
    }
  }, [cinemaId, getJourneyDataUsingCinemaId]);

  const stepNumber = step;
  const journeyTypeConfig = journeyTypeConfigs[journeyType];
  const pathname = `/${getRouteFromStep(journeyTypeConfig, stepNumber)}`;

  return shouldRedirect ? (
    <Navigate to={pathname} state={{ from: location }} />
  ) : null;
};

export default StartConcessions;
