/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
/* eslint-disable no-console */
import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import CollapsiblePaymentProviderContainer from './CollapsiblePaymentProviderContainer';
import CreditCardPaymentsHeadingContent from './CreditCardPaymentsHeadingContent';
import PaymentProviderSelector from './PaymentProviderSelector';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectContent,
  selectGrandTotalAfterDiscountsInCents,
  selectIsCustomerReadyForPayment,
  selectPaymentProviders,
  selectSelectedPaymentProvider,
} from '../../../../../store/Selectors';
import Heading from '../../../heading/Heading';
import RichText from '../../../richtext/RichText';
import ApplePayWorldPay from '../providers/applepay/ApplePayWorldPay';

interface Props {
  isPageValidated: boolean;
  creditCardType: string;
  handleValidatePage: () => void;
  setCreditCardType: (creditCardType: string) => void;
}

const CreditCardPaymentsContainer: React.FC<Props> = ({
  isPageValidated,
  creditCardType,
  handleValidatePage,
  setCreditCardType,
}) => {
  const dispatch = useDispatch();
  const paymentProviders = useSelector(selectPaymentProviders);
  const content = useSelector(selectContent);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  // sets selectedPaymentProvider when only one option
  useEffect(() => {
    if (paymentProviders.length === 1 && !selectedPaymentProvider) {
      dispatch(actionCreators.setSelectedPaymentProvider(paymentProviders[0]));
    }
  }, [dispatch, paymentProviders, selectedPaymentProvider]);

  // closes all payments when form is invalid
  useEffect(() => {
    if (!isCustomerReadyForPayment) {
      console.log(
        'this closes and deselects all paymentproviders - form is invalid'
      );
      dispatch(actionCreators.setSelectedPaymentProvider(null));
    }
  }, [dispatch, isCustomerReadyForPayment]);

  const handleContainerClick = (paymentProvider: PaymentProvidersEnum) => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    const newPaymentProvider =
      paymentProvider === selectedPaymentProvider ? null : paymentProvider;
    dispatch(actionCreators.setSelectedPaymentProvider(newPaymentProvider));
  };

  const renderApplePay = paymentProviders.includes(
    PaymentProvidersEnum.WORLDPAYAPPLEPAY
  );
  const hasMultipleProviders = paymentProviders.length > 1;

  return (
    <>
      {grandTotal > 0 && (
        <>
          <Heading className='text-center' size={2}>
            {content.payment.paymentDetailsSubtitle}
          </Heading>
          <RichText text={content.payment.paymentInstructionsRichText} />
        </>
      )}

      {renderApplePay && <ApplePayWorldPay />}

      {paymentProviders
        .filter((x) => x != PaymentProvidersEnum.WORLDPAYAPPLEPAY)
        .map((paymentProvider, index: number) => (
          <div
            className={classNames(
              'credit-card-payments-container mt-1',
              hasMultipleProviders && 'multi'
            )}
            key={`payment_form_${index}`}
            sx={{
              '&.multi': {
                '.bordered-collapse': {
                  backgroundColor: 'websiteBackground',
                },
              },

              svg: {
                '.plus-minus': {
                  fill: 'boxBorderColor',
                },
              },
            }}
          >
            <CollapsiblePaymentProviderContainer
              hasOnePaymentOption={!hasMultipleProviders}
              key={`payment_form_${index}`}
              setShow={() => handleContainerClick(paymentProvider)}
              show={selectedPaymentProvider === paymentProvider}
              headingContent={
                <CreditCardPaymentsHeadingContent
                  paymentProvider={paymentProvider}
                  creditCardType={creditCardType}
                  hasOnePaymentOption={!hasMultipleProviders}
                />
              }
              isShownClassName='highlight-border'
              paymentProvider={paymentProvider}
            >
              <PaymentProviderSelector
                isPageValidated={isPageValidated}
                handleValidatePage={handleValidatePage}
                setCreditCardType={setCreditCardType}
                paymentProvider={paymentProvider}
              />
            </CollapsiblePaymentProviderContainer>
          </div>
        ))}
    </>
  );
};

export default CreditCardPaymentsContainer;
