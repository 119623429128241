import React from 'react';

import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectGuestNewsletterSignUp,
} from '../../../../store/Selectors';
import CheckBoxButton from '../../checkboxbutton/CheckBoxButton';
import ContainedRow from '../../layout/ContainedRow';

const GuestNewsletterSignUpCheckbox: React.FC = () => {
  const dispatch = useDispatch();

  const guestNewsletterSignUp = useSelector(selectGuestNewsletterSignUp);
  const configPayment = useSelector(selectConfig).payment;
  const contentPayment = useSelector(selectContent).payment;

  const handleNewsLetterClick = () => {
    dispatch(actionCreators.setGuestNewsletterSignUp(!guestNewsletterSignUp));
  };

  if (!configPayment || !contentPayment) return null;

  return (
    <ContainedRow classNameRow='guest-marketing-wrapper'>
      <Row className='g-0 checkbox-with-text mt-2 ms-1'>
        <Col xs='auto' className='d-flex align-items-center'>
          <CheckBoxButton
            checked={guestNewsletterSignUp}
            onClick={handleNewsLetterClick}
          />
        </Col>
        <Col className='d-flex align-items-center'>
          <p
            className={classnames(
              'm-0 text-start',
              guestNewsletterSignUp && 'strong'
            )}
            data-testid='crm-newsletter'
          >
            {contentPayment.guestNewsletterSignUpCheckboxText}
          </p>
        </Col>
      </Row>
    </ContainedRow>
  );
};

export default GuestNewsletterSignUpCheckbox;
