/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { Offcanvas, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectGrandTotalAfterDiscountsInCents,
  selectIsCartSummeryOpen,
  selectJourneyTypeConfig,
  selectStep,
} from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';
import { ReactComponent as Cross } from '../../../svgs/cross.svg';
import CartSummary from '../cartsummary/CartSummary';

const CartSummaryOffcanvas: React.FC = () => {
  const dispatch = useDispatch();

  const grandTotalToUse = useSelector(selectGrandTotalAfterDiscountsInCents);
  const isCartSummeryOpen = useSelector(selectIsCartSummeryOpen);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);

  return (
    <Offcanvas
      show={isCartSummeryOpen}
      onHide={() => dispatch(actionCreators.setCartSummaryOpen(false))}
      sx={{
        backgroundColor: 'sidebarBackground',
        color: 'mostReadableOnSidebarBackground',

        svg: {
          '.icon': {
            fill: 'mostReadableOnSidebarBackground',
          },
        },
      }}
    >
      <div className='offcanvas-cart-summary'>
        <div className='offcanvas-cart-summary-header d-flex align-items-center justify-content-between'>
          {grandTotalToUse ? <CartFull /> : <CartEmpty />}
          <Button
            className='d-flex'
            variant='link'
            onClick={() => dispatch(actionCreators.setCartSummaryOpen(false))}
          >
            <Cross />
          </Button>
        </div>

        <CartSummary hideTotal={journeyTypeConfig.isSeatsFirst && step < 2} />
      </div>
    </Offcanvas>
  );
};

export default CartSummaryOffcanvas;
