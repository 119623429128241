import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectCurrentCinema } from '../../../store/Selectors';

const Address: React.FC = () => {
  const currentCinema = useSelector(selectCurrentCinema);

  return (
    <Row data-testid='address'>
      <Col className='contained text-start'>
        <p>{currentCinema.title}</p>
        <p>{currentCinema.address1}</p>
        <p>
          {currentCinema.city}
          {', '}
          {currentCinema.stateName} {currentCinema.zipCode}
        </p>
      </Col>
    </Row>
  );
};

export default Address;
