/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch } from 'react';

import { getQualifiedDeals } from './Helpers';

import { QuantitySelectorContext } from '../@types/actionTypes';
import { Config } from '../@types/configTypes';
import { ContentTickets } from '../@types/contentTypes';
import { TicketGroupsOrder } from '../@types/enums';
import {
  Deal,
  DealInTicketsStep,
  TicketTypeModel,
  TicketTypes,
  Tickets,
} from '../@types/modelTypes';
import TicketGroup from '../components/common/tickets/TicketGroup';
import { JOURNEY_TYPES } from '../constants';
import { actionCreators } from '../store/ActionCreators';

export const getOrderedTicketGroups = (
  config: Config,
  orderHasMaxTickets: boolean,
  showHeadings: boolean,
  contentTickets: ContentTickets,
  memberTickets: TicketTypeModel[],
  packageTickets: TicketTypeModel[],
  standardTickets: TicketTypeModel[],
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>
) => {
  const getGroup = (
    id: string,
    tickets: TicketTypeModel[],
    classNames: string,
    heading: string
  ) => {
    return (
      <TicketGroup
        key={id}
        orderHasMaxTickets={orderHasMaxTickets}
        classNames={classNames}
        dataTestId={id}
        tickets={tickets}
        handleTicketButtonClick={handleTicketButtonClick}
        ticketsHeading={showHeadings ? heading : undefined}
      />
    );
  };

  const ticketGroupArray: React.ReactElement[] = [];

  const hasMemberTickets = memberTickets.length > 0;

  const memberTicketsGrp = (classNames: string) =>
    getGroup(
      'member-ticket-selector',
      memberTickets,
      classNames,
      contentTickets.memberTicketsHeading
    );

  const packageTicketsGrp = (classNames: string) =>
    getGroup(
      'package-ticket-selector',
      packageTickets,
      classNames,
      contentTickets.packageTicketsHeading
    );

  const standardTicketsGrp = (classNames: string) =>
    getGroup(
      'standard-ticket-selector',
      standardTickets,
      classNames,
      contentTickets.standardTicketsHeading
    );

  const withHiddenTopBorder = (className: string) => {
    return `${className} ${hasMemberTickets ? 'hide-top-border' : ''}`;
  };

  const memberTicketClassname = config.tickets
    .enableMultipleLoyaltyCardTicketPurchase
    ? 'text-center border-bottom-box-separator'
    : config.tickets.enableMemberTicketsHighlight
    ? 'ticket-group-highlight'
    : 'ticket-group';

  const packageTicketClassname = config.tickets.enablePackageTicketsHighlight
    ? 'ticket-group-highlight'
    : 'ticket-group';

  switch (config.tickets.ticketGroupsOrderStrategy) {
    case TicketGroupsOrder.MEMBER_STANDARD_PACKAGE:
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        standardTicketsGrp(withHiddenTopBorder('ticket-group'))
      );
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      break;
    case TicketGroupsOrder.PACKAGE_MEMBER_STANDARD:
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        standardTicketsGrp(withHiddenTopBorder('ticket-group'))
      );
      break;
    case TicketGroupsOrder.PACKAGE_STANDARD_MEMBER:
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      break;
    case TicketGroupsOrder.STANDARD_MEMBER_PACKAGE:
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        packageTicketsGrp(withHiddenTopBorder(packageTicketClassname))
      );
      break;
    case TicketGroupsOrder.STANDARD_PACKAGE_MEMBER:
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      ticketGroupArray.push(packageTicketsGrp(packageTicketClassname));
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      break;
    default:
      ticketGroupArray.push(memberTicketsGrp(memberTicketClassname));
      ticketGroupArray.push(
        packageTicketsGrp(withHiddenTopBorder(packageTicketClassname))
      );
      ticketGroupArray.push(standardTicketsGrp('ticket-group'));
      break;
  }

  return ticketGroupArray;
};

export const updateJourneyType = (
  tickets: Tickets,
  journeyType: string,
  dispatch: Dispatch<any>
) => {
  const isAllocatableSeatingTicketSelected = !!tickets.groupedTicketTypes.find(
    (x) =>
      x.isAllocatableSeating && x.ticketTypeModels.find((y) => y.quantity > 0)
  );

  if (
    !isAllocatableSeatingTicketSelected &&
    journeyType === JOURNEY_TYPES.TICKET_FIRST
  ) {
    dispatch(actionCreators.setJourneyType(JOURNEY_TYPES.TICKETS_ONLY));
  } else if (
    journeyType === JOURNEY_TYPES.TICKETS_ONLY &&
    isAllocatableSeatingTicketSelected
  ) {
    dispatch(actionCreators.setJourneyType(JOURNEY_TYPES.TICKET_FIRST));
  }
};

export const setDeals = (
  ticketTypeId: TicketTypeModel['id'],
  ticketTypes: TicketTypes,
  deals: Deal[],
  dealsInTicketsStep: DealInTicketsStep[],
  context: QuantitySelectorContext,
  dispatch: Dispatch<any>
) => {
  const { ticketTypeModels } = ticketTypes;

  const qualifiedDeals = getQualifiedDeals(
    ticketTypeId,
    ticketTypeModels,
    dealsInTicketsStep ?? [],
    deals,
    context
  );

  dispatch(actionCreators.setDealsInTicketsStep(qualifiedDeals));
  dispatch(actionCreators.setAppliedDealsWithDiscount([], 0));
};

export const getPackageTicketsDescription = (ticket: TicketTypeModel) => {
  return ticket.listPackageTicket
    .map((t, i) => {
      return (
        t.quantity +
        ' x ' +
        t.displayName +
        `${ticket.listPackageTicket.length - 1 > i ? ', ' : ''}`
      );
    })
    .join('');
};
