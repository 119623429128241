import React from 'react';

import TokenReplacer from '../../../services/TokenReplacer';

interface Props {
  className?: string;
  text: string;
}

const RichText: React.FC<Props> = ({ text, className }) => {
  if (!text) return null;

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: TokenReplacer(text),
      }}
      data-testid='richtext'
    />
  );
};

export default RichText;
