/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { getRouteFromStep } from '../../../services/JourneyService';
import {
  selectContent,
  selectJourneyTypeConfig,
  selectStep,
} from '../../../store/Selectors';
import SeasonPassSeatsMoviesContainer from '../../common/seasonpass/SeasonPassSeatsMoviesContainer';
import SelectedSeasonPassStepContainer from '../../common/seasonpass/SelectedSeasonPassStepContainer';

const SeasonPassSeats: React.FC = () => {
  const content = useSelector(selectContent);
  const navigate = useNavigate();
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);

  const handleContinueClick = () => {
    const path = getRouteFromStep(journeyTypeConfig, step + 1);
    navigate(`/${path}`);
  };

  return (
    <SelectedSeasonPassStepContainer
      pageTitle={content.seasonPass.confirmSeatsTitle}
      pageText={content.seasonPass.confirmSeatsRichText}
      continueButtonText={content.continueButtonText}
      onContinueClicked={() => handleContinueClick()}
      disableContinueButton={false}
    >
      <SeasonPassSeatsMoviesContainer />
    </SelectedSeasonPassStepContainer>
  );
};

export default SeasonPassSeats;
