import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getNumberOfItemsToSelect } from '../../../services/Helpers';
import {
  selectContent,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';

interface Props {
  allSelected: boolean;
  numberSelected: number;
  numberToSelect: number;
  mt?: string;
}

const SelectedHeading: React.FC<Props> = ({
  allSelected,
  numberSelected,
  numberToSelect,
  mt = 'mt-3',
}) => {
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  return (
    <ContainedRow classNameCol='d-flex justify-content-center'>
      <Heading
        size={2}
        className={classnames(
          'select-heading d-flex align-items-center',
          allSelected && 'all-selected',
          mt
        )}
      >
        {allSelected && <RadioSelectedSvg className='all-selected-check' />}
        <div>
          {getNumberOfItemsToSelect(
            journeyTypeConfig.type,
            content,
            numberToSelect,
            numberSelected
          )}
        </div>
      </Heading>
    </ContainedRow>
  );
};

export default SelectedHeading;
