import React from 'react';

import classNames from 'classnames';
import { Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { resolveSvg } from './SeatSvgResolver';

import { selectContentSeats } from '../../../store/Selectors';

interface Props {
  label: string;
  svgType: string;
  svgClassName: string;
  svgSource?: string;
}

const LegendItem: React.FC<Props> = ({
  label: title,
  svgType,
  svgClassName,
  svgSource,
}) => {
  const contentSeats = useSelector(selectContentSeats);

  return (
    <Col xs={6} md={4} className='mt-2 d-flex'>
      {resolveSvg(
        svgType,
        classNames(svgClassName, 'legend-icon'),
        title,
        svgSource
      )}
      <span className='ms-2'>
        {title}
        <span className='visually-hidden'> {contentSeats.titleSeatText}</span>
      </span>
    </Col>
  );
};

export default LegendItem;
