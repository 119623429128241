import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { displayPrice } from './Helpers';

import { GlobalState } from '../@types/modelTypes';
import { replaceText } from '../services/Helpers';
import {
  selectBookingFeeStrategy,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectState,
} from '../store/Selectors';

interface TokenReplacements {
  [key: string]: string;
}

function getSubTotalBeforeTaxToCurrencyFormat(state: GlobalState) {
  if (state?.confirmationData) {
    const priceBeforeTaxInCents =
      state.confirmationData.totalPrice - state.confirmationData.productsTax;
    const priceBeforeTaxInDollars = (priceBeforeTaxInCents / 100).toFixed(2);
    return String(priceBeforeTaxInDollars);
  } else {
    return '';
  }
}

function getTotal(state: GlobalState) {
  if (state?.confirmationData) {
    const priceBeforeTaxInDollars = (
      state.confirmationData.totalPrice / 100
    ).toFixed(2);
    return String(priceBeforeTaxInDollars);
  } else {
    return '';
  }
}

function getCreditsAvailable(state: GlobalState) {
  return state.ticketCreditsAvailable === undefined
    ? ''
    : String(state.ticketCreditsAvailable);
}

function getRemainingRequiredSessionsNumber(state: GlobalState) {
  return `${
    state?.selectedSeasonPass?.seasonPassItem?.minRequiredMovies -
    state?.selectedSeasonPass?.sessions.length
  }`;
}

const TokenReplacer = (originalText: string) => {
  const state = useSelector(selectState);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const bookingFeeStrategy = useSelector(selectBookingFeeStrategy);
  const { orderId } = useParams();
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const bookingData = state.bookingData;
  const cartSummary = state.cartSummary;
  const config = state.config;
  const giftCardRecipient = state.giftCardRecipient;

  if (!state || !originalText) {
    return originalText;
  }

  const tokenReplacements: TokenReplacements = {
    '##LoyaltyCreditApplied##': displayPrice(
      Math.min(bookingData?.loyaltyCardBalance, grandTotal),
      currencyConfig
    ),
    '##LoyaltyDollars##': displayPrice(
      bookingData?.loyaltyCardBalance,
      currencyConfig
    ),
    '##LoyaltyDollarsRemaining##': displayPrice(
      bookingData?.loyaltyCardBalance - state.loyaltyRedemptionRewards,
      currencyConfig
    ),
    '##BalanceToPayAfterLoyaltyDollars##': displayPrice(
      grandTotal - state.loyaltyRedemptionRewards,
      currencyConfig
    ),
    '##LoyaltyPoints##': bookingData?.loyaltyCardPoints
      ? `${bookingData?.loyaltyCardPoints}`
      : '',
    '##cinema##': config.currentCinema?.title ?? '',
    '##film##': cartSummary?.title ?? '',
    '##date##': cartSummary?.displayDate ?? '',
    '##time##': cartSummary?.startTime ?? '',
    '##RECIPIENTEMAIL##': giftCardRecipient?.email ?? '',
    '##BUFFERSEATS##': `${config?.seats.socialDistancingBuffer}`,
    '##MAXTICKETSPERORDER##': `${config?.tickets.maxTicketsPerOrder}`,
    '##EXTERNALORDERID##': orderId ?? '',
    '##SUBTOTALBEFORETAXESDECIMAL##':
      getSubTotalBeforeTaxToCurrencyFormat(state),
    '##ORDERTOTAL##': getTotal(state),
    '##TICKETCREDITSAVAILABLE##': getCreditsAvailable(state),
    '##BookingFeeValue##': bookingFeeStrategy
      ? displayPrice(bookingFeeStrategy?.bookingFeeValue, currencyConfig)
      : '',
    '##minRequiredMovies##': `${state?.selectedSeasonPass?.seasonPassItem?.minRequiredMovies}`,
    '##maxRequiredMovies##': `${state?.selectedSeasonPass?.seasonPassItem?.maxRequiredMovies}`,
    '##remainingRequiredSessions##': getRemainingRequiredSessionsNumber(state),
    '##selectedMovies##': `${state?.selectedSeasonPass?.sessions?.length}`,
  };

  let adjustedText = originalText;

  Object.keys(tokenReplacements).forEach((token) => {
    if (originalText.includes(token)) {
      adjustedText = replaceText(adjustedText, token, tokenReplacements[token]);
    }
  });

  return adjustedText;
};

export default TokenReplacer;
