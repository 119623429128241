/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Row, Col, Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Concession } from '../../../../@types/modelTypes';
import { displayPrice } from '../../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectGiftCardRecipient,
} from '../../../../store/Selectors';
import { ReactComponent as EyeHideSvg } from '../../../../svgs/eyeHide.svg';
import { ReactComponent as EyeShowSvg } from '../../../../svgs/eyeShow.svg';
import GiftCardImage from '../../concessionsonlycommon/GiftCardImage';

interface GiftCardsRowProps {
  concession: Concession;
}

const GiftCardUpsellSummaryRow: React.FC<GiftCardsRowProps> = ({
  concession,
}) => {
  const navigate = useNavigate();

  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);

  const [showGiftCardDetails, setShowGiftCardDetails] = useState(false);

  const imagePath = concession?.image
    ? concession.image.replace('https://', '')
    : content.giftCards.defaultImage;

  if (!concession || concession.quantity < 1) return null;

  const getDescription = () => {
    return `${displayPrice(concession.cost, currencyConfig)} ${
      content.giftCards.summaryGiftCardLabel
    }`;
  };

  return (
    <div className='giftcards-row mt-3'>
      <Row className='g-0 d-flex align-items-center'>
        <Col xs={'auto'} className='image-container pe-2'>
          <GiftCardImage imageUrl={imagePath} />
        </Col>
        <Col className='text-start summary-item b'>
          <span>{getDescription()}</span>
        </Col>
        <Col xs={'auto'} className='text-end ps-2'>
          <Button
            className='collapse-btn d-flex align-items-center small'
            onClick={() => {
              setShowGiftCardDetails(!showGiftCardDetails);
            }}
            variant='link'
            sx={{
              svg: {
                fill: 'mostReadableOnWebsiteBackground',
              },
            }}
          >
            <div>
              {showGiftCardDetails
                ? content.payment.giftCardSummaryHideDetailsButtonText
                : content.payment.giftCardSummaryShowDetailsButtonText}
            </div>
            <div>{showGiftCardDetails ? <EyeHideSvg /> : <EyeShowSvg />}</div>
          </Button>
        </Col>
        <Collapse in={showGiftCardDetails}>
          <Row className='recipient-details text-start tiny g-0'>
            <Col xs={12} className='contained'>
              <div className='mt-1'>
                <div className='d-flex justify-content-between'>
                  <div>
                    <div className='detail-line'>
                      <span className='fw-bold'>
                        {content.giftCards.paymentRecipientNameLabel}
                      </span>{' '}
                      <span>{giftCardRecipient.name}</span>
                    </div>
                    <div className='detail-line'>
                      <span className='fw-bold'>
                        {content.giftCards.paymentRecipientEmailLabel}
                      </span>{' '}
                      <span>{giftCardRecipient.email}</span>
                    </div>
                    {giftCardRecipient.message && (
                      <div className='mt-1'>
                        <span className='fw-bold'>
                          {content.giftCards.paymentRecipientMessageLabel}
                        </span>{' '}
                        <span>{giftCardRecipient.message}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='mt-3'>
                  <Button
                    onClick={() => navigate(-1)}
                    variant='link'
                    className='btn-edit b text-center text-uppercase'
                  >
                    {content.giftCards.paymentEditText}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Collapse>
      </Row>
    </div>
  );
};

export default GiftCardUpsellSummaryRow;
