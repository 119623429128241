import { takeLeading, call, select, put } from 'redux-saga/effects';

import { PaymentProvidersEnum } from '../../@types/enums';
import { PEACH_CODES } from '../../constants';
import { createConfirmation } from '../../services/Helpers';
import { getContentForError } from '../../services/PeachErrorResolver';
import backend from '../../services/RestUtilities';
import { actionCreators } from '../ActionCreators';
import {
  ADYEN_DROPIN_HOSTED_PAYMENT,
  HOSTED_PAYMENT_REQUEST,
  INITIALIZE_AMAZONPAY_PAYMENT,
  SUBMIT_MAKE_PAYMENT_REQUEST,
} from '../Actions';
import {
  selectConfig,
  selectContent,
  selectCustomer,
  selectGrandTotalAfterDiscountsInCents,
  selectMakePaymentForHostedRequest,
  selectMakePaymentRequestModel,
  selectBankCardAmount,
  selectSelectedSeats,
  selectJourneyTypeConfig,
  selectJourneyType,
} from '../Selectors';

function* initializeAmazonPayPaymentHandler(action) {
  yield put(actionCreators.setLoading(true));

  const { executeRecaptcha, turnstile, recaptcha } = action.payload;

  const makePaymentModel = yield select(selectMakePaymentForHostedRequest);
  const content = yield select(selectContent);
  const config = yield select(selectConfig);
  const grandTotal = yield select(selectGrandTotalAfterDiscountsInCents);
  const bankCardAmount = yield select(selectBankCardAmount);
  const recaptchaToken =
    (yield recaptcha?.getRecaptchaToken(
      'HostedPaymentRequest',
      executeRecaptcha
    )) ?? null;

  const turnstileToken = yield turnstile?.getToken();

  makePaymentModel.recaptchaToken = recaptchaToken;
  makePaymentModel.paymentProvider = PaymentProvidersEnum.AMAZONPAY;

  const response = yield call(
    backend.post,
    'api/Payment/Hosted',
    makePaymentModel,
    turnstileToken
  );

  if (!response.ok) {
    yield put(actionCreators.setHostedPaymentInProgress(false));
    yield put(actionCreators.setError(content.error.unexpectedErrorRichText));
    turnstile?.resetToken();
    yield put(actionCreators.setLoading(false));
    return;
  }

  const responseContent = response.content;

  if (responseContent.peachCode !== PEACH_CODES.noError) {
    yield put(
      actionCreators.setError(
        getContentForError(responseContent.peachCode, content),
        responseContent.peachCode
      )
    );

    turnstile?.resetToken();

    yield put(actionCreators.setLoading(false));

    return;
  }

  if (config.enableCountDown) {
    yield put(actionCreators.setCountDown(responseContent.secondsToExpiration));
  }

  yield put(
    actionCreators.setHostedPayment(
      PaymentProvidersEnum.AMAZONPAY,
      {
        clientId: responseContent.postValues.clientId,
        merchantId: responseContent.postValues.merchantId,
        widgetsUrl: responseContent.postValues.widgetsUrl,
        grandTotal,
        bankCardAmount,
      },
      responseContent.guestSessionToken
    )
  );

  turnstile?.resetToken();

  yield put(actionCreators.setLoading(false));
}

function* submitMakePaymentHandler(action) {
  yield put(actionCreators.setLoading(true));

  const {
    executeRecaptcha,
    makePaymentModelOverrideProps,
    callBackFunction,
    turnstile,
    recaptcha,
  } = action.payload;

  const recaptchaToken =
    (yield recaptcha?.getRecaptchaToken(
      'MakePaymentRequest',
      executeRecaptcha
    )) ?? null;

  const turnstileToken = yield turnstile?.getToken();

  const makePaymentModel = yield select(selectMakePaymentRequestModel);
  const customer = yield select(selectCustomer);
  const content = yield select(selectContent);
  const selectedSeats = yield select(selectSelectedSeats);
  const journeyTypeConfig = yield select(selectJourneyTypeConfig);
  const journeyType = yield select(selectJourneyType);

  makePaymentModel.recaptchaToken = recaptchaToken ?? null;

  const request = {
    ...makePaymentModel,
    ...makePaymentModelOverrideProps,
  };

  const response = yield call(
    backend.post,
    'api/Payment/MakePaymentRequest',
    request,
    turnstileToken
  );

  if (!response.ok) {
    yield put(actionCreators.setError(content.error.paymentModelErrorRichText));

    turnstile?.resetToken();

    yield put(actionCreators.setLoading(false));
    return;
  }

  const data = response.content;

  if (data.peachCode === PEACH_CODES.noError) {
    const confirmation = createConfirmation(data, customer, journeyType);
    if (
      journeyTypeConfig.isConcessionsOnlyJourney &&
      selectedSeats &&
      confirmation.selectedSeats.length !== selectedSeats.length
    ) {
      confirmation.selectedSeats = selectedSeats;
    }
    yield put(actionCreators.setSendAnalytics(true));
    yield put(actionCreators.setConfirmation(confirmation));
    yield put(actionCreators.setGuestSessionToken(data.guestSessionToken));
  } else {
    yield put(
      actionCreators.setError(
        getContentForError(data.peachCode, content),
        data.peachCode
      )
    );
  }

  turnstile?.resetToken();

  yield put(actionCreators.setLoading(false));
  if (callBackFunction) {
    yield callBackFunction();
  }
}

function* hostedPaymentRequestHandler(action) {
  yield put(actionCreators.setLoading(true));

  const content = yield select(selectContent);
  const config = yield select(selectConfig);
  const bankCardAmount = yield select(selectBankCardAmount);
  const makePaymentModel = yield select(selectMakePaymentForHostedRequest);

  const {
    paymentProvider,
    grandTotal,
    executeRecaptcha,
    turnstile,
    recaptcha,
  } = action.payload;

  const recaptchaToken = yield recaptcha?.getRecaptchaToken(
    'HostedPaymentRequest',
    executeRecaptcha
  );

  const turnstileToken = yield turnstile?.getToken();

  const response = yield call(
    backend.post,
    'api/Payment/Hosted',
    {
      ...makePaymentModel,
      recaptchaToken: recaptchaToken ?? null,
      paymentProvider,
    },
    turnstileToken
  );

  if (response.ok) {
    const responseContent = response.content;
    if (responseContent.peachCode === PEACH_CODES.noError) {
      const transactionId =
        paymentProvider === PaymentProvidersEnum.RTSVANTIV
          ? responseContent.postValues['PaymentId']
          : responseContent.transactionId;

      yield put(
        actionCreators.setHostedPayment(
          paymentProvider,
          {
            actionUrl: responseContent.actionUrl,
            transactionId,
            grandTotal,
            bankCardAmount,
            publishKey: response.content.postValues.PublishKey,
          },
          responseContent.guestSessionToken
        )
      );

      yield put(actionCreators.setHostedPaymentInProgress(true));
      yield put(actionCreators.setSendAnalytics(true));
      if (config.enableCountDown) {
        yield put(
          actionCreators.setCountDown(responseContent.secondsToExpiration)
        );
      }
    } else {
      yield put(
        actionCreators.setError(
          getContentForError(responseContent.peachCode, content),
          responseContent.peachCode
        )
      );
    }
  } else {
    yield put(actionCreators.setHostedPayment(paymentProvider, null));
    yield put(actionCreators.setHostedPaymentInProgress(false));

    let errorMessage = content.error.unexpectedErrorRichText;

    switch (response.content.peachCode) {
      case PEACH_CODES.seatingCapacityExceeded:
      case PEACH_CODES.sessionSoldOut:
        errorMessage = content.error.sessionSoldOutRichText;
        break;
      case PEACH_CODES.sessionNotBookable:
        errorMessage = content.error.sessionNotBookableRichText;
        break;
      case PEACH_CODES.orderMaxRetriesReached:
        yield put(actionCreators.setMaxRetriesExceeded(true));
        errorMessage = content.error.invalidOrderErrorRichText;
        break;
    }
    yield put(
      actionCreators.setError(errorMessage, response.content.peachCode)
    );
  }

  turnstile?.resetToken();

  yield put(actionCreators.setLoading(false));
}

function* adyenDropinHostedPaymentHandler(action) {
  yield put(actionCreators.setLoading(true));

  const content = yield select(selectContent);
  const makePaymentModel = yield select(selectMakePaymentForHostedRequest);

  const { executeRecaptcha, turnstile, recaptcha, callBackFunction } =
    action.payload;

  const recaptchaToken = yield recaptcha?.getRecaptchaToken(
    'HostedPaymentRequest',
    executeRecaptcha
  );

  const turnstileToken = yield turnstile?.getToken();

  const response = yield call(
    backend.post,
    'api/Payment/Hosted',
    {
      ...makePaymentModel,
      recaptchaToken: recaptchaToken ?? null,
      paymentProvider: PaymentProvidersEnum.ADYEN,
    },
    turnstileToken
  );

  if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
    yield put(
      actionCreators.setHostedPayment(
        PaymentProvidersEnum.ADYEN,
        response.content,
        response.content.guestSessionToken
      )
    );

    if (callBackFunction) {
      yield callBackFunction(response);
    }
  } else {
    yield put(
      actionCreators.setError(
        getContentForError(response.content.peachCode, content),
        response.content.peachCode
      )
    );
  }

  turnstile?.resetToken();

  yield put(actionCreators.setLoading(false));
}

export function* initializeAmazonPayPaymentWatch() {
  yield takeLeading(
    [INITIALIZE_AMAZONPAY_PAYMENT],
    initializeAmazonPayPaymentHandler
  );
}

export function* submitMakePaymentWatch() {
  yield takeLeading([SUBMIT_MAKE_PAYMENT_REQUEST], submitMakePaymentHandler);
}

export function* hostedPaymentRequestWatch() {
  yield takeLeading([HOSTED_PAYMENT_REQUEST], hostedPaymentRequestHandler);
}

export function* adyenDropinHostedPaymentWatch() {
  yield takeLeading(
    [ADYEN_DROPIN_HOSTED_PAYMENT],
    adyenDropinHostedPaymentHandler
  );
}
