import React from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  Concession,
  ConcessionPricing,
  Modifier,
} from '../../../@types/modelTypes';
import { splitDeliveryItemFromConcessions } from '../../../services/GiftCardHelpers';
import { displayPrice } from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectCurrencyConfig,
  selectAllConcessions,
  selectSelectedGiftCards,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
}

const CartSummaryDeliveryItems: React.FC<Props> = ({ hideTax }) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const concessions = useSelector(selectAllConcessions);
  const selectedGCConcessions = useSelector(selectSelectedGiftCards);

  const concessionWithModifiers = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);

    return (
      <>
        <Row className='concession-item g-0'>
          <Col className='text-start pe-1'>{c.description}</Col>
          <Col xs={1} className={classnames('text-center tickets-quantity')}>
            {c.quantity}
          </Col>
          <Col xs={2} className='text-end price'>
            {displayPrice(
              c.quantity *
                (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
              currencyConfig
            )}
          </Col>
        </Row>
        {c.modifiers &&
          c.modifiers.map((m: Modifier, mi: number) => (
            <Row key={`c_${c.id}_${ci}_m_${m.id}_${mi}`}>
              <Col xs={12} className='text-start'>
                {'- '}
                {m.description}
                {m.quantity > 1 && <span> x {m.quantity}</span>}
              </Col>
            </Row>
          ))}
      </>
    );
  };

  if (!selectedGCConcessions) {
    return null;
  }

  const { deliveryItem } = splitDeliveryItemFromConcessions(
    concessions,
    selectedGCConcessions
  );

  if (!deliveryItem) return null;

  return (
    <div className='cart-summary-row'>
      <div className='concessions-item-list' data-testid='cs-concessions'>
        {concessionWithModifiers(deliveryItem, hideTax, 0)}
      </div>
    </div>
  );
};

export default CartSummaryDeliveryItems;
