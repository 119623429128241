/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { useSelector, useDispatch } from 'react-redux';

import {
  ChangeGiftCardByIdContext,
  QuantitySelectorContext,
} from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  Modifier,
  ModifierGroup,
} from '../../../@types/modelTypes';
import {
  displayPrice,
  handleAddRemoveConcessionDynamicBasket,
  handleUpdateConcessionsDynamicBasket,
} from '../../../services/Helpers';
import {
  findModifiedConcessionItemCost,
  currentGroupQuantity,
  currentSmartGroupQuantity,
} from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCurrencyConfig,
  selectSelectedDeliveryWindow,
  selectToken,
  selectJourneyTypeConfig,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import { ReactComponent as AddSvg } from '../../../svgs/add.svg';
import { ReactComponent as MinusSvg } from '../../../svgs/minus.svg';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../svgs/radioUnselected.svg';
import QuantityButton from '../quantitybutton/QuantityButton';
interface ConcessionItemProps extends Concession {
  concessionIndex?: number;
  closeModal: (bool: boolean) => void;
}
const ConcessionItemModal: React.FC<ConcessionItemProps> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isSoldOut,
  concessionIndex,
  closeModal,
}) => {
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const bookingData = useSelector(selectBookingData);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);

  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem: false,
    minVariableCost: 0,
    maxVariableCost: 0,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut,
  };

  const [refresh, setRefresh] = useState<number>(0);
  const [concessionQuantity, setConcessionQuantity] = useState<number>(
    concession.quantity
  );
  const [concessionAdded, setConcessionAdded] = useState<boolean>(false);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const [showModifierGroups, setShowModifierGroups] = useState<boolean>(
    modifierGroups && modifierGroups.length <= 1
  );
  const currencyConfig = useSelector(selectCurrencyConfig);

  const hideTax = config.currentCinema.hideTax;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const minQuantityNotReached = concession.modifierGroups?.some(
    (mg: ModifierGroup) =>
      mg.minimumQuantity > 0 && currentGroupQuantity(mg) < mg.minimumQuantity
  );

  const requiredModifiersCount = concession.modifierGroups.filter(
    (mg: ModifierGroup) => mg.minimumQuantity > 0
  ).length;

  const requiredModifiersSelectedCount = concession.modifierGroups.filter(
    (mg: ModifierGroup) =>
      mg.minimumQuantity > 0 && currentGroupQuantity(mg) >= mg.minimumQuantity
  ).length;

  const modifiersSelectedCount = concession.modifierGroups.filter(
    (mg: ModifierGroup) =>
      mg.isSmart ? currentSmartGroupQuantity(mg) : currentGroupQuantity(mg)
  ).length;

  const resetSelectedConcession = () => {
    setConcessionAdded(true);
    setTimeout(() => {
      setConcessionAdded(false);
      setConcessionQuantity(1);
      concession.modifierGroups.forEach((mg: ModifierGroup) =>
        mg.modifiers.forEach((m: Modifier) => {
          mg.show = false;
          m.quantity = mg.isSmart ? 1 : 0;
          closeModal(true);
        })
      );
    }, 1500);
  };

  const handleQuantityChange = async (context: QuantitySelectorContext) => {
    if (context === 'add') {
      setConcessionQuantity(concessionQuantity + 1);
    } else {
      setConcessionQuantity(concessionQuantity - 1);
    }
  };

  const handleConcessionChange = async (
    context: ChangeGiftCardByIdContext,
    selectedQuantity: number
  ) => {
    const concessionToChange: Concession = JSON.parse(
      JSON.stringify(concession)
    );

    concessionToChange.quantity = selectedQuantity;

    if (context === 'update' && concessionIndex !== undefined) {
      dispatch(
        actionCreators.changeConcessionByIndex(
          concessionToChange,
          concessionIndex,
          true
        )
      );
    } else if (context === 'add') {
      dispatch(actionCreators.addConcessionWithMods(concessionToChange, true));
    } else {
      dispatch(
        actionCreators.removeConcessionWithMods(concessionToChange, true)
      );
    }
    if (config.useDynamicBasket) {
      if (context === 'update') {
        dispatch(actionCreators.setLoading(true));
        await handleUpdateConcessionsDynamicBasket(
          context,
          selectedConcessions.list,
          dataToken,
          journeyTypeConfig.type,
          selectedDeliveryWindow,
          bookingData,
          dispatch,
          content
        );
        dispatch(actionCreators.setLoading(false));
      } else {
        dispatch(actionCreators.setLoading(true));
        await handleAddRemoveConcessionDynamicBasket(
          context,
          concessionToChange,
          dataToken,
          journeyTypeConfig.type,
          selectedDeliveryWindow,
          bookingData,
          dispatch,
          content
        );
        dispatch(actionCreators.setLoading(false));
      }
    }
    resetSelectedConcession();
  };

  const handleSmartModifierQuantityChange = (
    context: QuantitySelectorContext,
    mg: ModifierGroup,
    m: Modifier
  ) => {
    if (context === 'add') {
      m.quantity += 1;
    } else if (context === 'remove' && m.quantity > 0) {
      m.quantity -= 1;
    }
    setRefresh(refresh + 1);
  };

  const handleModifierQuantityChange = (
    context: QuantitySelectorContext,
    mg: ModifierGroup,
    m: Modifier
  ) => {
    if (context === 'add' && currentGroupQuantity(mg) < mg.maximumQuantity) {
      m.quantity += 1;
    } else if (context === 'remove' && m.quantity > 0) {
      m.quantity -= 1;
    }
    setRefresh(refresh + 1);
  };

  const handleMultiOptionToggle = (mg: ModifierGroup) => {
    mg.show = !mg.show;
    setRefresh(refresh + 1);
  };

  const renderLocalStateQuantityButtons = (isSoldOut: boolean) => {
    const priceToDisplay = hideTax
      ? itemPricing.costIncTax
      : itemPricing.costMinusTax;
    const subTotal =
      (concessionQuantity > 0 ? concessionQuantity : 1) * priceToDisplay;
    let buttonText;
    if (concessionAdded) {
      buttonText = content.kiosk.buttonUpdatedText;
    } else if (minQuantityNotReached) {
      buttonText = content.kiosk.buttonRequiredText;
    } else {
      buttonText = `${content.kiosk.buttonUpdateText?.replace(
        '##quantity##',
        String(concessionQuantity)
      )} ${
        itemPricing.costIncTax > 0 && displayPrice(subTotal, currencyConfig)
      }`;
    }

    return (
      <div>
        {!isSoldOut && (
          <Row className='quantity-selector'>
            <Col sm={12} md={12} className='d-flex justify-content-center mb-1'>
              <div className='quantity-button-container'>
                {renderLocalStateButton('remove', concessionQuantity <= 1)}
              </div>
              <div className='quantity-col d-flex align-items-center justify-content-center fw-bold'>
                <div>{concessionQuantity}</div>
              </div>
              <div className='quantity-button-container'>
                {renderLocalStateButton(
                  'add',
                  minQuantityNotReached || isSoldOut
                )}
              </div>
            </Col>

            <Col sm={12} md={12}>
              <div
                className={classnames(
                  ' btn w-100 quantity-button-first d-flex align-items-center justify-content-center',
                  (minQuantityNotReached || concessionAdded) && 'disabled'
                )}
                onClick={() =>
                  !minQuantityNotReached &&
                  handleConcessionChange(
                    concessionQuantity !== 0 ? 'update' : 'remove',
                    concessionQuantity
                  )
                }
              >
                {buttonText}
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const renderLocalStateButton = (
    context: QuantitySelectorContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={concessionAdded ? true : disabled}
        onClick={() => handleQuantityChange(context)}
        size='small'
      />
    );
  };

  const hasOneMandatoryModifierInGroup = (
    mg: ModifierGroup,
    grpQuantity: number
  ) => {
    return (
      grpQuantity === 1 && mg.minimumQuantity === 1 && mg.modifiers.length === 1
    );
  };

  const radioOptionsSelector = (
    mg: ModifierGroup,
    m: Modifier,
    grpQuantity: number
  ) => {
    const selectModifier = () => {
      if (!hasOneMandatoryModifierInGroup(mg, grpQuantity)) {
        mg.modifiers.forEach((x: Modifier) => {
          x.quantity = x.id === m.id && m.quantity === 0 ? 1 : 0;
        });
      }
      setRefresh(refresh + 1);
    };
    return (
      <Row
        className={classnames(
          'modifier-item mx-1 py-1 g-0',
          m.quantity >= 1 && 'selected'
        )}
        key={`u_${mg.id}_${m.id}`}
      >
        <Col
          xs={12}
          md={12}
          className='d-flex align-items-center justify-content-start'
        >
          <Button
            className='d-flex align-items-center justify-content-center w-100 text-start'
            onClick={selectModifier}
            variant='link'
            disabled={m.isSoldOut}
          >
            <div className='d-flex flex-grow-1 align-items-center'>
              <div
                className={classnames(
                  'flex-grow-1 align-items-center',
                  m.quantity >= 1 && 'fw-bold'
                )}
              >
                {m.description}
              </div>
              {m.priceInCents > 0 && (
                <div className='text-end tiny align-items-center modifier-cost'>
                  {displayPrice(
                    hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
                    currencyConfig
                  )}
                </div>
              )}
            </div>
            {m.quantity >= 1 ? (
              <RadioSelectedSvg className='check' />
            ) : (
              <RadioUnselectedSvg className='check' />
            )}
          </Button>
        </Col>
      </Row>
    );
  };

  const quantityOptionSelector = (
    mg: ModifierGroup,
    m: Modifier,
    grpQuantity: number
  ) => {
    return (
      <Row
        key={`u_${mg.id}_${m.id}`}
        className={classnames('modifier-item mx-1 py-1 g-0')}
      >
        <Col xs={6} className='d-flex align-items-center justify-content-start'>
          {m.description}
        </Col>
        <Col
          xs={4}
          className='d-flex align-items-center justify-content-center'
        >
          <QuantityButton
            context='remove'
            disabled={m.quantity <= 0}
            onClick={() => handleModifierQuantityChange('remove', mg, m)}
            size='tiny'
          />
          <div className='flex-grow-1 quantity-col text-center'>
            {m.quantity}
          </div>
          <QuantityButton
            context='add'
            disabled={grpQuantity >= mg.maximumQuantity || m.isSoldOut}
            onClick={() => handleModifierQuantityChange('add', mg, m)}
            size='tiny'
          />
        </Col>
        <Col
          xs={2}
          className='d-flex align-items-center justify-content-end tiny pe-1'
        >
          +
          {displayPrice(
            hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
            currencyConfig
          )}
        </Col>
      </Row>
    );
  };

  const quantityOptionSelectorSmart = (mg: ModifierGroup, m: Modifier) => {
    return (
      <Row
        key={`u_${mg.id}_${m.id}`}
        className={classnames('modifier-item mx-1 py-1 g-0')}
      >
        <Col xs={6} className='d-flex align-items-center justify-content-start'>
          {m.description}
        </Col>
        <Col
          xs={4}
          className='d-flex align-items-center justify-content-center'
        >
          <QuantityButton
            context='remove'
            disabled={m.quantity <= 0}
            onClick={() => handleSmartModifierQuantityChange('remove', mg, m)}
            size='tiny'
          />
          <div className='flex-grow-1 quantity-col text-center'>
            {m.quantity}
          </div>
          <QuantityButton
            context='add'
            disabled={m.quantity >= mg.maximumQuantity || m.isSoldOut}
            onClick={() => handleSmartModifierQuantityChange('add', mg, m)}
            size='tiny'
          />
        </Col>
        <Col
          xs={2}
          className='d-flex align-items-center justify-content-end tiny pe-1'
        >
          +
          {displayPrice(
            hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
            currencyConfig
          )}
        </Col>
      </Row>
    );
  };

  const radioOptionsSelected = (mg: ModifierGroup, m: Modifier) => {
    return (
      <Row
        className={classnames(
          'modifier-item mx-1 g-0 modifier-item-selected',
          m.quantity >= 1 && 'selected'
        )}
        key={`u_${mg.id}_${m.id}`}
      >
        <Col
          xs={12}
          md={12}
          className='d-flex align-items-center justify-content-start'
        >
          <Button
            className='d-flex align-items-center justify-content-center w-100 text-start'
            onClick={() => handleMultiOptionToggle(mg)}
            variant='link'
            disabled={m.isSoldOut}
          >
            <div className='d-flex flex-grow-1 align-items-center'>
              <div
                className={classnames(
                  'flex-grow-1 align-items-center tiny',
                  m.quantity >= 1 && 'fw-bold'
                )}
              >
                {m.description}
              </div>
              {m.priceInCents > 0 && (
                <div className='text-end tiny align-items-center modifier-cost'>
                  {displayPrice(
                    hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
                    currencyConfig
                  )}
                </div>
              )}
            </div>
            {m.quantity >= 1 ? (
              <RadioSelectedSvg className='check' />
            ) : (
              <RadioUnselectedSvg className='check' />
            )}
          </Button>
        </Col>
      </Row>
    );
  };

  const quantityOptionSelected = (mg: ModifierGroup, m: Modifier) => {
    return (
      <Row
        key={`u_${mg.id}_${m.id}`}
        className={classnames('modifier-item mx-1 g-0 modifier-item-selected')}
        onClick={() => handleMultiOptionToggle(mg)}
      >
        <Col
          xs={6}
          className='d-flex align-items-center justify-content-start tiny'
        >
          {m.description}
        </Col>
        <Col
          xs={4}
          className='d-flex align-items-center justify-content-center'
        >
          <QuantityButton
            context='remove'
            disabled={true}
            onClick={() => handleMultiOptionToggle(mg)}
            size='tiny'
          />
          <div className='flex-grow-1 quantity-col text-center'>
            {m.quantity}
          </div>
          <QuantityButton
            context='add'
            disabled={true}
            size='tiny'
            onClick={() => handleMultiOptionToggle(mg)}
          />
        </Col>
        <Col
          xs={2}
          className='d-flex align-items-center justify-content-end tiny pe-1'
        >
          +
          {displayPrice(
            hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
            currencyConfig
          )}
        </Col>
      </Row>
    );
  };

  const modifierGroupRow = (mg: ModifierGroup) => {
    const grpQuantity: number = currentGroupQuantity(mg);
    const hideHeader = hasOneMandatoryModifierInGroup(mg, grpQuantity);
    const isOptional = mg.minimumQuantity === 0;

    let buttonText = isOptional
      ? content.kiosk.optionalLabel
      : content.kiosk.groupRequiredDescription;

    if (grpQuantity > 0) {
      buttonText = `${grpQuantity} ${content.kiosk.groupSelectedDescription}`;
    }

    return (
      <div key={`u_${mg.id}`}>
        <Row className='modifier-groups'>
          <Col
            sm={12}
            md={{ span: 2 }}
            className='text-start text-md-left tiny'
          >
            {isOptional && content.kiosk.optionalLabel
              ? `${mg.description} ${content.kiosk.optionalLabel}`
              : mg.description}
          </Col>
          <Col sm={12} md={10} className='mt-1 mt-md-0'>
            <div
              className={classnames(
                'multi-option-container bordered',
                grpQuantity > 0 && !mg.show && 'has-selection'
              )}
            >
              {hideHeader || (
                <div className='multi-option text-center'>
                  <Button
                    onClick={() => handleMultiOptionToggle(mg)}
                    variant='link'
                    className='heading-btn text-start d-flex align-items-center'
                    data-toggle='collapse'
                    data-target='#multi-option-collapse'
                  >
                    <div className='fw-bold flex-grow-1'>
                      {getModifierGroupButtonText(mg, grpQuantity, isOptional)}
                    </div>
                    <div
                      className={classnames(
                        'text-right tiny required-selection',
                        grpQuantity > 0 && 'valid'
                      )}
                    >
                      {buttonText}
                    </div>
                    <div className='accordion-icon'>
                      {mg.show ? <MinusSvg /> : <AddSvg />}
                    </div>
                  </Button>
                </div>
              )}
              <Accordion.Collapse in={mg.show || hideHeader} eventKey='0'>
                <div>
                  {mg.modifiers.map((m: Modifier) =>
                    mg.maximumQuantity > 1
                      ? quantityOptionSelector(mg, m, grpQuantity)
                      : radioOptionsSelector(mg, m, grpQuantity)
                  )}
                </div>
              </Accordion.Collapse>
              <Accordion.Collapse
                in={concessionAdded ? false : !mg.show}
                eventKey='0'
              >
                <div className='modifier-group-selected'>
                  {mg.modifiers.map(
                    (m: Modifier) =>
                      m.quantity > 0 &&
                      (mg.maximumQuantity > 1
                        ? quantityOptionSelected(mg, m)
                        : radioOptionsSelected(mg, m))
                  )}
                </div>
              </Accordion.Collapse>
            </div>
          </Col>
        </Row>
        <Accordion.Collapse
          in={
            requiredModifiersSelectedCount == requiredModifiersCount
              ? true
              : mg.show
          }
          eventKey='0'
        >
          <Row className='modified-concession-summary'>
            <Col sm={12} md={2}></Col>

            <Col md={10} xs={{ span: 8, offset: 2 }}>
              {concessionQuantitySelector(concession.isSoldOut, true)}
            </Col>
          </Row>
        </Accordion.Collapse>
      </div>
    );
  };

  const multiModifierGroupRow = (mg: ModifierGroup) => {
    const grpQuantity: number = currentGroupQuantity(mg);
    const hideHeader = hasOneMandatoryModifierInGroup(mg, grpQuantity);
    const isOptional = mg.minimumQuantity === 0;
    let buttonText = isOptional
      ? content.kiosk.optionalLabel
      : content.kiosk.groupRequiredDescription;
    if (grpQuantity > 0) {
      buttonText = `${grpQuantity} ${content.kiosk.groupSelectedDescription}`;
    }

    return (
      <Row className='quantity-options mt-1' key={`u_${mg.id}`}>
        <Col sm={12} md={12} className=''>
          <div
            className={classnames(
              'multi-option-container bordered',
              grpQuantity > 0 && !mg.show && 'has-selection'
            )}
          >
            {hideHeader || (
              <div className='multi-option'>
                <Button
                  onClick={() => handleMultiOptionToggle(mg)}
                  variant='link'
                  className='heading-btn text-start d-flex align-items-center'
                  data-toggle='collapse'
                  data-target='#multi-option-collapse'
                >
                  <div className='fw-bold flex-grow-1'>{mg.description}</div>
                  <div
                    className={classnames(
                      'text-right tiny required-selection',
                      grpQuantity > 0 && 'valid'
                    )}
                  >
                    {buttonText}
                  </div>
                  <div className='accordion-icon'>
                    {mg.show ? <MinusSvg /> : <AddSvg />}
                  </div>
                </Button>
              </div>
            )}
            <Accordion.Collapse in={mg.show || hideHeader} eventKey='0'>
              <div>
                {mg.modifiers.map((m: Modifier) =>
                  mg.maximumQuantity > 1
                    ? quantityOptionSelector(mg, m, grpQuantity)
                    : radioOptionsSelector(mg, m, grpQuantity)
                )}
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse
              in={concessionAdded ? false : !mg.show}
              eventKey='0'
            >
              <div className='modifier-group-selected'>
                {mg.modifiers.map(
                  (m: Modifier) =>
                    m.quantity > 0 &&
                    (mg.maximumQuantity > 1
                      ? quantityOptionSelected(mg, m)
                      : radioOptionsSelected(mg, m))
                )}
              </div>
            </Accordion.Collapse>
          </div>
        </Col>
      </Row>
    );
  };

  const multiSmartModifierGroupRow = (mg: ModifierGroup) => {
    const grpQuantity: number = currentGroupQuantity(mg);
    const hideHeader = hasOneMandatoryModifierInGroup(mg, grpQuantity);
    const isOptional = mg.minimumQuantity === 0;
    const totalUpdated = mg.modifiers.filter(
      (m: Modifier) => m.quantity > 1 || m.quantity == 0
    ).length;
    let buttonText = isOptional
      ? content.kiosk.optionalLabel
      : content.kiosk.groupRequiredDescription;
    if (totalUpdated > 0) {
      buttonText = `${totalUpdated} ${content.kiosk.groupChangesMadeDescription}`;
    }

    return (
      <Row className='quantity-options mt-1' key={`u_${mg.id}`}>
        <Col sm={12} md={12} className=''>
          <div
            className={classnames(
              'multi-option-container bordered',
              grpQuantity > 0 && !mg.show && 'has-selection'
            )}
          >
            {hideHeader || (
              <div className='multi-option'>
                <Button
                  onClick={() => handleMultiOptionToggle(mg)}
                  variant='link'
                  className='heading-btn text-start d-flex align-items-center'
                  data-toggle='collapse'
                  data-target='#multi-option-collapse'
                >
                  <div className='fw-bold flex-grow-1'>
                    {content.kiosk.smartModifierGroupTitle}
                  </div>
                  <div
                    className={classnames(
                      'text-right tiny required-selection',
                      totalUpdated > 0 && 'valid'
                    )}
                  >
                    {buttonText}
                  </div>
                  <div className='accordion-icon'>
                    {mg.show ? <MinusSvg /> : <AddSvg />}
                  </div>
                </Button>
              </div>
            )}
            <Accordion.Collapse in={mg.show || hideHeader} eventKey='0'>
              <div>
                {mg.modifiers.map((m: Modifier) =>
                  mg.maximumQuantity > 1
                    ? quantityOptionSelectorSmart(mg, m)
                    : radioOptionsSelector(mg, m, grpQuantity)
                )}
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse
              in={concessionAdded ? false : !mg.show}
              eventKey='0'
            >
              <div className='modifier-group-selected'>
                {mg.modifiers.map(
                  (m: Modifier) =>
                    (m.quantity > 1 || m.quantity == 0) &&
                    (mg.maximumQuantity > 1
                      ? quantityOptionSelected(mg, m)
                      : radioOptionsSelected(mg, m))
                )}
              </div>
            </Accordion.Collapse>
          </div>
        </Col>
      </Row>
    );
  };

  const concessionQuantitySelector = (
    isSoldOut: boolean,
    isModifierGroup?: boolean
  ) => {
    return (
      <Row className='options-selector-container text-center flex-grow-1'>
        <Col md={12}>
          <Row className='align-items-center'>
            <Col
              md={isModifierGroup ? { span: 8, offset: 2 } : 12}
              className={classnames(
                'options-selector p-1',
                isModifierGroup && 'options-selector-bordered'
              )}
            >
              {renderLocalStateQuantityButtons(isSoldOut)}
              {concession.isSoldOut && content.kiosk.soldOutText && (
                <p className='tiny'>{content.kiosk.soldOutText}</p>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const getModifierGroupButtonText = (
    mg: ModifierGroup,
    grpQuantity: number,
    isOptional: boolean
  ) => {
    const currentQuantity = currentGroupQuantity(mg);
    let text: string = isOptional
      ? content.kiosk.selectOptionalExtras
      : content.kiosk.selectOptionsButtonText;
    if (mg.maximumQuantity <= 1 && currentQuantity === 1) {
      text = mg.show
        ? content.kiosk.hideOptionsButtonText
        : content.kiosk.editOptionsButtonText;
    } else if (mg.show) {
      text = content.kiosk.selectedOptionsButtonText
        ?.replace('##quantity##', String(grpQuantity))
        .replace('##maxquantity##', String(mg.maximumQuantity));
    }
    return text;
  };

  return (
    <Row className='concession concession-modal text-center align-items-center'>
      <Col>
        <Row>
          <Col xs={12} md={12}>
            {concession.modifierGroups?.length == 1 && (
              <div className={concession.isSoldOut ? 'disabled' : undefined}>
                {concession.modifierGroups.map((mg: ModifierGroup) =>
                  modifierGroupRow(mg)
                )}
              </div>
            )}

            {concession.modifierGroups?.length > 1 && (
              <Row
                className='modifier-groups mt-2'
                sx={{
                  '.quantity-options': {
                    '.multi-option-container': {
                      backgroundColor: 'websiteBackground',
                    },
                  },
                }}
              >
                <Col md={{ span: 12 }}>
                  <div className='multi-option-container text-start bordered'>
                    <div className='multi-option'>
                      <Button
                        className={classnames(
                          'heading-btn btn btn-link text-start d-flex align-items-center fw-bold',
                          showModifierGroups && 'open'
                        )}
                        onClick={() =>
                          !showModifierGroups ||
                          (requiredModifiersCount == 0 &&
                            modifiersSelectedCount == 0) ||
                          (showModifierGroups &&
                            requiredModifiersSelectedCount !=
                              requiredModifiersCount)
                            ? setShowModifierGroups(!showModifierGroups)
                            : false
                        }
                        variant='link'
                      >
                        <div className='fw-bold flex-grow-1'>
                          {requiredModifiersCount > 0 ? (
                            <span>
                              {content.kiosk.selectRequiredText?.replace(
                                '##quantityRequired##',
                                String(requiredModifiersCount)
                              )}
                            </span>
                          ) : (
                            <span>{content.kiosk.selectOptionalExtras}</span>
                          )}
                        </div>
                      </Button>
                    </div>
                    <Accordion.Collapse in={true} eventKey='0'>
                      <Row className='g-0'>
                        <Col
                          md={{ span: 12 }}
                          className='modifier-groups text-start p-1'
                        >
                          {requiredModifiersCount > 0 && (
                            <div
                              className={classnames(
                                'text-center justify-content-center align-items-center d-flex required-selection',
                                requiredModifiersSelectedCount >=
                                  requiredModifiersCount && 'valid'
                              )}
                            >
                              {requiredModifiersSelectedCount >=
                                requiredModifiersCount && (
                                <RadioSelectedSvg className='valid' />
                              )}
                              {content.kiosk.selectedOfRequiredText
                                ?.replace(
                                  '##quantitySelected##',
                                  String(requiredModifiersSelectedCount)
                                )
                                .replace(
                                  '##quantityRequired##',
                                  String(requiredModifiersCount)
                                )}
                            </div>
                          )}
                          <div
                            className={
                              concession.isSoldOut ? 'disabled' : undefined
                            }
                          >
                            {concession.modifierGroups?.map(
                              (mg: ModifierGroup) =>
                                mg.isSmart
                                  ? multiSmartModifierGroupRow(mg)
                                  : multiModifierGroupRow(mg)
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Accordion.Collapse>
                  </div>
                  <Accordion.Collapse in={true} eventKey='0'>
                    <Row className='modified-concession-summary'>
                      <Col
                        md={{ span: 12, offset: 0 }}
                        xs={{ span: 8, offset: 2 }}
                      >
                        {concessionQuantitySelector(concession.isSoldOut, true)}
                      </Col>
                    </Row>
                  </Accordion.Collapse>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConcessionItemModal;
