import { useRef, useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SelectSeatsModel } from '../@types/modelTypes';
import { PEACH_CODES } from '../constants';
import backend from '../services/RestUtilities';
import { actionCreators } from '../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectSelectedSeats,
  selectToken,
  selectDynamicSeatingEnabled,
} from '../store/Selectors';

export const useDynamicSeating = () => {
  const dispatch = useDispatch();
  const selectedSeats = useSelector(selectSelectedSeats);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const dynamicSeatingEnabled = useSelector(selectDynamicSeatingEnabled);
  const token = useSelector(selectToken);
  const tokenRef = useRef<string>(token);

  useEffect(() => {
    // we store the token in a ref to avoid retriggering the effect
    if (tokenRef.current !== token && dynamicSeatingEnabled) {
      tokenRef.current = token;
    }
  }, [dynamicSeatingEnabled, token]);

  const sendSeatsToBackend = useCallback(async () => {
    if (selectedSeats.length > 0) {
      dispatch(actionCreators.setLoading(true));
      const data = {
        selectedSeats: selectedSeats,
        dataToken: tokenRef.current,
        useDynamicSeating: true,
      };
      const response = await backend.post('api/Seats/Dynamic', data);
      if (response.ok) {
        if (response.content.peachCode === PEACH_CODES.noError) {
          dispatch(actionCreators.setToken(response.content.dataToken));
          if (config?.enableCountDown) {
            dispatch(
              actionCreators.setCountDown(response.content.secondsToExpiration)
            );
          }
        } else if (
          response.content.peachCode ===
          PEACH_CODES.seatsUnavailableRefreshLayout
        ) {
          dispatch(
            actionCreators.setError(
              content?.error.seatsAlreadyTakenRichText,
              PEACH_CODES.seatsUnavailableRefreshLayout
            )
          );
          dispatch(
            actionCreators.setSeatsModel(
              response.content.selectSeatsModel as SelectSeatsModel
            )
          );
          dispatch(actionCreators.removeAllSeats());
        } else if (
          response.content.peachCode === PEACH_CODES.chooseOtherSeats
        ) {
          dispatch(
            actionCreators.setError(
              content?.error.seatsAlreadyTakenRichText,
              PEACH_CODES.chooseOtherSeats
            )
          );
          dispatch(actionCreators.removeAllSeats());
        }
      }
      dispatch(actionCreators.setLoading(false));
    }
  }, [
    config,
    content?.error.seatsAlreadyTakenRichText,
    dispatch,
    selectedSeats,
  ]);

  useEffect(() => {
    if (dynamicSeatingEnabled) {
      sendSeatsToBackend();
    }
  }, [dynamicSeatingEnabled, sendSeatsToBackend]);
};
