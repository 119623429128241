/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, {
  useEffect,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';

import classNames from 'classnames';
import { Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { StoredCardPaymentInfo } from './StoredCardPaymentTypes';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { PEACH_CODES } from '../../../../../constants';
import { getCardExpiryDateString } from '../../../../../services/Helpers';
import backend from '../../../../../services/RestUtilities';
import {
  selectContent,
  selectToken,
  selectBookingData,
} from '../../../../../store/Selectors';
import CheckBoxButton from '../../../checkboxbutton/CheckBoxButton';
import CollapsiblePaymentProviderContainer from '../cardpayments/CollapsiblePaymentProviderContainer';
import PaymentIconSingle from '../cardpayments/paymenticons/PaymentIconSingle';

interface Props {
  setSelectedStoredCardTokenIdentifier: (
    selectedStoredCardTokenIdentifier: string | null
  ) => void;
  shouldStoreCardToken: boolean;
  setShouldStoreCardToken: (shouldStoreCardToken: boolean) => void;
  paymentFormHasValidationError?: boolean;
  paymentProvider: PaymentProvidersEnum;
  handleNewPaymentFormShow?: () => void;
  handleShouldStoreCardTokenChanged?: (shouldStoreCardToken: boolean) => void;
  shouldStoreCardTokenCheckboxDisabled?: boolean;
}

const StoredCardPayment: React.FC<PropsWithChildren<Props>> = ({
  setSelectedStoredCardTokenIdentifier,
  children,
  shouldStoreCardToken,
  setShouldStoreCardToken,
  paymentFormHasValidationError,
  paymentProvider,
  handleNewPaymentFormShow,
  handleShouldStoreCardTokenChanged,
  shouldStoreCardTokenCheckboxDisabled,
}) => {
  const dataToken = useSelector(selectToken);
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  const [showNewCardPayment, setShowNewCardPayment] = useState<boolean>(false);

  const [storedCards, setStoredCards] = useState<
    StoredCardPaymentInfo[] | undefined
  >(undefined);
  const [selectedCard, setSelectedCard] = useState<
    StoredCardPaymentInfo | undefined
  >(undefined);

  const [showRemoveCardExpando, setShowRemoveCardExpando] =
    useState<boolean>(false);

  const getStoredCardInfo = useCallback(async () => {
    const data = {
      paymentProvider,
      dataToken: dataToken,
    };
    const response = await backend.post(
      'api/Payment/GetMemberInfoPaymentsForPaymentProvider',
      data
    );
    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      const listInfoPayment: StoredCardPaymentInfo[] =
        response.content.listInfoPayment;

      if (listInfoPayment.length) {
        setShowNewCardPayment(false);
      }
      setStoredCards(
        listInfoPayment.map((storedCardPaymentInfo) => {
          const result: StoredCardPaymentInfo = {
            cardNumber: storedCardPaymentInfo.cardNumber,
            idPayment: storedCardPaymentInfo.idPayment,
            tokenIdentifier: storedCardPaymentInfo.tokenIdentifier,
            paymentMethod: storedCardPaymentInfo.paymentMethod,
            expirationCard: storedCardPaymentInfo.expirationCard,
            expirationToken: storedCardPaymentInfo.expirationToken,
          };
          return result;
        })
      );
    } else {
      setStoredCards([]);
    }
  }, [dataToken, paymentProvider]);

  const deleteStoredCardById = useCallback(
    async (storedCardTokenIdentifier: string) => {
      const data = {
        paymentProvider: paymentProvider,
        dataToken: dataToken,
        tokenIdentifier: storedCardTokenIdentifier,
      };
      await backend.post('api/Payment/DeleteMemberInfoPayment', data);
    },
    [dataToken, paymentProvider]
  );

  const setSelectedStoredCardAndStoredCardTokenIdentifier = (
    selectedStoredCard: StoredCardPaymentInfo | undefined
  ) => {
    setSelectedCard(selectedStoredCard);
    setSelectedStoredCardTokenIdentifier(
      selectedStoredCard?.tokenIdentifier ?? null
    );
  };

  const displayCardLastFour = (
    storedCardPaymentInfo: StoredCardPaymentInfo
  ) => {
    const cardLastFour = (cardNumber: string) => {
      return cardNumber.substring(cardNumber.length - 4);
    };
    return (
      <div className='tiny'>
        <span className='fw-bold ml'>&bull;&bull;&bull;&bull;</span>
        <span className='spaced ml'>
          {cardLastFour(storedCardPaymentInfo.cardNumber)}
        </span>
      </div>
    );
  };

  const displayCardExpiry = (storedCardPaymentInfo: StoredCardPaymentInfo) => {
    const cardExpDateTime = (cardExpDateString: string) => {
      const date = new Date(cardExpDateString);
      return getCardExpiryDateString(date);
    };
    return (
      <div className='tiny ml'>
        <span>{content.payment.storedCardPaymentExpirationLabel}: </span>
        <span className='spaced'>
          {cardExpDateTime(storedCardPaymentInfo.expirationCard)}
        </span>
      </div>
    );
  };

  const toggleShowPayWithNewCard = (show: boolean) => {
    if (show) {
      handleNewPaymentFormShow && handleNewPaymentFormShow();
    }

    setSelectedStoredCardAndStoredCardTokenIdentifier(undefined);
    setShowRemoveCardExpando(false);
    setShowNewCardPayment(show);
  };

  const toggleSelectedCard = (selectedStoredCard: StoredCardPaymentInfo) => {
    if (selectedCard === selectedStoredCard) {
      setSelectedStoredCardAndStoredCardTokenIdentifier(undefined);
    } else {
      setShowNewCardPayment(false);
      setSelectedStoredCardAndStoredCardTokenIdentifier(selectedStoredCard);
    }
    setShowRemoveCardExpando(false);
  };

  const handleRemoveCardConfirmClicked = (
    storedCardToDelete: StoredCardPaymentInfo
  ) => {
    const remainingStoredCards =
      storedCards?.filter(
        (x) => x.tokenIdentifier !== storedCardToDelete.tokenIdentifier
      ) ?? [];

    if (selectedCard?.tokenIdentifier === storedCardToDelete.tokenIdentifier) {
      setSelectedStoredCardAndStoredCardTokenIdentifier(undefined);
    }

    setStoredCards(remainingStoredCards);
    deleteStoredCardById(storedCardToDelete.tokenIdentifier);
  };

  // Loads stored cards for member
  useEffect(() => {
    if (bookingData && bookingData.isUserValidated && !!paymentProvider) {
      getStoredCardInfo();
    }
  }, [bookingData, getStoredCardInfo, paymentProvider]);

  // on validation error, show new card form
  useEffect(() => {
    if (paymentFormHasValidationError && !selectedCard) {
      setShowNewCardPayment(true);
    }
  }, [paymentFormHasValidationError, selectedCard]);

  return (
    <div
      className={classNames(
        'stored-card-payment',
        PaymentProvidersEnum[paymentProvider]
      )}
    >
      <div className='p-0 stored-card-payment-item-rows'>
        <div className='p-0'>
          {storedCards && storedCards.length > 0
            ? content.payment.storedCardPaymentSelectSavedCardText
            : content.payment.storedCardPaymentNoSavedCardsText}
        </div>
        {storedCards &&
          storedCards.map((storedCard) => {
            const isChecked = storedCard === selectedCard;
            return (
              <div
                key={storedCard.idPayment}
                className={classNames(
                  'stored-card-payment-item-row p-1 mt-1',
                  isChecked && 'glow'
                )}
                onClick={() => toggleSelectedCard(storedCard)}
              >
                <div className='d-flex flex-row justify-content-between'>
                  <div className='d-flex align-items-center card-details'>
                    <PaymentIconSingle
                      creditCardType={storedCard.paymentMethod.toLowerCase()}
                      isActive={true}
                    />
                    {displayCardLastFour(storedCard)}
                    {displayCardExpiry(storedCard)}
                  </div>

                  <div className='d-flex align-items-center'>
                    {isChecked && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRemoveCardExpando(!showRemoveCardExpando);
                        }}
                        variant='link'
                        className='remove-button'
                        sx={{
                          color: 'uiError',

                          '&:hover': {
                            color: 'uiError',
                          },
                        }}
                      >
                        -
                        <span className='text-uppercase tiny'>
                          {
                            content.payment
                              .storedCardPaymentRemoveCardButtonText
                          }
                        </span>
                        -
                      </Button>
                    )}
                  </div>
                  <CheckBoxButton checked={isChecked} smaller />
                </div>
                {isChecked && showRemoveCardExpando && (
                  <div className='d-flex flex-row mt-1'>
                    <Collapse
                      in={showRemoveCardExpando}
                      className='bordered contained g-0'
                    >
                      <div>
                        <div className='d-flex align-items-center justify-content-center'>
                          {
                            content.payment
                              .storedCardPaymentRemoveCardConfirmationText
                          }
                        </div>

                        <div className='d-flex flex-row mt-2'>
                          <Button
                            className='contained justify-content-center me-1'
                            variant='danger'
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRemoveCardExpando(false);
                              handleRemoveCardConfirmClicked(storedCard);
                            }}
                          >
                            {
                              content.payment
                                .storedCardPaymentRemoveCardConfirmationYesButtonText
                            }
                          </Button>
                          <Button
                            className='contained justify-content-center'
                            variant='secondary'
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRemoveCardExpando(false);
                            }}
                          >
                            {
                              content.payment
                                .storedCardPaymentRemoveCardConfirmationNoButtonText
                            }
                          </Button>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <CollapsiblePaymentProviderContainer
        show={showNewCardPayment}
        setShow={() => toggleShowPayWithNewCard(!showNewCardPayment)}
        headingContent={
          <div className='text-uppercase h3 text-start'>
            {content.payment.storedCardPaymentPayWithNewCardHeading}
          </div>
        }
        className='mt-3'
        hasOnePaymentOption={false}
        paymentProvider={paymentProvider}
        isShownClassName='glow'
      >
        {children}

        <div className='p-1 d-flex align-items-center'>
          <div>
            <CheckBoxButton
              checked={shouldStoreCardToken}
              disabled={shouldStoreCardTokenCheckboxDisabled}
              onClick={() => {
                const newValue = !shouldStoreCardToken;
                setShouldStoreCardToken(newValue);
                handleShouldStoreCardTokenChanged &&
                  handleShouldStoreCardTokenChanged(newValue);
              }}
              smaller
            />
          </div>
          <div>
            <label htmlFor='stored-card-save-new-card'>
              {content.payment.storedCardPaymentSaveNewCardText}
            </label>
          </div>
        </div>
      </CollapsiblePaymentProviderContainer>
    </div>
  );
};

export default StoredCardPayment;
