import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectConfig } from '../../../store/Selectors';
import Heading from '../heading/Heading';

const GMAP_BASE_URL = 'https://www.google.com/maps/embed/v1/place';
const GMAP_ZOOM = 17;

interface Props {
  title?: string;
}

const GoogleMap: React.FC<Props> = ({ title }) => {
  const config = useSelector(selectConfig);

  const googleMapSrc =
    config.currentCinema.gmapsPlaceName && config.gmapsApikey
      ? `${GMAP_BASE_URL}?q=${encodeURIComponent(
          config.currentCinema.gmapsPlaceName
        )}&zoom=${GMAP_ZOOM}&key=${config.gmapsApikey}`
      : '';

  if (!googleMapSrc) return null;

  return (
    <Row data-testid='gmap'>
      <Col xs={12} className='contained'>
        {!!title && (
          <Heading className='mt-5' size={2}>
            {title}
          </Heading>
        )}
        <div className='mt-3'>
          <iframe
            title='Map'
            style={{ height: '280px', width: '100%', border: '0 none' }}
            src={googleMapSrc}
          />
        </div>
      </Col>
    </Row>
  );
};

export default GoogleMap;
