/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState, ChangeEvent, useRef } from 'react';

import { Col, Row, Form, Overlay, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../../@types/modelTypes';
import {
  selectBookingData,
  selectContent,
  selectCurrencySymbol,
  selectDisplayPrice,
} from '../../../../store/Selectors';

interface Props {
  grandTotal: number;
  customAmount: string;
  setCustomAmount: React.Dispatch<React.SetStateAction<string>>;
}

const CustomLoyaltyAmountInput: React.FC<Props> = ({
  grandTotal,
  customAmount,
  setCustomAmount,
}) => {
  const sessionCurrencySymbol = useSelector(selectCurrencySymbol);
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  const [showTooltip, setShowTooltip] = useState(false);
  const inputRef = useRef(null);

  const maxAmount = Math.min(grandTotal, bookingData.loyaltyCardBalance);
  const maxAmountToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, maxAmount)
  );

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numberPatternWithUpToTwoDecimals = '^\\d*\\.?\\d{0,2}$';
    const customLoyaltyRegex = new RegExp(numberPatternWithUpToTwoDecimals);

    if (value === '.') {
      setCustomAmount('0.');
    } else if (customLoyaltyRegex.test(value)) {
      const numberValue = value === '' ? 0 : parseFloat(value);
      const capValue = Math.min(numberValue, maxAmount / 100);
      setShowTooltip(numberValue > maxAmount / 100);
      setCustomAmount(
        capValue !== numberValue ? String(capValue.toFixed(2)) : value
      );
    }
  };

  return (
    <Row>
      <Col xs={{ span: 6, offset: 3 }}>
        <Form.Label>
          {content.payment.customLoyaltyValueToApplyLabel}
        </Form.Label>
        <div className='custom-loyalty-wrapper'>
          <span
            className='currency-symbol'
            sx={{
              borderLeft: 'mostReadableOnWebsiteBackgroundBorder',
              borderBottom: 'mostReadableOnWebsiteBackgroundBorder',
              borderTop: 'mostReadableOnWebsiteBackgroundBorder',
              backgroundColor: 'boxBackground',
            }}
          >
            {sessionCurrencySymbol}
          </span>
          <Form.Control
            className='custom-loyalty-input'
            aria-label={content.payment.customLoyaltyValueToApplyLabel}
            ref={inputRef}
            value={customAmount}
            onChange={handleInputChange}
            placeholder={content.payment.customLoyaltyValueInputPlaceholder}
          />
        </div>

        <Overlay placement='top' show={showTooltip} target={inputRef.current}>
          <Tooltip
            data-testid='custom-loyalty-amount-tooltip'
            id='maxAmountLimitReached'
          >
            {`${content.payment.customLoyaltyValueTooltipText} ${maxAmountToDisplay}`}
          </Tooltip>
        </Overlay>
      </Col>
    </Row>
  );
};

export default CustomLoyaltyAmountInput;
