import React, { useRef, useState } from 'react';

import classnames from 'classnames';
import { Row, Col, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import {
  ModalButtonContext,
  QuantitySelectorContext,
} from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  Modifier,
  ModifierGroup,
} from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import { splitDeliveryItemFromConcessions } from '../../../services/GiftCardHelpers';
import {
  displayPrice,
  getMatchingConcessionDealDetails,
  handleAddRemoveConcessionDynamicBasket,
  handleConcessionQuantityChange,
  handleUpdateConcessionsDynamicBasket,
} from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectFaBConcessions,
  selectConfig,
  selectCurrencyConfig,
  selectDeals,
  selectSelectedDeliveryWindow,
  selectToken,
  selectTotalFaBItemsSelected,
  selectJourneyTypeConfig,
  selectKioskSubStep,
  selectContent,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../svgs/deal.svg';
import { ReactComponent as SummaryCloseSvg } from '../../../svgs/summaryClose.svg';
import { ReactComponent as SummaryEditSvg } from '../../../svgs/summaryEdit.svg';
import ActionButton from '../actionbutton/ActionButton';
import ConcessionItemModal from '../kiosk/ConcessionItemModal';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  hideTax: boolean;
  pathname: string;
}

const CartSummaryFaBConcessions: React.FC<Props> = ({ hideTax, pathname }) => {
  const dispatch = useDispatch();
  const concessionModalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const concessions = useSelector(selectFaBConcessions);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const config = useSelector(selectConfig);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const deals = useSelector(selectDeals);
  const selectedConcessionCount = useSelector(selectTotalFaBItemsSelected);
  const dataToken = useSelector(selectToken);
  const bookingData = useSelector(selectBookingData);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const kioskSubStep = useSelector(selectKioskSubStep);
  const [concessionToEdit, setConcessionToEdit] = useState<
    Concession | undefined
  >(undefined);
  const [concessionIndex, setConcessionIndex] = useState<number>(0);

  const canEdit =
    (pathname.startsWith('/kiosk/') && kioskSubStep === 'fab') ||
    pathname.startsWith('/giftcards/select') ||
    pathname.startsWith('/giftmembership/select') ||
    pathname.startsWith('/physicalgiftcards/select') ||
    pathname.startsWith('/concessions/select');

  const toggleEdit = (c: Concession, i: number) => {
    setConcessionIndex(i);
    if (c.modifierGroups && c.modifierGroups.length > 0) {
      setConcessionToEdit(c);
      setModal();
    } else {
      setConcessionToEdit(concessionToEdit === c ? undefined : c);
    }
  };

  const handleConcessionChange = async (
    context: QuantitySelectorContext,
    concession: Concession | undefined,
    concessionIndex: number
  ) => {
    const concessionToChange: Concession = JSON.parse(
      JSON.stringify(concession)
    );
    concessionToChange.quantity = 1;
    if (config.useDynamicBasket) {
      dispatch(actionCreators.setLoading(true));
      await handleAddRemoveConcessionDynamicBasket(
        context,
        concessionToChange,
        dataToken,
        journeyTypeConfig.type,
        selectedDeliveryWindow,
        bookingData,
        dispatch,
        content
      );
      dispatch(actionCreators.setLoading(false));
    }

    if (context == 'add') {
      handleConcessionQuantityChange(
        'add',
        concessionToChange,
        concessionIndex,
        dispatch,
        true
      );
    } else {
      handleConcessionQuantityChange(
        'remove',
        concessionToChange,
        concessionIndex,
        dispatch,
        true
      );
    }
  };

  const getEditRow = (c: Concession, ci: number) => {
    return (
      <Row className='concessions-item-edit g-0'>
        <Col className='d-flex justify-content-end'>
          <div className='d-flex align-items-center quantity-selector mt-1'>
            <QuantityButton
              context='remove'
              disabled={c.quantity <= 0}
              onClick={() => handleConcessionChange('remove', c, ci)}
              size='tiny'
            />
            <div className='flex-grow-1 text-center'>{c.quantity}</div>
            <QuantityButton
              context='add'
              disabled={isAddDisabled()}
              onClick={() => handleConcessionChange('add', c, ci)}
              size='tiny'
            />
          </div>
        </Col>
      </Row>
    );
  };

  const isAddDisabled = () => {
    if (selectedConcessions == null) {
      return false;
    }
    const maxQuantity =
      journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY
        ? config.giftCardsPurchase.maxQuantityOfGiftCards
        : journeyTypeConfig.usesPhysicalGiftCardsConfigs
        ? config.giftCardsPurchase.maxQuantityOfPhysicalGiftCards
        : 1000;
    return selectedConcessionCount >= maxQuantity;
  };

  const getDescription = (c: Concession) => {
    if (journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY) {
      return `${displayPrice(c.cost, currencyConfig)} ${
        content.giftCards.summaryGiftCardLabel
      }`;
    } else {
      return c.description;
    }
  };

  const concessionWithModifiers = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    return (
      <>
        <Row className='concession-item g-0'>
          <Col className='text-start pe-1'>{getDescription(c)}</Col>
          <Col xs={1} className={classnames('text-center tickets-quantity')}>
            {c.quantity}
          </Col>
          <Col xs={2} className='text-end price'>
            {displayPrice(
              c.quantity *
                (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
              currencyConfig
            )}
          </Col>
        </Row>
        {c.modifiers?.map((m: Modifier, mi: number) => (
          <Row key={`c_${c.id}_${ci}_m_${m.id}_${mi}`}>
            <Col xs={12} className='text-start'>
              {'- '}
              {m.description}
              {m.quantity > 1 && <span> x {m.quantity}</span>}
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const concessionWithModifierGroups = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    const isEditing = canEdit && concessionToEdit === c;
    const matchedConcessionDeal = getMatchingConcessionDealDetails(c.id, deals);
    return (
      <>
        <Row className='concession-item g-0'>
          <Col className='text-start pe-1 d-flex align-items-center'>
            {matchedConcessionDeal && <DealSvg className='deal-icon' />}
            {getDescription(c)}
          </Col>
          <Col
            xs={2}
            className={classnames(
              'edit-col d-flex justify-content-center align-items-top',
              (canEdit && !c.isDeliveryItem) || 'invisible'
            )}
            onClick={() => toggleEdit(c, ci)}
          >
            <Button className='close-button text-uppercase tiny' variant='link'>
              {isEditing && c.modifierGroups.length == 0
                ? content.cartSummary.closeButtonText
                : content.cartSummary.editButtonText}
            </Button>
            {isEditing && c.modifierGroups.length == 0 ? (
              <SummaryCloseSvg className='icon d-flex align-items-center' />
            ) : (
              <SummaryEditSvg className='icon d-flex align-items-center' />
            )}
          </Col>
          <Col
            xs={1}
            className={classnames(
              'text-center tickets-quantity',
              isEditing && c.modifierGroups.length == 0 && 'invisible'
            )}
          >
            {c.quantity}
          </Col>
          <Col xs={2} className='text-end price'>
            {displayPrice(
              c.quantity *
                (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
              currencyConfig
            )}
          </Col>
        </Row>
        {c.modifierGroups?.map((mg: ModifierGroup, mgi: number) => (
          <Row key={`c_${c.id}_${ci}_mg_${mg.id}_${mgi}`}>
            {mg.modifiers
              .filter((x: Modifier) => x.quantity > 0)
              .map((m: Modifier, mi: number) => (
                <Col
                  xs={12}
                  className='text-start'
                  key={`c_${c.id}_${ci}_mg_${mg.id}_${mgi}_m_${m.id}_${mi}`}
                >
                  {'- '}
                  {m.description}
                  {mg.maximumQuantity > 1 && m.quantity > 0 && (
                    <span> x {m.quantity}</span>
                  )}
                </Col>
              ))}
          </Row>
        ))}
        {isEditing && c.modifierGroups.length == 0 && getEditRow(c, ci)}
      </>
    );
  };

  if (
    selectedConcessions === null ||
    selectedConcessions?.list === null ||
    !selectedConcessions?.list?.some((x: Concession) => x.quantity > 0)
  ) {
    return null;
  }

  const { listWithNoDeliveryItem } = splitDeliveryItemFromConcessions(
    concessions,
    selectedConcessions
  );

  const setModal = () => {
    setShowModal(true);
  };

  const handleModalButtons = async (context: ModalButtonContext) => {
    if (context === 'remove') {
      if (concessionToEdit) {
        dispatch(
          actionCreators.removeConcessionWithMods(concessionToEdit, true, true)
        );
        if (config.useDynamicBasket) {
          dispatch(actionCreators.setLoading(true));
          await handleUpdateConcessionsDynamicBasket(
            context,
            selectedConcessions.list,
            dataToken,
            journeyTypeConfig.type,
            selectedDeliveryWindow,
            bookingData,
            dispatch,
            content
          );

          dispatch(actionCreators.setLoading(false));
        }
      }
    }

    if (concessionToEdit && concessionIndex) {
      toggleEdit(concessionToEdit, concessionIndex);
    }

    setShowModal(false);
  };

  return (
    <div className='cart-summary-row'>
      {listWithNoDeliveryItem
        .filter((c: Concession) => c.quantity > 0)
        .map((c: Concession, ci: number) => (
          <div
            className='concessions-item-list'
            data-testid='cs-concessions'
            key={`c_${c.id}_${ci}`}
          >
            {c.modifierGroups
              ? concessionWithModifierGroups(c, hideTax, ci)
              : concessionWithModifiers(c, hideTax, ci)}
          </div>
        ))}
      <Modal
        show={showModal}
        onHide={() => handleModalButtons('close')}
        centered
        className='layout-modal small-buttons'
        backdrop='static'
        keyboard={false}
        ref={concessionModalRef}
      >
        <Modal.Header>
          {concessionToEdit && (
            <Modal.Title>{concessionToEdit.description}</Modal.Title>
          )}
          <Button
            onClick={() => handleModalButtons('close')}
            className='btn-close'
            variant='text'
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className='layout'>
            {concessionToEdit && (
              <ConcessionItemModal
                {...concessionToEdit}
                concessionIndex={concessionIndex}
                closeModal={() => handleModalButtons('close')}
                key={concessionToEdit.id}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ActionButton
            variant='primary'
            onClick={() => handleModalButtons('remove')}
            mb='mb-0'
            mt='mt-0'
          >
            {content.kiosk.kioskModalRemove}
          </ActionButton>
          <ActionButton
            variant='secondary'
            onClick={() => handleModalButtons('close')}
            mb='mb-0'
            mt='mt-0'
          >
            {content.kiosk.kioskModalCancel}
          </ActionButton>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CartSummaryFaBConcessions;
