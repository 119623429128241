import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import VoucherBreakDown from './VoucherBreakDown';

import { GlobalState } from '../../../../@types/modelTypes';
import { displayPrice } from '../../../../services/Helpers';
import {
  selectAppliedVouchers,
  selectContent,
  selectCurrencyConfig,
  selectDisplayPrice,
  selectGrandTotalAfterDiscountsInCents,
  selectTandCsAccepted,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';

interface Props {
  isFormValid: boolean;
  voucherCoversFullPayment: boolean;
  setPaymentFormVisibility: (isVisible: boolean) => void;
  isPageValidated: boolean;
  handleMakeVoucherPaymentClick: () => void;
}

const VoucherPaymentForm: React.FC<Props> = ({
  isFormValid,
  voucherCoversFullPayment,
  setPaymentFormVisibility,
  isPageValidated,
  handleMakeVoucherPaymentClick,
}) => {
  const appliedVouchers = useSelector(selectAppliedVouchers);
  const content = useSelector(selectContent);
  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, grandTotal)
  );
  const submitButtonText = `${content.payment.submitText} ${priceToDisplay}`;

  useEffect(() => {
    if (!appliedVouchers.length) return;

    if (voucherCoversFullPayment) {
      setPaymentFormVisibility(false);
    } else {
      setPaymentFormVisibility(true);
    }
  }, [
    appliedVouchers.length,
    setPaymentFormVisibility,
    voucherCoversFullPayment,
  ]);

  const isPaymentValid = isPageValidated && isFormValid && isTandCsAccepted;

  if (!appliedVouchers?.length) return null;

  return (
    <>
      <div className='mt-2'>
        {appliedVouchers?.length > 0 && (
          <p>
            {content.payment.voucherAppliedText}{' '}
            <b>
              {displayPrice(
                appliedVouchers[appliedVouchers.length - 1].totalDiscount,
                currencyConfig
              )}
            </b>{' '}
            {content.payment.voucherAppliedPartTwoText}
          </p>
        )}
      </div>

      <VoucherBreakDown grandTotal={grandTotal} />

      {appliedVouchers?.length > 0 && !voucherCoversFullPayment && (
        <div className='info-container mt-2 p-2 text-center'>
          <p>{content.payment.voucherRemainingBalanceText}</p>
        </div>
      )}

      {voucherCoversFullPayment && (
        <ActionButton
          onClick={handleMakeVoucherPaymentClick}
          showIcon
          mb={'mb-1'}
          warningMessage={content.payment.formErrorsMessage}
          warningTitle={content.payment.formErrorsSubTitle}
          showWarningMessage={!isPaymentValid}
          disabled={!isPaymentValid}
          variant='primary'
        >
          {submitButtonText}
        </ActionButton>
      )}
    </>
  );
};

export default VoucherPaymentForm;
