import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import DeliveryChargeOption from './DeliveryChargeOption';

import { Concession } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectDeliveryItemId,
  selectAllConcessions,
} from '../../../store/Selectors';
import Heading from '../heading/Heading';
import RichText from '../richtext/RichText';

const DeliveryOptionsSelector: React.FC = () => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const concessions = useSelector(selectAllConcessions);
  const deliveryItemId = useSelector(selectDeliveryItemId);
  if (!content) {
    return null;
  }

  const handleClick = (c: Concession) => {
    if (c.id !== deliveryItemId) {
      dispatch(actionCreators.changeDeliveryItem(c, 'change'));
    }
  };

  if (!concessions || concessions[0].deliveryItems.length === 1) return null;
  return (
    <div className='delivery-charge-options'>
      <Heading size={2} className='mt-5 text-center'>
        {content.concessionsOnlyJourney.postageOptionsHeading}
      </Heading>
      <RichText
        className='mb-3'
        text={content.concessionsOnlyJourney.postageOptionsRichText}
      />
      {concessions[0].deliveryItems.map((c: Concession) => (
        <DeliveryChargeOption
          key={c.id}
          concession={c}
          selected={c.id === deliveryItemId}
          onClick={handleClick}
        />
      ))}
    </div>
  );
};

export default DeliveryOptionsSelector;
