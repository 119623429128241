/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const SeatsContainer: React.FC<Props> = ({ children }) => {
  return (
    <div
      className='seats'
      data-testid='seats'
      sx={{
        '.seat-button, .seating-legend': {
          '.seat-id': {
            color: 'mostReadableOnWebsiteBackground',

            '&.available': {
              color: 'mostReadableOnSeatAvailable',
            },
            '&.unavailable': {
              color: 'mostReadableOnSeatUnvailable',
            },
            '&.selected': {
              color: 'mostReadableOnSeatSelected',
            },
          },

          '.seat-background': {
            fill: 'websiteBackground',
          },
          '.seat-border': {
            fill: 'mostReadableOnWebsiteBackground',
          },
          '.seat-icon': {
            fill: 'mostReadableOnWebsiteBackground',
          },

          '.available': {
            '.seat-background': {
              fill: 'seatAvailable',
            },
            '.seat-border': {
              fill: 'mostReadableOnSeatAvailable',
            },
            '.seat-icon': {
              fill: 'mostReadableOnSeatAvailable',
            },
            '&.recliner': {
              '.seat-border': {
                fill: 'mostReadableOnSeatAvailable',
              },
            },
          },

          '.unavailable': {
            '.seat-background': {
              fill: 'seatUnavailable',
            },
            '.seat-border': {
              fill: 'seatUnavailableBorder',
            },
            '.seat-icon': {
              fill: 'mostReadableOnSeatUnvailable',
            },
          },

          '.selected': {
            '.seat-background': {
              fill: 'seatSelected',
            },
            '.seat-border': {
              fill: 'seatSelectedBorder',
            },
            '.seat-icon': {
              fill: 'mostReadableOnSeatSelected',
            },
          },
        },
      }}
    >
      {children}
    </div>
  );
};

export default SeatsContainer;
