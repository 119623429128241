import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import PhysicalConcessionRecipientDetails from './RecipientDetailsPhysical';

import { selectContentPhysicalGiftcards } from '../../../../store/Selectors';
import CustomerDetails from '../../customerdetails/CustomerDetails';
import Heading from '../../heading/Heading';
import RichText from '../../richtext/RichText';
import DeliveryOptionsSelector from '../DeliveryOptionsSelector';

interface Props {
  isPageValidated: boolean;
}

const DeliveryDetails: React.FC<Props> = ({ isPageValidated }) => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);

  if (!contentPhysicalGiftCards) {
    return null;
  }

  return (
    <Row className='delivery-details'>
      <Col xs={12} className='contained'>
        <Heading size={2} className='text-center mt-5'>
          2. {contentPhysicalGiftCards.recipientDetailsHeading}
        </Heading>
        <RichText
          className='mb-3'
          text={contentPhysicalGiftCards.recipientDetailsRichText}
        />
        <PhysicalConcessionRecipientDetails isPageValidated={isPageValidated} />
        <DeliveryOptionsSelector />
        <Heading size={2} className='mt-5 text-center'>
          3. {contentPhysicalGiftCards.billingDetailsHeading}
        </Heading>
        <RichText text={contentPhysicalGiftCards.billingDetailsRichText} />
        <CustomerDetails isPageValidated={isPageValidated} />
      </Col>
    </Row>
  );
};

export default DeliveryDetails;
