/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectContent } from '../../../store/Selectors';
import FooterPrivacySettings from '../footerPrivacySettings/FooterPrivacySettings';
import Logo from '../logo/Logo';

const Footer: React.FC = () => {
  const content = useSelector(selectContent);

  if (!content) return null;

  return (
    <Row
      className='footer text-center justify-content-md-center'
      data-testid='footer'
      sx={{
        backgroundColor: 'footerBackground',
        '.a': {
          color: 'mostReadableOnFooterBackground',
        },
      }}
    >
      <Col lg={6}>
        <footer>
          <Logo footer />
          <FooterPrivacySettings />
          <div
            className='footer-copy mt-2'
            sx={{
              borderTop: 'footerSeparator',
              color: 'mostReadableOnFooterBackground',
            }}
          >
            <p>
              &copy; {content.title} {moment(new Date()).format('YYYY')}
            </p>
          </div>
        </footer>
      </Col>
    </Row>
  );
};

export default Footer;
