import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Concession, GlobalState } from '../../../../@types/modelTypes';
import {
  selectContentPhysicalGiftcards,
  selectDisplayPrice,
} from '../../../../store/Selectors';
import GiftCardImage from '../GiftCardImage';

interface GiftCardsRowProps {
  concession: Concession;
}

const PaymentSummaryRowPhysical: React.FC<GiftCardsRowProps> = ({
  concession,
}) => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, concession.cost * concession.quantity)
  );

  const imagePath = concession?.image
    ? concession.image.replace('https://', '')
    : contentPhysicalGiftCards.physicalGiftCardDefaultImage;

  if (!concession || concession.quantity < 1) return null;

  const getDescription = () => {
    return `${concession.description} x ${concession.quantity}`;
  };

  return (
    <div className='giftcards-row'>
      <Row className='g-0 d-flex align-items-center py-3'>
        <Col xs={2} className='image-container me-2'>
          <GiftCardImage imageUrl={imagePath} isPhysicalGc />
        </Col>
        <Col className='text-start summary-item fw-bold'>
          {getDescription()}
        </Col>
        <Col xs={'auto'} className='text-end ps-3 summary-item fw-bold'>
          <span>{priceToDisplay}</span>
        </Col>
      </Row>
    </div>
  );
};

export default PaymentSummaryRowPhysical;
