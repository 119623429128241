import React, { ReactElement, useEffect, useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Concession, ConcessionGrouping } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { getRouteFromStep } from '../../../services/JourneyService';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectSelectedFaBConcessions,
  selectStep,
  selectJourneyTypeConfig,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import TierItem from '../../common/loyalty/TierItem';
import RichText from '../../common/richtext/RichText';

const SelectSignupTier: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies();
  const availableConcessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const step: number = useSelector(selectStep);
  const [freeConcessionSelected, setFreeConcessionSelected] =
    useState<boolean>(false);

  const hasConcessions = availableConcessions?.some(
    (x: ConcessionGrouping) => !!x.items.length
  );

  const hasSelection =
    selectedConcessions?.list.length > 0 &&
    selectedConcessions?.list.some((x) => x.quantity >= 1);

  useEffect(() => {
    setFreeConcessionSelected(
      !!(
        selectedConcessions?.list.length > 0 &&
        selectedConcessions?.list.filter((x) => x.cost < 100).length > 0
      )
    );
  }, [selectedConcessions, setFreeConcessionSelected]);

  useEffect(() => {
    const getData = async () => {
      dispatch(actionCreators.setLoading(true));
      const qs = buildQueryString({
        circuitId: config.circuitId,
      });
      const response = await backend.get(
        `api/Member/GetSignUpConcessions${qs}`
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(
          actionCreators.setConcessions(response.content.listConcessionGrouping)
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!availableConcessions) {
      getData();
    }
  }, [availableConcessions, config.circuitId, cookies, dispatch, searchParams]);

  const navigateToNextPath = () => {
    navigate(`/${getRouteFromStep(journeyTypeConfig, step + 1)}`);
  };

  useEffect(() => {
    const loyaltyGroups: ConcessionGrouping[] | null | undefined =
      availableConcessions?.filter((x: ConcessionGrouping) => !!x.items.length);
    loyaltyGroups?.forEach((loyaltyGroup: ConcessionGrouping) => {
      if (!hasSelection) {
        loyaltyGroup.items
          .filter((x) => x.cost < 100)
          .forEach((concession: Concession) => {
            concession.cost = 0;
            dispatch(actionCreators.setLoyaltyTierSelection(concession, 'add'));
          });
      }
    });
  }, [availableConcessions, dispatch, hasSelection]);

  const renderUpgradeItems = () => {
    const loyaltyGroups: ConcessionGrouping[] | null | undefined =
      availableConcessions?.filter((x: ConcessionGrouping) => !!x.items.length);
    const list: ReactElement[] = [];
    loyaltyGroups?.forEach((loyaltyGroup: ConcessionGrouping) => {
      loyaltyGroup.items.forEach((concession: Concession) => {
        list.push(<TierItem {...concession} key={concession.id} />);
      });
    });
    return list;
  };

  if (!content?.loyalty) return null;

  return (
    <div className='loyalty' data-testid='loyalty'>
      <Row className='text-center'>
        <Col xs={12} className='contained'>
          <Heading size={1}>{content.loyalty.signupSelectTierHeading}</Heading>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className='contained mt-3'>
          <Heading size={2} className='text-center'>
            {content.loyalty.signUpSelectionBoxHeading}
          </Heading>
          <RichText
            text={content.loyalty.signupSelectTierInstructionsRichText}
            className='text-center'
          />
        </Col>
      </Row>

      {hasConcessions ?? (
        <Row>
          <Col xs={12} className='contained mt-3'>
            <div className='warning-container mt-3 p-3'>
              <p>{content.loyalty.noUpgradesMessage}</p>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12} className='loyalty-rows-container mt-4'>
          {renderUpgradeItems()}
        </Col>
      </Row>
      <Row>
        <Col md={12} className='mt-4'>
          <ActionButton
            onClick={navigateToNextPath}
            stickyMobileDesktop={false}
            showIcon
            contained
            mx='mx-3'
            variant='primary'
          >
            {freeConcessionSelected
              ? content.loyalty.signupFreeTierSelectedContinueButton
              : content.loyalty.signupPaidTierSelectedContinueButton}
          </ActionButton>
        </Col>
      </Row>
    </div>
  );
};

export default SelectSignupTier;
