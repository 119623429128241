export const WARNING = 'warning';
export const STARTTICKETING = 'startticketing';
export const STARTCONCESSIONS = 'startconcessions';
export const LOGIN = 'login';
export const TICKETS = 'tickets';
export const SEATS = 'seats';
export const PAYMENT = 'payment';
export const KIOSK = 'kiosk';
export const SELECT = 'select';
export const SELECTTIER = 'selecttier';
export const CONFIRMATION = 'confirmation';
export const PHYSICALGIFTCARDS = 'physicalgiftcards';
export const GIFTMEMBERSHIP = 'giftmembership';
export const VOUCHERS = 'vouchers';
export const GIFTCARDS = 'giftcards';
export const CONCESSIONS = 'concessions';
export const LOYALTY = 'loyalty';
export const LANDING = 'landing';
export const SIGNUP = 'signup';
export const UPGRADE = 'upgrade';
export const DETAILS = 'details';
export const REFUND = 'refund';
export const ERROR = 'error';
export const STARTSEASONPASS = 'startseasonpass';
export const SEASONPASS = 'seasonpass';
export const SHOWTIMES = 'showtimes';
