/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import SelectedSeasonPassImage from './SelectedSeasonPassImage';
import SelectedSeasonPassTitleAndLocation from './SelectedSeasonPassTitleAndLocation';

import { SEASON_PASS_STEPS } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import TokenReplacer from '../../../services/TokenReplacer';
import { selectStep } from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';
import TotalWrapper from '../totalwrapper/TotalWrapper';

interface Props {
  pageTitle: string;
  pageText: string;
  continueButtonText: string;
  disableContinueButton: boolean;
  onContinueClicked: () => void;
  children: React.ReactNode;
}

const SelectedSeasonPassStepContainer: React.FC<Props> = ({
  pageTitle,
  pageText,
  continueButtonText,
  disableContinueButton,
  onContinueClicked,
  children,
}) => {
  const { isLargeScreenWidth } = useScreenWidth();

  const step = useSelector(selectStep);

  const isSeatsStep = step === SEASON_PASS_STEPS.SEATS;

  return (
    <>
      <div className='season-pass-showtimes'>
        <ContainedRow classNameCol='text-center'>
          <Heading className='mb-2' size={1}>
            {TokenReplacer(pageTitle)}
          </Heading>
        </ContainedRow>
        <RichText text={TokenReplacer(pageText)} />
      </div>

      {isLargeScreenWidth && isSeatsStep && <TotalWrapper />}

      <div className='season-pass-showtimes'>
        <SelectedSeasonPassImage />

        <SelectedSeasonPassTitleAndLocation />

        <div
          className='season-pass-movie-list w-100 mt-2'
          sx={{
            borderTop: 'boxSeparator',
          }}
        >
          {children}
        </div>
      </div>
      <ActionButton
        onClick={onContinueClicked}
        sticky={true}
        stickyMobileDesktop={true}
        showIcon
        contained
        variant='primary'
        disabled={disableContinueButton}
        mx='mx-3'
        showCartSummaryButtonOnMobile
      >
        {TokenReplacer(continueButtonText)}
      </ActionButton>
    </>
  );
};

export default SelectedSeasonPassStepContainer;
