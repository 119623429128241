import React from 'react';

import { useSelector } from 'react-redux';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  selectConfig,
  selectContent,
  selectSelectedSeasonPassItem,
} from '../../../store/Selectors';
import Heading from '../heading/Heading';

const SelectedSeasonPassTitleAndLocation: React.FC = () => {
  const { isLargeScreenWidth } = useScreenWidth();
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);

  if (isLargeScreenWidth || !selectedSeasonPassItem || !content || !config) {
    return null;
  }

  const { cartSummary: cartSummaryContent } = content;

  const heading = selectedSeasonPassItem.title;

  return (
    <div className='mt-2 text-center'>
      <Heading size={3}>{heading}</Heading>

      <p>
        <b>{cartSummaryContent.atLabel} </b>
        {config.currentCinema.title}
      </p>
    </div>
  );
};

export default SelectedSeasonPassTitleAndLocation;
