import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import GiftCardBreakDown from './GiftCardBreakDown';
import GiftCardDonationNotIncludedMessage from './GiftCardDonationMessage';

import { CheckedGiftCard } from '../../../../@types/modelTypes';
import {
  calculateGiftCardRemainingBalance,
  displayGiftCardAmountChargedInCents,
} from '../../../../services/GiftCardHelpers';
import { displayPrice } from '../../../../services/Helpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectContent,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectGrandTotalWithoutDonationAndRewards,
  selectTandCsAccepted,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';

interface Props {
  amountToPayByBankCard: number;
  checkedGiftCardState: CheckedGiftCard | null;
  isFormValid: boolean;
  giftCardCoversFullPayment: boolean;
  isMaxNumberOfGiftCardsUsed: boolean;
  setGiftCardNumberInputValue: (giftCardNumber: string) => void;
  setCheckedGiftCardState: (giftcard: CheckedGiftCard) => void;
  setIsCheckButtonDisabled: (isDisabled: boolean) => void;
  setPaymentFormVisibility: (isVisible: boolean) => void;
  isPageValidated: boolean;
  handleMakeGiftCardPaymentClick: () => void;
  handleValidatePage: () => void;
}

const GiftCardPaymentForm: React.FC<Props> = ({
  amountToPayByBankCard,
  checkedGiftCardState,
  isFormValid,
  giftCardCoversFullPayment,
  isMaxNumberOfGiftCardsUsed,
  setGiftCardNumberInputValue,
  setCheckedGiftCardState,
  setIsCheckButtonDisabled,
  setPaymentFormVisibility,
  isPageValidated,
  handleMakeGiftCardPaymentClick,
  handleValidatePage,
}) => {
  const dispatch = useDispatch();

  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const content = useSelector(selectContent);
  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const grandTotalWithoutDonationAndRewards = useSelector(
    selectGrandTotalWithoutDonationAndRewards
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const submitButtonText = `${content.payment.submitText} ${displayPrice(
    grandTotal,
    currencyConfig
  )}`;

  useEffect(() => {
    if (giftCardCoversFullPayment) {
      setIsCheckButtonDisabled(true);
      setPaymentFormVisibility(false);
    } else {
      setIsCheckButtonDisabled(isMaxNumberOfGiftCardsUsed);
      setPaymentFormVisibility(true);
    }
  }, [
    amountToPayByBankCard,
    giftCardCoversFullPayment,
    isMaxNumberOfGiftCardsUsed,
    setIsCheckButtonDisabled,
    setPaymentFormVisibility,
  ]);

  const handleApplyGiftCard = () => {
    if (
      !checkedGiftCardState ||
      appliedGiftCards.find(
        (giftCard) =>
          giftCard.giftCardNumber === checkedGiftCardState.giftCardNumber
      )
    )
      return;

    if (!isPageValidated) {
      handleValidatePage();
    }

    dispatch(actionCreators.addGiftCard(checkedGiftCardState));

    setGiftCardNumberInputValue('');

    setCheckedGiftCardState({
      giftCardNumber: '',
      giftCardPin: '',
      giftCardBalanceInCents: 0,
      isChecked: false,
      cardExpiry: '',
    });
  };

  const isPaymentValid = isPageValidated && isFormValid && isTandCsAccepted;

  if (!appliedGiftCards?.length && !checkedGiftCardState?.isChecked)
    return null;

  return (
    <>
      <div className='mt-3' data-testid='gift-card-split-payment'>
        {checkedGiftCardState &&
          checkedGiftCardState.giftCardBalanceInCents > 0 && (
            <>
              {checkedGiftCardState.isExpired ? (
                <p className='text-uppercase'>
                  {content.payment.giftCardExpiredText}{' '}
                  <strong>{checkedGiftCardState.cardExpiry}</strong>
                </p>
              ) : (
                <>
                  <p className='text-uppercase'>
                    {content.payment.giftCardBalanceText}{' '}
                    <strong>
                      {displayPrice(
                        checkedGiftCardState.giftCardBalanceInCents,
                        currencyConfig
                      )}
                    </strong>
                  </p>
                  {checkedGiftCardState.cardExpiry && (
                    <p className='text-uppercase'>
                      {content.payment.giftCardExpirationDateText}{' '}
                      <strong>{checkedGiftCardState.cardExpiry}</strong>
                    </p>
                  )}

                  <GiftCardDonationNotIncludedMessage />

                  <ActionButton
                    onClick={handleApplyGiftCard}
                    mt={'mt-1'}
                    mb={'mb=0'}
                    variant='primary'
                  >
                    {content.payment.giftCardPayButtonText}
                  </ActionButton>
                </>
              )}
            </>
          )}

        {checkedGiftCardState?.isChecked &&
          checkedGiftCardState.giftCardBalanceInCents === 0 && (
            <div className='info-container p-2 text-center'>
              <p>{content.payment.giftCardZeroBalanceText}</p>
            </div>
          )}

        {appliedGiftCards?.length > 0 && (
          <div className='info-container p-2 text-center'>
            {!checkedGiftCardState?.isChecked && (
              <>
                <p>
                  <b>
                    {displayGiftCardAmountChargedInCents(
                      appliedGiftCards,
                      0,
                      grandTotalWithoutDonationAndRewards,
                      currencyConfig
                    )}
                  </b>{' '}
                  {content.payment.giftCardAppliedText}{' '}
                  <b>
                    {displayPrice(
                      appliedGiftCards[appliedGiftCards.length - 1]
                        .giftCardBalanceInCents,
                      currencyConfig
                    )}
                  </b>{' '}
                  {content.payment.giftCardAppliedPartTwoText.replace(
                    '##giftCardNumber##',
                    `${appliedGiftCards[
                      appliedGiftCards.length - 1
                    ].giftCardNumber.slice(-4)}`
                  )}
                </p>

                {giftCardCoversFullPayment && (
                  <p>
                    {content.payment.giftCardRemainingBalanceText}{' '}
                    {displayPrice(
                      calculateGiftCardRemainingBalance(
                        appliedGiftCards,
                        grandTotalWithoutDonationAndRewards
                      ),
                      currencyConfig
                    )}
                  </p>
                )}

                {!giftCardCoversFullPayment && !isMaxNumberOfGiftCardsUsed && (
                  <p className='mt-1'>
                    {content.payment.giftCardPayRemainingBalanceText}
                  </p>
                )}

                {!giftCardCoversFullPayment && isMaxNumberOfGiftCardsUsed && (
                  <p className='mt-1'>
                    {content.payment.giftCardPayRemainingBalanceByCardText}
                  </p>
                )}
              </>
            )}

            <div className='box-separator mt-2'>
              <p className='mt-2'>
                <b>{displayPrice(amountToPayByBankCard, currencyConfig)}</b>{' '}
                {content.payment.giftCardStillToPayLabel}
              </p>
            </div>
          </div>
        )}
      </div>

      <GiftCardBreakDown variant='paymentForm' pricingStrategy='journey' />

      {giftCardCoversFullPayment && (
        <ActionButton
          onClick={handleMakeGiftCardPaymentClick}
          showIcon
          mb={'mb-1'}
          warningMessage={content.payment.formErrorsMessage}
          warningTitle={content.payment.formErrorsSubTitle}
          showWarningMessage={!isPaymentValid}
          variant='primary'
        >
          {submitButtonText}
        </ActionButton>
      )}
    </>
  );
};

export default GiftCardPaymentForm;
