import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import {
  selectContentCartSummary,
  selectDisplayPrice,
} from '../../../store/Selectors';

interface Props {
  bookingFee: number;
  bookingFeeTax: number;
  hideTax: boolean;
  isInsideSubtotal: boolean;
}

const CartSummaryBookingFee: React.FC<Props> = ({
  bookingFee,
  bookingFeeTax,
  hideTax,
  isInsideSubtotal,
}) => {
  const bookingFeeToDisplay = hideTax ? bookingFee : bookingFee - bookingFeeTax;
  const contentCartSummary = useSelector(selectContentCartSummary);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, bookingFeeToDisplay)
  );

  if (bookingFeeToDisplay === 0) return null;

  return (
    <div
      className={classnames(
        !isInsideSubtotal && 'cart-summary-row',
        'cart-summary-row-flex'
      )}
      data-testid='cs-booking-fee'
    >
      <span className='text-start'>
        {contentCartSummary.serviceChargeLabel}
      </span>
      <span className='text-end'>{priceToDisplay}</span>
    </div>
  );
};

export default CartSummaryBookingFee;
