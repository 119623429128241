import React from 'react';

import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { JOURNEY_TYPES } from '../../../constants';
import { useCancelExistingSeasonPassOrder } from '../../../hooks/useCancelExistingSeasonPassOrder';
import {
  selectContent,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import RichText from '../richtext/RichText';

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  navigateToPreviousRoutePath: () => void;
}

const SeasonPassBackToShowtimesWarningModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  navigateToPreviousRoutePath,
}) => {
  const cancelExistingSeasonPassOrder = useCancelExistingSeasonPassOrder();
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const content = useSelector(selectContent);

  if (!content) {
    return null;
  }

  const { seasonPass: seasonPassContent } = content;

  if (
    journeyTypeConfig.type !== JOURNEY_TYPES.SEASON_PASS ||
    !seasonPassContent
  ) {
    return null;
  }

  const {
    backToShowtimesWarningModalTitle: modalTitle,
    backToShowtimesWarningModalRichText: modalMessage,
    backToShowtimesWarningModalCancelButtonText: cancelButtonText,
    backToShowtimesWarningModalAcceptButtonText: acceptButtonText,
  } = seasonPassContent;

  const handleModalOkButton = () => {
    setShowModal(false);
    cancelExistingSeasonPassOrder();
    navigateToPreviousRoutePath();
  };

  return (
    <Modal
      show={showModal}
      centered
      className='layout-modal'
      backdrop='static'
      keyboard={false}
    >
      {modalTitle && (
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
      )}
      {modalMessage && (
        <Modal.Body>
          <RichText text={modalMessage} />
        </Modal.Body>
      )}
      <Modal.Footer>
        <ActionButton
          onClick={handleModalOkButton}
          mb='mb-0'
          mt='mt-0'
          variant='primary'
        >
          {acceptButtonText}
        </ActionButton>

        <ActionButton
          variant='secondary'
          onClick={() => setShowModal(false)}
          mb='mb-0'
          mt='mt-0'
        >
          {cancelButtonText}
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default SeasonPassBackToShowtimesWarningModal;
