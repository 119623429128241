import { getPurchasePayload } from './googleAnalyticsHelpers';

import { TrackingEvent } from '../@types/trackingTypes';
import {
  populateDataLayerInPaymentStep,
  populateDataLayerWithBookingData,
} from '../scripts/dataLayerFunctions';

export class DefaultEventTrackingStrategy {
  processEvent(eventName, state, options) {
    if (state === null) return null;

    switch (eventName) {
      case TrackingEvent.PURCHASE:
        return this.processPurchaseEvent(state, options);
      case TrackingEvent.PAYMENT_LANDING:
        return this.processPaymentEvent(state, options);
      case TrackingEvent.TICKET_CHECKOUT:
        return this.processConfirmationEvent(state);
      default:
        return;
    }
  }

  processPurchaseEvent = (state, options) => {
    const globalState = state;
    const totalTax = options.totalTax;
    const trackingCategory = options.trackingCategory;
    const campaignData = options.campaignData;
    const userId = options.userId;
    const dataLayerName = options.dataLayerName;

    const purchaseProperties = {
      confirmationData: globalState.confirmationData,
      ticketTypes: globalState.ticketTypes,
      cartSummary: globalState.cartSummary,
      config: globalState.config,
      totalTax: totalTax,
      bookingFee: globalState.bookingFee,
      bookingData: globalState.bookingData,
      trackingCategory: trackingCategory,
      content: globalState.content,
      selectedConcessions: globalState.selectedConcessions,
      selectedGiftCards: globalState.selectedGiftCards,
    };

    const purchaseData = getPurchasePayload(purchaseProperties);

    const purchasePayload = {
      ...purchaseData,
      ...userId,
      ...campaignData,
    };

    const populateDataLayer = function () {
      const dataLayerArray = window[dataLayerName];
      if (Array.isArray(dataLayerArray)) {
        dataLayerArray.push(arguments);
      }
    };

    if (window[dataLayerName] && Array.isArray(window[dataLayerName])) {
      populateDataLayer('event', 'purchase', purchasePayload);
    }
  };

  processPaymentEvent = (state, options) => {
    const {
      bookingData,
      config,
      ticketTypes,
      bookingFee,
      donation,
      selectedGratuity,
    } = state;

    const selectedConcessions = state.selectedConcessions?.list;

    const { grandTotal, userAgent } = options;

    const populateData = async () => {
      if (!bookingData || !config) {
        return;
      }
      populateDataLayerInPaymentStep(
        bookingData,
        config,
        grandTotal,
        ticketTypes,
        bookingFee,
        selectedConcessions,
        donation,
        selectedGratuity,
        userAgent
      );
    };
    populateData();
  };

  processConfirmationEvent = (state) => {
    const { ticketTypes, cartSummary, config, customer, confirmationData } =
      state;

    const populateData = async () => {
      if (
        !confirmationData ||
        !customer ||
        !config ||
        !ticketTypes ||
        !cartSummary
      ) {
        return;
      }

      const ticketsQuantity = ticketTypes.ticketTypeModels.reduce(
        (a, b) => a + (b.quantity || 0),
        0
      );

      populateDataLayerWithBookingData(
        confirmationData,
        customer,
        config,
        ticketsQuantity,
        cartSummary.title
      );
    };
    populateData();
  };
}
