import React, { useEffect } from 'react';

import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  ConcessionGrouping,
  Concession,
  Deal,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectQueryString,
  selectAllConcessions,
} from '../../../store/Selectors';
import Heading from '../../common/heading/Heading';
import EGiftCardsWrapper from '../../common/kiosk/EGiftCardWrapper';

const GiftCardSelect: React.FC = () => {
  const dispatch = useDispatch();

  const bookingData = useSelector(selectBookingData);
  const concessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const queryString = useSelector(selectQueryString);

  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/GiftCard/GetEGiftCards/${bookingData.circuitId}/${bookingData.cinemaId}`;
      const response = await backend.get(path);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        const deals = response.content.deals?.filter(
          (x: Deal) => x.isConcessionRelated && !x.isTicketRelated
        );
        if (deals) {
          dispatch(actionCreators.setDeals(deals));
        }
        const groupList: ConcessionGrouping[] =
          response.content.listConcessionGrouping;
        const concessionGrouping: ConcessionGrouping = groupList[0];
        const items: Concession[] = concessionGrouping.items;
        const searchParams = new URLSearchParams(queryString);
        const queryStringId = searchParams.get('id')?.toLowerCase();
        const getIdToCompare = (id: string) =>
          id.substring(id.indexOf('_') + 1);
        if (queryStringId) {
          const selectedConcession =
            items.find(
              (concession) => getIdToCompare(concession.id) === queryStringId
            ) ?? null;
          if (selectedConcession) {
            if (!selectedConcession.isVariablePriceItem) {
              dispatch(
                actionCreators.changeGiftCardById(selectedConcession, 'add')
              );
            }
          }
        }
        dispatch(actionCreators.setConcessions(groupList));
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!concessions && bookingData) {
      getConcessionData();
    }
  }, [concessions, dispatch, bookingData, config.circuitId, queryString]);

  if (!content.giftCards || !config) return null;

  return (
    <div className='giftcards' data-testid='giftcards'>
      <Row className='text-center'>
        <Col md={{ span: 10, offset: 1 }}>
          <Heading size={1}>{content.giftCards.mainTitle}</Heading>
        </Col>
      </Row>

      <Row className='text-center'>
        <Col md={{ span: 10, offset: 1 }}>
          <EGiftCardsWrapper />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardSelect;
