import { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getTicketingStartJourneyRoute } from '../../services/JourneyService';
import {
  selectBookingData,
  selectCartSummary,
  selectQueryString,
} from '../../store/Selectors';

const newJourneyKey = 'forceNewJourney';

export const useForceRestartJourney = () => {
  const { externalCinemaId, externalSessionId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [cookies] = useCookies();

  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const queryString = useSelector(selectQueryString);

  const { isShowtimeChange } = cookies;

  const isValidRouteData =
    bookingData &&
    externalSessionId === bookingData.externalSessionId &&
    externalCinemaId === bookingData.externalCinemaId;

  const isLoyaltyOnlyTicketingWithGuest =
    cartSummary?.isLoyaltyOnlyShowtime &&
    !bookingData?.isUserValidated &&
    !pathname.startsWith('/startticketing') &&
    !pathname.startsWith('/login') &&
    !pathname.startsWith('/confirmation');

  const shouldRemoveNewJourneyKey =
    (isValidRouteData &&
      window.sessionStorage.getItem(newJourneyKey) === '1') ||
    isLoyaltyOnlyTicketingWithGuest;

  const shouldRedirect = !isShowtimeChange && shouldRemoveNewJourneyKey;

  useEffect(() => {
    if (shouldRemoveNewJourneyKey) {
      window.sessionStorage.removeItem(newJourneyKey);
    }

    if (shouldRedirect) {
      navigate({
        pathname: getTicketingStartJourneyRoute(bookingData),
        search: queryString,
      });
    }
  }, [
    bookingData,
    navigate,
    queryString,
    shouldRedirect,
    shouldRemoveNewJourneyKey,
  ]);
};
