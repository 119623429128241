import React from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import { useRecaptcha } from '../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectBankCardAmountDisplayPrice,
  selectContent,
  selectIsCustomerReadyForPayment,
  selectLoyaltyRecognitionNumber,
} from '../../../../../store/Selectors';
import ActionButton from '../../../actionbutton/ActionButton';
import 'moment/locale/en-gb';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
}

const ZeroBankPayment: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const content = useSelector(selectContent);
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );

  const handleMakePayment = async () => {
    if (!executeRecaptcha) return;

    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          loyaltyCardNumber: loyaltyRecognitionNumber,
        },
        executeRecaptcha,
        turnstile,
        recaptcha,
      })
    );
  };

  const isPaymentValid = isCustomerReadyForPayment;

  const handlePaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    if (isPaymentValid) {
      handleMakePayment();
    }
  };

  return (
    <div className='zero-payment' data-testid='zero-payment'>
      <ActionButton
        onClick={handlePaymentClick}
        showIcon
        showWarningMessage={isPageValidated && !isPaymentValid}
        mb='mb-0'
        variant='primary'
      >
        {`${content.payment.submitText} ${priceToDisplay}`}
      </ActionButton>
    </div>
  );
};

export default ZeroBankPayment;
