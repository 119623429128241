/* eslint-disable react/no-find-dom-node */
import React, { useCallback, useEffect, useState } from 'react';

import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { PEACH_CODES } from '../../../constants';
import {
  getConcessionsBasedStartJourneyRoute,
  getStartJourneyRoute,
} from '../../../services/JourneyService';
import {
  shouldRestartJourney,
  shouldTryGoBack,
} from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCountdownExpired,
  selectError,
  selectJourneyTypeConfig,
  selectLoading,
  selectQueryString,
  selectRequestData,
  selectStep,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import RichText from '../richtext/RichText';

const countdownExpiredContext = 9999; // random code, not a peachCode

const ErrorModal: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookies, _removeCookies] = useCookies();
  const { pathname } = useLocation();
  const bookingData = useSelector(selectBookingData);
  const dataToken = useSelector(selectToken);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const countdownExpired = useSelector(selectCountdownExpired);
  const error = useSelector(selectError);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const queryString = useSelector(selectQueryString);
  const step = useSelector(selectStep);
  const loading = useSelector(selectLoading);
  const requestData = useSelector(selectRequestData);
  const [modalBody, setModalBody] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [modalContext, setModalContext] = useState(0);
  const [modalTitle, setModalTitle] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleCountdownExpired = useCallback(() => {
    setModalBody(content.error.countDownRichText);
    setModalButtonText(content.continueButtonText);
    setModalContext(countdownExpiredContext);
    setModalTitle(content.error.title);

    const shouldHideModal =
      step === 0 ||
      pathname.startsWith('/confirmation/') ||
      pathname.startsWith('/refund/') ||
      pathname.startsWith('/error');

    setShowModal(!shouldHideModal);
  }, [content, step, pathname]);

  const setErrorMessage = useCallback(() => {
    if (!error || !content) return;
    if (error.show) {
      setModalBody(error.message);
      setModalButtonText(error.continueButtonText);
      setModalContext(error.peachCode);
      setModalTitle(error.title);
      setShowModal(true);
    }
  }, [content, error]);

  // resets Modal on first step
  useEffect(() => {
    if (step === 0 || step === 1) {
      dispatch(actionCreators.clearError());
      hideModal();
    }
  }, [dispatch, step]);

  // trigger countdown expired
  useEffect(() => {
    if (countdownExpired) {
      handleCountdownExpired();
    }
  }, [countdownExpired, handleCountdownExpired]);

  // showModal on error
  useEffect(() => {
    if (error.show) {
      setErrorMessage();
    }
  }, [error, setErrorMessage]);

  const setCookieAndNavigateToJourneyStart = () => {
    setCookies('requestData', requestData, {
      path: '/',
      expires: moment().add(1, 'm').toDate(),
      secure: true,
      sameSite: true,
    });
    navigate({
      pathname: getStartJourneyRoute(journeyTypeConfig, config, bookingData),
      search: queryString,
    });
  };

  const handleContinueConcessionsCouldNotBeAdded = () => {
    if (journeyTypeConfig.isConcessionsOnlyJourney) {
      dispatch(actionCreators.setLoading(true));
      window.location.href = getConcessionsBasedStartJourneyRoute(
        journeyTypeConfig,
        config
      );
    } else {
      const baseurl = `/payment/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`;
      navigate({
        pathname: baseurl,
        search: queryString,
      });
    }
  };

  const cancelOrderAndRestartJourney = async () => {
    const cancelOrderRequest = {
      dataToken: dataToken,
    };
    dispatch(actionCreators.setLoading(true));
    await backend.post('api/order/cancel', cancelOrderRequest);
    dispatch(actionCreators.setLoading(false));
    setCookieAndNavigateToJourneyStart();
  };

  const handleGoBackOrReturn = () => {
    if (history.length > 1) {
      history.back();
    } else {
      location.href = `${config.currentCinema.cinemaHomeUrl}`;
    }
  };

  const handleModalContinueClick = async () => {
    if (modalContext === countdownExpiredContext) {
      setCookieAndNavigateToJourneyStart();
      dispatch(actionCreators.setCountDownExpired(false));
    } else if (modalContext === PEACH_CODES.concessionsCouldNotBeAdded) {
      handleContinueConcessionsCouldNotBeAdded();
    } else if (shouldRestartJourney(error.peachCode, journeyTypeConfig)) {
      cancelOrderAndRestartJourney();
    } else if (shouldTryGoBack(pathname, modalContext, journeyTypeConfig)) {
      handleGoBackOrReturn();
    }
    dispatch(actionCreators.clearError());
    hideModal();
  };

  const hideModal = async () => {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
    setShowModal(false);
  };

  if (loading) return null;

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      centered
      className='layout-modal'
      size='lg'
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header>
        {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <RichText text={modalBody} />
      </Modal.Body>
      <Modal.Footer>
        <ActionButton
          onClick={handleModalContinueClick}
          showIcon
          contained
          mt='mt-0'
          mb='mb-0'
          variant='primary'
        >
          {modalButtonText ?? content.continueButtonText}{' '}
        </ActionButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
